export const noneOption = 'None of the above';

export const options = [
  {
    title: "Can't feel the baby move",
    id: 'dangerSignA',
  },
  {
    title: 'Bleeding',
    id: 'dangerSignB',
  },
  {
    title: 'Convulsions/fits',
    id: 'dangerSignC',
  },
  {
    title:
      'Severe abdominal pain (possible sign of internal bleeding; ectopic pregnancy)',
    id: 'dangerSignD',
  },
  {
    title: 'Severe headaches and fits (due to high blood pressure)',
    id: 'dangerSignE',
  },
  {
    title: 'Swelling of hands face and feet (due to high blood pressure)',
    id: 'dangerSignF',
  },
  {
    title: 'High fever (possible sign of infection)',
    id: 'dangerSignG',
  },
  {
    title: 'Very pale skin (possible sign of anaemia due to lack of iron)',
    id: 'dangerSignH',
  },
  {
    title: 'Smelly discharge from the vagina or vaginal sores',
    id: 'dangerSignI',
  },
  {
    title: noneOption,
  },
];
