export const weightPerWeek = {
  date: [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
    59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77,
    78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96,
    97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112,
    113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127,
    128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142,
    143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157,
    158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172,
    173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187,
    188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202,
    203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217,
    218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232,
    233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247,
    248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262,
    263, 264,
  ],
  median: {
    label: 'median',
    weight: [
      3.2453714285714286, 3.433471428571429, 3.682357142857143,
      3.949071428571429, 4.2068, 4.448271428571428, 4.6729714285714286,
      4.882214285714285, 5.077528571428572, 5.261157142857144,
      5.4344142857142845, 5.598257142857142, 5.753614285714286,
      5.9014428571428565, 6.042371428571428, 6.176814285714285,
      6.305142857142857, 6.427742857142857, 6.544985714285715,
      6.657114285714285, 6.764385714285715, 6.867128571428572,
      6.965742857142857, 7.060585714285715, 7.151971428571429,
      7.240200000000001, 7.325528571428571, 7.408142857142858, 7.4882,
      7.565914285714285, 7.6414857142857135, 7.715000000000001,
      7.7866285714285715, 7.8565, 7.9246428571428575, 7.991214285714285,
      8.056242857142857, 8.119857142857143, 8.182157142857145,
      8.243185714285714, 8.303085714285713, 8.361942857142859,
      8.419885714285714, 8.476885714285714, 8.533142857142858,
      8.588642857142856, 8.643442857142857, 8.6976, 8.751185714285715, 8.8043,
      8.856928571428572, 8.909142857142857, 8.96097142857143, 9.012442857142856,
      9.063585714285715, 9.114414285714286, 9.165000000000001,
      9.215285714285713, 9.265342857142857, 9.3152, 9.364871428571428,
      9.414357142857142, 9.463700000000001, 9.512857142857143,
      9.561899999999998, 9.610771428571427, 9.659585714285713,
      9.708271428571427, 9.756900000000002, 9.805428571428573,
      9.853885714285713, 9.9023, 9.9506, 9.9989, 10.047128571428573,
      10.095257142857145, 10.143314285714284, 10.191314285714286,
      10.239214285714285, 10.2871, 10.334914285714285, 10.382671428571427,
      10.4304, 10.4781, 10.5257, 10.5733, 10.6209, 10.668499999999998,
      10.716099999999999, 10.7637, 10.811300000000001, 10.8589,
      10.90652857142857, 10.954228571428573, 11.001942857142856,
      11.049685714285713, 11.097471428571428, 11.14527142857143,
      11.193142857142858, 11.241028571428572, 11.288942857142857, 11.3369,
      11.384885714285716, 11.432885714285716, 11.480914285714286,
      11.52897142857143, 11.577014285714286, 11.625057142857145,
      11.673085714285715, 11.72107142857143, 11.769028571428569,
      11.81697142857143, 11.864828571428573, 11.91262857142857,
      11.960314285714286, 12.00792857142857, 12.055442857142856,
      12.102814285714285, 12.15002857142857, 12.197142857142856,
      12.244100000000001, 12.290885714285713, 12.337528571428573,
      12.383999999999999, 12.430300000000003, 12.476471428571429,
      12.522457142857144, 12.56827142857143, 12.613900000000001,
      12.65937142857143, 12.70467142857143, 12.749814285714283,
      12.794785714285714, 12.8396, 12.884242857142857, 12.928771428571428,
      12.973142857142856, 13.0174, 13.061585714285714, 13.105599999999999,
      13.149557142857143, 13.193414285714285, 13.237228571428572,
      13.280914285714287, 13.32457142857143, 13.368157142857143,
      13.411728571428572, 13.455228571428574, 13.498700000000001,
      13.542100000000001, 13.5855, 13.6289, 13.672299999999998,
      13.715657142857141, 13.759000000000002, 13.802314285714285,
      13.845642857142858, 13.88894285714286, 13.932242857142857,
      13.97552857142857, 14.0188, 14.062057142857142, 14.105314285714286,
      14.148542857142857, 14.191742857142858, 14.23492857142857, 14.2781,
      14.321257142857146, 14.364385714285715, 14.407471428571426,
      14.450528571428572, 14.493571428571428, 14.536557142857143,
      14.57952857142857, 14.622457142857144, 14.665342857142857,
      14.708185714285714, 14.751, 14.793785714285713, 14.8365,
      14.879171428571428, 14.921785714285713, 14.964357142857143, 15.0069,
      15.0494, 15.091814285714284, 15.13422857142857, 15.176571428571426,
      15.218885714285713, 15.261128571428571, 15.303357142857141,
      15.34554285714286, 15.387671428571426, 15.429785714285714,
      15.471857142857145, 15.513900000000003, 15.555899999999998,
      15.59782857142857, 15.63977142857143, 15.68167142857143,
      15.723557142857143, 15.7654, 15.807242857142855, 15.849028571428573,
      15.890828571428571, 15.932600000000003, 15.974357142857142,
      16.016099999999998, 16.057814285714283, 16.09954285714286,
      16.141257142857143, 16.18294285714286, 16.22462857142857,
      16.26632857142857, 16.308, 16.34967142857143, 16.39132857142857,
      16.43297142857143, 16.47462857142857, 16.51627142857143,
      16.557928571428572, 16.599528571428575, 16.64115714285714,
      16.68277142857143, 16.724371428571427, 16.765957142857143,
      16.80754285714286, 16.8491, 16.89067142857143, 16.932199999999998,
      16.973714285714287, 17.015214285714286, 17.05667142857143,
      17.09812857142857, 17.139542857142857, 17.180914285714287,
      17.222299999999997, 17.2636, 17.3049, 17.346128571428572,
      17.38734285714286, 17.428500000000003, 17.469614285714282, 17.5107,
      17.551714285714286, 17.59267142857143, 17.633599999999998,
      17.674471428571426, 17.715285714285717, 17.75605714285714,
      17.79677142857143, 17.8374, 17.878000000000004, 17.918557142857143,
      17.959042857142858, 17.999457142857143, 18.039814285714282,
      18.08012857142857, 18.120371428571428, 18.16057142857143, 18.2007,
      18.24075714285714, 18.280771428571427, 18.320699999999995, 18.3606,
    ],
  },
  SD2: {
    label: '2 SD',
    weight: [
      4.29, 4.542142857142857, 4.852142857142857, 5.182142857142857,
      5.499142857142858, 5.795857142857143, 6.071285714285714, 6.328,
      6.567428571428571, 6.793285714285715, 7.006428571428572,
      7.208571428571429, 7.400571428571429, 7.583285714285714,
      7.758428571428572, 7.92542857142857, 8.085428571428572, 8.23842857142857,
      8.385, 8.525428571428572, 8.660571428571428, 8.789714285714286,
      8.914285714285715, 9.034571428571429, 9.150428571428574,
      9.262857142857143, 9.372142857142856, 9.478, 9.581142857142856,
      9.681714285714287, 9.77957142857143, 9.875, 9.968571428571428, 10.06,
      10.14942857142857, 10.237, 10.322714285714286, 10.40685714285714,
      10.489285714285714, 10.570142857142857, 10.649571428571429, 10.728,
      10.805000000000001, 10.880857142857142, 10.955857142857143,
      11.029714285714286, 11.103, 11.175, 11.246571428571428,
      11.316999999999998, 11.387142857142859, 11.456857142857142,
      11.525571428571428, 11.594142857142858, 11.662, 11.72942857142857,
      11.796428571428573, 11.862857142857143, 11.929142857142855, 11.995,
      12.06057142857143, 12.12557142857143, 12.190714285714284,
      12.25542857142857, 12.319571428571427, 12.383857142857142,
      12.447999999999999, 12.511857142857142, 12.575714285714284,
      12.638999999999998, 12.702857142857145, 12.766, 12.828999999999997,
      12.892714285714288, 12.956000000000001, 13.019000000000002,
      13.081999999999997, 13.144999999999998, 13.207285714285714,
      13.269999999999998, 13.333, 13.395857142857142, 13.458, 13.521,
      13.583571428571428, 13.646, 13.709000000000001, 13.77142857142857,
      13.834000000000001, 13.897, 13.959999999999997, 14.022999999999998,
      14.086, 14.149000000000001, 14.212000000000002, 14.275714285714285,
      14.339, 14.40257142857143, 14.466142857142856, 14.529857142857143,
      14.593714285714285, 14.657428571428571, 14.721571428571426,
      14.78557142857143, 14.849857142857141, 14.914, 14.978428571428571,
      15.042714285714286, 15.107285714285718, 15.171571428571427,
      15.235857142857144, 15.300428571428572, 15.364999999999998,
      15.429285714285713, 15.493571428571428, 15.558142857142856,
      15.62257142857143, 15.686714285714285, 15.750714285714286, 15.815,
      15.879000000000001, 15.942714285714285, 16.006285714285713, 16.07,
      16.13357142857143, 16.197000000000003, 16.259999999999998, 16.323, 16.386,
      16.449, 16.512, 16.574428571428573, 16.637, 16.699285714285715,
      16.761714285714284, 16.823857142857143, 16.88585714285714, 16.948,
      17.010142857142856, 17.072000000000003, 17.134, 17.196142857142856,
      17.258142857142854, 17.319857142857142, 17.382, 17.444000000000003,
      17.50614285714286, 17.568, 17.630571428571425, 17.692714285714285, 17.755,
      17.817714285714285, 17.88, 17.943, 18.005142857142854, 18.068, 18.131,
      18.194000000000003, 18.257, 18.32057142857143, 18.383999999999997,
      18.447285714285716, 18.511, 18.574571428571428, 18.63828571428572,
      18.702285714285715, 18.766, 18.830000000000002, 18.894142857142857,
      18.958428571428573, 19.02257142857143, 19.087, 19.15142857142857,
      19.215714285714284, 19.28042857142857, 19.345142857142857,
      19.40957142857143, 19.474285714285717, 19.539142857142856,
      19.60357142857143, 19.66857142857143, 19.73342857142857,
      19.798142857142857, 19.863142857142858, 19.92785714285714,
      19.993142857142857, 20.057857142857138, 20.122714285714288,
      20.187857142857144, 20.252714285714287, 20.317857142857143,
      20.382714285714286, 20.447857142857146, 20.51314285714286, 20.578,
      20.643142857142855, 20.708142857142857, 20.773285714285713,
      20.838285714285714, 20.90342857142857, 20.968714285714288,
      21.03357142857143, 21.098857142857145, 21.163999999999998,
      21.229142857142854, 21.294428571428572, 21.35957142857143,
      21.424714285714284, 21.489857142857144, 21.55542857142857,
      21.620571428571427, 21.686000000000003, 21.75114285714286,
      21.816714285714287, 21.88214285714286, 21.947285714285716,
      22.012857142857143, 22.078285714285716, 22.143857142857144,
      22.20957142857143, 22.275000000000002, 22.34042857142857,
      22.40628571428572, 22.471857142857143, 22.537285714285712,
      22.602999999999998, 22.66857142857143, 22.73414285714286,
      22.799857142857142, 22.865571428571428, 22.931142857142856,
      22.996857142857145, 23.062142857142856, 23.128000000000004,
      23.19357142857143, 23.259142857142855, 23.32471428571429,
      23.390285714285714, 23.45585714285715, 23.521142857142856,
      23.58671428571429, 23.652142857142856, 23.717571428571432,
      23.783000000000005, 23.848285714285716, 23.91342857142857,
      23.978714285714286, 24.044, 24.109, 24.174285714285713,
      24.239285714285717, 24.304142857142857, 24.369285714285713,
      24.433999999999997, 24.498857142857137, 24.563571428571432,
      24.628428571428575, 24.693, 24.757571428571428, 24.822285714285716,
      24.886571428571433, 24.950714285714287, 25.01557142857143,
      25.079857142857144, 25.14414285714286,
    ],
  },
  SD3: {
    label: '3 SD',
    weight: [
      4.890714285714286, 5.187857142857142, 5.536428571428572,
      5.905714285714285, 6.260142857142857, 6.590857142857142,
      6.897714285714286, 7.183857142857143, 7.450857142857143,
      7.703142857142857, 7.941714285714285, 8.168142857142858,
      8.383714285714285, 8.589428571428572, 8.786, 8.974428571428572,
      9.154857142857143, 9.327857142857143, 9.493857142857141,
      9.653428571428574, 9.806428571428572, 9.953571428571431,
      10.095857142857142, 10.232714285714286, 10.36542857142857,
      10.494714285714284, 10.619857142857143, 10.741714285714286,
      10.86057142857143, 10.976714285714285, 11.09, 11.200999999999999,
      11.309571428571429, 11.415999999999999, 11.520285714285714,
      11.622428571428571, 11.722714285714286, 11.821, 11.918000000000001,
      12.012714285714285, 12.106142857142858, 12.197999999999999,
      12.288714285714287, 12.378142857142857, 12.46642857142857,
      12.553428571428572, 12.639285714285714, 12.724285714285713,
      12.80842857142857, 12.891714285714285, 12.974000000000002,
      13.055999999999997, 13.136714285714286, 13.217428571428572,
      13.297142857142857, 13.376142857142856, 13.454571428571429, 13.533,
      13.610142857142858, 13.687571428571431, 13.764, 13.840571428571428,
      13.91642857142857, 13.992, 14.067142857142857, 14.142, 14.21685714285714,
      14.291142857142855, 14.36557142857143, 14.439571428571428,
      14.51357142857143, 14.587428571428571, 14.661, 14.73457142857143,
      14.808285714285716, 14.88157142857143, 14.954857142857142,
      15.028142857142857, 15.101142857142857, 15.174142857142858,
      15.247428571428571, 15.320142857142857, 15.393142857142859,
      15.466142857142856, 15.539142857142858, 15.611857142857144, 15.685,
      15.758142857142856, 15.831142857142856, 15.904571428571428,
      15.977571428571427, 16.05142857142857, 16.124714285714287,
      16.198571428571427, 16.272714285714283, 16.34657142857143,
      16.420571428571428, 16.494999999999997, 16.569714285714287,
      16.644142857142857, 16.718714285714288, 16.79342857142857,
      16.86857142857143, 16.943714285714286, 17.019142857142857,
      17.094428571428573, 17.169857142857143, 17.24542857142857,
      17.321142857142856, 17.396714285714285, 17.472285714285714,
      17.548285714285715, 17.624285714285715, 17.7, 17.776, 17.851714285714287,
      17.928, 18.003714285714288, 18.079714285714285, 18.155285714285714,
      18.231, 18.306714285714285, 18.38228571428571, 18.45757142857143,
      18.532857142857146, 18.60842857142857, 18.683714285714284,
      18.758857142857146, 18.833714285714287, 18.908714285714286,
      18.983571428571427, 19.05857142857143, 19.133142857142854,
      19.20785714285714, 19.28257142857143, 19.357428571428574,
      19.431571428571427, 19.50614285714286, 19.580714285714286,
      19.655571428571424, 19.73057142857143, 19.80485714285714, 19.88,
      19.954714285714285, 20.029714285714284, 20.104714285714287,
      20.180285714285713, 20.255285714285716, 20.331142857142858,
      20.406714285714283, 20.482142857142858, 20.55857142857143,
      20.63457142857143, 20.711000000000002, 20.78757142857143,
      20.864285714285717, 20.941142857142857, 21.01842857142857, 21.096,
      21.173571428571428, 21.251285714285718, 21.329428571428572,
      21.40757142857143, 21.485857142857146, 21.56457142857143,
      21.643285714285717, 21.722285714285714, 21.801571428571428,
      21.880857142857142, 21.960428571428572, 22.040142857142857, 22.12, 22.2,
      22.280142857142856, 22.360571428571433, 22.441, 22.52157142857143,
      22.602285714285717, 22.683285714285713, 22.764142857142854, 22.845,
      22.92628571428571, 23.007714285714286, 23.089142857142857,
      23.170571428571428, 23.252142857142854, 23.333714285714283,
      23.415571428571422, 23.497428571428575, 23.579142857142863,
      23.661285714285714, 23.743, 23.825428571428574, 23.90742857142857,
      23.990000000000002, 24.072142857142858, 24.154285714285713,
      24.23671428571428, 24.31942857142857, 24.402000000000005,
      24.484571428571428, 24.567000000000004, 24.649571428571427, 24.732,
      24.815285714285714, 24.897857142857138, 24.980999999999998,
      25.063714285714287, 25.14671428571428, 25.229999999999997,
      25.313142857142857, 25.395999999999997, 25.479571428571433,
      25.562857142857144, 25.646571428571427, 25.729999999999997,
      25.81357142857143, 25.896857142857137, 25.98085714285714,
      26.06442857142857, 26.148285714285713, 26.232, 26.315857142857144,
      26.400000000000002, 26.48357142857143, 26.568, 26.651571428571426,
      26.735999999999997, 26.819999999999997, 26.904, 26.988142857142858,
      27.072285714285716, 27.156571428571432, 27.24085714285714,
      27.324999999999996, 27.409142857142854, 27.49342857142857,
      27.577571428571428, 27.662000000000003, 27.746000000000002,
      27.830142857142857, 27.91471428571428, 27.999, 28.083000000000002,
      28.16728571428572, 28.251, 28.335142857142863, 28.41942857142857,
      28.503428571428568, 28.587428571428568, 28.671285714285712,
      28.755142857142857, 28.839285714285715, 28.92314285714286,
      29.007000000000005, 29.090714285714288, 29.17442857142857,
      29.25814285714286, 29.341857142857148, 29.425571428571427,
      29.50914285714286, 29.592571428571425, 29.676142857142853,
      29.75957142857143, 29.843142857142855,
    ],
  },
  SD3neg: {
    label: '-3 SD',
    weight: [
      2.0295714285714284, 2.166857142857143, 2.3535714285714286,
      2.553142857142857, 2.7467142857142854, 2.9292857142857143,
      3.099999999999999, 3.258714285714286, 3.407428571428572,
      3.547285714285714, 3.6792857142857147, 3.803714285714286,
      3.921714285714286, 4.034, 4.140857142857143, 4.242714285714286,
      4.339714285714286, 4.432714285714286, 4.521142857142857,
      4.605714285714286, 4.686428571428571, 4.763714285714286, 4.838,
      4.908857142857143, 4.977142857142858, 5.042714285714285, 5.106,
      5.167285714285714, 5.226285714285714, 5.283571428571428,
      5.338714285714286, 5.39242857142857, 5.444714285714285,
      5.4951428571428576, 5.544428571428571, 5.592571428571429,
      5.639142857142857, 5.684714285714286, 5.729571428571428,
      5.773142857142858, 5.816000000000001, 5.858, 5.899000000000001,
      5.939571428571428, 5.9794285714285715, 6.018857142857144,
      6.057714285714285, 6.096285714285714, 6.134428571428572,
      6.1720000000000015, 6.2092857142857145, 6.246714285714285,
      6.283428571428571, 6.320142857142857, 6.356571428571428,
      6.392714285714286, 6.428857142857143, 6.464857142857143,
      6.500571428571429, 6.5360000000000005, 6.572000000000001,
      6.606999999999999, 6.642857142857143, 6.677999999999999,
      6.713000000000001, 6.748, 6.783, 6.818, 6.853, 6.888, 6.922857142857142,
      6.957000000000001, 6.992, 7.026714285714285, 7.061, 7.095428571428572,
      7.13, 7.164, 7.198428571428572, 7.232285714285714, 7.2664285714285715,
      7.300571428571429, 7.3342857142857145, 7.368285714285714,
      7.401857142857144, 7.435714285714286, 7.469285714285715,
      7.502714285714286, 7.536428571428572, 7.569857142857144,
      7.603428571428571, 7.636571428571429, 7.669857142857142,
      7.703428571428573, 7.736571428571429, 7.769857142857143,
      7.803142857142858, 7.836428571428572, 7.869428571428571,
      7.902714285714285, 7.936000000000001, 7.969, 8.002, 8.035285714285715,
      8.068285714285715, 8.101142857142857, 8.134285714285713, 8.167,
      8.200000000000001, 8.232714285714286, 8.265285714285714, 8.298,
      8.330428571428572, 8.363000000000001, 8.395142857142856,
      8.427428571428573, 8.459285714285715, 8.491142857142858,
      8.522714285714287, 8.554285714285713, 8.585714285714287,
      8.616857142857144, 8.648, 8.678857142857144, 8.709428571428571, 8.74,
      8.770285714285714, 8.800571428571429, 8.830285714285713, 8.86,
      8.88957142857143, 8.918857142857142, 8.948142857142857, 8.977285714285715,
      9.006, 9.034714285714287, 9.063, 9.091714285714286, 9.12, 9.148, 9.176,
      9.203857142857142, 9.230999999999998, 9.259, 9.286285714285714,
      9.313857142857144, 9.340999999999998, 9.368142857142857,
      9.395142857142858, 9.422, 9.449, 9.475714285714286, 9.502571428571429,
      9.529285714285715, 9.555571428571428, 9.582285714285714,
      9.608428571428572, 9.634857142857143, 9.661142857142858,
      9.687285714285716, 9.71342857142857, 9.739428571428572, 9.765285714285714,
      9.791, 9.816857142857142, 9.842428571428572, 9.868, 9.893714285714285,
      9.918999999999999, 9.944428571428572, 9.96942857142857, 9.994714285714284,
      10.019714285714285, 10.044714285714287, 10.069571428571427,
      10.094285714285714, 10.119142857142856, 10.143714285714285,
      10.168285714285714, 10.192714285714287, 10.217285714285714,
      10.241428571428571, 10.265714285714285, 10.289857142857143,
      10.313999999999998, 10.338, 10.361857142857144, 10.385714285714284,
      10.409571428571427, 10.433285714285715, 10.456857142857144,
      10.480571428571428, 10.504142857142854, 10.527571428571429, 10.551,
      10.574285714285713, 10.597857142857142, 10.621142857142859,
      10.644428571428568, 10.66757142857143, 10.690714285714288,
      10.713857142857142, 10.737142857142858, 10.760142857142855,
      10.783285714285714, 10.806285714285716, 10.829285714285716,
      10.852428571428572, 10.875285714285715, 10.898285714285715,
      10.921285714285714, 10.944285714285716, 10.967142857142857, 10.99,
      11.012857142857142, 11.035857142857143, 11.058571428571428,
      11.081428571428571, 11.104000000000001, 11.127142857142859,
      11.149857142857142, 11.17257142857143, 11.19542857142857,
      11.218142857142857, 11.241000000000001, 11.263571428571426,
      11.286428571428571, 11.309142857142858, 11.331857142857142,
      11.35457142857143, 11.377285714285716, 11.400142857142855,
      11.42257142857143, 11.445142857142857, 11.467714285714285,
      11.49042857142857, 11.513285714285713, 11.535428571428572,
      11.557857142857141, 11.58057142857143, 11.602857142857143,
      11.625428571428571, 11.647571428571426, 11.670142857142858,
      11.692285714285715, 11.71457142857143, 11.736714285714285,
      11.759000000000002, 11.781285714285714, 11.803285714285716,
      11.82542857142857, 11.84742857142857, 11.869428571428571,
      11.891142857142857, 11.913142857142857, 11.935, 11.956999999999999,
      11.978571428571428, 12.000285714285715, 12.022000000000002,
      12.043714285714286, 12.065142857142858, 12.086857142857145,
      12.108000000000002, 12.129857142857142,
    ],
  },
  SD2neg: {
    label: '-2 SD',
    weight: [
      2.391714285714286, 2.5414285714285714, 2.745714285714286,
      2.964857142857143, 3.1771428571428575, 3.377, 3.5629999999999997,
      3.7367142857142857, 3.8990000000000005, 4.051428571428572,
      4.194999999999999, 4.330857142857143, 4.459857142857143,
      4.582000000000001, 4.698714285714286, 4.809714285714286,
      4.915571428571428, 5.0167142857142855, 5.113285714285714,
      5.2057142857142855, 5.293857142857143, 5.378142857142858,
      5.4590000000000005, 5.536714285714285, 5.611142857142858, 5.683,
      5.752571428571429, 5.819571428571429, 5.884, 5.946857142857143,
      6.007714285714285, 6.066857142857143, 6.124, 6.18, 6.234285714285713,
      6.287285714285715, 6.3389999999999995, 6.389428571428572, 6.439,
      6.486857142857142, 6.534285714285715, 6.580857142857142,
      6.626428571428572, 6.671285714285713, 6.715714285714286,
      6.759428571428571, 6.802571428571428, 6.845, 6.887142857142857,
      6.929000000000001, 6.970857142857143, 7.0120000000000005,
      7.052714285714286, 7.093428571428572, 7.133714285714285,
      7.173857142857143, 7.214, 7.2537142857142864, 7.293285714285715,
      7.332857142857144, 7.372142857142857, 7.411428571428572,
      7.450571428571428, 7.489571428571429, 7.528571428571429,
      7.567285714285715, 7.606285714285713, 7.644714285714286,
      7.683571428571429, 7.7222857142857135, 7.760714285714285,
      7.799285714285715, 7.83742857142857, 7.875714285714286, 7.914285714285713,
      7.952285714285714, 7.990428571428572, 8.028428571428572,
      8.066428571428572, 8.104285714285714, 8.142, 8.180000000000001,
      8.217571428571429, 8.255142857142856, 8.292714285714284,
      8.330142857142858, 8.367714285714285, 8.405142857142858,
      8.442714285714287, 8.48, 8.517285714285714, 8.554714285714285,
      8.591857142857142, 8.629142857142856, 8.666285714285713,
      8.703714285714286, 8.740857142857143, 8.778142857142857,
      8.815285714285713, 8.852714285714287, 8.889714285714286,
      8.927000000000001, 8.964142857142859, 9.001285714285714,
      9.038714285714287, 9.075714285714286, 9.112857142857143,
      9.149714285714285, 9.186857142857145, 9.223857142857144,
      9.260571428571428, 9.297571428571429, 9.334142857142856, 9.371,
      9.40742857142857, 9.443714285714284, 9.48, 9.516142857142857,
      9.552000000000001, 9.588, 9.623428571428573, 9.659, 9.694, 9.729, 9.764,
      9.798999999999998, 9.833571428571428, 9.867999999999999, 9.902,
      9.935999999999998, 9.969857142857142, 10.00357142857143, 10.037,
      10.07042857142857, 10.103571428571428, 10.136285714285714,
      10.168999999999999, 10.201714285714285, 10.234285714285715,
      10.266714285714286, 10.299000000000001, 10.331142857142856,
      10.363142857142858, 10.395142857142858, 10.426857142857143,
      10.458571428571428, 10.490285714285715, 10.521714285714285,
      10.553285714285712, 10.584571428571428, 10.615857142857141, 10.647,
      10.678285714285712, 10.709428571428571, 10.740285714285715,
      10.771285714285714, 10.802000000000001, 10.833, 10.863714285714286,
      10.894428571428572, 10.925142857142859, 10.955714285714285, 10.986,
      11.016571428571428, 11.046857142857144, 11.077142857142858,
      11.107428571428573, 11.13757142857143, 11.16757142857143,
      11.19757142857143, 11.227428571428572, 11.257285714285715, 11.287,
      11.316857142857144, 11.346428571428572, 11.376, 11.405285714285712,
      11.434714285714287, 11.464, 11.493142857142857, 11.522428571428572,
      11.551571428571426, 11.58057142857143, 11.609571428571428,
      11.638428571428571, 11.667000000000002, 11.695999999999998,
      11.724285714285713, 11.753, 11.781714285714285, 11.81, 11.838142857142856,
      11.867, 11.895, 11.92314285714286, 11.951714285714287, 11.98, 12.008,
      12.036000000000001, 12.064000000000002, 12.091999999999999,
      12.120000000000001, 12.148, 12.176000000000002, 12.203999999999997,
      12.23142857142857, 12.259, 12.287000000000003, 12.315, 12.342857142857143,
      12.370142857142858, 12.398, 12.426000000000002, 12.453714285714286,
      12.480999999999998, 12.509, 12.536857142857144, 12.564000000000002,
      12.591999999999999, 12.61957142857143, 12.647000000000002,
      12.674857142857144, 12.701999999999998, 12.73, 12.757428571428573, 12.785,
      12.81242857142857, 12.84, 12.867714285714285, 12.895, 12.92242857142857,
      12.95, 12.977428571428572, 13.004999999999999, 13.032, 13.059571428571429,
      13.086857142857141, 13.114142857142857, 13.141428571428573,
      13.168714285714287, 13.19585714285714, 13.222999999999999, 13.25,
      13.277142857142858, 13.304285714285715, 13.331285714285714,
      13.358142857142857, 13.385142857142858, 13.411857142857144,
      13.438857142857144, 13.465428571428571, 13.492428571428572,
      13.518714285714285, 13.54557142857143, 13.57242857142857,
      13.598571428571429, 13.625142857142857, 13.65157142857143,
      13.678142857142856, 13.70442857142857, 13.730571428571428,
      13.756857142857143, 13.783000000000001, 13.809142857142856,
      13.83514285714286,
    ],
  },
};
