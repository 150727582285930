export const weightPerYear = {
  date: [0, 1, 2, 3, 4],
  median: {
    label: 'median',
    weight: [3.3174, 9.646, 12.1482, 14.3387, 16.3435],
  },
  SD2: {
    label: '2 SD',
    weight: [4.394, 11.983, 15.272, 18.307, 21.173],
  },
  SD3: {
    label: '3 SD',
    weight: [5.013, 13.341, 17.128, 20.719, 24.169],
  },
  SD3neg: {
    label: '-3 SD',
    weight: [2.065, 6.926, 8.63, 10.014, 11.236],
  },
  SD2neg: {
    label: '-2 SD',
    weight: [2.437, 7.741, 9.67, 11.276, 12.709],
  },
};
