export const weightPerWeek = {
  date: [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
    59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77,
    78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96,
    97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112,
    113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127,
    128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142,
    143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157,
    158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172,
    173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187,
    188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202,
    203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217,
    218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232,
    233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247,
    248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262,
    263, 264,
  ],
  median: {
    label: 'median',
    weight: [
      3.375971428571429, 3.596571428571429, 3.883485714285714,
      4.192614285714285, 4.493800000000001, 4.776957142857143, 5.0396,
      5.283042857142858, 5.509099999999999, 5.719900000000001, 5.9169,
      6.1013142857142855, 6.274342857142857, 6.437242857142857,
      6.591028571428572, 6.736685714285714, 6.874999999999999,
      7.006671428571429, 7.132285714285715, 7.252300000000001, 7.3671,
      7.476999999999999, 7.582257142857142, 7.683242857142858,
      7.780385714285714, 7.8739285714285705, 7.9642, 8.051371428571427,
      8.135628571428573, 8.217157142857143, 8.29617142857143, 8.372842857142857,
      8.4473, 8.519714285714286, 8.590271428571427, 8.659114285714285,
      8.726342857142857, 8.792142857142856, 8.856614285714286,
      8.919814285714285, 8.981885714285715, 9.042842857142858,
      9.102771428571428, 9.161742857142857, 9.2199, 9.277242857142856,
      9.333842857142857, 9.3898, 9.445142857142859, 9.499914285714286,
      9.554142857142859, 9.607857142857142, 9.661114285714286,
      9.713899999999999, 9.766285714285715, 9.818257142857144,
      9.869828571428572, 9.921085714285713, 9.971971428571427,
      10.022585714285714, 10.0729, 10.122942857142856, 10.1728,
      10.222342857142857, 10.271714285714287, 10.320885714285714,
      10.369885714285713, 10.418714285714286, 10.467371428571427,
      10.51587142857143, 10.564257142857143, 10.612457142857144,
      10.660514285714287, 10.70842857142857, 10.756185714285715,
      10.803871428571428, 10.85137142857143, 10.898771428571427,
      10.946071428571429, 10.993228571428572, 11.040314285714286,
      11.087299999999999, 11.134200000000002, 11.181014285714285,
      11.22777142857143, 11.274500000000002, 11.32117142857143,
      11.367814285714287, 11.414428571428571, 11.46097142857143,
      11.507514285714285, 11.554, 11.600471428571428, 11.646885714285714,
      11.693257142857144, 11.739614285714286, 11.7859, 11.8322, 11.8784,
      11.9246, 11.970742857142856, 12.016842857142857, 12.062857142857144,
      12.108828571428573, 12.154757142857145, 12.200614285714286,
      12.24637142857143, 12.292042857142858, 12.3376, 12.383042857142856,
      12.428357142857141, 12.473528571428574, 12.518557142857144, 12.5634,
      12.608157142857143, 12.652714285714286, 12.6971, 12.741299999999999,
      12.78535714285714, 12.829214285714286, 12.872871428571427, 12.9164,
      12.959657142857141, 13.002771428571432, 13.04567142857143,
      13.088400000000002, 13.130942857142859, 13.1733, 13.215471428571428,
      13.257457142857145, 13.299257142857142, 13.3409, 13.382371428571428,
      13.423671428571428, 13.4648, 13.505799999999999, 13.546614285714286,
      13.587299999999999, 13.62782857142857, 13.668214285714285,
      13.708457142857142, 13.748571428571427, 13.7886, 13.828457142857143,
      13.868214285714286, 13.907871428571429, 13.94742857142857,
      13.986899999999997, 14.026271428571425, 14.065600000000002, 14.1048,
      14.143957142857143, 14.183042857142855, 14.22205714285714,
      14.260999999999997, 14.299914285714285, 14.338742857142858,
      14.377542857142856, 14.416300000000001, 14.454999999999998,
      14.493657142857144, 14.532314285714287, 14.570914285714286, 14.6095,
      14.648085714285713, 14.686614285714285, 14.7252, 14.7637, 14.8022,
      14.8407, 14.8792, 14.9177, 14.9562, 14.9947, 15.033199999999999,
      15.071700000000002, 15.1102, 15.148671428571427, 15.1871,
      15.225599999999998, 15.264100000000001, 15.30257142857143, 15.341,
      15.379500000000002, 15.417942857142858, 15.4564, 15.494842857142856,
      15.5333, 15.571700000000002, 15.610142857142858, 15.648557142857141,
      15.686957142857143, 15.725342857142858, 15.763728571428572,
      15.802071428571429, 15.840442857142857, 15.878757142857143,
      15.917085714285713, 15.955414285714284, 15.993714285714285,
      16.032014285714286, 16.0703, 16.10857142857143, 16.14682857142857,
      16.1851, 16.223328571428574, 16.261557142857143, 16.299799999999998,
      16.338, 16.376214285714287, 16.414428571428573, 16.45262857142857,
      16.490828571428573, 16.529014285714286, 16.567214285714286, 16.6054,
      16.643571428571427, 16.68177142857143, 16.71995714285714,
      16.75812857142857, 16.79632857142857, 16.8345, 16.872685714285716,
      16.91087142857143, 16.94907142857143, 16.987257142857143,
      17.025457142857142, 17.063642857142856, 17.101828571428573,
      17.140028571428573, 17.178214285714287, 17.216385714285714,
      17.254571428571428, 17.292742857142855, 17.330928571428572,
      17.369071428571427, 17.40722857142857, 17.44537142857143,
      17.48352857142857, 17.52162857142857, 17.55975714285714,
      17.59785714285714, 17.63595714285714, 17.674042857142858, 17.7121,
      17.750171428571427, 17.7882, 17.826228571428572, 17.864242857142855,
      17.90224285714286, 17.94024285714286, 17.9782, 18.016157142857143,
      18.05407142857143, 18.092, 18.1299, 18.167785714285717, 18.2056,
      18.243485714285715, 18.2813, 18.319042857142854, 18.3568,
      18.39452857142857, 18.43222857142857, 18.4699,
    ],
  },
  SD2: {
    label: '2 SD',
    weight: [
      4.467571428571428, 4.740714285714286, 5.089571428571429,
      5.463714285714285, 5.825714285714286, 6.163142857142857, 6.47357142857143,
      6.759285714285713, 7.022857142857143, 7.267571428571429,
      7.495285714285714, 7.708000000000001, 7.906999999999999, 8.09442857142857,
      8.27142857142857, 8.439285714285715, 8.599428571428572, 8.751714285714286,
      8.897999999999998, 9.038142857142857, 9.172857142857142,
      9.302714285714286, 9.427285714285714, 9.547714285714287,
      9.663857142857141, 9.776285714285715, 9.885285714285713,
      9.990857142857143, 10.093714285714286, 10.193, 10.290000000000001,
      10.384428571428572, 10.476142857142857, 10.566, 10.653428571428568,
      10.739, 10.822857142857144, 10.905, 10.98557142857143, 11.064714285714286,
      11.142999999999999, 11.219571428571427, 11.295, 11.369285714285713,
      11.442714285714287, 11.515142857142857, 11.586714285714288,
      11.658000000000001, 11.727999999999998, 11.797571428571429,
      11.86657142857143, 11.934714285714287, 12.002714285714287,
      12.070000000000002, 12.136857142857142, 12.203428571428573,
      12.269428571428572, 12.334999999999999, 12.400428571428568,
      12.465428571428571, 12.53014285714286, 12.594571428571427,
      12.658857142857142, 12.722714285714286, 12.786428571428571,
      12.849999999999998, 12.913714285714287, 12.977000000000002,
      13.040000000000001, 13.102999999999998, 13.165999999999999, 13.229,
      13.291571428571428, 13.354, 13.416571428571428, 13.479,
      13.541142857142857, 13.60342857142857, 13.665714285714285,
      13.727857142857143, 13.789714285714286, 13.851714285714285,
      13.913857142857143, 13.975714285714286, 14.037714285714284,
      14.099714285714287, 14.161714285714286, 14.223857142857144,
      14.285714285714286, 14.34785714285714, 14.409857142857144,
      14.472142857142856, 14.534142857142857, 14.596285714285715,
      14.658714285714286, 14.721000000000002, 14.783000000000001,
      14.84542857142857, 14.90742857142857, 14.969714285714286,
      15.031999999999998, 15.094428571428569, 15.156714285714283,
      15.218999999999998, 15.280999999999997, 15.343428571428573,
      15.405714285714286, 15.467714285714285, 15.529857142857143,
      15.591714285714286, 15.65357142857143, 15.715428571428573, 15.777,
      15.838142857142858, 15.899571428571429, 15.960571428571427,
      16.021714285714285, 16.082428571428572, 16.142857142857142,
      16.203142857142858, 16.26342857142857, 16.323285714285714,
      16.383142857142854, 16.44242857142857, 16.501714285714282, 16.561,
      16.619714285714288, 16.678285714285714, 16.736857142857144,
      16.794857142857143, 16.853, 16.910857142857143, 16.96842857142857,
      17.02585714285714, 17.083142857142857, 17.140285714285714, 17.197,
      17.253999999999998, 17.31057142857143, 17.366999999999997, 17.423, 17.479,
      17.535, 17.591, 17.64685714285714, 17.702142857142857, 17.758,
      17.813142857142857, 17.868285714285715, 17.923285714285715,
      17.978571428571428, 18.033571428571427, 18.088428571428572,
      18.14314285714286, 18.19814285714286, 18.25257142857143,
      18.30742857142857, 18.36171428571428, 18.416571428571434,
      18.47085714285714, 18.52528571428571, 18.579857142857144,
      18.634285714285713, 18.68857142857143, 18.743, 18.797428571428576,
      18.851714285714284, 18.906142857142857, 18.96057142857143,
      19.015000000000004, 19.06942857142857, 19.123714285714282,
      19.178285714285717, 19.232714285714287, 19.287285714285712,
      19.34171428571429, 19.39657142857143, 19.450857142857142,
      19.505571428571432, 19.560285714285715, 19.615000000000002,
      19.66957142857143, 19.72457142857143, 19.779571428571433,
      19.834285714285716, 19.88914285714286, 19.944142857142854,
      19.999285714285715, 20.054142857142857, 20.10942857142857,
      20.16428571428571, 20.21957142857143, 20.275, 20.330000000000002,
      20.385428571428573, 20.441, 20.49614285714286, 20.552, 20.607,
      20.663000000000004, 20.717999999999996, 20.774, 20.829857142857147,
      20.88585714285714, 20.941, 20.996999999999996, 21.053, 21.109, 21.165,
      21.221, 21.276999999999997, 21.333000000000002, 21.389000000000003,
      21.44557142857143, 21.502000000000002, 21.558, 21.614857142857144,
      21.671285714285712, 21.728, 21.784142857142857, 21.841142857142852,
      21.897999999999996, 21.954285714285714, 22.011571428571425, 22.068,
      22.125285714285717, 22.181999999999995, 22.239285714285717, 22.296,
      22.35342857142857, 22.41042857142857, 22.467714285714287,
      22.524714285714285, 22.582285714285714, 22.639571428571426,
      22.696714285714286, 22.754428571428573, 22.811571428571426,
      22.869285714285716, 22.926714285714286, 22.98428571428571,
      23.041714285714285, 23.099285714285713, 23.157, 23.21457142857143,
      23.272142857142857, 23.32957142857143, 23.387428571428575,
      23.444999999999997, 23.502571428571425, 23.560428571428577,
      23.618285714285715, 23.676, 23.73371428571429, 23.791285714285713,
      23.849285714285713, 23.907142857142855, 23.964714285714287,
      24.02257142857143, 24.08042857142857, 24.13814285714286,
      24.19571428571428, 24.25357142857143, 24.31142857142857,
      24.369285714285716,
    ],
  },
  SD3: {
    label: '3 SD',
    weight: [
      5.096285714285714, 5.4032857142857145, 5.788285714285714,
      6.199571428571429, 6.5961428571428575, 6.964428571428571,
      7.301571428571428, 7.610999999999999, 7.895428571428572,
      8.159142857142855, 8.404, 8.632142857142858, 8.845857142857144,
      9.046571428571431, 9.236714285714285, 9.417142857142858, 9.589,
      9.75357142857143, 9.91157142857143, 10.063428571428572,
      10.209571428571431, 10.350714285714286, 10.486857142857144,
      10.61857142857143, 10.745857142857144, 10.869428571428573,
      10.989714285714285, 11.106285714285715, 11.219999999999999,
      11.330285714285713, 11.437857142857144, 11.543000000000001,
      11.645428571428571, 11.745285714285716, 11.843142857142857,
      11.938857142857142, 12.032857142857145, 12.125, 12.215428571428571,
      12.304428571428572, 12.392, 12.478428571428571, 12.56342857142857,
      12.647142857142857, 12.730000000000002, 12.811857142857145,
      12.892714285714286, 12.972999999999999, 13.052428571428573,
      13.131142857142859, 13.209, 13.286285714285713, 13.363142857142858,
      13.439714285714285, 13.515714285714285, 13.591142857142856,
      13.666142857142855, 13.740857142857143, 13.815142857142856,
      13.889142857142856, 13.962857142857143, 14.036285714285714,
      14.10957142857143, 14.18257142857143, 14.255285714285716,
      14.328285714285714, 14.400428571428574, 14.472857142857142,
      14.545142857142858, 14.617428571428572, 14.68942857142857,
      14.761571428571429, 14.833428571428572, 14.905142857142854,
      14.976999999999999, 15.048428571428571, 15.120142857142856,
      15.191714285714285, 15.263285714285715, 15.334714285714284,
      15.406285714285715, 15.477857142857143, 15.549571428571427,
      15.621142857142859, 15.692857142857141, 15.76442857142857, 15.836, 15.908,
      15.979857142857142, 16.052, 16.123714285714282, 16.195857142857143,
      16.267999999999997, 16.340285714285713, 16.412857142857142,
      16.485000000000003, 16.557428571428574, 16.63, 16.702428571428573,
      16.775142857142857, 16.847857142857144, 16.920571428571428,
      16.993142857142857, 17.066142857142857, 17.138714285714283,
      17.211714285714287, 17.28457142857143, 17.357142857142858,
      17.42985714285714, 17.50257142857143, 17.575, 17.64742857142857,
      17.720000000000002, 17.79171428571428, 17.863857142857142,
      17.93585714285714, 18.007571428571428, 18.079, 18.15028571428571,
      18.22142857142857, 18.291999999999998, 18.36285714285714,
      18.433285714285713, 18.503285714285713, 18.573428571428572,
      18.643285714285714, 18.713, 18.782285714285717, 18.851,
      18.920142857142853, 18.988857142857142, 19.057142857142853,
      19.125428571428568, 19.193714285714286, 19.26142857142857,
      19.32928571428571, 19.39685714285714, 19.464142857142857,
      19.53142857142857, 19.598285714285712, 19.665142857142854,
      19.732000000000003, 19.798571428571428, 19.864857142857144,
      19.931142857142856, 19.99742857142857, 20.063428571428574,
      20.129142857142856, 20.195, 20.26085714285714, 20.326285714285714,
      20.39185714285714, 20.457285714285714, 20.522714285714283,
      20.587999999999997, 20.65342857142857, 20.718571428571433,
      20.78357142857143, 20.848714285714287, 20.91371428571429,
      20.978714285714286, 21.043714285714284, 21.10871428571428,
      21.173857142857145, 21.238857142857146, 21.304, 21.369142857142855,
      21.434, 21.499, 21.56414285714286, 21.629285714285718, 21.694428571428574,
      21.75971428571429, 21.825, 21.890285714285714, 21.95571428571429,
      22.021142857142856, 22.086571428571432, 22.152142857142856,
      22.21771428571429, 22.28357142857143, 22.34957142857143,
      22.415142857142854, 22.481142857142856, 22.547142857142855,
      22.613285714285716, 22.679285714285715, 22.745714285714286,
      22.812285714285714, 22.87871428571429, 22.94528571428571,
      23.011714285714284, 23.078714285714284, 23.14542857142857,
      23.212285714285713, 23.279571428571426, 23.34657142857143,
      23.41371428571429, 23.481, 23.548428571428577, 23.615857142857145,
      23.68342857142857, 23.751, 23.819, 23.886571428571425, 23.954857142857144,
      24.022714285714283, 24.09114285714286, 24.15885714285714,
      24.22757142857143, 24.296285714285712, 24.364857142857144,
      24.432857142857138, 24.501714285714286, 24.57071428571428,
      24.639714285714287, 24.70914285714286, 24.77857142857142,
      24.84757142857143, 24.917, 24.986285714285714, 25.056285714285714,
      25.12557142857143, 25.195714285714285, 25.265571428571427,
      25.335571428571427, 25.406, 25.47585714285714, 25.545999999999996,
      25.61685714285714, 25.68728571428571, 25.757714285714286, 25.828,
      25.899142857142856, 25.97, 26.040714285714284, 26.111428571428572, 26.182,
      26.253714285714288, 26.324714285714286, 26.395571428571426,
      26.467142857142854, 26.538571428571426, 26.609999999999996,
      26.681571428571427, 26.752857142857142, 26.823999999999995,
      26.895857142857142, 26.96757142857143, 27.039142857142856,
      27.110857142857146, 27.182714285714287, 27.254571428571428,
      27.326428571428572, 27.398142857142858, 27.470285714285716,
      27.542285714285715, 27.61414285714286, 27.68614285714286,
      27.758428571428567, 27.83042857142857, 27.902428571428572,
      27.97457142857143, 28.046714285714284, 28.118857142857145,
    ],
  },
  SD3neg: {
    label: '-3 SD',
    weight: [
      2.109, 2.277142857142857, 2.4917142857142855, 2.723, 2.9502857142857146,
      3.166714285714286, 3.3698571428571427, 3.560428571428571,
      3.738571428571429, 3.9065714285714286, 4.064571428571429, 4.213,
      4.352714285714286, 4.484428571428571, 4.6088571428571425,
      4.726571428571428, 4.838, 4.944142857142857, 5.044714285714286,
      5.140142857142857, 5.231142857142857, 5.317857142857142,
      5.3999999999999995, 5.478571428571429, 5.553714285714286,
      5.6257142857142854, 5.694428571428571, 5.760571428571429,
      5.824142857142857, 5.885571428571429, 5.944857142857143, 6.002,
      6.057142857142858, 6.110714285714286, 6.162857142857143,
      6.2134285714285715, 6.2628571428571425, 6.310714285714285, 6.358,
      6.403714285714286, 6.448714285714284, 6.493000000000001,
      6.536285714285714, 6.579000000000001, 6.621000000000001,
      6.6621428571428565, 6.702571428571429, 6.742857142857143,
      6.782428571428571, 6.821714285714285, 6.860428571428572,
      6.898714285714286, 6.936714285714287, 6.974142857142858,
      7.011285714285714, 7.0484285714285715, 7.084571428571429,
      7.120857142857143, 7.156857142857143, 7.192428571428572, 7.228, 7.263,
      7.298, 7.332571428571428, 7.367, 7.401000000000001, 7.435428571428572,
      7.469428571428572, 7.503, 7.5365714285714285, 7.570142857142857,
      7.603428571428571, 7.636571428571429, 7.6692857142857145, 7.702,
      7.734714285714285, 7.767285714285714, 7.799571428571428,
      7.831857142857144, 7.863857142857142, 7.895714285714285,
      7.927714285714286, 7.9592857142857145, 7.990714285714285,
      8.022285714285713, 8.053571428571429, 8.084714285714286,
      8.115857142857141, 8.147142857142857, 8.178142857142857, 8.209, 8.24,
      8.270714285714286, 8.301428571428572, 8.332, 8.362714285714286, 8.393,
      8.423428571428571, 8.453857142857144, 8.484, 8.514285714285714,
      8.544285714285715, 8.574142857142858, 8.604142857142858,
      8.634142857142857, 8.663714285714285, 8.693428571428571,
      8.722857142857142, 8.752285714285716, 8.781428571428572,
      8.810857142857143, 8.839714285714285, 8.868571428571428,
      8.897571428571428, 8.926, 8.954714285714285, 8.983, 9.011, 9.039, 9.067,
      9.095, 9.122714285714286, 9.150000000000002, 9.177285714285714,
      9.204571428571427, 9.231714285714286, 9.258571428571429,
      9.285285714285715, 9.311857142857145, 9.33842857142857, 9.364571428571429,
      9.391, 9.417, 9.443, 9.468714285714286, 9.494428571428571,
      9.520142857142856, 9.54557142857143, 9.571000000000002, 9.596142857142857,
      9.621285714285715, 9.646285714285714, 9.671285714285714,
      9.696285714285713, 9.721142857142857, 9.745714285714286,
      9.770428571428571, 9.795142857142858, 9.819714285714285,
      9.844285714285714, 9.868571428571428, 9.892857142857142,
      9.917285714285715, 9.941714285714285, 9.965714285714286,
      9.989857142857144, 10.014142857142858, 10.038285714285715,
      10.06242857142857, 10.08642857142857, 10.11042857142857,
      10.134428571428572, 10.158428571428571, 10.182428571428572,
      10.206285714285714, 10.230142857142857, 10.254142857142854, 10.278,
      10.302000000000001, 10.325714285714286, 10.349571428571428,
      10.373428571428573, 10.397285714285713, 10.421142857142856,
      10.445000000000002, 10.468571428571428, 10.492285714285714,
      10.516142857142855, 10.539857142857143, 10.563428571428572,
      10.587142857142856, 10.610857142857144, 10.634285714285713, 10.658,
      10.681285714285716, 10.705, 10.728285714285715, 10.751857142857144,
      10.775142857142855, 10.798428571428571, 10.821857142857144, 10.845,
      10.868, 10.891428571428571, 10.914571428571426, 10.93757142857143,
      10.96042857142857, 10.983428571428572, 11.006428571428572,
      11.029285714285715, 11.052142857142856, 11.075000000000001,
      11.097571428571428, 11.120285714285712, 11.143, 11.165428571428572,
      11.188285714285715, 11.210714285714287, 11.233285714285714,
      11.255571428571429, 11.278, 11.300285714285716, 11.322857142857142,
      11.345142857142859, 11.367428571428572, 11.38957142857143,
      11.411428571428571, 11.433714285714284, 11.455714285714288,
      11.478142857142856, 11.5, 11.521857142857144, 11.544142857142857, 11.566,
      11.587857142857143, 11.61, 11.631714285714285, 11.653428571428572,
      11.675428571428572, 11.697142857142856, 11.719, 11.740714285714287,
      11.762285714285715, 11.784, 11.806, 11.82757142857143, 11.849000000000002,
      11.870857142857144, 11.892142857142856, 11.914, 11.935142857142859,
      11.956999999999999, 11.978285714285715, 12, 12.021, 12.042857142857141,
      12.064000000000002, 12.084999999999999, 12.106714285714286, 12.128,
      12.149000000000001, 12.170142857142858, 12.191714285714287, 12.213,
      12.233999999999998, 12.254999999999999, 12.275999999999998,
      12.296999999999999, 12.318, 12.339, 12.360142857142858, 12.381,
      12.402000000000001, 12.423, 12.444,
    ],
  },
  SD2neg: {
    label: '-2 SD',
    weight: [
      2.4857142857142853, 2.668857142857143, 2.9052857142857142,
      3.1601428571428576, 3.410142857142857, 3.646857142857143,
      3.8685714285714283, 4.075285714285714, 4.268571428571429,
      4.449857142857143, 4.62, 4.779857142857144, 4.929857142857143,
      5.071428571428571, 5.205, 5.331428571428572, 5.451428571428571,
      5.5649999999999995, 5.6734285714285715, 5.776428571428572,
      5.874428571428572, 5.967999999999999, 6.057571428571428,
      6.142857142857143, 6.224571428571429, 6.302999999999999,
      6.378142857142856, 6.4507142857142865, 6.52042857142857,
      6.587714285714285, 6.652714285714285, 6.715571428571429,
      6.776285714285714, 6.835428571428571, 6.893, 6.949, 7.003285714285714,
      7.056714285714286, 7.108714285714285, 7.159714285714285,
      7.209857142857143, 7.258714285714285, 7.306857142857142,
      7.354142857142858, 7.400571428571429, 7.446714285714286,
      7.491857142857143, 7.5365714285714285, 7.580714285714286,
      7.624428571428571, 7.667571428571429, 7.710142857142857, 7.753,
      7.794714285714285, 7.835999999999999, 7.877285714285715, 7.918,
      7.958571428571429, 7.998857142857142, 8.038714285714287,
      8.078285714285714, 8.117714285714285, 8.156857142857143,
      8.195714285714287, 8.23457142857143, 8.272714285714285, 8.311285714285715,
      8.349428571428572, 8.387428571428572, 8.425142857142857, 8.463,
      8.500428571428571, 8.537857142857144, 8.575, 8.612, 8.648857142857143,
      8.685571428571428, 8.722285714285716, 8.758571428571427, 8.795,
      8.831142857142856, 8.867285714285714, 8.903142857142857, 8.939,
      8.975000000000001, 9.010428571428571, 9.046, 9.081857142857142, 9.117,
      9.15257142857143, 9.188, 9.223, 9.258, 9.292999999999997, 9.328, 9.363,
      9.398, 9.433000000000002, 9.468, 9.502714285714285, 9.537000000000003,
      9.572, 9.605999999999998, 9.640571428571429, 9.675, 9.709,
      9.743285714285715, 9.777142857142858, 9.811142857142856,
      9.844857142857142, 9.87857142857143, 9.912, 9.94542857142857,
      9.978571428571428, 10.011714285714286, 10.044714285714287,
      10.077285714285713, 10.110142857142858, 10.14257142857143,
      10.174999999999999, 10.206999999999999, 10.239285714285716,
      10.270857142857142, 10.302714285714286, 10.334285714285715,
      10.365571428571428, 10.396714285714285, 10.427714285714286,
      10.458571428571428, 10.489571428571427, 10.52, 10.55042857142857,
      10.580857142857143, 10.610857142857144, 10.640857142857142,
      10.670857142857143, 10.700571428571427, 10.730285714285714,
      10.759857142857141, 10.789142857142858, 10.818428571428571,
      10.847714285714286, 10.876714285714286, 10.905714285714286,
      10.934571428571429, 10.963428571428569, 10.992, 11.020857142857142,
      11.049285714285716, 11.078000000000001, 11.105999999999998,
      11.135000000000002, 11.163, 11.191285714285714, 11.219714285714286,
      11.248000000000001, 11.276, 11.304000000000002, 11.331999999999997, 11.36,
      11.388000000000002, 11.416, 11.444, 11.471999999999998, 11.5, 11.528,
      11.556, 11.583714285714285, 11.611142857142857, 11.639,
      11.667000000000002, 11.695000000000002, 11.72285714285714,
      11.750142857142858, 11.778, 11.806, 11.833857142857143,
      11.861142857142857, 11.889, 11.917000000000002, 11.94414285714286,
      11.971999999999998, 11.999571428571429, 12.027000000000001,
      12.054857142857143, 12.081999999999997, 12.109857142857141, 12.137,
      12.16457142857143, 12.191999999999998, 12.219142857142856,
      12.246714285714285, 12.273857142857143, 12.301142857142858,
      12.32814285714286, 12.355428571428574, 12.382571428571428,
      12.409571428571427, 12.436571428571426, 12.463571428571427,
      12.490571428571428, 12.51757142857143, 12.54442857142857,
      12.571285714285713, 12.598285714285712, 12.624857142857142,
      12.65157142857143, 12.678285714285712, 12.704999999999998,
      12.731571428571428, 12.758285714285714, 12.784571428571427,
      12.811428571428573, 12.837857142857144, 12.864428571428572,
      12.890714285714285, 12.917142857142858, 12.94357142857143,
      12.969857142857142, 12.99642857142857, 13.022571428571428,
      13.048714285714286, 13.075142857142856, 13.101285714285712,
      13.127571428571429, 13.153714285714285, 13.180000000000001,
      13.206142857142856, 13.23242857142857, 13.25842857142857,
      13.284571428571427, 13.310571428571427, 13.336714285714285,
      13.36257142857143, 13.388571428571428, 13.414714285714286,
      13.440714285714288, 13.466571428571429, 13.492428571428572,
      13.518285714285714, 13.544142857142857, 13.570142857142859,
      13.595857142857142, 13.621714285714287, 13.647285714285715,
      13.67314285714286, 13.698857142857142, 13.724571428571428,
      13.750142857142857, 13.775857142857143, 13.80157142857143, 13.827,
      13.852714285714287, 13.878142857142858, 13.903857142857143,
      13.929285714285713, 13.954714285714285, 13.980142857142855,
      14.005714285714285, 14.031, 14.056285714285716, 14.081714285714286,
      14.107, 14.132142857142856, 14.15757142857143,
    ],
  },
};
