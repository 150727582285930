export const weightForAgeGirls = {
  date: [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
    59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77,
    78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96,
    97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112,
    113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127,
    128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142,
    143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157,
    158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172,
    173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187,
    188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202,
    203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217,
    218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232,
    233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247,
    248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262,
    263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275, 276, 277,
    278, 279, 280, 281, 282, 283, 284, 285, 286, 287, 288, 289, 290, 291, 292,
    293, 294, 295, 296, 297, 298, 299, 300, 301, 302, 303, 304, 305, 306, 307,
    308, 309, 310, 311, 312, 313, 314, 315, 316, 317, 318, 319, 320, 321, 322,
    323, 324, 325, 326, 327, 328, 329, 330, 331, 332, 333, 334, 335, 336, 337,
    338, 339, 340, 341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351, 352,
    353, 354, 355, 356, 357, 358, 359, 360, 361, 362, 363, 364, 365, 366, 367,
    368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378, 379, 380, 381, 382,
    383, 384, 385, 386, 387, 388, 389, 390, 391, 392, 393, 394, 395, 396, 397,
    398, 399, 400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412,
    413, 414, 415, 416, 417, 418, 419, 420, 421, 422, 423, 424, 425, 426, 427,
    428, 429, 430, 431, 432, 433, 434, 435, 436, 437, 438, 439, 440, 441, 442,
    443, 444, 445, 446, 447, 448, 449, 450, 451, 452, 453, 454, 455, 456, 457,
    458, 459, 460, 461, 462, 463, 464, 465, 466, 467, 468, 469, 470, 471, 472,
    473, 474, 475, 476, 477, 478, 479, 480, 481, 482, 483, 484, 485, 486, 487,
    488, 489, 490, 491, 492, 493, 494, 495, 496, 497, 498, 499, 500, 501, 502,
    503, 504, 505, 506, 507, 508, 509, 510, 511, 512, 513, 514, 515, 516, 517,
    518, 519, 520, 521, 522, 523, 524, 525, 526, 527, 528, 529, 530, 531, 532,
    533, 534, 535, 536, 537, 538, 539, 540, 541, 542, 543, 544, 545, 546, 547,
    548, 549, 550, 551, 552, 553, 554, 555, 556, 557, 558, 559, 560, 561, 562,
    563, 564, 565, 566, 567, 568, 569, 570, 571, 572, 573, 574, 575, 576, 577,
    578, 579, 580, 581, 582, 583, 584, 585, 586, 587, 588, 589, 590, 591, 592,
    593, 594, 595, 596, 597, 598, 599, 600, 601, 602, 603, 604, 605, 606, 607,
    608, 609, 610, 611, 612, 613, 614, 615, 616, 617, 618, 619, 620, 621, 622,
    623, 624, 625, 626, 627, 628, 629, 630, 631, 632, 633, 634, 635, 636, 637,
    638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 648, 649, 650, 651, 652,
    653, 654, 655, 656, 657, 658, 659, 660, 661, 662, 663, 664, 665, 666, 667,
    668, 669, 670, 671, 672, 673, 674, 675, 676, 677, 678, 679, 680, 681, 682,
    683, 684, 685, 686, 687, 688, 689, 690, 691, 692, 693, 694, 695, 696, 697,
    698, 699, 700, 701, 702, 703, 704, 705, 706, 707, 708, 709, 710, 711, 712,
    713, 714, 715, 716, 717, 718, 719, 720, 721, 722, 723, 724, 725, 726, 727,
    728, 729, 730, 731, 732, 733, 734, 735, 736, 737, 738, 739, 740, 741, 742,
    743, 744, 745, 746, 747, 748, 749, 750, 751, 752, 753, 754, 755, 756, 757,
    758, 759, 760, 761, 762, 763, 764, 765, 766, 767, 768, 769, 770, 771, 772,
    773, 774, 775, 776, 777, 778, 779, 780, 781, 782, 783, 784, 785, 786, 787,
    788, 789, 790, 791, 792, 793, 794, 795, 796, 797, 798, 799, 800, 801, 802,
    803, 804, 805, 806, 807, 808, 809, 810, 811, 812, 813, 814, 815, 816, 817,
    818, 819, 820, 821, 822, 823, 824, 825, 826, 827, 828, 829, 830, 831, 832,
    833, 834, 835, 836, 837, 838, 839, 840, 841, 842, 843, 844, 845, 846, 847,
    848, 849, 850, 851, 852, 853, 854, 855, 856, 857, 858, 859, 860, 861, 862,
    863, 864, 865, 866, 867, 868, 869, 870, 871, 872, 873, 874, 875, 876, 877,
    878, 879, 880, 881, 882, 883, 884, 885, 886, 887, 888, 889, 890, 891, 892,
    893, 894, 895, 896, 897, 898, 899, 900, 901, 902, 903, 904, 905, 906, 907,
    908, 909, 910, 911, 912, 913, 914, 915, 916, 917, 918, 919, 920, 921, 922,
    923, 924, 925, 926, 927, 928, 929, 930, 931, 932, 933, 934, 935, 936, 937,
    938, 939, 940, 941, 942, 943, 944, 945, 946, 947, 948, 949, 950, 951, 952,
    953, 954, 955, 956, 957, 958, 959, 960, 961, 962, 963, 964, 965, 966, 967,
    968, 969, 970, 971, 972, 973, 974, 975, 976, 977, 978, 979, 980, 981, 982,
    983, 984, 985, 986, 987, 988, 989, 990, 991, 992, 993, 994, 995, 996, 997,
    998, 999, 1000, 1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009, 1010,
    1011, 1012, 1013, 1014, 1015, 1016, 1017, 1018, 1019, 1020, 1021, 1022,
    1023, 1024, 1025, 1026, 1027, 1028, 1029, 1030, 1031, 1032, 1033, 1034,
    1035, 1036, 1037, 1038, 1039, 1040, 1041, 1042, 1043, 1044, 1045, 1046,
    1047, 1048, 1049, 1050, 1051, 1052, 1053, 1054, 1055, 1056, 1057, 1058,
    1059, 1060, 1061, 1062, 1063, 1064, 1065, 1066, 1067, 1068, 1069, 1070,
    1071, 1072, 1073, 1074, 1075, 1076, 1077, 1078, 1079, 1080, 1081, 1082,
    1083, 1084, 1085, 1086, 1087, 1088, 1089, 1090, 1091, 1092, 1093, 1094,
    1095, 1096, 1097, 1098, 1099, 1100, 1101, 1102, 1103, 1104, 1105, 1106,
    1107, 1108, 1109, 1110, 1111, 1112, 1113, 1114, 1115, 1116, 1117, 1118,
    1119, 1120, 1121, 1122, 1123, 1124, 1125, 1126, 1127, 1128, 1129, 1130,
    1131, 1132, 1133, 1134, 1135, 1136, 1137, 1138, 1139, 1140, 1141, 1142,
    1143, 1144, 1145, 1146, 1147, 1148, 1149, 1150, 1151, 1152, 1153, 1154,
    1155, 1156, 1157, 1158, 1159, 1160, 1161, 1162, 1163, 1164, 1165, 1166,
    1167, 1168, 1169, 1170, 1171, 1172, 1173, 1174, 1175, 1176, 1177, 1178,
    1179, 1180, 1181, 1182, 1183, 1184, 1185, 1186, 1187, 1188, 1189, 1190,
    1191, 1192, 1193, 1194, 1195, 1196, 1197, 1198, 1199, 1200, 1201, 1202,
    1203, 1204, 1205, 1206, 1207, 1208, 1209, 1210, 1211, 1212, 1213, 1214,
    1215, 1216, 1217, 1218, 1219, 1220, 1221, 1222, 1223, 1224, 1225, 1226,
    1227, 1228, 1229, 1230, 1231, 1232, 1233, 1234, 1235, 1236, 1237, 1238,
    1239, 1240, 1241, 1242, 1243, 1244, 1245, 1246, 1247, 1248, 1249, 1250,
    1251, 1252, 1253, 1254, 1255, 1256, 1257, 1258, 1259, 1260, 1261, 1262,
    1263, 1264, 1265, 1266, 1267, 1268, 1269, 1270, 1271, 1272, 1273, 1274,
    1275, 1276, 1277, 1278, 1279, 1280, 1281, 1282, 1283, 1284, 1285, 1286,
    1287, 1288, 1289, 1290, 1291, 1292, 1293, 1294, 1295, 1296, 1297, 1298,
    1299, 1300, 1301, 1302, 1303, 1304, 1305, 1306, 1307, 1308, 1309, 1310,
    1311, 1312, 1313, 1314, 1315, 1316, 1317, 1318, 1319, 1320, 1321, 1322,
    1323, 1324, 1325, 1326, 1327, 1328, 1329, 1330, 1331, 1332, 1333, 1334,
    1335, 1336, 1337, 1338, 1339, 1340, 1341, 1342, 1343, 1344, 1345, 1346,
    1347, 1348, 1349, 1350, 1351, 1352, 1353, 1354, 1355, 1356, 1357, 1358,
    1359, 1360, 1361, 1362, 1363, 1364, 1365, 1366, 1367, 1368, 1369, 1370,
    1371, 1372, 1373, 1374, 1375, 1376, 1377, 1378, 1379, 1380, 1381, 1382,
    1383, 1384, 1385, 1386, 1387, 1388, 1389, 1390, 1391, 1392, 1393, 1394,
    1395, 1396, 1397, 1398, 1399, 1400, 1401, 1402, 1403, 1404, 1405, 1406,
    1407, 1408, 1409, 1410, 1411, 1412, 1413, 1414, 1415, 1416, 1417, 1418,
    1419, 1420, 1421, 1422, 1423, 1424, 1425, 1426, 1427, 1428, 1429, 1430,
    1431, 1432, 1433, 1434, 1435, 1436, 1437, 1438, 1439, 1440, 1441, 1442,
    1443, 1444, 1445, 1446, 1447, 1448, 1449, 1450, 1451, 1452, 1453, 1454,
    1455, 1456, 1457, 1458, 1459, 1460, 1461, 1462, 1463, 1464, 1465, 1466,
    1467, 1468, 1469, 1470, 1471, 1472, 1473, 1474, 1475, 1476, 1477, 1478,
    1479, 1480, 1481, 1482, 1483, 1484, 1485, 1486, 1487, 1488, 1489, 1490,
    1491, 1492, 1493, 1494, 1495, 1496, 1497, 1498, 1499, 1500, 1501, 1502,
    1503, 1504, 1505, 1506, 1507, 1508, 1509, 1510, 1511, 1512, 1513, 1514,
    1515, 1516, 1517, 1518, 1519, 1520, 1521, 1522, 1523, 1524, 1525, 1526,
    1527, 1528, 1529, 1530, 1531, 1532, 1533, 1534, 1535, 1536, 1537, 1538,
    1539, 1540, 1541, 1542, 1543, 1544, 1545, 1546, 1547, 1548, 1549, 1550,
    1551, 1552, 1553, 1554, 1555, 1556, 1557, 1558, 1559, 1560, 1561, 1562,
    1563, 1564, 1565, 1566, 1567, 1568, 1569, 1570, 1571, 1572, 1573, 1574,
    1575, 1576, 1577, 1578, 1579, 1580, 1581, 1582, 1583, 1584, 1585, 1586,
    1587, 1588, 1589, 1590, 1591, 1592, 1593, 1594, 1595, 1596, 1597, 1598,
    1599, 1600, 1601, 1602, 1603, 1604, 1605, 1606, 1607, 1608, 1609, 1610,
    1611, 1612, 1613, 1614, 1615, 1616, 1617, 1618, 1619, 1620, 1621, 1622,
    1623, 1624, 1625, 1626, 1627, 1628, 1629, 1630, 1631, 1632, 1633, 1634,
    1635, 1636, 1637, 1638, 1639, 1640, 1641, 1642, 1643, 1644, 1645, 1646,
    1647, 1648, 1649, 1650, 1651, 1652, 1653, 1654, 1655, 1656, 1657, 1658,
    1659, 1660, 1661, 1662, 1663, 1664, 1665, 1666, 1667, 1668, 1669, 1670,
    1671, 1672, 1673, 1674, 1675, 1676, 1677, 1678, 1679, 1680, 1681, 1682,
    1683, 1684, 1685, 1686, 1687, 1688, 1689, 1690, 1691, 1692, 1693, 1694,
    1695, 1696, 1697, 1698, 1699, 1700, 1701, 1702, 1703, 1704, 1705, 1706,
    1707, 1708, 1709, 1710, 1711, 1712, 1713, 1714, 1715, 1716, 1717, 1718,
    1719, 1720, 1721, 1722, 1723, 1724, 1725, 1726, 1727, 1728, 1729, 1730,
    1731, 1732, 1733, 1734, 1735, 1736, 1737, 1738, 1739, 1740, 1741, 1742,
    1743, 1744, 1745, 1746, 1747, 1748, 1749, 1750, 1751, 1752, 1753, 1754,
    1755, 1756, 1757, 1758, 1759, 1760, 1761, 1762, 1763, 1764, 1765, 1766,
    1767, 1768, 1769, 1770, 1771, 1772, 1773, 1774, 1775, 1776, 1777, 1778,
    1779, 1780, 1781, 1782, 1783, 1784, 1785, 1786, 1787, 1788, 1789, 1790,
    1791, 1792, 1793, 1794, 1795, 1796, 1797, 1798, 1799, 1800, 1801, 1802,
    1803, 1804, 1805, 1806, 1807, 1808, 1809, 1810, 1811, 1812, 1813, 1814,
    1815, 1816, 1817, 1818, 1819, 1820, 1821, 1822, 1823, 1824, 1825, 1826,
    1827, 1828, 1829, 1830, 1831, 1832, 1833, 1834, 1835, 1836, 1837, 1838,
    1839, 1840, 1841, 1842, 1843, 1844, 1845, 1846, 1847, 1848, 1849, 1850,
    1851, 1852, 1853, 1854, 1855, 1856,
  ],
  median: {
    label: 'median',
    weight: [
      3.1957, 3.2104, 3.2315, 3.2322, 3.2558, 3.2821, 3.3099, 3.3388, 3.3687,
      3.3995, 3.4314, 3.4643, 3.4983, 3.5333, 3.5693, 3.6063, 3.6438, 3.6818,
      3.7201, 3.7584, 3.7968, 3.8352, 3.8735, 3.9116, 3.9495, 3.9872, 4.0247,
      4.0618, 4.0987, 4.1353, 4.1716, 4.2075, 4.2431, 4.2783, 4.3131, 4.3476,
      4.3818, 4.4155, 4.449, 4.482, 4.5148, 4.5472, 4.5793, 4.611, 4.6425,
      4.6736, 4.7044, 4.7349, 4.7651, 4.795, 4.8245, 4.8538, 4.8828, 4.9115,
      4.9399, 4.968, 4.9959, 5.0235, 5.0509, 5.078, 5.1049, 5.1315, 5.158,
      5.1842, 5.2102, 5.236, 5.2616, 5.287, 5.3121, 5.337, 5.3618, 5.3863,
      5.4107, 5.4348, 5.4587, 5.4825, 5.5061, 5.5295, 5.5527, 5.5757, 5.5986,
      5.6213, 5.6438, 5.6662, 5.6883, 5.7104, 5.7322, 5.7539, 5.7755, 5.7969,
      5.8181, 5.8393, 5.8602, 5.881, 5.9017, 5.9223, 5.9427, 5.9629, 5.9831,
      6.0031, 6.0229, 6.0426, 6.0622, 6.0817, 6.101, 6.1202, 6.1393, 6.1582,
      6.1771, 6.1958, 6.2143, 6.2328, 6.2511, 6.2693, 6.2874, 6.3054, 6.3232,
      6.341, 6.3586, 6.3761, 6.3935, 6.4108, 6.428, 6.445, 6.462, 6.4788,
      6.4956, 6.5122, 6.5288, 6.5452, 6.5615, 6.5777, 6.5939, 6.6099, 6.6258,
      6.6416, 6.6573, 6.6729, 6.6884, 6.7039, 6.7192, 6.7344, 6.7495, 6.7646,
      6.7795, 6.7944, 6.8091, 6.8238, 6.8384, 6.8529, 6.8673, 6.8816, 6.8959,
      6.91, 6.9241, 6.9381, 6.952, 6.9659, 6.9797, 6.9934, 7.007, 7.0205, 7.034,
      7.0474, 7.0607, 7.074, 7.0872, 7.1003, 7.1133, 7.1263, 7.1393, 7.1521,
      7.1649, 7.1776, 7.1903, 7.2029, 7.2154, 7.2279, 7.2403, 7.2527, 7.265,
      7.2772, 7.2894, 7.3016, 7.3136, 7.3256, 7.3376, 7.3495, 7.3614, 7.3732,
      7.3849, 7.3966, 7.4082, 7.4198, 7.4314, 7.4429, 7.4543, 7.4657, 7.477,
      7.4883, 7.4995, 7.5107, 7.5219, 7.533, 7.544, 7.5551, 7.566, 7.5769,
      7.5878, 7.5986, 7.6094, 7.6202, 7.6309, 7.6416, 7.6522, 7.6628, 7.6733,
      7.6838, 7.6943, 7.7047, 7.7151, 7.7254, 7.7357, 7.746, 7.7562, 7.7664,
      7.7766, 7.7867, 7.7968, 7.8068, 7.8169, 7.8268, 7.8368, 7.8467, 7.8566,
      7.8664, 7.8762, 7.886, 7.8957, 7.9054, 7.9151, 7.9247, 7.9343, 7.9439,
      7.9534, 7.9629, 7.9724, 7.9819, 7.9913, 8.0007, 8.01, 8.0193, 8.0286,
      8.0379, 8.0471, 8.0563, 8.0655, 8.0746, 8.0837, 8.0928, 8.1019, 8.1109,
      8.1199, 8.1289, 8.1378, 8.1468, 8.1557, 8.1645, 8.1734, 8.1822, 8.191,
      8.1998, 8.2085, 8.2172, 8.2259, 8.2346, 8.2432, 8.2519, 8.2605, 8.269,
      8.2776, 8.2861, 8.2946, 8.3031, 8.3116, 8.3201, 8.3285, 8.3369, 8.3453,
      8.3536, 8.362, 8.3703, 8.3786, 8.3869, 8.3952, 8.4035, 8.4117, 8.4199,
      8.4281, 8.4363, 8.4445, 8.4526, 8.4607, 8.4688, 8.4769, 8.485, 8.4931,
      8.5011, 8.5092, 8.5172, 8.5252, 8.5332, 8.5411, 8.5491, 8.557, 8.565,
      8.5729, 8.5808, 8.5887, 8.5965, 8.6044, 8.6122, 8.6201, 8.6279, 8.6357,
      8.6435, 8.6512, 8.659, 8.6667, 8.6745, 8.6822, 8.6899, 8.6976, 8.7053,
      8.713, 8.7207, 8.7283, 8.736, 8.7436, 8.7512, 8.7588, 8.7664, 8.774,
      8.7816, 8.7892, 8.7968, 8.8043, 8.8119, 8.8194, 8.8269, 8.8344, 8.842,
      8.8495, 8.8569, 8.8644, 8.8719, 8.8794, 8.8868, 8.8943, 8.9017, 8.9092,
      8.9166, 8.924, 8.9314, 8.9388, 8.9462, 8.9536, 8.961, 8.9684, 8.9757,
      8.9831, 8.9904, 8.9978, 9.0051, 9.0125, 9.0198, 9.0271, 9.0344, 9.0417,
      9.049, 9.0563, 9.0636, 9.0709, 9.0782, 9.0854, 9.0927, 9.0999, 9.1072,
      9.1144, 9.1217, 9.1289, 9.1361, 9.1434, 9.1506, 9.1578, 9.165, 9.1722,
      9.1794, 9.1866, 9.1938, 9.2009, 9.2081, 9.2153, 9.2225, 9.2296, 9.2368,
      9.2439, 9.2511, 9.2582, 9.2654, 9.2725, 9.2796, 9.2867, 9.2939, 9.301,
      9.3081, 9.3152, 9.3223, 9.3294, 9.3365, 9.3436, 9.3507, 9.3578, 9.3649,
      9.372, 9.379, 9.3861, 9.3932, 9.4002, 9.4073, 9.4144, 9.4214, 9.4285,
      9.4355, 9.4426, 9.4496, 9.4567, 9.4637, 9.4707, 9.4778, 9.4848, 9.4918,
      9.4988, 9.5058, 9.5129, 9.5199, 9.5269, 9.5339, 9.5409, 9.5479, 9.5549,
      9.5619, 9.5689, 9.5759, 9.5829, 9.5898, 9.5968, 9.6038, 9.6108, 9.6178,
      9.6247, 9.6317, 9.6387, 9.6457, 9.6526, 9.6596, 9.6665, 9.6735, 9.6805,
      9.6874, 9.6944, 9.7013, 9.7083, 9.7152, 9.7222, 9.7291, 9.7361, 9.743,
      9.75, 9.7569, 9.7638, 9.7708, 9.7777, 9.7846, 9.7916, 9.7985, 9.8054,
      9.8124, 9.8193, 9.8262, 9.8331, 9.8401, 9.847, 9.8539, 9.8608, 9.8677,
      9.8746, 9.8816, 9.8885, 9.8954, 9.9023, 9.9092, 9.9161, 9.923, 9.9299,
      9.9368, 9.9437, 9.9506, 9.9575, 9.9644, 9.9713, 9.9782, 9.9851, 9.992,
      9.9989, 10.0058, 10.0127, 10.0196, 10.0265, 10.0334, 10.0402, 10.0471,
      10.054, 10.0609, 10.0678, 10.0746, 10.0815, 10.0884, 10.0953, 10.1021,
      10.109, 10.1159, 10.1227, 10.1296, 10.1365, 10.1433, 10.1502, 10.157,
      10.1639, 10.1707, 10.1776, 10.1845, 10.1913, 10.1982, 10.205, 10.2119,
      10.2187, 10.2255, 10.2324, 10.2392, 10.2461, 10.2529, 10.2597, 10.2666,
      10.2734, 10.2803, 10.2871, 10.2939, 10.3008, 10.3076, 10.3144, 10.3213,
      10.3281, 10.3349, 10.3417, 10.3486, 10.3554, 10.3622, 10.369, 10.3759,
      10.3827, 10.3895, 10.3963, 10.4031, 10.41, 10.4168, 10.4236, 10.4304,
      10.4372, 10.444, 10.4508, 10.4577, 10.4645, 10.4713, 10.4781, 10.4849,
      10.4917, 10.4985, 10.5053, 10.5121, 10.5189, 10.5257, 10.5325, 10.5393,
      10.5461, 10.5529, 10.5597, 10.5665, 10.5733, 10.5801, 10.5869, 10.5937,
      10.6005, 10.6073, 10.6141, 10.6209, 10.6277, 10.6345, 10.6413, 10.6481,
      10.6549, 10.6617, 10.6685, 10.6753, 10.6821, 10.6889, 10.6957, 10.7025,
      10.7093, 10.7161, 10.7229, 10.7297, 10.7365, 10.7433, 10.7501, 10.7569,
      10.7637, 10.7705, 10.7773, 10.7841, 10.7909, 10.7977, 10.8045, 10.8113,
      10.8181, 10.8249, 10.8317, 10.8385, 10.8453, 10.8521, 10.8589, 10.8657,
      10.8725, 10.8793, 10.8861, 10.8929, 10.8997, 10.9065, 10.9133, 10.9202,
      10.927, 10.9338, 10.9406, 10.9474, 10.9542, 10.961, 10.9679, 10.9747,
      10.9815, 10.9883, 10.9951, 11.0019, 11.0088, 11.0156, 11.0224, 11.0292,
      11.036, 11.0429, 11.0497, 11.0565, 11.0633, 11.0702, 11.077, 11.0838,
      11.0906, 11.0975, 11.1043, 11.1111, 11.118, 11.1248, 11.1316, 11.1384,
      11.1453, 11.1521, 11.1589, 11.1658, 11.1726, 11.1795, 11.1863, 11.1931,
      11.2, 11.2068, 11.2137, 11.2205, 11.2273, 11.2342, 11.241, 11.2479,
      11.2547, 11.2616, 11.2684, 11.2753, 11.2821, 11.2889, 11.2958, 11.3026,
      11.3095, 11.3163, 11.3232, 11.33, 11.3369, 11.3438, 11.3506, 11.3575,
      11.3643, 11.3712, 11.378, 11.3849, 11.3917, 11.3986, 11.4055, 11.4123,
      11.4192, 11.426, 11.4329, 11.4397, 11.4466, 11.4535, 11.4603, 11.4672,
      11.4741, 11.4809, 11.4878, 11.4946, 11.5015, 11.5084, 11.5152, 11.5221,
      11.529, 11.5358, 11.5427, 11.5496, 11.5564, 11.5633, 11.5702, 11.577,
      11.5839, 11.5907, 11.5976, 11.6045, 11.6113, 11.6182, 11.6251, 11.6319,
      11.6388, 11.6456, 11.6525, 11.6594, 11.6662, 11.6731, 11.6799, 11.6868,
      11.6937, 11.7005, 11.7074, 11.7142, 11.7211, 11.7279, 11.7348, 11.7416,
      11.7485, 11.7553, 11.7622, 11.769, 11.7759, 11.7827, 11.7896, 11.7964,
      11.8033, 11.8101, 11.817, 11.8238, 11.8307, 11.8375, 11.8443, 11.8512,
      11.858, 11.8648, 11.8717, 11.8785, 11.8853, 11.8922, 11.899, 11.9058,
      11.9126, 11.9194, 11.9263, 11.9331, 11.9399, 11.9467, 11.9535, 11.9603,
      11.9671, 11.9739, 11.9808, 11.9876, 11.9944, 12.0011, 12.0079, 12.0147,
      12.0215, 12.0283, 12.0351, 12.0419, 12.0487, 12.0554, 12.0622, 12.069,
      12.0758, 12.0825, 12.0893, 12.0961, 12.1028, 12.1096, 12.1163, 12.1231,
      12.1298, 12.1366, 12.1433, 12.15, 12.1568, 12.1635, 12.1702, 12.177,
      12.1837, 12.1904, 12.1971, 12.2039, 12.2106, 12.2173, 12.224, 12.2307,
      12.2374, 12.2441, 12.2508, 12.2575, 12.2642, 12.2709, 12.2775, 12.2842,
      12.2909, 12.2976, 12.3042, 12.3109, 12.3176, 12.3242, 12.3309, 12.3375,
      12.3442, 12.3508, 12.3575, 12.3641, 12.3707, 12.3774, 12.384, 12.3906,
      12.3973, 12.4039, 12.4105, 12.4171, 12.4237, 12.4303, 12.4369, 12.4435,
      12.4501, 12.4567, 12.4633, 12.4699, 12.4765, 12.4831, 12.4896, 12.4962,
      12.5028, 12.5093, 12.5159, 12.5225, 12.529, 12.5356, 12.5421, 12.5487,
      12.5552, 12.5617, 12.5683, 12.5748, 12.5813, 12.5879, 12.5944, 12.6009,
      12.6074, 12.6139, 12.6204, 12.6269, 12.6334, 12.6399, 12.6464, 12.6529,
      12.6594, 12.6659, 12.6723, 12.6788, 12.6853, 12.6918, 12.6982, 12.7047,
      12.7111, 12.7176, 12.724, 12.7305, 12.7369, 12.7434, 12.7498, 12.7563,
      12.7627, 12.7691, 12.7755, 12.782, 12.7884, 12.7948, 12.8012, 12.8076,
      12.814, 12.8204, 12.8268, 12.8332, 12.8396, 12.846, 12.8524, 12.8588,
      12.8651, 12.8715, 12.8779, 12.8843, 12.8906, 12.897, 12.9033, 12.9097,
      12.9161, 12.9224, 12.9288, 12.9351, 12.9415, 12.9478, 12.9541, 12.9605,
      12.9668, 12.9732, 12.9795, 12.9858, 12.9921, 12.9985, 13.0048, 13.0111,
      13.0174, 13.0237, 13.03, 13.0363, 13.0427, 13.049, 13.0553, 13.0616,
      13.0679, 13.0742, 13.0804, 13.0867, 13.093, 13.0993, 13.1056, 13.1119,
      13.1182, 13.1245, 13.1307, 13.137, 13.1433, 13.1496, 13.1558, 13.1621,
      13.1684, 13.1746, 13.1809, 13.1872, 13.1934, 13.1997, 13.2059, 13.2122,
      13.2185, 13.2247, 13.231, 13.2372, 13.2435, 13.2497, 13.256, 13.2622,
      13.2684, 13.2747, 13.2809, 13.2872, 13.2934, 13.2996, 13.3059, 13.3121,
      13.3183, 13.3246, 13.3308, 13.337, 13.3433, 13.3495, 13.3557, 13.3619,
      13.3682, 13.3744, 13.3806, 13.3868, 13.3931, 13.3993, 13.4055, 13.4117,
      13.4179, 13.4242, 13.4304, 13.4366, 13.4428, 13.449, 13.4552, 13.4614,
      13.4677, 13.4739, 13.4801, 13.4863, 13.4925, 13.4987, 13.5049, 13.5111,
      13.5173, 13.5235, 13.5297, 13.5359, 13.5421, 13.5483, 13.5545, 13.5607,
      13.5669, 13.5731, 13.5793, 13.5855, 13.5917, 13.5979, 13.6041, 13.6103,
      13.6165, 13.6227, 13.6289, 13.6351, 13.6413, 13.6475, 13.6537, 13.6599,
      13.6661, 13.6723, 13.6785, 13.6847, 13.6909, 13.6971, 13.7033, 13.7095,
      13.7157, 13.7218, 13.728, 13.7342, 13.7404, 13.7466, 13.7528, 13.759,
      13.7652, 13.7714, 13.7776, 13.7838, 13.7899, 13.7961, 13.8023, 13.8085,
      13.8147, 13.8209, 13.8271, 13.8333, 13.8395, 13.8456, 13.8518, 13.858,
      13.8642, 13.8704, 13.8766, 13.8828, 13.8889, 13.8951, 13.9013, 13.9075,
      13.9137, 13.9199, 13.9261, 13.9322, 13.9384, 13.9446, 13.9508, 13.957,
      13.9632, 13.9693, 13.9755, 13.9817, 13.9879, 13.9941, 14.0003, 14.0064,
      14.0126, 14.0188, 14.025, 14.0312, 14.0373, 14.0435, 14.0497, 14.0559,
      14.0621, 14.0682, 14.0744, 14.0806, 14.0868, 14.093, 14.0991, 14.1053,
      14.1115, 14.1177, 14.1238, 14.13, 14.1362, 14.1424, 14.1485, 14.1547,
      14.1609, 14.1671, 14.1732, 14.1794, 14.1856, 14.1917, 14.1979, 14.2041,
      14.2103, 14.2164, 14.2226, 14.2288, 14.2349, 14.2411, 14.2473, 14.2534,
      14.2596, 14.2658, 14.2719, 14.2781, 14.2843, 14.2904, 14.2966, 14.3028,
      14.3089, 14.3151, 14.3213, 14.3274, 14.3336, 14.3397, 14.3459, 14.3521,
      14.3582, 14.3644, 14.3705, 14.3767, 14.3829, 14.389, 14.3952, 14.4013,
      14.4075, 14.4136, 14.4198, 14.4259, 14.4321, 14.4382, 14.4444, 14.4505,
      14.4567, 14.4628, 14.469, 14.4751, 14.4813, 14.4874, 14.4936, 14.4997,
      14.5059, 14.512, 14.5181, 14.5243, 14.5304, 14.5366, 14.5427, 14.5488,
      14.555, 14.5611, 14.5673, 14.5734, 14.5795, 14.5857, 14.5918, 14.5979,
      14.6041, 14.6102, 14.6163, 14.6225, 14.6286, 14.6347, 14.6408, 14.647,
      14.6531, 14.6592, 14.6653, 14.6715, 14.6776, 14.6837, 14.6898, 14.696,
      14.7021, 14.7082, 14.7143, 14.7204, 14.7265, 14.7327, 14.7388, 14.7449,
      14.751, 14.7571, 14.7632, 14.7693, 14.7754, 14.7816, 14.7877, 14.7938,
      14.7999, 14.806, 14.8121, 14.8182, 14.8243, 14.8304, 14.8365, 14.8426,
      14.8487, 14.8548, 14.8609, 14.867, 14.8731, 14.8792, 14.8853, 14.8913,
      14.8974, 14.9035, 14.9096, 14.9157, 14.9218, 14.9279, 14.934, 14.94,
      14.9461, 14.9522, 14.9583, 14.9644, 14.9704, 14.9765, 14.9826, 14.9887,
      14.9948, 15.0008, 15.0069, 15.013, 15.019, 15.0251, 15.0312, 15.0373,
      15.0433, 15.0494, 15.0555, 15.0615, 15.0676, 15.0736, 15.0797, 15.0858,
      15.0918, 15.0979, 15.1039, 15.11, 15.1161, 15.1221, 15.1282, 15.1342,
      15.1403, 15.1463, 15.1524, 15.1584, 15.1645, 15.1705, 15.1766, 15.1826,
      15.1887, 15.1947, 15.2008, 15.2068, 15.2128, 15.2189, 15.2249, 15.231,
      15.237, 15.243, 15.2491, 15.2551, 15.2611, 15.2672, 15.2732, 15.2792,
      15.2853, 15.2913, 15.2973, 15.3034, 15.3094, 15.3154, 15.3214, 15.3275,
      15.3335, 15.3395, 15.3455, 15.3516, 15.3576, 15.3636, 15.3696, 15.3756,
      15.3817, 15.3877, 15.3937, 15.3997, 15.4057, 15.4117, 15.4178, 15.4238,
      15.4298, 15.4358, 15.4418, 15.4478, 15.4538, 15.4598, 15.4658, 15.4719,
      15.4779, 15.4839, 15.4899, 15.4959, 15.5019, 15.5079, 15.5139, 15.5199,
      15.5259, 15.5319, 15.5379, 15.5439, 15.5499, 15.5559, 15.5619, 15.5679,
      15.5739, 15.5799, 15.5859, 15.5918, 15.5978, 15.6038, 15.6098, 15.6158,
      15.6218, 15.6278, 15.6338, 15.6398, 15.6458, 15.6517, 15.6577, 15.6637,
      15.6697, 15.6757, 15.6817, 15.6877, 15.6936, 15.6996, 15.7056, 15.7116,
      15.7176, 15.7236, 15.7295, 15.7355, 15.7415, 15.7475, 15.7534, 15.7594,
      15.7654, 15.7714, 15.7774, 15.7833, 15.7893, 15.7953, 15.8013, 15.8072,
      15.8132, 15.8192, 15.8252, 15.8311, 15.8371, 15.8431, 15.849, 15.855,
      15.861, 15.8669, 15.8729, 15.8789, 15.8849, 15.8908, 15.8968, 15.9028,
      15.9087, 15.9147, 15.9207, 15.9266, 15.9326, 15.9386, 15.9445, 15.9505,
      15.9565, 15.9624, 15.9684, 15.9744, 15.9803, 15.9863, 15.9922, 15.9982,
      16.0042, 16.0101, 16.0161, 16.0221, 16.028, 16.034, 16.0399, 16.0459,
      16.0519, 16.0578, 16.0638, 16.0697, 16.0757, 16.0817, 16.0876, 16.0936,
      16.0995, 16.1055, 16.1115, 16.1174, 16.1234, 16.1293, 16.1353, 16.1413,
      16.1472, 16.1532, 16.1591, 16.1651, 16.171, 16.177, 16.1829, 16.1889,
      16.1949, 16.2008, 16.2068, 16.2127, 16.2187, 16.2246, 16.2306, 16.2365,
      16.2425, 16.2485, 16.2544, 16.2604, 16.2663, 16.2723, 16.2782, 16.2842,
      16.2901, 16.2961, 16.302, 16.308, 16.314, 16.3199, 16.3259, 16.3318,
      16.3378, 16.3437, 16.3497, 16.3556, 16.3616, 16.3675, 16.3735, 16.3794,
      16.3854, 16.3913, 16.3973, 16.4032, 16.4092, 16.4151, 16.4211, 16.427,
      16.433, 16.4389, 16.4449, 16.4508, 16.4568, 16.4627, 16.4687, 16.4746,
      16.4806, 16.4865, 16.4925, 16.4984, 16.5044, 16.5103, 16.5163, 16.5222,
      16.5282, 16.5341, 16.5401, 16.546, 16.552, 16.5579, 16.5639, 16.5698,
      16.5758, 16.5817, 16.5876, 16.5936, 16.5995, 16.6055, 16.6114, 16.6174,
      16.6233, 16.6293, 16.6352, 16.6412, 16.6471, 16.653, 16.659, 16.6649,
      16.6709, 16.6768, 16.6828, 16.6887, 16.6947, 16.7006, 16.7065, 16.7125,
      16.7184, 16.7244, 16.7303, 16.7363, 16.7422, 16.7481, 16.7541, 16.76,
      16.766, 16.7719, 16.7778, 16.7838, 16.7897, 16.7957, 16.8016, 16.8075,
      16.8135, 16.8194, 16.8254, 16.8313, 16.8372, 16.8432, 16.8491, 16.855,
      16.861, 16.8669, 16.8729, 16.8788, 16.8847, 16.8907, 16.8966, 16.9025,
      16.9085, 16.9144, 16.9203, 16.9263, 16.9322, 16.9381, 16.9441, 16.95,
      16.9559, 16.9619, 16.9678, 16.9737, 16.9796, 16.9856, 16.9915, 16.9974,
      17.0034, 17.0093, 17.0152, 17.0211, 17.0271, 17.033, 17.0389, 17.0448,
      17.0508, 17.0567, 17.0626, 17.0685, 17.0744, 17.0804, 17.0863, 17.0922,
      17.0981, 17.104, 17.11, 17.1159, 17.1218, 17.1277, 17.1336, 17.1395,
      17.1455, 17.1514, 17.1573, 17.1632, 17.1691, 17.175, 17.1809, 17.1868,
      17.1927, 17.1987, 17.2046, 17.2105, 17.2164, 17.2223, 17.2282, 17.2341,
      17.24, 17.2459, 17.2518, 17.2577, 17.2636, 17.2695, 17.2754, 17.2813,
      17.2872, 17.2931, 17.299, 17.3049, 17.3108, 17.3167, 17.3226, 17.3285,
      17.3344, 17.3402, 17.3461, 17.352, 17.3579, 17.3638, 17.3697, 17.3756,
      17.3815, 17.3873, 17.3932, 17.3991, 17.405, 17.4109, 17.4167, 17.4226,
      17.4285, 17.4344, 17.4403, 17.4461, 17.452, 17.4579, 17.4637, 17.4696,
      17.4755, 17.4814, 17.4872, 17.4931, 17.499, 17.5048, 17.5107, 17.5166,
      17.5224, 17.5283, 17.5341, 17.54, 17.5459, 17.5517, 17.5576, 17.5634,
      17.5693, 17.5751, 17.581, 17.5868, 17.5927, 17.5985, 17.6044, 17.6102,
      17.6161, 17.6219, 17.6278, 17.6336, 17.6394, 17.6453, 17.6511, 17.657,
      17.6628, 17.6686, 17.6745, 17.6803, 17.6861, 17.692, 17.6978, 17.7036,
      17.7095, 17.7153, 17.7211, 17.7269, 17.7328, 17.7386, 17.7444, 17.7502,
      17.7561, 17.7619, 17.7677, 17.7735, 17.7793, 17.7851, 17.791, 17.7968,
      17.8026, 17.8084, 17.8142, 17.82, 17.8258, 17.8316, 17.8374, 17.8432,
      17.849, 17.8548, 17.8606, 17.8664, 17.8722, 17.878, 17.8838, 17.8896,
      17.8954, 17.9012, 17.907, 17.9128, 17.9186, 17.9243, 17.9301, 17.9359,
      17.9417, 17.9475, 17.9533, 17.959, 17.9648, 17.9706, 17.9764, 17.9821,
      17.9879, 17.9937, 17.9995, 18.0052, 18.011, 18.0168, 18.0225, 18.0283,
      18.0341, 18.0398, 18.0456, 18.0513, 18.0571, 18.0629, 18.0686, 18.0744,
      18.0801, 18.0859, 18.0916, 18.0974, 18.1031, 18.1089, 18.1146, 18.1204,
      18.1261, 18.1319, 18.1376, 18.1434, 18.1491, 18.1548, 18.1606, 18.1663,
      18.172, 18.1778, 18.1835, 18.1892, 18.195, 18.2007, 18.2064, 18.2122,
      18.2179, 18.2236, 18.2293, 18.235, 18.2408, 18.2465, 18.2522, 18.2579,
      18.2636, 18.2693, 18.2751, 18.2808, 18.2865, 18.2922, 18.2979, 18.3036,
      18.3093, 18.315, 18.3207, 18.3264, 18.3321, 18.3378, 18.3435, 18.3492,
      18.3549, 18.3606, 18.3663, 18.372, 18.3777, 18.3834, 18.389,
    ],
  },
  SD2: {
    label: '2 SD',
    weight: [
      4.222, 4.23, 4.249, 4.28, 4.314, 4.349, 4.386, 4.423, 4.461, 4.5, 4.54,
      4.581, 4.623, 4.667, 4.712, 4.758, 4.804, 4.852, 4.899, 4.946, 4.994,
      5.042, 5.089, 5.136, 5.183, 5.229, 5.275, 5.321, 5.366, 5.411, 5.456, 5.5,
      5.544, 5.587, 5.63, 5.672, 5.714, 5.756, 5.797, 5.837, 5.878, 5.917,
      5.957, 5.995, 6.034, 6.072, 6.11, 6.147, 6.184, 6.221, 6.257, 6.293,
      6.329, 6.364, 6.399, 6.433, 6.467, 6.501, 6.535, 6.568, 6.601, 6.634,
      6.666, 6.699, 6.731, 6.762, 6.794, 6.825, 6.856, 6.886, 6.917, 6.947,
      6.977, 7.007, 7.036, 7.066, 7.095, 7.124, 7.152, 7.181, 7.209, 7.237,
      7.265, 7.292, 7.32, 7.347, 7.374, 7.401, 7.428, 7.454, 7.48, 7.506, 7.532,
      7.558, 7.584, 7.609, 7.634, 7.66, 7.685, 7.71, 7.734, 7.759, 7.783, 7.807,
      7.831, 7.855, 7.879, 7.902, 7.926, 7.949, 7.972, 7.995, 8.018, 8.041,
      8.063, 8.086, 8.108, 8.13, 8.152, 8.174, 8.196, 8.217, 8.239, 8.26, 8.281,
      8.302, 8.323, 8.344, 8.365, 8.385, 8.406, 8.426, 8.446, 8.466, 8.486,
      8.506, 8.526, 8.545, 8.565, 8.584, 8.604, 8.623, 8.642, 8.661, 8.679,
      8.698, 8.717, 8.735, 8.753, 8.772, 8.79, 8.808, 8.826, 8.844, 8.862,
      8.879, 8.897, 8.914, 8.932, 8.949, 8.967, 8.984, 9.001, 9.018, 9.035,
      9.051, 9.068, 9.085, 9.101, 9.118, 9.134, 9.151, 9.167, 9.183, 9.199,
      9.215, 9.231, 9.247, 9.263, 9.279, 9.295, 9.31, 9.326, 9.341, 9.357,
      9.372, 9.388, 9.403, 9.418, 9.433, 9.448, 9.463, 9.478, 9.493, 9.508,
      9.523, 9.537, 9.552, 9.567, 9.581, 9.596, 9.61, 9.625, 9.639, 9.653,
      9.668, 9.682, 9.696, 9.71, 9.724, 9.738, 9.752, 9.766, 9.78, 9.793, 9.807,
      9.821, 9.834, 9.848, 9.862, 9.875, 9.889, 9.902, 9.915, 9.929, 9.942,
      9.955, 9.969, 9.982, 9.995, 10.008, 10.021, 10.034, 10.047, 10.06, 10.073,
      10.086, 10.099, 10.111, 10.124, 10.137, 10.15, 10.162, 10.175, 10.187,
      10.2, 10.212, 10.225, 10.237, 10.249, 10.262, 10.274, 10.286, 10.298,
      10.311, 10.323, 10.335, 10.347, 10.359, 10.371, 10.383, 10.395, 10.407,
      10.419, 10.431, 10.442, 10.454, 10.466, 10.478, 10.489, 10.501, 10.513,
      10.524, 10.536, 10.547, 10.559, 10.57, 10.582, 10.593, 10.604, 10.616,
      10.627, 10.638, 10.65, 10.661, 10.672, 10.683, 10.695, 10.706, 10.717,
      10.728, 10.739, 10.75, 10.761, 10.772, 10.783, 10.794, 10.805, 10.816,
      10.827, 10.838, 10.849, 10.859, 10.87, 10.881, 10.892, 10.902, 10.913,
      10.924, 10.935, 10.945, 10.956, 10.966, 10.977, 10.988, 10.998, 11.009,
      11.019, 11.03, 11.04, 11.051, 11.061, 11.072, 11.082, 11.093, 11.103,
      11.113, 11.124, 11.134, 11.144, 11.154, 11.165, 11.175, 11.185, 11.196,
      11.206, 11.216, 11.226, 11.236, 11.247, 11.257, 11.267, 11.277, 11.287,
      11.297, 11.307, 11.317, 11.327, 11.337, 11.347, 11.357, 11.368, 11.377,
      11.387, 11.397, 11.407, 11.417, 11.427, 11.437, 11.447, 11.457, 11.467,
      11.477, 11.486, 11.496, 11.506, 11.516, 11.526, 11.535, 11.545, 11.555,
      11.565, 11.575, 11.584, 11.594, 11.604, 11.614, 11.623, 11.633, 11.643,
      11.652, 11.662, 11.672, 11.681, 11.691, 11.701, 11.71, 11.72, 11.729,
      11.739, 11.749, 11.758, 11.768, 11.777, 11.787, 11.796, 11.806, 11.816,
      11.825, 11.835, 11.844, 11.853, 11.863, 11.872, 11.882, 11.891, 11.901,
      11.91, 11.92, 11.929, 11.939, 11.948, 11.957, 11.967, 11.976, 11.986,
      11.995, 12.004, 12.014, 12.023, 12.032, 12.042, 12.051, 12.061, 12.07,
      12.079, 12.089, 12.098, 12.107, 12.116, 12.126, 12.135, 12.144, 12.153,
      12.163, 12.172, 12.181, 12.191, 12.2, 12.209, 12.219, 12.228, 12.237,
      12.246, 12.255, 12.265, 12.274, 12.283, 12.292, 12.301, 12.31, 12.32,
      12.329, 12.338, 12.347, 12.356, 12.366, 12.375, 12.384, 12.393, 12.402,
      12.411, 12.421, 12.43, 12.439, 12.448, 12.457, 12.466, 12.475, 12.484,
      12.494, 12.503, 12.512, 12.521, 12.53, 12.539, 12.548, 12.557, 12.567,
      12.576, 12.585, 12.594, 12.603, 12.612, 12.621, 12.63, 12.639, 12.648,
      12.657, 12.666, 12.675, 12.685, 12.694, 12.703, 12.712, 12.721, 12.73,
      12.739, 12.748, 12.757, 12.766, 12.775, 12.784, 12.793, 12.802, 12.811,
      12.82, 12.829, 12.838, 12.847, 12.856, 12.865, 12.874, 12.884, 12.893,
      12.902, 12.911, 12.92, 12.929, 12.938, 12.947, 12.956, 12.965, 12.974,
      12.983, 12.992, 13.001, 13.01, 13.019, 13.028, 13.037, 13.046, 13.055,
      13.064, 13.073, 13.082, 13.091, 13.1, 13.109, 13.118, 13.127, 13.136,
      13.145, 13.154, 13.163, 13.172, 13.18, 13.189, 13.198, 13.208, 13.217,
      13.225, 13.234, 13.243, 13.252, 13.261, 13.27, 13.279, 13.288, 13.297,
      13.306, 13.315, 13.324, 13.333, 13.342, 13.351, 13.36, 13.369, 13.378,
      13.387, 13.396, 13.405, 13.414, 13.422, 13.431, 13.44, 13.449, 13.458,
      13.467, 13.476, 13.485, 13.494, 13.503, 13.512, 13.521, 13.53, 13.539,
      13.548, 13.557, 13.566, 13.575, 13.583, 13.593, 13.601, 13.61, 13.619,
      13.628, 13.637, 13.646, 13.655, 13.664, 13.673, 13.682, 13.691, 13.7,
      13.709, 13.718, 13.727, 13.736, 13.745, 13.754, 13.762, 13.772, 13.78,
      13.789, 13.798, 13.807, 13.816, 13.825, 13.834, 13.843, 13.852, 13.861,
      13.87, 13.879, 13.888, 13.897, 13.906, 13.915, 13.924, 13.933, 13.942,
      13.951, 13.96, 13.969, 13.978, 13.987, 13.996, 14.005, 14.014, 14.023,
      14.032, 14.041, 14.05, 14.059, 14.068, 14.077, 14.086, 14.095, 14.104,
      14.113, 14.122, 14.131, 14.14, 14.149, 14.158, 14.167, 14.176, 14.185,
      14.194, 14.203, 14.212, 14.221, 14.23, 14.239, 14.248, 14.257, 14.267,
      14.276, 14.285, 14.294, 14.303, 14.312, 14.321, 14.33, 14.339, 14.348,
      14.357, 14.366, 14.375, 14.384, 14.393, 14.403, 14.412, 14.421, 14.43,
      14.439, 14.448, 14.457, 14.466, 14.475, 14.484, 14.494, 14.502, 14.512,
      14.521, 14.53, 14.539, 14.548, 14.557, 14.566, 14.575, 14.585, 14.594,
      14.603, 14.612, 14.621, 14.63, 14.639, 14.648, 14.657, 14.667, 14.676,
      14.685, 14.694, 14.703, 14.712, 14.722, 14.731, 14.74, 14.749, 14.758,
      14.767, 14.776, 14.786, 14.795, 14.804, 14.813, 14.822, 14.832, 14.841,
      14.85, 14.859, 14.868, 14.877, 14.886, 14.896, 14.905, 14.914, 14.923,
      14.932, 14.942, 14.951, 14.96, 14.969, 14.978, 14.988, 14.997, 15.006,
      15.015, 15.024, 15.034, 15.043, 15.052, 15.061, 15.07, 15.08, 15.089,
      15.098, 15.107, 15.116, 15.126, 15.135, 15.144, 15.153, 15.162, 15.172,
      15.181, 15.19, 15.199, 15.208, 15.217, 15.227, 15.236, 15.245, 15.254,
      15.264, 15.273, 15.282, 15.291, 15.3, 15.31, 15.319, 15.328, 15.337,
      15.347, 15.356, 15.365, 15.374, 15.383, 15.393, 15.402, 15.411, 15.42,
      15.429, 15.438, 15.448, 15.457, 15.466, 15.475, 15.484, 15.494, 15.503,
      15.512, 15.521, 15.531, 15.54, 15.549, 15.558, 15.567, 15.576, 15.586,
      15.595, 15.604, 15.613, 15.623, 15.632, 15.641, 15.65, 15.659, 15.668,
      15.678, 15.687, 15.696, 15.705, 15.714, 15.723, 15.732, 15.742, 15.751,
      15.76, 15.769, 15.778, 15.788, 15.797, 15.806, 15.815, 15.824, 15.833,
      15.842, 15.852, 15.861, 15.87, 15.879, 15.888, 15.897, 15.906, 15.915,
      15.924, 15.934, 15.943, 15.952, 15.961, 15.97, 15.979, 15.988, 15.997,
      16.006, 16.015, 16.025, 16.034, 16.043, 16.052, 16.061, 16.07, 16.079,
      16.088, 16.097, 16.106, 16.115, 16.124, 16.134, 16.143, 16.152, 16.161,
      16.17, 16.179, 16.188, 16.197, 16.206, 16.215, 16.224, 16.233, 16.242,
      16.251, 16.26, 16.269, 16.278, 16.287, 16.296, 16.305, 16.314, 16.323,
      16.332, 16.341, 16.35, 16.359, 16.368, 16.377, 16.386, 16.395, 16.404,
      16.413, 16.422, 16.431, 16.44, 16.449, 16.458, 16.467, 16.476, 16.485,
      16.494, 16.503, 16.512, 16.521, 16.53, 16.539, 16.548, 16.556, 16.566,
      16.574, 16.584, 16.592, 16.601, 16.61, 16.619, 16.628, 16.637, 16.646,
      16.655, 16.664, 16.673, 16.681, 16.691, 16.699, 16.708, 16.717, 16.726,
      16.735, 16.744, 16.753, 16.762, 16.771, 16.779, 16.788, 16.797, 16.806,
      16.815, 16.824, 16.833, 16.842, 16.85, 16.859, 16.868, 16.877, 16.886,
      16.895, 16.904, 16.912, 16.921, 16.93, 16.939, 16.948, 16.957, 16.966,
      16.975, 16.984, 16.992, 17.001, 17.01, 17.019, 17.028, 17.037, 17.045,
      17.054, 17.063, 17.072, 17.081, 17.09, 17.099, 17.107, 17.116, 17.125,
      17.134, 17.143, 17.152, 17.161, 17.17, 17.178, 17.187, 17.196, 17.205,
      17.214, 17.223, 17.232, 17.24, 17.249, 17.258, 17.267, 17.276, 17.285,
      17.293, 17.302, 17.311, 17.32, 17.329, 17.338, 17.346, 17.355, 17.364,
      17.373, 17.382, 17.391, 17.4, 17.409, 17.417, 17.426, 17.435, 17.444,
      17.453, 17.462, 17.471, 17.48, 17.488, 17.497, 17.506, 17.515, 17.524,
      17.533, 17.541, 17.55, 17.559, 17.568, 17.577, 17.586, 17.595, 17.604,
      17.613, 17.622, 17.631, 17.639, 17.648, 17.657, 17.666, 17.675, 17.684,
      17.693, 17.702, 17.71, 17.719, 17.728, 17.737, 17.746, 17.755, 17.764,
      17.773, 17.782, 17.791, 17.8, 17.809, 17.818, 17.827, 17.835, 17.844,
      17.853, 17.862, 17.871, 17.88, 17.889, 17.898, 17.907, 17.916, 17.925,
      17.934, 17.943, 17.952, 17.961, 17.97, 17.978, 17.987, 17.996, 18.005,
      18.015, 18.023, 18.032, 18.041, 18.05, 18.059, 18.068, 18.077, 18.086,
      18.095, 18.104, 18.113, 18.122, 18.131, 18.14, 18.149, 18.158, 18.167,
      18.176, 18.185, 18.194, 18.203, 18.212, 18.221, 18.23, 18.239, 18.248,
      18.257, 18.266, 18.275, 18.284, 18.293, 18.303, 18.311, 18.32, 18.33,
      18.339, 18.348, 18.357, 18.366, 18.375, 18.384, 18.393, 18.402, 18.411,
      18.42, 18.429, 18.438, 18.447, 18.457, 18.465, 18.475, 18.484, 18.493,
      18.502, 18.511, 18.52, 18.529, 18.538, 18.547, 18.556, 18.566, 18.574,
      18.584, 18.593, 18.602, 18.611, 18.62, 18.629, 18.638, 18.647, 18.657,
      18.666, 18.675, 18.684, 18.693, 18.702, 18.711, 18.721, 18.73, 18.739,
      18.748, 18.757, 18.766, 18.775, 18.784, 18.793, 18.803, 18.812, 18.821,
      18.83, 18.839, 18.848, 18.857, 18.867, 18.876, 18.885, 18.894, 18.903,
      18.912, 18.922, 18.931, 18.94, 18.949, 18.959, 18.967, 18.977, 18.986,
      18.995, 19.004, 19.013, 19.023, 19.032, 19.041, 19.05, 19.059, 19.068,
      19.078, 19.087, 19.096, 19.106, 19.115, 19.124, 19.133, 19.142, 19.151,
      19.161, 19.17, 19.179, 19.188, 19.197, 19.207, 19.216, 19.225, 19.234,
      19.243, 19.253, 19.262, 19.271, 19.28, 19.29, 19.299, 19.308, 19.317,
      19.327, 19.336, 19.345, 19.354, 19.364, 19.373, 19.382, 19.391, 19.4,
      19.41, 19.419, 19.428, 19.437, 19.446, 19.456, 19.465, 19.475, 19.483,
      19.493, 19.502, 19.511, 19.521, 19.53, 19.539, 19.548, 19.558, 19.567,
      19.576, 19.585, 19.594, 19.604, 19.613, 19.622, 19.631, 19.641, 19.65,
      19.659, 19.669, 19.678, 19.687, 19.696, 19.706, 19.715, 19.724, 19.733,
      19.743, 19.752, 19.761, 19.77, 19.78, 19.789, 19.798, 19.807, 19.817,
      19.826, 19.835, 19.845, 19.854, 19.863, 19.872, 19.882, 19.891, 19.9,
      19.909, 19.919, 19.928, 19.937, 19.946, 19.956, 19.965, 19.975, 19.984,
      19.993, 20.002, 20.012, 20.021, 20.03, 20.039, 20.049, 20.058, 20.067,
      20.076, 20.086, 20.095, 20.104, 20.113, 20.123, 20.132, 20.141, 20.151,
      20.16, 20.169, 20.179, 20.188, 20.197, 20.206, 20.216, 20.225, 20.234,
      20.243, 20.253, 20.262, 20.272, 20.28, 20.29, 20.299, 20.309, 20.318,
      20.327, 20.336, 20.346, 20.355, 20.364, 20.373, 20.383, 20.392, 20.402,
      20.41, 20.42, 20.429, 20.439, 20.448, 20.457, 20.466, 20.476, 20.485,
      20.495, 20.504, 20.513, 20.522, 20.532, 20.541, 20.55, 20.559, 20.569,
      20.578, 20.587, 20.597, 20.606, 20.615, 20.625, 20.634, 20.643, 20.652,
      20.662, 20.671, 20.68, 20.69, 20.699, 20.708, 20.717, 20.727, 20.736,
      20.745, 20.755, 20.764, 20.773, 20.783, 20.792, 20.801, 20.81, 20.82,
      20.829, 20.838, 20.848, 20.857, 20.866, 20.876, 20.885, 20.894, 20.903,
      20.913, 20.922, 20.931, 20.941, 20.95, 20.959, 20.969, 20.978, 20.987,
      20.997, 21.006, 21.015, 21.024, 21.034, 21.043, 21.052, 21.061, 21.071,
      21.08, 21.09, 21.099, 21.108, 21.117, 21.127, 21.136, 21.145, 21.155,
      21.164, 21.173, 21.183, 21.192, 21.201, 21.211, 21.22, 21.229, 21.238,
      21.248, 21.257, 21.267, 21.276, 21.285, 21.294, 21.304, 21.313, 21.322,
      21.332, 21.341, 21.35, 21.359, 21.369, 21.378, 21.388, 21.397, 21.406,
      21.415, 21.425, 21.434, 21.443, 21.453, 21.462, 21.471, 21.481, 21.49,
      21.499, 21.508, 21.518, 21.527, 21.537, 21.546, 21.555, 21.565, 21.574,
      21.584, 21.592, 21.602, 21.611, 21.621, 21.63, 21.639, 21.649, 21.658,
      21.667, 21.677, 21.686, 21.695, 21.705, 21.714, 21.723, 21.732, 21.742,
      21.751, 21.761, 21.77, 21.779, 21.789, 21.798, 21.807, 21.817, 21.826,
      21.835, 21.845, 21.854, 21.864, 21.872, 21.882, 21.892, 21.901, 21.91,
      21.919, 21.929, 21.938, 21.947, 21.957, 21.966, 21.975, 21.985, 21.994,
      22.003, 22.013, 22.022, 22.032, 22.041, 22.05, 22.06, 22.069, 22.078,
      22.088, 22.097, 22.106, 22.116, 22.125, 22.134, 22.144, 22.154, 22.162,
      22.172, 22.181, 22.191, 22.2, 22.21, 22.219, 22.228, 22.238, 22.247,
      22.256, 22.266, 22.275, 22.284, 22.294, 22.303, 22.312, 22.322, 22.331,
      22.34, 22.35, 22.359, 22.369, 22.378, 22.388, 22.397, 22.406, 22.416,
      22.425, 22.434, 22.444, 22.453, 22.462, 22.472, 22.481, 22.491, 22.5,
      22.509, 22.519, 22.528, 22.537, 22.547, 22.556, 22.565, 22.575, 22.584,
      22.594, 22.603, 22.612, 22.622, 22.631, 22.64, 22.65, 22.659, 22.669,
      22.678, 22.687, 22.697, 22.706, 22.715, 22.725, 22.734, 22.744, 22.753,
      22.762, 22.772, 22.781, 22.79, 22.8, 22.809, 22.819, 22.828, 22.837,
      22.847, 22.856, 22.866, 22.875, 22.884, 22.894, 22.903, 22.912, 22.922,
      22.931, 22.941, 22.95, 22.959, 22.969, 22.978, 22.988, 22.997, 23.006,
      23.015, 23.025, 23.034, 23.043, 23.053, 23.062, 23.072, 23.081, 23.09,
      23.1, 23.109, 23.119, 23.128, 23.137, 23.147, 23.156, 23.166, 23.175,
      23.184, 23.193, 23.203, 23.212, 23.222, 23.231, 23.24, 23.25, 23.259,
      23.269, 23.278, 23.287, 23.296, 23.306, 23.316, 23.325, 23.334, 23.343,
      23.353, 23.362, 23.372, 23.381, 23.39, 23.399, 23.409, 23.419, 23.428,
      23.437, 23.446, 23.456, 23.465, 23.475, 23.484, 23.493, 23.502, 23.512,
      23.521, 23.531, 23.54, 23.549, 23.559, 23.568, 23.577, 23.587, 23.596,
      23.605, 23.615, 23.624, 23.634, 23.643, 23.652, 23.661, 23.671, 23.68,
      23.69, 23.699, 23.708, 23.717, 23.727, 23.736, 23.746, 23.755, 23.764,
      23.774, 23.783, 23.792, 23.802, 23.811, 23.82, 23.83, 23.839, 23.848,
      23.858, 23.867, 23.876, 23.886, 23.895, 23.904, 23.913, 23.923, 23.932,
      23.941, 23.951, 23.96, 23.969, 23.979, 23.988, 23.998, 24.006, 24.016,
      24.025, 24.035, 24.044, 24.054, 24.062, 24.072, 24.081, 24.09, 24.1,
      24.109, 24.119, 24.127, 24.137, 24.146, 24.156, 24.165, 24.174, 24.184,
      24.193, 24.202, 24.211, 24.221, 24.23, 24.239, 24.249, 24.258, 24.267,
      24.276, 24.286, 24.295, 24.304, 24.313, 24.323, 24.332, 24.342, 24.351,
      24.36, 24.369, 24.378, 24.388, 24.397, 24.406, 24.415, 24.425, 24.434,
      24.443, 24.453, 24.462, 24.471, 24.48, 24.489, 24.499, 24.508, 24.518,
      24.527, 24.536, 24.545, 24.554, 24.564, 24.573, 24.582, 24.591, 24.6,
      24.61, 24.619, 24.629, 24.638, 24.647, 24.656, 24.665, 24.675, 24.684,
      24.693, 24.702, 24.711, 24.721, 24.73, 24.739, 24.748, 24.758, 24.767,
      24.776, 24.785, 24.795, 24.804, 24.813, 24.822, 24.831, 24.841, 24.85,
      24.859, 24.868, 24.877, 24.887, 24.896, 24.905, 24.914, 24.923, 24.932,
      24.942, 24.951, 24.96, 24.969, 24.978, 24.988, 24.997, 25.006, 25.016,
      25.025, 25.034, 25.043, 25.052, 25.062, 25.07, 25.08, 25.089, 25.098,
      25.108, 25.116, 25.126, 25.135, 25.144, 25.153, 25.163, 25.172, 25.181,
      25.19,
    ],
  },
  SD3: {
    label: '3 SD',
    weight: [
      4.793, 4.81, 4.846, 4.885, 4.925, 4.967, 5.009, 5.052, 5.096, 5.14, 5.186,
      5.232, 5.28, 5.329, 5.379, 5.431, 5.483, 5.536, 5.589, 5.642, 5.695,
      5.749, 5.802, 5.854, 5.906, 5.958, 6.01, 6.061, 6.112, 6.162, 6.212,
      6.261, 6.31, 6.358, 6.406, 6.453, 6.5, 6.546, 6.592, 6.637, 6.682, 6.726,
      6.77, 6.813, 6.856, 6.898, 6.941, 6.982, 7.024, 7.064, 7.105, 7.145,
      7.185, 7.224, 7.263, 7.301, 7.339, 7.377, 7.414, 7.452, 7.488, 7.525,
      7.561, 7.597, 7.633, 7.669, 7.704, 7.739, 7.773, 7.807, 7.842, 7.875,
      7.909, 7.942, 7.975, 8.008, 8.041, 8.073, 8.105, 8.137, 8.169, 8.2, 8.231,
      8.262, 8.293, 8.324, 8.354, 8.384, 8.414, 8.444, 8.473, 8.503, 8.532,
      8.561, 8.59, 8.618, 8.647, 8.675, 8.703, 8.731, 8.759, 8.786, 8.814,
      8.841, 8.868, 8.895, 8.922, 8.948, 8.975, 9.001, 9.027, 9.053, 9.079,
      9.104, 9.13, 9.155, 9.18, 9.206, 9.23, 9.255, 9.28, 9.304, 9.328, 9.352,
      9.376, 9.4, 9.424, 9.448, 9.471, 9.494, 9.517, 9.54, 9.563, 9.586, 9.609,
      9.631, 9.654, 9.676, 9.698, 9.72, 9.742, 9.764, 9.785, 9.807, 9.828,
      9.849, 9.87, 9.892, 9.912, 9.933, 9.954, 9.974, 9.995, 10.015, 10.036,
      10.056, 10.076, 10.096, 10.116, 10.136, 10.155, 10.175, 10.194, 10.214,
      10.233, 10.252, 10.271, 10.29, 10.309, 10.328, 10.347, 10.366, 10.384,
      10.403, 10.421, 10.44, 10.458, 10.477, 10.495, 10.513, 10.531, 10.549,
      10.566, 10.585, 10.602, 10.62, 10.638, 10.655, 10.673, 10.69, 10.707,
      10.725, 10.742, 10.759, 10.776, 10.793, 10.81, 10.827, 10.844, 10.861,
      10.877, 10.894, 10.911, 10.927, 10.944, 10.961, 10.977, 10.993, 11.009,
      11.026, 11.042, 11.058, 11.074, 11.09, 11.106, 11.122, 11.138, 11.154,
      11.17, 11.185, 11.201, 11.217, 11.232, 11.248, 11.263, 11.279, 11.294,
      11.31, 11.325, 11.34, 11.356, 11.371, 11.386, 11.401, 11.416, 11.431,
      11.446, 11.461, 11.476, 11.491, 11.506, 11.52, 11.535, 11.55, 11.564,
      11.579, 11.594, 11.608, 11.622, 11.637, 11.651, 11.666, 11.68, 11.694,
      11.709, 11.723, 11.737, 11.751, 11.765, 11.779, 11.793, 11.807, 11.821,
      11.835, 11.849, 11.863, 11.877, 11.891, 11.904, 11.918, 11.932, 11.945,
      11.959, 11.972, 11.986, 11.999, 12.013, 12.026, 12.04, 12.053, 12.066,
      12.08, 12.093, 12.106, 12.119, 12.133, 12.146, 12.159, 12.172, 12.185,
      12.198, 12.211, 12.224, 12.237, 12.25, 12.263, 12.276, 12.289, 12.302,
      12.314, 12.327, 12.34, 12.353, 12.366, 12.378, 12.391, 12.403, 12.416,
      12.429, 12.442, 12.454, 12.466, 12.479, 12.491, 12.504, 12.516, 12.529,
      12.541, 12.554, 12.566, 12.578, 12.59, 12.603, 12.615, 12.627, 12.639,
      12.651, 12.664, 12.676, 12.688, 12.7, 12.712, 12.724, 12.737, 12.748,
      12.761, 12.773, 12.785, 12.796, 12.808, 12.82, 12.833, 12.844, 12.856,
      12.868, 12.88, 12.892, 12.904, 12.915, 12.927, 12.939, 12.951, 12.962,
      12.974, 12.986, 12.997, 13.009, 13.021, 13.033, 13.044, 13.056, 13.068,
      13.079, 13.091, 13.102, 13.114, 13.125, 13.137, 13.148, 13.16, 13.171,
      13.183, 13.195, 13.206, 13.218, 13.229, 13.24, 13.251, 13.263, 13.274,
      13.286, 13.297, 13.309, 13.32, 13.331, 13.342, 13.354, 13.365, 13.376,
      13.387, 13.399, 13.41, 13.421, 13.432, 13.443, 13.455, 13.466, 13.477,
      13.488, 13.5, 13.511, 13.522, 13.533, 13.544, 13.555, 13.566, 13.577,
      13.588, 13.599, 13.61, 13.622, 13.632, 13.643, 13.655, 13.666, 13.676,
      13.688, 13.698, 13.709, 13.721, 13.731, 13.742, 13.753, 13.764, 13.775,
      13.786, 13.797, 13.808, 13.819, 13.829, 13.841, 13.852, 13.862, 13.873,
      13.884, 13.895, 13.906, 13.916, 13.927, 13.938, 13.949, 13.96, 13.97,
      13.981, 13.992, 14.003, 14.014, 14.024, 14.035, 14.046, 14.056, 14.067,
      14.078, 14.089, 14.099, 14.11, 14.121, 14.131, 14.142, 14.153, 14.163,
      14.174, 14.185, 14.195, 14.206, 14.217, 14.228, 14.238, 14.249, 14.259,
      14.27, 14.281, 14.291, 14.302, 14.312, 14.323, 14.334, 14.344, 14.355,
      14.366, 14.376, 14.387, 14.397, 14.408, 14.418, 14.429, 14.44, 14.45,
      14.461, 14.471, 14.482, 14.493, 14.503, 14.514, 14.524, 14.534, 14.545,
      14.556, 14.567, 14.577, 14.587, 14.598, 14.608, 14.619, 14.629, 14.64,
      14.65, 14.661, 14.672, 14.682, 14.693, 14.703, 14.714, 14.724, 14.735,
      14.745, 14.755, 14.766, 14.777, 14.787, 14.798, 14.808, 14.819, 14.829,
      14.84, 14.85, 14.86, 14.871, 14.882, 14.892, 14.903, 14.913, 14.923,
      14.934, 14.944, 14.955, 14.966, 14.976, 14.986, 14.997, 15.007, 15.018,
      15.028, 15.039, 15.049, 15.059, 15.07, 15.08, 15.091, 15.101, 15.112,
      15.122, 15.132, 15.143, 15.153, 15.164, 15.174, 15.185, 15.195, 15.205,
      15.216, 15.227, 15.237, 15.247, 15.258, 15.268, 15.279, 15.289, 15.299,
      15.31, 15.32, 15.331, 15.341, 15.351, 15.362, 15.372, 15.383, 15.393,
      15.403, 15.414, 15.425, 15.435, 15.445, 15.456, 15.466, 15.477, 15.487,
      15.497, 15.508, 15.518, 15.529, 15.539, 15.55, 15.56, 15.57, 15.58,
      15.591, 15.602, 15.612, 15.622, 15.633, 15.643, 15.654, 15.664, 15.674,
      15.685, 15.696, 15.706, 15.716, 15.727, 15.737, 15.747, 15.758, 15.769,
      15.779, 15.79, 15.8, 15.81, 15.821, 15.831, 15.841, 15.852, 15.863,
      15.873, 15.884, 15.894, 15.905, 15.915, 15.925, 15.936, 15.946, 15.957,
      15.967, 15.977, 15.988, 15.999, 16.009, 16.02, 16.03, 16.041, 16.052,
      16.062, 16.072, 16.083, 16.093, 16.104, 16.114, 16.125, 16.135, 16.146,
      16.156, 16.167, 16.177, 16.188, 16.198, 16.209, 16.22, 16.231, 16.241,
      16.252, 16.262, 16.273, 16.283, 16.294, 16.304, 16.315, 16.325, 16.336,
      16.347, 16.357, 16.368, 16.378, 16.389, 16.399, 16.41, 16.42, 16.431,
      16.442, 16.453, 16.463, 16.474, 16.484, 16.495, 16.506, 16.516, 16.527,
      16.538, 16.548, 16.559, 16.57, 16.58, 16.591, 16.602, 16.612, 16.623,
      16.634, 16.644, 16.655, 16.665, 16.676, 16.687, 16.697, 16.708, 16.719,
      16.729, 16.74, 16.751, 16.761, 16.772, 16.783, 16.793, 16.804, 16.815,
      16.826, 16.836, 16.847, 16.858, 16.869, 16.879, 16.89, 16.901, 16.912,
      16.922, 16.933, 16.944, 16.954, 16.965, 16.976, 16.987, 16.998, 17.008,
      17.019, 17.03, 17.041, 17.051, 17.062, 17.073, 17.084, 17.094, 17.105,
      17.116, 17.127, 17.138, 17.148, 17.159, 17.17, 17.181, 17.191, 17.202,
      17.213, 17.224, 17.235, 17.245, 17.256, 17.267, 17.278, 17.289, 17.299,
      17.31, 17.321, 17.332, 17.343, 17.354, 17.364, 17.375, 17.386, 17.397,
      17.408, 17.418, 17.429, 17.44, 17.451, 17.461, 17.472, 17.483, 17.494,
      17.505, 17.516, 17.527, 17.537, 17.548, 17.559, 17.57, 17.581, 17.592,
      17.603, 17.613, 17.624, 17.635, 17.646, 17.657, 17.668, 17.678, 17.689,
      17.7, 17.711, 17.722, 17.732, 17.743, 17.754, 17.765, 17.776, 17.787,
      17.798, 17.809, 17.819, 17.83, 17.841, 17.852, 17.863, 17.873, 17.884,
      17.895, 17.906, 17.917, 17.928, 17.939, 17.95, 17.961, 17.971, 17.982,
      17.993, 18.004, 18.015, 18.025, 18.036, 18.047, 18.058, 18.069, 18.08,
      18.091, 18.101, 18.112, 18.123, 18.134, 18.144, 18.155, 18.166, 18.177,
      18.188, 18.199, 18.209, 18.22, 18.231, 18.242, 18.253, 18.263, 18.274,
      18.285, 18.296, 18.307, 18.318, 18.328, 18.339, 18.35, 18.361, 18.371,
      18.382, 18.393, 18.404, 18.415, 18.425, 18.436, 18.447, 18.458, 18.468,
      18.479, 18.49, 18.501, 18.511, 18.522, 18.533, 18.544, 18.554, 18.565,
      18.576, 18.587, 18.597, 18.609, 18.619, 18.63, 18.641, 18.652, 18.662,
      18.673, 18.684, 18.694, 18.705, 18.716, 18.727, 18.737, 18.748, 18.759,
      18.77, 18.78, 18.791, 18.802, 18.812, 18.823, 18.834, 18.844, 18.855,
      18.866, 18.877, 18.887, 18.898, 18.909, 18.919, 18.93, 18.941, 18.952,
      18.962, 18.973, 18.984, 18.994, 19.005, 19.015, 19.027, 19.037, 19.048,
      19.058, 19.069, 19.08, 19.091, 19.101, 19.112, 19.122, 19.133, 19.144,
      19.155, 19.165, 19.176, 19.186, 19.197, 19.208, 19.219, 19.229, 19.24,
      19.251, 19.261, 19.272, 19.283, 19.293, 19.304, 19.314, 19.326, 19.336,
      19.347, 19.357, 19.368, 19.379, 19.389, 19.4, 19.41, 19.421, 19.432,
      19.442, 19.453, 19.463, 19.474, 19.485, 19.496, 19.506, 19.517, 19.527,
      19.538, 19.549, 19.559, 19.57, 19.581, 19.591, 19.602, 19.613, 19.623,
      19.634, 19.645, 19.656, 19.666, 19.677, 19.688, 19.698, 19.709, 19.72,
      19.731, 19.741, 19.752, 19.763, 19.773, 19.783, 19.794, 19.805, 19.816,
      19.826, 19.837, 19.848, 19.859, 19.869, 19.88, 19.891, 19.901, 19.912,
      19.922, 19.933, 19.944, 19.955, 19.966, 19.976, 19.987, 19.998, 20.008,
      20.019, 20.03, 20.04, 20.051, 20.062, 20.073, 20.083, 20.094, 20.105,
      20.115, 20.126, 20.137, 20.148, 20.159, 20.169, 20.18, 20.191, 20.202,
      20.213, 20.223, 20.234, 20.244, 20.255, 20.266, 20.277, 20.288, 20.299,
      20.309, 20.32, 20.331, 20.342, 20.353, 20.364, 20.374, 20.385, 20.396,
      20.407, 20.418, 20.428, 20.439, 20.449, 20.46, 20.471, 20.482, 20.494,
      20.504, 20.515, 20.526, 20.537, 20.548, 20.559, 20.569, 20.58, 20.591,
      20.602, 20.613, 20.624, 20.635, 20.645, 20.656, 20.667, 20.678, 20.689,
      20.7, 20.711, 20.722, 20.733, 20.744, 20.755, 20.765, 20.776, 20.787,
      20.799, 20.81, 20.821, 20.832, 20.842, 20.853, 20.864, 20.876, 20.886,
      20.897, 20.908, 20.919, 20.93, 20.942, 20.952, 20.963, 20.974, 20.985,
      20.997, 21.008, 21.018, 21.029, 21.04, 21.052, 21.063, 21.074, 21.085,
      21.096, 21.107, 21.118, 21.129, 21.14, 21.152, 21.162, 21.173, 21.185,
      21.196, 21.207, 21.218, 21.229, 21.24, 21.251, 21.262, 21.274, 21.285,
      21.296, 21.307, 21.318, 21.329, 21.341, 21.352, 21.363, 21.374, 21.385,
      21.396, 21.408, 21.419, 21.43, 21.441, 21.452, 21.463, 21.475, 21.486,
      21.497, 21.509, 21.519, 21.531, 21.542, 21.553, 21.565, 21.576, 21.587,
      21.598, 21.609, 21.621, 21.632, 21.643, 21.655, 21.666, 21.677, 21.688,
      21.7, 21.711, 21.722, 21.734, 21.745, 21.756, 21.768, 21.779, 21.791,
      21.802, 21.812, 21.824, 21.835, 21.847, 21.858, 21.87, 21.881, 21.892,
      21.903, 21.915, 21.926, 21.938, 21.949, 21.961, 21.971, 21.983, 21.995,
      22.006, 22.018, 22.029, 22.04, 22.051, 22.063, 22.074, 22.086, 22.097,
      22.109, 22.12, 22.131, 22.143, 22.154, 22.166, 22.177, 22.189, 22.2,
      22.211, 22.223, 22.234, 22.246, 22.257, 22.269, 22.28, 22.292, 22.303,
      22.314, 22.326, 22.338, 22.349, 22.361, 22.372, 22.383, 22.395, 22.406,
      22.418, 22.43, 22.441, 22.453, 22.464, 22.475, 22.487, 22.499, 22.51,
      22.522, 22.533, 22.544, 22.556, 22.567, 22.579, 22.591, 22.603, 22.614,
      22.625, 22.637, 22.648, 22.66, 22.672, 22.683, 22.695, 22.707, 22.718,
      22.729, 22.741, 22.752, 22.764, 22.776, 22.788, 22.799, 22.81, 22.822,
      22.833, 22.845, 22.857, 22.868, 22.88, 22.892, 22.903, 22.914, 22.926,
      22.938, 22.95, 22.961, 22.973, 22.985, 22.996, 23.008, 23.019, 23.031,
      23.042, 23.054, 23.066, 23.078, 23.089, 23.101, 23.112, 23.124, 23.135,
      23.147, 23.159, 23.171, 23.182, 23.194, 23.206, 23.217, 23.229, 23.24,
      23.252, 23.264, 23.276, 23.287, 23.299, 23.311, 23.322, 23.333, 23.345,
      23.357, 23.369, 23.381, 23.392, 23.404, 23.416, 23.427, 23.439, 23.45,
      23.462, 23.474, 23.486, 23.497, 23.509, 23.521, 23.533, 23.544, 23.556,
      23.567, 23.579, 23.591, 23.603, 23.614, 23.626, 23.638, 23.65, 23.661,
      23.673, 23.685, 23.696, 23.708, 23.719, 23.731, 23.743, 23.755, 23.767,
      23.778, 23.79, 23.802, 23.814, 23.826, 23.837, 23.849, 23.86, 23.872,
      23.884, 23.896, 23.907, 23.919, 23.931, 23.943, 23.955, 23.966, 23.978,
      23.99, 24.002, 24.014, 24.025, 24.037, 24.049, 24.06, 24.073, 24.084,
      24.095, 24.107, 24.119, 24.131, 24.142, 24.154, 24.166, 24.178, 24.19,
      24.201, 24.213, 24.225, 24.237, 24.249, 24.26, 24.272, 24.284, 24.296,
      24.308, 24.319, 24.331, 24.343, 24.355, 24.367, 24.378, 24.39, 24.402,
      24.414, 24.426, 24.437, 24.449, 24.461, 24.473, 24.485, 24.496, 24.508,
      24.52, 24.532, 24.543, 24.555, 24.567, 24.579, 24.591, 24.602, 24.614,
      24.626, 24.638, 24.65, 24.661, 24.673, 24.685, 24.697, 24.708, 24.72,
      24.732, 24.744, 24.756, 24.767, 24.779, 24.792, 24.804, 24.815, 24.827,
      24.839, 24.851, 24.863, 24.874, 24.886, 24.898, 24.91, 24.921, 24.933,
      24.945, 24.957, 24.969, 24.981, 24.993, 25.005, 25.017, 25.028, 25.04,
      25.052, 25.064, 25.075, 25.087, 25.1, 25.111, 25.123, 25.135, 25.147,
      25.159, 25.17, 25.182, 25.194, 25.206, 25.218, 25.23, 25.242, 25.254,
      25.266, 25.277, 25.289, 25.301, 25.314, 25.325, 25.337, 25.349, 25.36,
      25.372, 25.384, 25.396, 25.408, 25.42, 25.432, 25.444, 25.455, 25.468,
      25.48, 25.492, 25.503, 25.515, 25.527, 25.539, 25.551, 25.563, 25.575,
      25.586, 25.599, 25.611, 25.623, 25.634, 25.646, 25.659, 25.671, 25.682,
      25.694, 25.706, 25.718, 25.73, 25.742, 25.754, 25.766, 25.778, 25.79,
      25.801, 25.813, 25.826, 25.838, 25.849, 25.861, 25.873, 25.885, 25.897,
      25.909, 25.92, 25.933, 25.945, 25.957, 25.969, 25.981, 25.993, 26.004,
      26.017, 26.029, 26.04, 26.052, 26.065, 26.076, 26.088, 26.101, 26.113,
      26.124, 26.136, 26.148, 26.16, 26.172, 26.185, 26.196, 26.208, 26.22,
      26.232, 26.244, 26.256, 26.268, 26.28, 26.292, 26.304, 26.316, 26.328,
      26.339, 26.352, 26.364, 26.376, 26.388, 26.4, 26.412, 26.424, 26.436,
      26.447, 26.46, 26.471, 26.484, 26.496, 26.507, 26.52, 26.532, 26.544,
      26.556, 26.568, 26.58, 26.592, 26.604, 26.615, 26.628, 26.639, 26.652,
      26.664, 26.675, 26.688, 26.7, 26.712, 26.724, 26.736, 26.748, 26.76,
      26.772, 26.784, 26.796, 26.808, 26.82, 26.832, 26.844, 26.856, 26.868,
      26.88, 26.892, 26.904, 26.916, 26.928, 26.94, 26.952, 26.964, 26.977,
      26.988, 27, 27.012, 27.024, 27.037, 27.048, 27.061, 27.072, 27.084,
      27.096, 27.108, 27.121, 27.132, 27.145, 27.156, 27.169, 27.181, 27.192,
      27.205, 27.217, 27.229, 27.24, 27.253, 27.265, 27.277, 27.289, 27.301,
      27.313, 27.325, 27.337, 27.349, 27.361, 27.374, 27.385, 27.397, 27.409,
      27.421, 27.433, 27.445, 27.457, 27.469, 27.482, 27.493, 27.506, 27.517,
      27.53, 27.541, 27.554, 27.565, 27.578, 27.589, 27.602, 27.614, 27.626,
      27.638, 27.65, 27.662, 27.674, 27.686, 27.698, 27.71, 27.722, 27.734,
      27.746, 27.758, 27.77, 27.782, 27.794, 27.806, 27.818, 27.83, 27.842,
      27.854, 27.867, 27.879, 27.891, 27.903, 27.915, 27.926, 27.939, 27.95,
      27.963, 27.975, 27.987, 27.999, 28.011, 28.023, 28.035, 28.047, 28.059,
      28.071, 28.083, 28.095, 28.107, 28.119, 28.131, 28.143, 28.156, 28.167,
      28.18, 28.191, 28.203, 28.215, 28.227, 28.239, 28.251, 28.263, 28.275,
      28.287, 28.299, 28.311, 28.323, 28.335, 28.347, 28.36, 28.371, 28.384,
      28.395, 28.408, 28.419, 28.432, 28.443, 28.455, 28.467, 28.479, 28.492,
      28.503, 28.516, 28.527, 28.54, 28.551, 28.564, 28.575, 28.588, 28.6,
      28.611, 28.623, 28.635, 28.647, 28.659, 28.672, 28.683, 28.696, 28.707,
      28.719, 28.731, 28.743, 28.755, 28.767, 28.78, 28.791, 28.804, 28.815,
      28.827, 28.839, 28.851, 28.864, 28.875, 28.888, 28.899, 28.911, 28.923,
      28.935, 28.947, 28.959, 28.972, 28.983, 28.995, 29.007, 29.018, 29.031,
      29.043, 29.054, 29.067, 29.079, 29.091, 29.102, 29.115, 29.127, 29.138,
      29.151, 29.163, 29.175, 29.186, 29.198, 29.21, 29.222, 29.235, 29.246,
      29.258, 29.27, 29.282, 29.294, 29.306, 29.318, 29.33, 29.342, 29.354,
      29.366, 29.377, 29.39, 29.402, 29.413, 29.426, 29.437, 29.45, 29.461,
      29.473, 29.485, 29.497, 29.51, 29.521, 29.533, 29.545, 29.557, 29.568,
      29.581, 29.593, 29.605, 29.616, 29.628, 29.64, 29.652, 29.664, 29.677,
      29.688, 29.7, 29.712, 29.723, 29.736, 29.748, 29.759, 29.772, 29.783,
      29.796, 29.807, 29.819, 29.832, 29.843, 29.855, 29.867, 29.879, 29.89,
      29.903,
    ],
  },
  SD3neg: {
    label: '-3 SD',
    weight: [
      1.994, 2.002, 2.017, 2.033, 2.034, 2.053, 2.074, 2.096, 2.118, 2.141,
      2.165, 2.19, 2.216, 2.242, 2.269, 2.297, 2.325, 2.353, 2.382, 2.41, 2.439,
      2.468, 2.496, 2.525, 2.553, 2.582, 2.61, 2.638, 2.665, 2.693, 2.72, 2.747,
      2.774, 2.801, 2.827, 2.853, 2.879, 2.904, 2.93, 2.955, 2.98, 3.004, 3.029,
      3.053, 3.077, 3.1, 3.124, 3.147, 3.17, 3.192, 3.215, 3.237, 3.259, 3.281,
      3.303, 3.324, 3.345, 3.366, 3.387, 3.408, 3.428, 3.449, 3.469, 3.489,
      3.508, 3.528, 3.548, 3.567, 3.586, 3.605, 3.624, 3.643, 3.661, 3.679,
      3.698, 3.716, 3.734, 3.752, 3.769, 3.787, 3.804, 3.821, 3.838, 3.855,
      3.872, 3.889, 3.905, 3.922, 3.938, 3.955, 3.971, 3.987, 4.003, 4.018,
      4.034, 4.05, 4.065, 4.081, 4.096, 4.111, 4.126, 4.141, 4.156, 4.171,
      4.185, 4.2, 4.214, 4.229, 4.243, 4.257, 4.271, 4.285, 4.299, 4.313, 4.326,
      4.34, 4.353, 4.367, 4.38, 4.394, 4.407, 4.42, 4.433, 4.446, 4.458, 4.471,
      4.484, 4.496, 4.509, 4.521, 4.534, 4.546, 4.558, 4.57, 4.582, 4.594,
      4.606, 4.618, 4.629, 4.641, 4.652, 4.664, 4.675, 4.687, 4.698, 4.709,
      4.72, 4.731, 4.742, 4.753, 4.764, 4.775, 4.785, 4.796, 4.807, 4.817,
      4.828, 4.838, 4.848, 4.859, 4.869, 4.879, 4.889, 4.899, 4.909, 4.919,
      4.929, 4.938, 4.948, 4.958, 4.968, 4.977, 4.987, 4.996, 5.006, 5.015,
      5.024, 5.034, 5.043, 5.052, 5.061, 5.07, 5.079, 5.088, 5.097, 5.106,
      5.115, 5.124, 5.133, 5.141, 5.15, 5.159, 5.167, 5.176, 5.185, 5.193,
      5.201, 5.21, 5.218, 5.226, 5.235, 5.243, 5.251, 5.259, 5.267, 5.276,
      5.284, 5.292, 5.3, 5.307, 5.315, 5.323, 5.331, 5.339, 5.347, 5.354, 5.362,
      5.37, 5.377, 5.385, 5.393, 5.4, 5.407, 5.415, 5.423, 5.43, 5.437, 5.445,
      5.452, 5.459, 5.467, 5.474, 5.481, 5.488, 5.495, 5.502, 5.509, 5.517,
      5.524, 5.531, 5.538, 5.544, 5.551, 5.558, 5.565, 5.572, 5.579, 5.586,
      5.593, 5.599, 5.606, 5.613, 5.619, 5.626, 5.633, 5.639, 5.646, 5.652,
      5.659, 5.665, 5.672, 5.678, 5.685, 5.691, 5.698, 5.704, 5.711, 5.717,
      5.723, 5.73, 5.736, 5.742, 5.748, 5.755, 5.761, 5.767, 5.773, 5.779,
      5.785, 5.792, 5.798, 5.804, 5.81, 5.816, 5.822, 5.828, 5.834, 5.84, 5.846,
      5.852, 5.858, 5.864, 5.87, 5.876, 5.881, 5.887, 5.893, 5.899, 5.905,
      5.911, 5.917, 5.922, 5.928, 5.934, 5.94, 5.945, 5.951, 5.957, 5.962,
      5.968, 5.974, 5.98, 5.985, 5.991, 5.996, 6.002, 6.008, 6.013, 6.019,
      6.024, 6.03, 6.036, 6.041, 6.047, 6.052, 6.058, 6.063, 6.069, 6.074, 6.08,
      6.085, 6.091, 6.096, 6.102, 6.107, 6.113, 6.118, 6.124, 6.129, 6.134,
      6.14, 6.145, 6.151, 6.156, 6.161, 6.167, 6.172, 6.177, 6.183, 6.188,
      6.193, 6.199, 6.204, 6.209, 6.215, 6.22, 6.225, 6.231, 6.236, 6.241,
      6.247, 6.252, 6.257, 6.263, 6.268, 6.273, 6.278, 6.283, 6.289, 6.294,
      6.299, 6.304, 6.31, 6.315, 6.32, 6.325, 6.331, 6.336, 6.341, 6.346, 6.351,
      6.357, 6.362, 6.367, 6.372, 6.377, 6.382, 6.388, 6.393, 6.398, 6.403,
      6.408, 6.413, 6.419, 6.424, 6.429, 6.434, 6.439, 6.444, 6.449, 6.455,
      6.46, 6.465, 6.47, 6.475, 6.48, 6.485, 6.49, 6.495, 6.501, 6.506, 6.511,
      6.516, 6.521, 6.526, 6.531, 6.536, 6.541, 6.546, 6.551, 6.557, 6.562,
      6.567, 6.572, 6.577, 6.582, 6.587, 6.592, 6.597, 6.602, 6.607, 6.612,
      6.617, 6.622, 6.628, 6.632, 6.638, 6.643, 6.648, 6.653, 6.658, 6.663,
      6.668, 6.673, 6.678, 6.683, 6.688, 6.693, 6.698, 6.703, 6.708, 6.713,
      6.718, 6.723, 6.728, 6.733, 6.738, 6.743, 6.748, 6.753, 6.758, 6.763,
      6.768, 6.773, 6.778, 6.783, 6.788, 6.793, 6.798, 6.803, 6.808, 6.813,
      6.818, 6.823, 6.828, 6.833, 6.838, 6.843, 6.848, 6.853, 6.858, 6.863,
      6.868, 6.873, 6.878, 6.883, 6.888, 6.893, 6.898, 6.903, 6.908, 6.913,
      6.918, 6.923, 6.928, 6.933, 6.937, 6.942, 6.947, 6.952, 6.957, 6.962,
      6.967, 6.972, 6.977, 6.982, 6.987, 6.992, 6.997, 7.002, 7.007, 7.012,
      7.017, 7.022, 7.027, 7.032, 7.036, 7.041, 7.046, 7.051, 7.056, 7.061,
      7.066, 7.071, 7.076, 7.081, 7.086, 7.091, 7.095, 7.1, 7.105, 7.11, 7.115,
      7.12, 7.125, 7.13, 7.135, 7.14, 7.145, 7.149, 7.154, 7.159, 7.164, 7.169,
      7.174, 7.179, 7.184, 7.189, 7.194, 7.198, 7.203, 7.208, 7.213, 7.218,
      7.223, 7.227, 7.232, 7.237, 7.242, 7.247, 7.252, 7.257, 7.262, 7.266,
      7.271, 7.276, 7.281, 7.286, 7.291, 7.296, 7.301, 7.305, 7.31, 7.315, 7.32,
      7.325, 7.329, 7.334, 7.339, 7.344, 7.349, 7.354, 7.359, 7.363, 7.368,
      7.373, 7.378, 7.383, 7.388, 7.392, 7.397, 7.402, 7.407, 7.411, 7.416,
      7.421, 7.426, 7.431, 7.436, 7.441, 7.445, 7.45, 7.455, 7.46, 7.464, 7.469,
      7.474, 7.479, 7.484, 7.488, 7.493, 7.498, 7.503, 7.508, 7.512, 7.517,
      7.522, 7.527, 7.532, 7.536, 7.541, 7.546, 7.551, 7.556, 7.56, 7.565, 7.57,
      7.575, 7.579, 7.584, 7.589, 7.594, 7.599, 7.603, 7.608, 7.613, 7.618,
      7.622, 7.627, 7.632, 7.637, 7.641, 7.646, 7.651, 7.656, 7.66, 7.665, 7.67,
      7.675, 7.679, 7.684, 7.689, 7.694, 7.699, 7.703, 7.708, 7.713, 7.718,
      7.722, 7.727, 7.732, 7.737, 7.741, 7.746, 7.751, 7.756, 7.76, 7.765, 7.77,
      7.775, 7.779, 7.784, 7.789, 7.794, 7.798, 7.803, 7.808, 7.813, 7.817,
      7.822, 7.827, 7.832, 7.836, 7.841, 7.846, 7.851, 7.855, 7.86, 7.865,
      7.869, 7.874, 7.879, 7.884, 7.889, 7.893, 7.898, 7.903, 7.907, 7.912,
      7.917, 7.922, 7.927, 7.931, 7.936, 7.941, 7.945, 7.95, 7.955, 7.96, 7.964,
      7.969, 7.974, 7.978, 7.983, 7.988, 7.993, 7.997, 8.002, 8.007, 8.011,
      8.016, 8.021, 8.026, 8.031, 8.035, 8.04, 8.045, 8.049, 8.054, 8.059,
      8.064, 8.068, 8.073, 8.078, 8.082, 8.087, 8.092, 8.096, 8.101, 8.106,
      8.111, 8.115, 8.12, 8.125, 8.13, 8.134, 8.139, 8.144, 8.148, 8.153, 8.158,
      8.162, 8.167, 8.172, 8.176, 8.181, 8.186, 8.191, 8.195, 8.2, 8.205, 8.209,
      8.214, 8.219, 8.223, 8.228, 8.233, 8.237, 8.242, 8.247, 8.251, 8.256,
      8.261, 8.265, 8.27, 8.275, 8.279, 8.284, 8.289, 8.293, 8.298, 8.303,
      8.307, 8.312, 8.317, 8.321, 8.326, 8.33, 8.335, 8.34, 8.344, 8.349, 8.354,
      8.358, 8.363, 8.368, 8.372, 8.377, 8.381, 8.386, 8.391, 8.395, 8.4, 8.404,
      8.409, 8.414, 8.418, 8.423, 8.427, 8.432, 8.437, 8.441, 8.446, 8.45,
      8.455, 8.459, 8.464, 8.468, 8.473, 8.477, 8.482, 8.487, 8.491, 8.496, 8.5,
      8.505, 8.509, 8.514, 8.518, 8.523, 8.527, 8.532, 8.536, 8.541, 8.545,
      8.55, 8.554, 8.559, 8.563, 8.568, 8.572, 8.577, 8.581, 8.586, 8.59, 8.595,
      8.599, 8.604, 8.608, 8.612, 8.617, 8.621, 8.626, 8.63, 8.635, 8.639,
      8.644, 8.648, 8.652, 8.657, 8.661, 8.666, 8.67, 8.674, 8.679, 8.683,
      8.688, 8.692, 8.696, 8.701, 8.705, 8.709, 8.714, 8.718, 8.723, 8.727,
      8.731, 8.736, 8.74, 8.744, 8.749, 8.753, 8.757, 8.762, 8.766, 8.77, 8.775,
      8.779, 8.783, 8.788, 8.792, 8.796, 8.801, 8.805, 8.809, 8.813, 8.817,
      8.822, 8.826, 8.83, 8.835, 8.839, 8.843, 8.847, 8.852, 8.856, 8.86, 8.864,
      8.868, 8.873, 8.877, 8.881, 8.885, 8.89, 8.894, 8.898, 8.902, 8.906,
      8.911, 8.915, 8.919, 8.923, 8.927, 8.931, 8.936, 8.94, 8.944, 8.948,
      8.952, 8.956, 8.961, 8.965, 8.969, 8.973, 8.977, 8.981, 8.986, 8.99,
      8.994, 8.998, 9.002, 9.006, 9.01, 9.014, 9.018, 9.022, 9.027, 9.03, 9.035,
      9.039, 9.043, 9.047, 9.051, 9.055, 9.059, 9.063, 9.067, 9.071, 9.075,
      9.079, 9.083, 9.088, 9.092, 9.096, 9.1, 9.104, 9.108, 9.112, 9.116, 9.12,
      9.124, 9.128, 9.132, 9.136, 9.14, 9.144, 9.148, 9.152, 9.156, 9.16, 9.164,
      9.168, 9.172, 9.176, 9.18, 9.184, 9.188, 9.192, 9.196, 9.2, 9.204, 9.207,
      9.212, 9.216, 9.219, 9.223, 9.227, 9.231, 9.235, 9.239, 9.243, 9.247,
      9.251, 9.255, 9.259, 9.263, 9.267, 9.271, 9.275, 9.279, 9.282, 9.286,
      9.29, 9.294, 9.298, 9.302, 9.306, 9.31, 9.314, 9.318, 9.322, 9.325, 9.329,
      9.333, 9.337, 9.341, 9.345, 9.349, 9.353, 9.357, 9.36, 9.364, 9.368,
      9.372, 9.376, 9.38, 9.384, 9.387, 9.391, 9.395, 9.399, 9.403, 9.407, 9.41,
      9.414, 9.418, 9.422, 9.426, 9.43, 9.434, 9.438, 9.441, 9.445, 9.449,
      9.453, 9.457, 9.46, 9.464, 9.468, 9.472, 9.476, 9.48, 9.483, 9.487, 9.491,
      9.495, 9.499, 9.503, 9.506, 9.51, 9.514, 9.518, 9.522, 9.525, 9.529,
      9.533, 9.537, 9.541, 9.544, 9.548, 9.552, 9.556, 9.559, 9.563, 9.567,
      9.571, 9.575, 9.578, 9.582, 9.586, 9.59, 9.594, 9.597, 9.601, 9.605,
      9.608, 9.612, 9.616, 9.62, 9.624, 9.627, 9.631, 9.635, 9.639, 9.642,
      9.646, 9.65, 9.654, 9.657, 9.661, 9.665, 9.669, 9.672, 9.676, 9.68, 9.684,
      9.687, 9.691, 9.695, 9.698, 9.702, 9.706, 9.71, 9.713, 9.717, 9.721,
      9.725, 9.728, 9.732, 9.736, 9.739, 9.743, 9.747, 9.751, 9.754, 9.758,
      9.762, 9.765, 9.769, 9.773, 9.776, 9.78, 9.784, 9.787, 9.791, 9.795,
      9.798, 9.802, 9.806, 9.809, 9.813, 9.817, 9.821, 9.824, 9.828, 9.832,
      9.835, 9.839, 9.842, 9.846, 9.85, 9.853, 9.857, 9.861, 9.864, 9.868,
      9.872, 9.875, 9.879, 9.883, 9.886, 9.89, 9.894, 9.897, 9.901, 9.905,
      9.908, 9.912, 9.915, 9.919, 9.923, 9.926, 9.93, 9.934, 9.937, 9.941,
      9.944, 9.948, 9.952, 9.955, 9.959, 9.962, 9.966, 9.969, 9.973, 9.977,
      9.98, 9.984, 9.988, 9.991, 9.995, 9.998, 10.002, 10.005, 10.009, 10.013,
      10.016, 10.02, 10.023, 10.027, 10.03, 10.034, 10.038, 10.041, 10.045,
      10.048, 10.052, 10.055, 10.059, 10.062, 10.066, 10.07, 10.073, 10.077,
      10.08, 10.084, 10.087, 10.091, 10.094, 10.098, 10.101, 10.105, 10.108,
      10.112, 10.116, 10.119, 10.123, 10.126, 10.13, 10.133, 10.137, 10.14,
      10.144, 10.147, 10.151, 10.154, 10.158, 10.161, 10.165, 10.168, 10.172,
      10.175, 10.179, 10.182, 10.186, 10.189, 10.193, 10.196, 10.2, 10.203,
      10.207, 10.21, 10.214, 10.217, 10.221, 10.224, 10.228, 10.231, 10.234,
      10.238, 10.242, 10.245, 10.248, 10.252, 10.255, 10.259, 10.262, 10.266,
      10.269, 10.273, 10.276, 10.28, 10.283, 10.286, 10.29, 10.293, 10.297,
      10.3, 10.304, 10.307, 10.31, 10.314, 10.318, 10.321, 10.324, 10.328,
      10.331, 10.335, 10.338, 10.341, 10.345, 10.348, 10.352, 10.355, 10.358,
      10.362, 10.365, 10.369, 10.372, 10.375, 10.379, 10.382, 10.386, 10.389,
      10.393, 10.396, 10.399, 10.403, 10.406, 10.41, 10.413, 10.416, 10.42,
      10.423, 10.427, 10.43, 10.433, 10.437, 10.44, 10.443, 10.447, 10.45,
      10.453, 10.457, 10.46, 10.464, 10.467, 10.47, 10.474, 10.477, 10.481,
      10.484, 10.487, 10.491, 10.494, 10.497, 10.501, 10.504, 10.508, 10.511,
      10.514, 10.517, 10.521, 10.524, 10.528, 10.531, 10.534, 10.538, 10.541,
      10.544, 10.548, 10.551, 10.554, 10.558, 10.561, 10.564, 10.568, 10.571,
      10.574, 10.578, 10.581, 10.584, 10.588, 10.591, 10.595, 10.598, 10.601,
      10.604, 10.608, 10.611, 10.615, 10.618, 10.621, 10.624, 10.628, 10.631,
      10.634, 10.638, 10.641, 10.645, 10.648, 10.651, 10.654, 10.657, 10.661,
      10.664, 10.668, 10.671, 10.674, 10.678, 10.681, 10.684, 10.687, 10.691,
      10.694, 10.697, 10.701, 10.704, 10.707, 10.711, 10.714, 10.717, 10.72,
      10.724, 10.727, 10.731, 10.734, 10.737, 10.74, 10.744, 10.747, 10.75,
      10.754, 10.757, 10.76, 10.763, 10.767, 10.77, 10.773, 10.777, 10.78,
      10.783, 10.787, 10.79, 10.793, 10.796, 10.8, 10.803, 10.806, 10.81,
      10.813, 10.816, 10.819, 10.823, 10.826, 10.829, 10.833, 10.836, 10.839,
      10.843, 10.846, 10.849, 10.852, 10.856, 10.859, 10.862, 10.865, 10.869,
      10.872, 10.875, 10.879, 10.882, 10.885, 10.888, 10.892, 10.895, 10.898,
      10.902, 10.905, 10.908, 10.911, 10.915, 10.918, 10.921, 10.925, 10.928,
      10.931, 10.934, 10.938, 10.941, 10.944, 10.948, 10.951, 10.954, 10.957,
      10.961, 10.964, 10.967, 10.97, 10.974, 10.977, 10.98, 10.983, 10.987,
      10.99, 10.993, 10.997, 11, 11.003, 11.006, 11.01, 11.013, 11.016, 11.019,
      11.023, 11.026, 11.029, 11.033, 11.036, 11.039, 11.042, 11.046, 11.049,
      11.052, 11.055, 11.059, 11.062, 11.065, 11.068, 11.072, 11.075, 11.078,
      11.081, 11.085, 11.088, 11.091, 11.094, 11.097, 11.101, 11.104, 11.107,
      11.111, 11.114, 11.117, 11.121, 11.124, 11.127, 11.13, 11.134, 11.137,
      11.14, 11.143, 11.147, 11.15, 11.153, 11.156, 11.16, 11.163, 11.166,
      11.169, 11.173, 11.176, 11.179, 11.182, 11.186, 11.189, 11.192, 11.195,
      11.199, 11.202, 11.205, 11.208, 11.212, 11.215, 11.218, 11.221, 11.225,
      11.228, 11.231, 11.234, 11.238, 11.241, 11.244, 11.248, 11.251, 11.254,
      11.257, 11.26, 11.264, 11.267, 11.27, 11.273, 11.277, 11.28, 11.283,
      11.286, 11.29, 11.293, 11.296, 11.299, 11.303, 11.306, 11.309, 11.312,
      11.316, 11.319, 11.322, 11.325, 11.329, 11.332, 11.335, 11.338, 11.342,
      11.345, 11.348, 11.351, 11.355, 11.358, 11.361, 11.364, 11.368, 11.371,
      11.374, 11.377, 11.38, 11.384, 11.387, 11.39, 11.394, 11.397, 11.4,
      11.403, 11.407, 11.41, 11.413, 11.416, 11.419, 11.423, 11.426, 11.429,
      11.432, 11.435, 11.439, 11.442, 11.445, 11.448, 11.452, 11.455, 11.458,
      11.461, 11.464, 11.468, 11.471, 11.474, 11.478, 11.481, 11.484, 11.487,
      11.49, 11.494, 11.497, 11.5, 11.504, 11.507, 11.51, 11.513, 11.516, 11.52,
      11.523, 11.526, 11.529, 11.532, 11.535, 11.539, 11.542, 11.545, 11.548,
      11.551, 11.555, 11.558, 11.561, 11.564, 11.568, 11.571, 11.574, 11.577,
      11.581, 11.584, 11.587, 11.59, 11.593, 11.597, 11.6, 11.603, 11.606,
      11.609, 11.612, 11.616, 11.619, 11.622, 11.625, 11.629, 11.632, 11.635,
      11.638, 11.641, 11.644, 11.648, 11.651, 11.654, 11.657, 11.661, 11.664,
      11.667, 11.67, 11.673, 11.676, 11.68, 11.683, 11.686, 11.689, 11.692,
      11.695, 11.699, 11.702, 11.705, 11.708, 11.711, 11.715, 11.718, 11.721,
      11.724, 11.727, 11.73, 11.734, 11.737, 11.74, 11.743, 11.746, 11.749,
      11.753, 11.756, 11.759, 11.762, 11.765, 11.769, 11.772, 11.775, 11.778,
      11.781, 11.784, 11.788, 11.791, 11.794, 11.797, 11.8, 11.803, 11.806,
      11.81, 11.813, 11.816, 11.819, 11.822, 11.825, 11.829, 11.832, 11.835,
      11.838, 11.841, 11.844, 11.847, 11.851, 11.854, 11.857, 11.86, 11.863,
      11.866, 11.869, 11.873, 11.876, 11.879, 11.882, 11.885, 11.888, 11.891,
      11.894, 11.897, 11.901, 11.904, 11.907, 11.91, 11.913, 11.916, 11.919,
      11.923, 11.926, 11.929, 11.932, 11.935, 11.938, 11.941, 11.944, 11.948,
      11.951, 11.954, 11.957, 11.96, 11.963, 11.966, 11.969, 11.972, 11.975,
      11.979, 11.982, 11.985, 11.988, 11.991, 11.994, 11.997, 12, 12.003,
      12.007, 12.01, 12.013, 12.016, 12.019, 12.022, 12.025, 12.028, 12.031,
      12.034, 12.037, 12.041, 12.044, 12.047, 12.05, 12.053, 12.056, 12.059,
      12.062, 12.065, 12.068, 12.071, 12.075, 12.077, 12.081, 12.084, 12.087,
      12.09, 12.093, 12.096, 12.099, 12.102, 12.105, 12.108, 12.111, 12.114,
      12.117, 12.12, 12.124, 12.127, 12.13, 12.133, 12.136, 12.139, 12.142,
      12.145,
    ],
  },
  SD2neg: {
    label: '-2 SD',
    weight: [
      2.352, 2.362, 2.378, 2.395, 2.397, 2.418, 2.44, 2.464, 2.488, 2.513, 2.54,
      2.567, 2.595, 2.623, 2.653, 2.683, 2.714, 2.745, 2.777, 2.808, 2.84,
      2.871, 2.903, 2.934, 2.965, 2.996, 3.027, 3.058, 3.088, 3.118, 3.148,
      3.178, 3.207, 3.236, 3.265, 3.294, 3.322, 3.35, 3.377, 3.405, 3.432,
      3.459, 3.485, 3.512, 3.538, 3.564, 3.589, 3.614, 3.639, 3.664, 3.689,
      3.713, 3.737, 3.761, 3.785, 3.808, 3.831, 3.854, 3.877, 3.899, 3.922,
      3.944, 3.966, 3.987, 4.009, 4.031, 4.052, 4.073, 4.094, 4.114, 4.135,
      4.155, 4.175, 4.195, 4.215, 4.235, 4.255, 4.274, 4.293, 4.312, 4.331,
      4.35, 4.369, 4.387, 4.406, 4.424, 4.442, 4.46, 4.478, 4.496, 4.513, 4.531,
      4.548, 4.565, 4.582, 4.599, 4.616, 4.633, 4.65, 4.666, 4.683, 4.699,
      4.715, 4.731, 4.747, 4.763, 4.779, 4.794, 4.81, 4.825, 4.841, 4.856,
      4.871, 4.886, 4.901, 4.916, 4.93, 4.945, 4.96, 4.974, 4.988, 5.003, 5.017,
      5.031, 5.045, 5.059, 5.073, 5.086, 5.1, 5.113, 5.127, 5.14, 5.154, 5.167,
      5.18, 5.193, 5.206, 5.219, 5.231, 5.244, 5.257, 5.269, 5.282, 5.294,
      5.306, 5.318, 5.331, 5.343, 5.355, 5.366, 5.378, 5.39, 5.402, 5.413,
      5.425, 5.436, 5.448, 5.459, 5.47, 5.482, 5.493, 5.504, 5.515, 5.526,
      5.537, 5.548, 5.558, 5.569, 5.58, 5.59, 5.601, 5.611, 5.622, 5.632, 5.642,
      5.653, 5.663, 5.673, 5.683, 5.693, 5.703, 5.713, 5.723, 5.733, 5.743,
      5.753, 5.762, 5.772, 5.782, 5.791, 5.801, 5.81, 5.82, 5.829, 5.838, 5.848,
      5.857, 5.866, 5.875, 5.884, 5.893, 5.902, 5.911, 5.92, 5.929, 5.938,
      5.947, 5.956, 5.965, 5.973, 5.982, 5.991, 5.999, 6.008, 6.016, 6.025,
      6.033, 6.042, 6.05, 6.059, 6.067, 6.075, 6.083, 6.092, 6.1, 6.108, 6.116,
      6.124, 6.132, 6.14, 6.148, 6.156, 6.164, 6.172, 6.18, 6.188, 6.196, 6.204,
      6.211, 6.219, 6.227, 6.234, 6.242, 6.25, 6.257, 6.265, 6.272, 6.28, 6.287,
      6.295, 6.302, 6.31, 6.317, 6.324, 6.332, 6.339, 6.346, 6.354, 6.361,
      6.368, 6.375, 6.382, 6.389, 6.397, 6.404, 6.411, 6.418, 6.425, 6.432,
      6.439, 6.446, 6.453, 6.46, 6.466, 6.473, 6.48, 6.487, 6.494, 6.501, 6.507,
      6.514, 6.521, 6.528, 6.534, 6.541, 6.548, 6.554, 6.561, 6.568, 6.574,
      6.581, 6.587, 6.594, 6.601, 6.607, 6.614, 6.62, 6.626, 6.633, 6.639,
      6.646, 6.652, 6.659, 6.665, 6.671, 6.678, 6.684, 6.69, 6.697, 6.703, 6.71,
      6.716, 6.722, 6.728, 6.734, 6.741, 6.747, 6.753, 6.759, 6.766, 6.772,
      6.778, 6.784, 6.79, 6.796, 6.803, 6.809, 6.815, 6.821, 6.827, 6.833,
      6.839, 6.845, 6.851, 6.857, 6.863, 6.869, 6.875, 6.882, 6.887, 6.893,
      6.899, 6.905, 6.911, 6.917, 6.923, 6.929, 6.935, 6.941, 6.947, 6.953,
      6.959, 6.965, 6.971, 6.977, 6.983, 6.988, 6.994, 7, 7.006, 7.012, 7.018,
      7.024, 7.03, 7.035, 7.041, 7.047, 7.053, 7.059, 7.064, 7.07, 7.076, 7.082,
      7.088, 7.093, 7.099, 7.105, 7.111, 7.116, 7.122, 7.128, 7.134, 7.14,
      7.145, 7.151, 7.157, 7.162, 7.168, 7.174, 7.18, 7.185, 7.191, 7.197,
      7.203, 7.208, 7.214, 7.22, 7.225, 7.231, 7.237, 7.242, 7.248, 7.254,
      7.259, 7.265, 7.271, 7.276, 7.282, 7.288, 7.293, 7.299, 7.305, 7.31,
      7.316, 7.322, 7.327, 7.333, 7.338, 7.344, 7.35, 7.355, 7.361, 7.367,
      7.372, 7.378, 7.383, 7.389, 7.395, 7.4, 7.406, 7.411, 7.417, 7.423, 7.428,
      7.434, 7.439, 7.445, 7.451, 7.456, 7.462, 7.467, 7.473, 7.478, 7.484,
      7.49, 7.495, 7.501, 7.506, 7.512, 7.517, 7.523, 7.529, 7.534, 7.54, 7.545,
      7.551, 7.556, 7.562, 7.567, 7.573, 7.578, 7.584, 7.59, 7.595, 7.601,
      7.606, 7.612, 7.617, 7.623, 7.628, 7.634, 7.639, 7.645, 7.65, 7.656,
      7.661, 7.667, 7.672, 7.678, 7.684, 7.689, 7.695, 7.7, 7.706, 7.711, 7.717,
      7.722, 7.728, 7.733, 7.739, 7.744, 7.75, 7.755, 7.761, 7.766, 7.772,
      7.777, 7.783, 7.788, 7.794, 7.799, 7.805, 7.81, 7.816, 7.821, 7.827,
      7.832, 7.837, 7.843, 7.848, 7.854, 7.859, 7.865, 7.87, 7.876, 7.881,
      7.887, 7.892, 7.898, 7.903, 7.909, 7.914, 7.92, 7.925, 7.931, 7.936,
      7.941, 7.947, 7.952, 7.958, 7.963, 7.969, 7.974, 7.98, 7.985, 7.99, 7.996,
      8.001, 8.007, 8.012, 8.018, 8.023, 8.028, 8.034, 8.039, 8.045, 8.05,
      8.056, 8.061, 8.066, 8.072, 8.077, 8.083, 8.088, 8.093, 8.099, 8.104,
      8.11, 8.115, 8.121, 8.126, 8.131, 8.137, 8.142, 8.147, 8.153, 8.158,
      8.164, 8.169, 8.175, 8.18, 8.185, 8.191, 8.196, 8.201, 8.207, 8.212,
      8.218, 8.223, 8.228, 8.234, 8.239, 8.244, 8.25, 8.255, 8.261, 8.266,
      8.271, 8.277, 8.282, 8.287, 8.293, 8.298, 8.303, 8.309, 8.314, 8.319,
      8.325, 8.33, 8.336, 8.341, 8.346, 8.352, 8.357, 8.362, 8.368, 8.373,
      8.378, 8.384, 8.389, 8.394, 8.4, 8.405, 8.411, 8.416, 8.421, 8.427, 8.432,
      8.437, 8.443, 8.448, 8.453, 8.459, 8.464, 8.469, 8.475, 8.48, 8.485,
      8.491, 8.496, 8.501, 8.507, 8.512, 8.517, 8.523, 8.528, 8.533, 8.539,
      8.544, 8.549, 8.555, 8.56, 8.565, 8.571, 8.576, 8.581, 8.587, 8.592,
      8.597, 8.602, 8.608, 8.613, 8.619, 8.624, 8.629, 8.634, 8.64, 8.645, 8.65,
      8.656, 8.661, 8.666, 8.672, 8.677, 8.682, 8.688, 8.693, 8.698, 8.704,
      8.709, 8.714, 8.72, 8.725, 8.73, 8.736, 8.741, 8.746, 8.751, 8.757, 8.762,
      8.768, 8.773, 8.778, 8.783, 8.789, 8.794, 8.799, 8.805, 8.81, 8.815,
      8.821, 8.826, 8.831, 8.837, 8.842, 8.847, 8.853, 8.858, 8.863, 8.869,
      8.874, 8.879, 8.884, 8.89, 8.895, 8.9, 8.906, 8.911, 8.916, 8.922, 8.927,
      8.932, 8.938, 8.943, 8.948, 8.954, 8.959, 8.964, 8.969, 8.975, 8.98,
      8.985, 8.991, 8.996, 9.001, 9.007, 9.012, 9.017, 9.023, 9.028, 9.033,
      9.039, 9.044, 9.049, 9.055, 9.06, 9.065, 9.07, 9.076, 9.081, 9.086, 9.092,
      9.097, 9.102, 9.108, 9.113, 9.118, 9.123, 9.129, 9.134, 9.139, 9.144,
      9.15, 9.155, 9.16, 9.166, 9.171, 9.176, 9.182, 9.187, 9.192, 9.197, 9.203,
      9.208, 9.213, 9.219, 9.224, 9.229, 9.234, 9.24, 9.245, 9.25, 9.255, 9.261,
      9.266, 9.271, 9.276, 9.282, 9.287, 9.292, 9.298, 9.303, 9.308, 9.313,
      9.318, 9.324, 9.329, 9.334, 9.339, 9.345, 9.35, 9.355, 9.361, 9.366,
      9.371, 9.376, 9.381, 9.387, 9.392, 9.397, 9.402, 9.407, 9.413, 9.418,
      9.423, 9.428, 9.433, 9.439, 9.444, 9.449, 9.454, 9.459, 9.465, 9.47,
      9.475, 9.48, 9.485, 9.49, 9.495, 9.501, 9.506, 9.511, 9.516, 9.521, 9.526,
      9.532, 9.537, 9.542, 9.547, 9.552, 9.557, 9.562, 9.567, 9.573, 9.578,
      9.583, 9.588, 9.593, 9.598, 9.603, 9.608, 9.613, 9.618, 9.624, 9.628,
      9.634, 9.639, 9.644, 9.649, 9.654, 9.659, 9.664, 9.669, 9.674, 9.679,
      9.684, 9.689, 9.694, 9.699, 9.704, 9.709, 9.714, 9.719, 9.724, 9.729,
      9.734, 9.739, 9.744, 9.749, 9.754, 9.759, 9.764, 9.769, 9.774, 9.779,
      9.784, 9.789, 9.794, 9.799, 9.804, 9.809, 9.814, 9.819, 9.824, 9.829,
      9.834, 9.838, 9.843, 9.848, 9.853, 9.858, 9.863, 9.868, 9.873, 9.878,
      9.883, 9.887, 9.892, 9.897, 9.902, 9.907, 9.912, 9.917, 9.921, 9.926,
      9.931, 9.936, 9.941, 9.946, 9.951, 9.955, 9.96, 9.965, 9.97, 9.975, 9.98,
      9.984, 9.989, 9.994, 9.999, 10.004, 10.008, 10.013, 10.018, 10.023,
      10.027, 10.032, 10.037, 10.042, 10.047, 10.051, 10.056, 10.061, 10.066,
      10.07, 10.075, 10.08, 10.085, 10.089, 10.094, 10.099, 10.104, 10.108,
      10.113, 10.118, 10.122, 10.127, 10.132, 10.136, 10.141, 10.146, 10.15,
      10.155, 10.16, 10.164, 10.169, 10.174, 10.178, 10.183, 10.188, 10.192,
      10.197, 10.202, 10.206, 10.211, 10.216, 10.22, 10.225, 10.23, 10.234,
      10.239, 10.244, 10.248, 10.253, 10.257, 10.262, 10.267, 10.271, 10.276,
      10.281, 10.285, 10.29, 10.294, 10.299, 10.304, 10.308, 10.313, 10.317,
      10.322, 10.327, 10.331, 10.336, 10.34, 10.345, 10.349, 10.354, 10.359,
      10.363, 10.368, 10.372, 10.377, 10.381, 10.386, 10.391, 10.395, 10.4,
      10.404, 10.409, 10.413, 10.418, 10.422, 10.427, 10.431, 10.436, 10.441,
      10.445, 10.449, 10.454, 10.459, 10.463, 10.468, 10.472, 10.477, 10.481,
      10.486, 10.49, 10.495, 10.499, 10.504, 10.508, 10.513, 10.517, 10.522,
      10.526, 10.531, 10.535, 10.54, 10.544, 10.549, 10.553, 10.558, 10.562,
      10.567, 10.571, 10.576, 10.58, 10.584, 10.589, 10.594, 10.598, 10.603,
      10.607, 10.611, 10.616, 10.62, 10.625, 10.629, 10.634, 10.638, 10.643,
      10.647, 10.651, 10.656, 10.66, 10.665, 10.669, 10.674, 10.678, 10.683,
      10.687, 10.692, 10.696, 10.701, 10.705, 10.709, 10.714, 10.718, 10.723,
      10.727, 10.731, 10.736, 10.74, 10.745, 10.749, 10.754, 10.758, 10.762,
      10.767, 10.771, 10.776, 10.78, 10.785, 10.789, 10.793, 10.798, 10.802,
      10.806, 10.811, 10.815, 10.82, 10.824, 10.829, 10.833, 10.837, 10.842,
      10.846, 10.851, 10.855, 10.859, 10.864, 10.868, 10.872, 10.877, 10.881,
      10.886, 10.89, 10.894, 10.899, 10.903, 10.908, 10.912, 10.916, 10.921,
      10.925, 10.93, 10.934, 10.938, 10.943, 10.947, 10.951, 10.956, 10.96,
      10.964, 10.969, 10.973, 10.977, 10.982, 10.986, 10.99, 10.995, 10.999,
      11.003, 11.008, 11.012, 11.017, 11.021, 11.025, 11.03, 11.034, 11.038,
      11.043, 11.047, 11.051, 11.055, 11.06, 11.064, 11.068, 11.073, 11.077,
      11.082, 11.086, 11.09, 11.095, 11.099, 11.103, 11.107, 11.112, 11.116,
      11.12, 11.125, 11.129, 11.133, 11.138, 11.142, 11.146, 11.15, 11.155,
      11.159, 11.163, 11.168, 11.172, 11.176, 11.18, 11.185, 11.189, 11.193,
      11.198, 11.202, 11.206, 11.21, 11.215, 11.219, 11.223, 11.227, 11.232,
      11.236, 11.24, 11.244, 11.249, 11.253, 11.257, 11.262, 11.266, 11.27,
      11.274, 11.279, 11.283, 11.287, 11.291, 11.295, 11.3, 11.304, 11.308,
      11.313, 11.317, 11.321, 11.325, 11.33, 11.334, 11.338, 11.342, 11.346,
      11.351, 11.355, 11.359, 11.363, 11.368, 11.372, 11.376, 11.38, 11.384,
      11.389, 11.393, 11.397, 11.401, 11.405, 11.409, 11.414, 11.418, 11.422,
      11.426, 11.431, 11.435, 11.439, 11.443, 11.447, 11.451, 11.456, 11.46,
      11.464, 11.468, 11.472, 11.477, 11.481, 11.485, 11.489, 11.493, 11.497,
      11.501, 11.506, 11.51, 11.514, 11.518, 11.522, 11.527, 11.531, 11.535,
      11.539, 11.543, 11.547, 11.552, 11.556, 11.56, 11.564, 11.568, 11.572,
      11.576, 11.581, 11.585, 11.589, 11.593, 11.597, 11.601, 11.605, 11.61,
      11.614, 11.618, 11.622, 11.626, 11.63, 11.634, 11.638, 11.643, 11.647,
      11.651, 11.655, 11.659, 11.663, 11.667, 11.671, 11.675, 11.679, 11.684,
      11.688, 11.692, 11.696, 11.7, 11.704, 11.708, 11.712, 11.716, 11.72,
      11.724, 11.728, 11.733, 11.737, 11.741, 11.745, 11.749, 11.753, 11.757,
      11.761, 11.765, 11.769, 11.774, 11.777, 11.782, 11.786, 11.79, 11.794,
      11.798, 11.802, 11.806, 11.81, 11.814, 11.818, 11.822, 11.826, 11.83,
      11.834, 11.838, 11.842, 11.846, 11.851, 11.855, 11.859, 11.863, 11.867,
      11.871, 11.875, 11.879, 11.883, 11.887, 11.891, 11.895, 11.899, 11.903,
      11.907, 11.911, 11.915, 11.919, 11.923, 11.927, 11.931, 11.936, 11.94,
      11.944, 11.947, 11.951, 11.956, 11.96, 11.964, 11.968, 11.972, 11.976,
      11.98, 11.984, 11.988, 11.992, 11.996, 12, 12.004, 12.008, 12.012, 12.016,
      12.02, 12.024, 12.028, 12.032, 12.036, 12.04, 12.044, 12.048, 12.052,
      12.056, 12.06, 12.064, 12.068, 12.072, 12.076, 12.08, 12.084, 12.088,
      12.092, 12.096, 12.1, 12.104, 12.108, 12.112, 12.116, 12.12, 12.124,
      12.128, 12.132, 12.136, 12.14, 12.144, 12.148, 12.152, 12.156, 12.16,
      12.164, 12.168, 12.172, 12.176, 12.18, 12.184, 12.188, 12.192, 12.196,
      12.2, 12.204, 12.208, 12.212, 12.216, 12.219, 12.224, 12.228, 12.231,
      12.235, 12.24, 12.243, 12.247, 12.251, 12.255, 12.259, 12.263, 12.267,
      12.271, 12.275, 12.279, 12.283, 12.287, 12.291, 12.295, 12.299, 12.303,
      12.307, 12.311, 12.315, 12.319, 12.323, 12.327, 12.331, 12.335, 12.339,
      12.343, 12.347, 12.351, 12.354, 12.359, 12.362, 12.366, 12.37, 12.374,
      12.378, 12.382, 12.386, 12.39, 12.394, 12.398, 12.402, 12.406, 12.41,
      12.414, 12.418, 12.422, 12.426, 12.43, 12.434, 12.438, 12.442, 12.446,
      12.45, 12.454, 12.457, 12.462, 12.465, 12.469, 12.473, 12.477, 12.481,
      12.485, 12.489, 12.493, 12.497, 12.501, 12.505, 12.509, 12.513, 12.517,
      12.521, 12.525, 12.529, 12.533, 12.537, 12.541, 12.544, 12.549, 12.552,
      12.556, 12.56, 12.564, 12.568, 12.572, 12.576, 12.58, 12.584, 12.588,
      12.592, 12.596, 12.6, 12.604, 12.608, 12.612, 12.616, 12.619, 12.624,
      12.627, 12.631, 12.635, 12.639, 12.643, 12.647, 12.651, 12.655, 12.659,
      12.663, 12.667, 12.671, 12.675, 12.679, 12.682, 12.687, 12.69, 12.694,
      12.698, 12.702, 12.706, 12.71, 12.714, 12.718, 12.722, 12.726, 12.73,
      12.734, 12.738, 12.742, 12.746, 12.75, 12.754, 12.757, 12.761, 12.765,
      12.769, 12.773, 12.777, 12.781, 12.785, 12.789, 12.793, 12.797, 12.801,
      12.805, 12.809, 12.812, 12.816, 12.82, 12.824, 12.828, 12.832, 12.836,
      12.84, 12.844, 12.848, 12.852, 12.856, 12.86, 12.864, 12.868, 12.872,
      12.875, 12.879, 12.883, 12.887, 12.891, 12.895, 12.899, 12.903, 12.907,
      12.911, 12.915, 12.918, 12.923, 12.926, 12.93, 12.934, 12.938, 12.942,
      12.946, 12.95, 12.954, 12.958, 12.962, 12.966, 12.97, 12.973, 12.978,
      12.981, 12.985, 12.989, 12.993, 12.997, 13.001, 13.005, 13.009, 13.013,
      13.017, 13.02, 13.024, 13.028, 13.032, 13.036, 13.04, 13.044, 13.048,
      13.052, 13.056, 13.06, 13.063, 13.067, 13.071, 13.075, 13.079, 13.083,
      13.087, 13.091, 13.095, 13.098, 13.103, 13.106, 13.11, 13.114, 13.118,
      13.122, 13.126, 13.13, 13.134, 13.138, 13.141, 13.145, 13.149, 13.153,
      13.157, 13.161, 13.165, 13.169, 13.172, 13.177, 13.18, 13.184, 13.188,
      13.192, 13.196, 13.2, 13.204, 13.207, 13.211, 13.215, 13.219, 13.223,
      13.227, 13.231, 13.235, 13.238, 13.242, 13.246, 13.25, 13.254, 13.258,
      13.262, 13.266, 13.269, 13.273, 13.277, 13.281, 13.285, 13.289, 13.293,
      13.297, 13.3, 13.304, 13.308, 13.312, 13.316, 13.32, 13.324, 13.327,
      13.331, 13.335, 13.339, 13.343, 13.347, 13.35, 13.354, 13.358, 13.362,
      13.366, 13.37, 13.374, 13.377, 13.381, 13.385, 13.389, 13.393, 13.397,
      13.4, 13.404, 13.408, 13.412, 13.416, 13.42, 13.423, 13.427, 13.431,
      13.435, 13.439, 13.443, 13.447, 13.45, 13.454, 13.458, 13.462, 13.465,
      13.469, 13.473, 13.477, 13.481, 13.485, 13.489, 13.492, 13.496, 13.5,
      13.504, 13.507, 13.511, 13.515, 13.519, 13.523, 13.526, 13.53, 13.534,
      13.538, 13.542, 13.546, 13.549, 13.553, 13.557, 13.561, 13.565, 13.569,
      13.572, 13.576, 13.58, 13.584, 13.587, 13.591, 13.595, 13.599, 13.602,
      13.606, 13.61, 13.614, 13.618, 13.621, 13.625, 13.629, 13.633, 13.636,
      13.64, 13.644, 13.648, 13.652, 13.655, 13.659, 13.663, 13.667, 13.671,
      13.674, 13.678, 13.682, 13.686, 13.689, 13.693, 13.697, 13.701, 13.704,
      13.708, 13.712, 13.716, 13.719, 13.723, 13.727, 13.731, 13.734, 13.738,
      13.742, 13.746, 13.749, 13.753, 13.757, 13.761, 13.764, 13.768, 13.772,
      13.776, 13.779, 13.783, 13.787, 13.79, 13.794, 13.798, 13.802, 13.805,
      13.809, 13.813, 13.817, 13.82, 13.824, 13.828, 13.831, 13.835, 13.839,
      13.843, 13.846, 13.85, 13.854,
    ],
  },
};
