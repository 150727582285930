export const lengthHeightForAgeGirls = {
  date: [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
    59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77,
    78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96,
    97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112,
    113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127,
    128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142,
    143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157,
    158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172,
    173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187,
    188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202,
    203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217,
    218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232,
    233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247,
    248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262,
    263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275, 276, 277,
    278, 279, 280, 281, 282, 283, 284, 285, 286, 287, 288, 289, 290, 291, 292,
    293, 294, 295, 296, 297, 298, 299, 300, 301, 302, 303, 304, 305, 306, 307,
    308, 309, 310, 311, 312, 313, 314, 315, 316, 317, 318, 319, 320, 321, 322,
    323, 324, 325, 326, 327, 328, 329, 330, 331, 332, 333, 334, 335, 336, 337,
    338, 339, 340, 341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351, 352,
    353, 354, 355, 356, 357, 358, 359, 360, 361, 362, 363, 364, 365, 366, 367,
    368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378, 379, 380, 381, 382,
    383, 384, 385, 386, 387, 388, 389, 390, 391, 392, 393, 394, 395, 396, 397,
    398, 399, 400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412,
    413, 414, 415, 416, 417, 418, 419, 420, 421, 422, 423, 424, 425, 426, 427,
    428, 429, 430, 431, 432, 433, 434, 435, 436, 437, 438, 439, 440, 441, 442,
    443, 444, 445, 446, 447, 448, 449, 450, 451, 452, 453, 454, 455, 456, 457,
    458, 459, 460, 461, 462, 463, 464, 465, 466, 467, 468, 469, 470, 471, 472,
    473, 474, 475, 476, 477, 478, 479, 480, 481, 482, 483, 484, 485, 486, 487,
    488, 489, 490, 491, 492, 493, 494, 495, 496, 497, 498, 499, 500, 501, 502,
    503, 504, 505, 506, 507, 508, 509, 510, 511, 512, 513, 514, 515, 516, 517,
    518, 519, 520, 521, 522, 523, 524, 525, 526, 527, 528, 529, 530, 531, 532,
    533, 534, 535, 536, 537, 538, 539, 540, 541, 542, 543, 544, 545, 546, 547,
    548, 549, 550, 551, 552, 553, 554, 555, 556, 557, 558, 559, 560, 561, 562,
    563, 564, 565, 566, 567, 568, 569, 570, 571, 572, 573, 574, 575, 576, 577,
    578, 579, 580, 581, 582, 583, 584, 585, 586, 587, 588, 589, 590, 591, 592,
    593, 594, 595, 596, 597, 598, 599, 600, 601, 602, 603, 604, 605, 606, 607,
    608, 609, 610, 611, 612, 613, 614, 615, 616, 617, 618, 619, 620, 621, 622,
    623, 624, 625, 626, 627, 628, 629, 630, 631, 632, 633, 634, 635, 636, 637,
    638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 648, 649, 650, 651, 652,
    653, 654, 655, 656, 657, 658, 659, 660, 661, 662, 663, 664, 665, 666, 667,
    668, 669, 670, 671, 672, 673, 674, 675, 676, 677, 678, 679, 680, 681, 682,
    683, 684, 685, 686, 687, 688, 689, 690, 691, 692, 693, 694, 695, 696, 697,
    698, 699, 700, 701, 702, 703, 704, 705, 706, 707, 708, 709, 710, 711, 712,
    713, 714, 715, 716, 717, 718, 719, 720, 721, 722, 723, 724, 725, 726, 727,
    728, 729, 730, 731, 732, 733, 734, 735, 736, 737, 738, 739, 740, 741, 742,
    743, 744, 745, 746, 747, 748, 749, 750, 751, 752, 753, 754, 755, 756, 757,
    758, 759, 760, 761, 762, 763, 764, 765, 766, 767, 768, 769, 770, 771, 772,
    773, 774, 775, 776, 777, 778, 779, 780, 781, 782, 783, 784, 785, 786, 787,
    788, 789, 790, 791, 792, 793, 794, 795, 796, 797, 798, 799, 800, 801, 802,
    803, 804, 805, 806, 807, 808, 809, 810, 811, 812, 813, 814, 815, 816, 817,
    818, 819, 820, 821, 822, 823, 824, 825, 826, 827, 828, 829, 830, 831, 832,
    833, 834, 835, 836, 837, 838, 839, 840, 841, 842, 843, 844, 845, 846, 847,
    848, 849, 850, 851, 852, 853, 854, 855, 856, 857, 858, 859, 860, 861, 862,
    863, 864, 865, 866, 867, 868, 869, 870, 871, 872, 873, 874, 875, 876, 877,
    878, 879, 880, 881, 882, 883, 884, 885, 886, 887, 888, 889, 890, 891, 892,
    893, 894, 895, 896, 897, 898, 899, 900, 901, 902, 903, 904, 905, 906, 907,
    908, 909, 910, 911, 912, 913, 914, 915, 916, 917, 918, 919, 920, 921, 922,
    923, 924, 925, 926, 927, 928, 929, 930, 931, 932, 933, 934, 935, 936, 937,
    938, 939, 940, 941, 942, 943, 944, 945, 946, 947, 948, 949, 950, 951, 952,
    953, 954, 955, 956, 957, 958, 959, 960, 961, 962, 963, 964, 965, 966, 967,
    968, 969, 970, 971, 972, 973, 974, 975, 976, 977, 978, 979, 980, 981, 982,
    983, 984, 985, 986, 987, 988, 989, 990, 991, 992, 993, 994, 995, 996, 997,
    998, 999, 1000, 1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009, 1010,
    1011, 1012, 1013, 1014, 1015, 1016, 1017, 1018, 1019, 1020, 1021, 1022,
    1023, 1024, 1025, 1026, 1027, 1028, 1029, 1030, 1031, 1032, 1033, 1034,
    1035, 1036, 1037, 1038, 1039, 1040, 1041, 1042, 1043, 1044, 1045, 1046,
    1047, 1048, 1049, 1050, 1051, 1052, 1053, 1054, 1055, 1056, 1057, 1058,
    1059, 1060, 1061, 1062, 1063, 1064, 1065, 1066, 1067, 1068, 1069, 1070,
    1071, 1072, 1073, 1074, 1075, 1076, 1077, 1078, 1079, 1080, 1081, 1082,
    1083, 1084, 1085, 1086, 1087, 1088, 1089, 1090, 1091, 1092, 1093, 1094,
    1095, 1096, 1097, 1098, 1099, 1100, 1101, 1102, 1103, 1104, 1105, 1106,
    1107, 1108, 1109, 1110, 1111, 1112, 1113, 1114, 1115, 1116, 1117, 1118,
    1119, 1120, 1121, 1122, 1123, 1124, 1125, 1126, 1127, 1128, 1129, 1130,
    1131, 1132, 1133, 1134, 1135, 1136, 1137, 1138, 1139, 1140, 1141, 1142,
    1143, 1144, 1145, 1146, 1147, 1148, 1149, 1150, 1151, 1152, 1153, 1154,
    1155, 1156, 1157, 1158, 1159, 1160, 1161, 1162, 1163, 1164, 1165, 1166,
    1167, 1168, 1169, 1170, 1171, 1172, 1173, 1174, 1175, 1176, 1177, 1178,
    1179, 1180, 1181, 1182, 1183, 1184, 1185, 1186, 1187, 1188, 1189, 1190,
    1191, 1192, 1193, 1194, 1195, 1196, 1197, 1198, 1199, 1200, 1201, 1202,
    1203, 1204, 1205, 1206, 1207, 1208, 1209, 1210, 1211, 1212, 1213, 1214,
    1215, 1216, 1217, 1218, 1219, 1220, 1221, 1222, 1223, 1224, 1225, 1226,
    1227, 1228, 1229, 1230, 1231, 1232, 1233, 1234, 1235, 1236, 1237, 1238,
    1239, 1240, 1241, 1242, 1243, 1244, 1245, 1246, 1247, 1248, 1249, 1250,
    1251, 1252, 1253, 1254, 1255, 1256, 1257, 1258, 1259, 1260, 1261, 1262,
    1263, 1264, 1265, 1266, 1267, 1268, 1269, 1270, 1271, 1272, 1273, 1274,
    1275, 1276, 1277, 1278, 1279, 1280, 1281, 1282, 1283, 1284, 1285, 1286,
    1287, 1288, 1289, 1290, 1291, 1292, 1293, 1294, 1295, 1296, 1297, 1298,
    1299, 1300, 1301, 1302, 1303, 1304, 1305, 1306, 1307, 1308, 1309, 1310,
    1311, 1312, 1313, 1314, 1315, 1316, 1317, 1318, 1319, 1320, 1321, 1322,
    1323, 1324, 1325, 1326, 1327, 1328, 1329, 1330, 1331, 1332, 1333, 1334,
    1335, 1336, 1337, 1338, 1339, 1340, 1341, 1342, 1343, 1344, 1345, 1346,
    1347, 1348, 1349, 1350, 1351, 1352, 1353, 1354, 1355, 1356, 1357, 1358,
    1359, 1360, 1361, 1362, 1363, 1364, 1365, 1366, 1367, 1368, 1369, 1370,
    1371, 1372, 1373, 1374, 1375, 1376, 1377, 1378, 1379, 1380, 1381, 1382,
    1383, 1384, 1385, 1386, 1387, 1388, 1389, 1390, 1391, 1392, 1393, 1394,
    1395, 1396, 1397, 1398, 1399, 1400, 1401, 1402, 1403, 1404, 1405, 1406,
    1407, 1408, 1409, 1410, 1411, 1412, 1413, 1414, 1415, 1416, 1417, 1418,
    1419, 1420, 1421, 1422, 1423, 1424, 1425, 1426, 1427, 1428, 1429, 1430,
    1431, 1432, 1433, 1434, 1435, 1436, 1437, 1438, 1439, 1440, 1441, 1442,
    1443, 1444, 1445, 1446, 1447, 1448, 1449, 1450, 1451, 1452, 1453, 1454,
    1455, 1456, 1457, 1458, 1459, 1460, 1461, 1462, 1463, 1464, 1465, 1466,
    1467, 1468, 1469, 1470, 1471, 1472, 1473, 1474, 1475, 1476, 1477, 1478,
    1479, 1480, 1481, 1482, 1483, 1484, 1485, 1486, 1487, 1488, 1489, 1490,
    1491, 1492, 1493, 1494, 1495, 1496, 1497, 1498, 1499, 1500, 1501, 1502,
    1503, 1504, 1505, 1506, 1507, 1508, 1509, 1510, 1511, 1512, 1513, 1514,
    1515, 1516, 1517, 1518, 1519, 1520, 1521, 1522, 1523, 1524, 1525, 1526,
    1527, 1528, 1529, 1530, 1531, 1532, 1533, 1534, 1535, 1536, 1537, 1538,
    1539, 1540, 1541, 1542, 1543, 1544, 1545, 1546, 1547, 1548, 1549, 1550,
    1551, 1552, 1553, 1554, 1555, 1556, 1557, 1558, 1559, 1560, 1561, 1562,
    1563, 1564, 1565, 1566, 1567, 1568, 1569, 1570, 1571, 1572, 1573, 1574,
    1575, 1576, 1577, 1578, 1579, 1580, 1581, 1582, 1583, 1584, 1585, 1586,
    1587, 1588, 1589, 1590, 1591, 1592, 1593, 1594, 1595, 1596, 1597, 1598,
    1599, 1600, 1601, 1602, 1603, 1604, 1605, 1606, 1607, 1608, 1609, 1610,
    1611, 1612, 1613, 1614, 1615, 1616, 1617, 1618, 1619, 1620, 1621, 1622,
    1623, 1624, 1625, 1626, 1627, 1628, 1629, 1630, 1631, 1632, 1633, 1634,
    1635, 1636, 1637, 1638, 1639, 1640, 1641, 1642, 1643, 1644, 1645, 1646,
    1647, 1648, 1649, 1650, 1651, 1652, 1653, 1654, 1655, 1656, 1657, 1658,
    1659, 1660, 1661, 1662, 1663, 1664, 1665, 1666, 1667, 1668, 1669, 1670,
    1671, 1672, 1673, 1674, 1675, 1676, 1677, 1678, 1679, 1680, 1681, 1682,
    1683, 1684, 1685, 1686, 1687, 1688, 1689, 1690, 1691, 1692, 1693, 1694,
    1695, 1696, 1697, 1698, 1699, 1700, 1701, 1702, 1703, 1704, 1705, 1706,
    1707, 1708, 1709, 1710, 1711, 1712, 1713, 1714, 1715, 1716, 1717, 1718,
    1719, 1720, 1721, 1722, 1723, 1724, 1725, 1726, 1727, 1728, 1729, 1730,
    1731, 1732, 1733, 1734, 1735, 1736, 1737, 1738, 1739, 1740, 1741, 1742,
    1743, 1744, 1745, 1746, 1747, 1748, 1749, 1750, 1751, 1752, 1753, 1754,
    1755, 1756, 1757, 1758, 1759, 1760, 1761, 1762, 1763, 1764, 1765, 1766,
    1767, 1768, 1769, 1770, 1771, 1772, 1773, 1774, 1775, 1776, 1777, 1778,
    1779, 1780, 1781, 1782, 1783, 1784, 1785, 1786, 1787, 1788, 1789, 1790,
    1791, 1792, 1793, 1794, 1795, 1796, 1797, 1798, 1799, 1800, 1801, 1802,
    1803, 1804, 1805, 1806, 1807, 1808, 1809, 1810, 1811, 1812, 1813, 1814,
    1815, 1816, 1817, 1818, 1819, 1820, 1821, 1822, 1823, 1824, 1825, 1826,
    1827, 1828, 1829, 1830, 1831, 1832, 1833, 1834, 1835, 1836, 1837, 1838,
    1839, 1840, 1841, 1842, 1843, 1844, 1845, 1846, 1847, 1848, 1849, 1850,
    1851, 1852, 1853, 1854, 1855, 1856,
  ],
  median: {
    label: 'median',
    weight: [
      49.1477, 49.3166, 49.4854, 49.6543, 49.8232, 49.9921, 50.1609, 50.3298,
      50.4987, 50.6676, 50.8365, 51.0053, 51.1742, 51.3431, 51.512, 51.651,
      51.7895, 51.9272, 52.0641, 52.2002, 52.3353, 52.4695, 52.6027, 52.7349,
      52.8661, 52.9963, 53.1255, 53.2537, 53.3809, 53.5072, 53.6326, 53.7571,
      53.8806, 54.0031, 54.1247, 54.2454, 54.3651, 54.4839, 54.6018, 54.7187,
      54.8348, 54.9499, 55.0642, 55.1777, 55.2903, 55.4021, 55.513, 55.623,
      55.7322, 55.8406, 55.9482, 56.0549, 56.1609, 56.266, 56.3704, 56.4739,
      56.5767, 56.6788, 56.78, 56.8806, 56.9805, 57.0796, 57.1782, 57.2761,
      57.3733, 57.4699, 57.5659, 57.6613, 57.756, 57.8501, 57.9436, 58.0365,
      58.1288, 58.2206, 58.3117, 58.4022, 58.4922, 58.5816, 58.6705, 58.7588,
      58.8465, 58.9337, 59.0204, 59.1066, 59.1922, 59.2773, 59.3619, 59.4459,
      59.5295, 59.6126, 59.6952, 59.7773, 59.8589, 59.9401, 60.0209, 60.1011,
      60.181, 60.2603, 60.3393, 60.4178, 60.4958, 60.5734, 60.6506, 60.7273,
      60.8036, 60.8795, 60.955, 61.0301, 61.1047, 61.1789, 61.2527, 61.3261,
      61.3991, 61.4717, 61.5439, 61.6156, 61.687, 61.758, 61.8286, 61.8988,
      61.9686, 62.0381, 62.1071, 62.1758, 62.2441, 62.312, 62.3795, 62.4467,
      62.5135, 62.58, 62.6461, 62.7118, 62.7772, 62.8423, 62.907, 62.9714,
      63.0354, 63.0991, 63.1626, 63.2257, 63.2884, 63.3509, 63.4131, 63.475,
      63.5365, 63.5978, 63.6588, 63.7196, 63.78, 63.8402, 63.9, 63.9597, 64.019,
      64.0781, 64.137, 64.1956, 64.2539, 64.312, 64.3699, 64.4276, 64.485,
      64.5422, 64.5991, 64.6559, 64.7124, 64.7688, 64.8249, 64.8808, 64.9366,
      64.9921, 65.0474, 65.1026, 65.1576, 65.2123, 65.267, 65.3214, 65.3757,
      65.4298, 65.4837, 65.5375, 65.5911, 65.6445, 65.6978, 65.751, 65.804,
      65.8568, 65.9095, 65.9621, 66.0145, 66.0668, 66.1189, 66.1709, 66.2228,
      66.2745, 66.3261, 66.3776, 66.429, 66.4802, 66.5313, 66.5823, 66.6331,
      66.6839, 66.7345, 66.785, 66.8354, 66.8857, 66.9359, 66.9859, 67.0359,
      67.0858, 67.1355, 67.1852, 67.2347, 67.2842, 67.3335, 67.3828, 67.432,
      67.481, 67.53, 67.5789, 67.6277, 67.6764, 67.725, 67.7735, 67.8219,
      67.8703, 67.9185, 67.9667, 68.0148, 68.0628, 68.1107, 68.1585, 68.2063,
      68.254, 68.3016, 68.3491, 68.3965, 68.4439, 68.4911, 68.5383, 68.5855,
      68.6325, 68.6795, 68.7264, 68.7732, 68.82, 68.8666, 68.9133, 68.9598,
      69.0063, 69.0527, 69.099, 69.1452, 69.1914, 69.2376, 69.2836, 69.3296,
      69.3755, 69.4214, 69.4672, 69.5129, 69.5585, 69.6041, 69.6496, 69.6951,
      69.7405, 69.7858, 69.8311, 69.8763, 69.9215, 69.9666, 70.0116, 70.0566,
      70.1015, 70.1463, 70.1911, 70.2358, 70.2805, 70.3251, 70.3697, 70.4142,
      70.4586, 70.503, 70.5474, 70.5917, 70.6359, 70.68, 70.7241, 70.7682,
      70.8122, 70.8561, 70.9, 70.9439, 70.9876, 71.0314, 71.075, 71.1187,
      71.1622, 71.2057, 71.2492, 71.2926, 71.3359, 71.3792, 71.4224, 71.4656,
      71.5088, 71.5518, 71.5949, 71.6378, 71.6808, 71.7236, 71.7664, 71.8092,
      71.8519, 71.8946, 71.9372, 71.9798, 72.0223, 72.0647, 72.1071, 72.1495,
      72.1918, 72.234, 72.2762, 72.3184, 72.3605, 72.4025, 72.4445, 72.4865,
      72.5284, 72.5702, 72.612, 72.6538, 72.6955, 72.7372, 72.7788, 72.8203,
      72.8618, 72.9033, 72.9447, 72.9861, 73.0274, 73.0686, 73.1099, 73.151,
      73.1922, 73.2332, 73.2743, 73.3152, 73.3562, 73.3971, 73.4379, 73.4787,
      73.5195, 73.5602, 73.6008, 73.6414, 73.682, 73.7225, 73.763, 73.8034,
      73.8438, 73.8842, 73.9245, 73.9647, 74.0049, 74.0451, 74.0852, 74.1253,
      74.1653, 74.2053, 74.2452, 74.2851, 74.325, 74.3648, 74.4045, 74.4443,
      74.4839, 74.5236, 74.5632, 74.6027, 74.6422, 74.6817, 74.7211, 74.7605,
      74.7998, 74.8391, 74.8784, 74.9176, 74.9567, 74.9959, 75.0349, 75.074,
      75.113, 75.1519, 75.1908, 75.2297, 75.2686, 75.3073, 75.3461, 75.3848,
      75.4235, 75.4621, 75.5007, 75.5392, 75.5777, 75.6162, 75.6546, 75.693,
      75.7313, 75.7696, 75.8079, 75.8461, 75.8843, 75.9224, 75.9605, 75.9986,
      76.0366, 76.0746, 76.1125, 76.1504, 76.1883, 76.2261, 76.2639, 76.3016,
      76.3393, 76.377, 76.4146, 76.4522, 76.4897, 76.5272, 76.5647, 76.6021,
      76.6395, 76.6769, 76.7142, 76.7515, 76.7887, 76.8259, 76.8631, 76.9002,
      76.9373, 76.9744, 77.0114, 77.0484, 77.0853, 77.1222, 77.1591, 77.1959,
      77.2327, 77.2695, 77.3062, 77.3429, 77.3796, 77.4162, 77.4528, 77.4893,
      77.5258, 77.5623, 77.5988, 77.6352, 77.6716, 77.7079, 77.7442, 77.7805,
      77.8167, 77.8529, 77.8891, 77.9252, 77.9613, 77.9974, 78.0334, 78.0694,
      78.1054, 78.1413, 78.1772, 78.2131, 78.249, 78.2848, 78.3205, 78.3563,
      78.392, 78.4276, 78.4633, 78.4989, 78.5345, 78.57, 78.6055, 78.641,
      78.6764, 78.7118, 78.7472, 78.7826, 78.8179, 78.8532, 78.8884, 78.9236,
      78.9588, 78.994, 79.0291, 79.0642, 79.0993, 79.1343, 79.1693, 79.2042,
      79.2392, 79.2741, 79.3089, 79.3438, 79.3786, 79.4134, 79.4481, 79.4828,
      79.5175, 79.5521, 79.5868, 79.6213, 79.6559, 79.6904, 79.7249, 79.7594,
      79.7938, 79.8282, 79.8626, 79.8969, 79.9312, 79.9655, 79.9998, 80.034,
      80.0682, 80.1023, 80.1365, 80.1706, 80.2046, 80.2387, 80.2727, 80.3067,
      80.3406, 80.3745, 80.4084, 80.4423, 80.4761, 80.5099, 80.5437, 80.5774,
      80.6112, 80.6448, 80.6785, 80.7121, 80.7457, 80.7793, 80.8128, 80.8464,
      80.8798, 80.9133, 80.9467, 80.9801, 81.0135, 81.0468, 81.0802, 81.1134,
      81.1467, 81.1799, 81.2131, 81.2463, 81.2795, 81.3126, 81.3457, 81.3788,
      81.4118, 81.4448, 81.4778, 81.5108, 81.5437, 81.5766, 81.6095, 81.6423,
      81.6752, 81.708, 81.7407, 81.7735, 81.8062, 81.8389, 81.8715, 81.9042,
      81.9368, 81.9694, 82.0019, 82.0345, 82.067, 82.0994, 82.1319, 82.1643,
      82.1967, 82.2291, 82.2614, 82.2938, 82.3261, 82.3583, 82.3906, 82.4228,
      82.455, 82.4872, 82.5193, 82.5514, 82.5835, 82.6156, 82.6476, 82.6796,
      82.7116, 82.7436, 82.7755, 82.8074, 82.8393, 82.8712, 82.903, 82.9348,
      82.9666, 82.9984, 83.0301, 83.0618, 83.0935, 83.1251, 83.1568, 83.1884,
      83.22, 83.2515, 83.2831, 83.3146, 83.3461, 83.3775, 83.4089, 83.4403,
      83.4717, 83.5031, 83.5344, 83.5657, 83.597, 83.6283, 83.6595, 83.6907,
      83.7219, 83.753, 83.7842, 83.8153, 83.8464, 83.8774, 83.9085, 83.9395,
      83.9705, 84.0014, 84.0324, 84.0633, 84.0941, 84.125, 84.1558, 84.1867,
      84.2174, 84.2482, 84.2789, 84.3096, 84.3403, 84.371, 84.4016, 84.4323,
      84.4628, 84.4934, 84.5239, 84.5545, 84.585, 84.6154, 84.6459, 84.6763,
      84.7067, 84.737, 84.7674, 84.7977, 84.828, 84.8583, 84.8885, 84.9188,
      84.949, 84.9791, 85.0093, 85.0394, 85.0695, 85.0996, 85.1297, 85.1597,
      85.1897, 85.2197, 85.2497, 85.2796, 85.3096, 85.3395, 85.3693, 85.3992,
      85.429, 85.4588, 85.4886, 85.5184, 85.5481, 85.5778, 85.6075, 85.6372,
      85.6668, 85.6964, 85.726, 85.7299, 85.7556, 85.7589, 85.7852, 85.788,
      85.8147, 85.817, 85.8442, 85.846, 85.8737, 85.8749, 85.9032, 85.9039,
      85.9326, 85.9328, 85.9617, 85.9621, 85.9906, 85.9915, 86.0194, 86.0208,
      86.0483, 86.0502, 86.0771, 86.0795, 86.1059, 86.1089, 86.1347, 86.1381,
      86.1634, 86.1674, 86.1921, 86.1967, 86.2209, 86.2259, 86.2495, 86.2551,
      86.2782, 86.2843, 86.3069, 86.3134, 86.3355, 86.3426, 86.3641, 86.3717,
      86.3927, 86.4008, 86.4212, 86.4498, 86.4783, 86.5068, 86.5353, 86.5638,
      86.5922, 86.6206, 86.649, 86.6774, 86.7057, 86.7341, 86.7624, 86.7907,
      86.819, 86.8472, 86.8754, 86.9037, 86.9319, 86.96, 86.9882, 87.0163,
      87.0444, 87.0725, 87.1006, 87.1286, 87.1567, 87.1847, 87.2126, 87.2406,
      87.2686, 87.2965, 87.3244, 87.3523, 87.3801, 87.408, 87.4358, 87.4636,
      87.4914, 87.5192, 87.5469, 87.5746, 87.6023, 87.63, 87.6577, 87.6853,
      87.7129, 87.7405, 87.7681, 87.7956, 87.8232, 87.8507, 87.8782, 87.9056,
      87.9331, 87.9605, 87.9879, 88.0153, 88.0427, 88.07, 88.0974, 88.1247,
      88.1519, 88.1792, 88.2065, 88.2337, 88.2609, 88.2881, 88.3152, 88.3423,
      88.3695, 88.3966, 88.4236, 88.4507, 88.4777, 88.5047, 88.5317, 88.5587,
      88.5856, 88.6126, 88.6395, 88.6664, 88.6932, 88.7201, 88.7469, 88.7737,
      88.8005, 88.8273, 88.854, 88.8807, 88.9074, 88.9341, 88.9608, 88.9874,
      89.014, 89.0406, 89.0672, 89.0938, 89.1203, 89.1468, 89.1733, 89.1998,
      89.2263, 89.2527, 89.2791, 89.3055, 89.3319, 89.3583, 89.3846, 89.4109,
      89.4372, 89.4635, 89.4898, 89.516, 89.5422, 89.5684, 89.5946, 89.6208,
      89.6469, 89.673, 89.6991, 89.7252, 89.7513, 89.7773, 89.8033, 89.8293,
      89.8553, 89.8813, 89.9072, 89.9331, 89.9591, 89.9849, 90.0108, 90.0366,
      90.0625, 90.0883, 90.1141, 90.1398, 90.1656, 90.1913, 90.217, 90.2427,
      90.2684, 90.294, 90.3197, 90.3453, 90.3709, 90.3965, 90.422, 90.4476,
      90.4731, 90.4986, 90.524, 90.5495, 90.575, 90.6004, 90.6258, 90.6512,
      90.6765, 90.7019, 90.7272, 90.7525, 90.7778, 90.8031, 90.8283, 90.8536,
      90.8788, 90.904, 90.9292, 90.9544, 90.9795, 91.0046, 91.0297, 91.0548,
      91.0799, 91.105, 91.13, 91.155, 91.18, 91.205, 91.23, 91.2549, 91.2799,
      91.3048, 91.3297, 91.3545, 91.3794, 91.4043, 91.4291, 91.4539, 91.4787,
      91.5035, 91.5282, 91.553, 91.5777, 91.6024, 91.6271, 91.6518, 91.6764,
      91.7011, 91.7257, 91.7503, 91.7749, 91.7995, 91.8241, 91.8486, 91.8731,
      91.8976, 91.9221, 91.9466, 91.9711, 91.9955, 92.02, 92.0444, 92.0688,
      92.0932, 92.1175, 92.1419, 92.1662, 92.1906, 92.2149, 92.2392, 92.2635,
      92.2877, 92.312, 92.3362, 92.3604, 92.3846, 92.4088, 92.433, 92.4572,
      92.4813, 92.5054, 92.5295, 92.5536, 92.5777, 92.6018, 92.6259, 92.6499,
      92.6739, 92.698, 92.722, 92.7459, 92.7699, 92.7939, 92.8178, 92.8418,
      92.8657, 92.8896, 92.9135, 92.9373, 92.9612, 92.985, 93.0089, 93.0327,
      93.0565, 93.0803, 93.1041, 93.1278, 93.1516, 93.1753, 93.1991, 93.2228,
      93.2465, 93.2702, 93.2938, 93.3175, 93.3411, 93.3648, 93.3884, 93.412,
      93.4356, 93.4592, 93.4827, 93.5063, 93.5298, 93.5534, 93.5769, 93.6004,
      93.6239, 93.6473, 93.6708, 93.6943, 93.7177, 93.7411, 93.7646, 93.788,
      93.8113, 93.8347, 93.8581, 93.8814, 93.9048, 93.9281, 93.9514, 93.9747,
      93.998, 94.0213, 94.0446, 94.0678, 94.0911, 94.1143, 94.1376, 94.1608,
      94.184, 94.2071, 94.2303, 94.2535, 94.2766, 94.2998, 94.3229, 94.346,
      94.3691, 94.3922, 94.4153, 94.4384, 94.4615, 94.4845, 94.5075, 94.5306,
      94.5536, 94.5766, 94.5996, 94.6226, 94.6455, 94.6685, 94.6914, 94.7144,
      94.7373, 94.7602, 94.7831, 94.806, 94.8289, 94.8518, 94.8747, 94.8975,
      94.9203, 94.9432, 94.966, 94.9888, 95.0116, 95.0344, 95.0572, 95.0799,
      95.1027, 95.1254, 95.1482, 95.1709, 95.1936, 95.2163, 95.239, 95.2617,
      95.2844, 95.307, 95.3297, 95.3523, 95.375, 95.3976, 95.4202, 95.4428,
      95.4654, 95.488, 95.5105, 95.5331, 95.5556, 95.5782, 95.6007, 95.6232,
      95.6457, 95.6682, 95.6907, 95.7132, 95.7356, 95.7581, 95.7805, 95.803,
      95.8254, 95.8478, 95.8702, 95.8926, 95.915, 95.9374, 95.9597, 95.9821,
      96.0044, 96.0268, 96.0491, 96.0714, 96.0937, 96.116, 96.1383, 96.1606,
      96.1828, 96.2051, 96.2273, 96.2495, 96.2718, 96.294, 96.3162, 96.3384,
      96.3606, 96.3827, 96.4049, 96.427, 96.4492, 96.4713, 96.4934, 96.5156,
      96.5377, 96.5598, 96.5818, 96.6039, 96.626, 96.648, 96.6701, 96.6921,
      96.7141, 96.7362, 96.7582, 96.7802, 96.8021, 96.8241, 96.8461, 96.868,
      96.89, 96.9119, 96.9339, 96.9558, 96.9777, 96.9996, 97.0215, 97.0434,
      97.0652, 97.0871, 97.1089, 97.1308, 97.1526, 97.1744, 97.1963, 97.2181,
      97.2399, 97.2616, 97.2834, 97.3052, 97.3269, 97.3487, 97.3704, 97.3922,
      97.4139, 97.4356, 97.4573, 97.479, 97.5007, 97.5223, 97.544, 97.5657,
      97.5873, 97.6089, 97.6306, 97.6522, 97.6738, 97.6954, 97.717, 97.7385,
      97.7601, 97.7817, 97.8032, 97.8248, 97.8463, 97.8678, 97.8893, 97.9108,
      97.9323, 97.9538, 97.9753, 97.9968, 98.0182, 98.0397, 98.0611, 98.0825,
      98.1039, 98.1253, 98.1467, 98.1681, 98.1895, 98.2109, 98.2322, 98.2536,
      98.2749, 98.2963, 98.3176, 98.3389, 98.3602, 98.3815, 98.4028, 98.4241,
      98.4453, 98.4666, 98.4878, 98.5091, 98.5303, 98.5515, 98.5727, 98.5939,
      98.6151, 98.6363, 98.6575, 98.6786, 98.6998, 98.7209, 98.7421, 98.7632,
      98.7843, 98.8054, 98.8265, 98.8476, 98.8687, 98.8897, 98.9108, 98.9318,
      98.9529, 98.9739, 98.9949, 99.0159, 99.0369, 99.0579, 99.0789, 99.0999,
      99.1208, 99.1418, 99.1628, 99.1837, 99.2046, 99.2255, 99.2464, 99.2673,
      99.2882, 99.3091, 99.33, 99.3509, 99.3717, 99.3926, 99.4134, 99.4342,
      99.455, 99.4758, 99.4966, 99.5174, 99.5382, 99.559, 99.5798, 99.6005,
      99.6212, 99.642, 99.6627, 99.6834, 99.7041, 99.7248, 99.7455, 99.7662,
      99.7869, 99.8075, 99.8282, 99.8488, 99.8695, 99.8901, 99.9107, 99.9313,
      99.9519, 99.9725, 99.9931, 100.0137, 100.0342, 100.0548, 100.0753,
      100.0959, 100.1164, 100.1369, 100.1574, 100.1779, 100.1984, 100.2189,
      100.2394, 100.2598, 100.2803, 100.3007, 100.3212, 100.3416, 100.362,
      100.3824, 100.4028, 100.4232, 100.4436, 100.464, 100.4843, 100.5047,
      100.525, 100.5454, 100.5657, 100.586, 100.6063, 100.6266, 100.6469,
      100.6672, 100.6875, 100.7077, 100.728, 100.7482, 100.7685, 100.7887,
      100.8089, 100.8291, 100.8493, 100.8695, 100.8897, 100.9099, 100.9301,
      100.9502, 100.9704, 100.9905, 101.0107, 101.0308, 101.0509, 101.071,
      101.0911, 101.1112, 101.1313, 101.1514, 101.1714, 101.1915, 101.2115,
      101.2316, 101.2516, 101.2716, 101.2917, 101.3117, 101.3317, 101.3517,
      101.3716, 101.3916, 101.4116, 101.4315, 101.4515, 101.4714, 101.4914,
      101.5113, 101.5312, 101.5511, 101.571, 101.5909, 101.6108, 101.6306,
      101.6505, 101.6704, 101.6902, 101.7101, 101.7299, 101.7497, 101.7695,
      101.7893, 101.8091, 101.8289, 101.8487, 101.8685, 101.8883, 101.908,
      101.9278, 101.9475, 101.9673, 101.987, 102.0067, 102.0264, 102.0461,
      102.0658, 102.0855, 102.1052, 102.1249, 102.1446, 102.1642, 102.1839,
      102.2035, 102.2232, 102.2428, 102.2624, 102.282, 102.3016, 102.3212,
      102.3408, 102.3604, 102.38, 102.3996, 102.4191, 102.4387, 102.4582,
      102.4778, 102.4973, 102.5168, 102.5364, 102.5559, 102.5754, 102.5949,
      102.6144, 102.6338, 102.6533, 102.6728, 102.6923, 102.7117, 102.7312,
      102.7506, 102.77, 102.7895, 102.8089, 102.8283, 102.8477, 102.8671,
      102.8865, 102.9059, 102.9252, 102.9446, 102.964, 102.9833, 103.0027,
      103.022, 103.0414, 103.0607, 103.08, 103.0993, 103.1186, 103.1379,
      103.1572, 103.1765, 103.1958, 103.2151, 103.2343, 103.2536, 103.2728,
      103.2921, 103.3113, 103.3306, 103.3498, 103.369, 103.3882, 103.4074,
      103.4266, 103.4458, 103.465, 103.4842, 103.5034, 103.5225, 103.5417,
      103.5608, 103.58, 103.5991, 103.6183, 103.6374, 103.6565, 103.6756,
      103.6947, 103.7138, 103.7329, 103.752, 103.7711, 103.7902, 103.8092,
      103.8283, 103.8473, 103.8664, 103.8854, 103.9045, 103.9235, 103.9425,
      103.9616, 103.9806, 103.9996, 104.0186, 104.0376, 104.0565, 104.0755,
      104.0945, 104.1135, 104.1324, 104.1514, 104.1703, 104.1893, 104.2082,
      104.2271, 104.2461, 104.265, 104.2839, 104.3028, 104.3217, 104.3406,
      104.3595, 104.3784, 104.3972, 104.4161, 104.435, 104.4538, 104.4727,
      104.4915, 104.5104, 104.5292, 104.548, 104.5668, 104.5856, 104.6045,
      104.6233, 104.6421, 104.6608, 104.6796, 104.6984, 104.7172, 104.736,
      104.7547, 104.7735, 104.7922, 104.811, 104.8297, 104.8484, 104.8672,
      104.8859, 104.9046, 104.9233, 104.942, 104.9607, 104.9794, 104.9981,
      105.0167, 105.0354, 105.0541, 105.0727, 105.0914, 105.11, 105.1287,
      105.1473, 105.166, 105.1846, 105.2032, 105.2218, 105.2404, 105.259,
      105.2776, 105.2962, 105.3148, 105.3334, 105.352, 105.3705, 105.3891,
      105.4076, 105.4262, 105.4447, 105.4633, 105.4818, 105.5003, 105.5189,
      105.5374, 105.5559, 105.5744, 105.5929, 105.6114, 105.6299, 105.6483,
      105.6668, 105.6853, 105.7037, 105.7222, 105.7406, 105.7591, 105.7775,
      105.796, 105.8144, 105.8328, 105.8512, 105.8696, 105.888, 105.9064,
      105.9248, 105.9432, 105.9616, 105.98, 105.9983, 106.0167, 106.0351,
      106.0534, 106.0718, 106.0901, 106.1084, 106.1268, 106.1451, 106.1634,
      106.1817, 106.2, 106.2183, 106.2366, 106.2549, 106.2732, 106.2915,
      106.3097, 106.328, 106.3463, 106.3645, 106.3828, 106.401, 106.4192,
      106.4375, 106.4557, 106.4739, 106.4921, 106.5103, 106.5285, 106.5467,
      106.5649, 106.5831, 106.6013, 106.6195, 106.6376, 106.6558, 106.6739,
      106.6921, 106.7102, 106.7284, 106.7465, 106.7646, 106.7828, 106.8009,
      106.819, 106.8371, 106.8552, 106.8733, 106.8914, 106.9094, 106.9275,
      106.9456, 106.9636, 106.9817, 106.9998, 107.0178, 107.0358, 107.0539,
      107.0719, 107.0899, 107.1079, 107.126, 107.144, 107.162, 107.1799,
      107.1979, 107.2159, 107.2339, 107.2519, 107.2698, 107.2878, 107.3057,
      107.3237, 107.3416, 107.3596, 107.3775, 107.3954, 107.4133, 107.4312,
      107.4492, 107.4671, 107.4849, 107.5028, 107.5207, 107.5386, 107.5565,
      107.5743, 107.5922, 107.61, 107.6279, 107.6457, 107.6636, 107.6814,
      107.6992, 107.717, 107.7349, 107.7527, 107.7705, 107.7883, 107.806,
      107.8238, 107.8416, 107.8594, 107.8772, 107.8949, 107.9127, 107.9304,
      107.9482, 107.9659, 107.9836, 108.0014, 108.0191, 108.0368, 108.0545,
      108.0722, 108.0899, 108.1076, 108.1253, 108.143, 108.1607, 108.1783,
      108.196, 108.2137, 108.2313, 108.249, 108.2666, 108.2842, 108.3019,
      108.3195, 108.3371, 108.3547, 108.3723, 108.3899, 108.4075, 108.4251,
      108.4427, 108.4603, 108.4779, 108.4954, 108.513, 108.5306, 108.5481,
      108.5657, 108.5832, 108.6008, 108.6183, 108.6358, 108.6533, 108.6709,
      108.6884, 108.7059, 108.7234, 108.7409, 108.7583, 108.7758, 108.7933,
      108.8108, 108.8282, 108.8457, 108.8632, 108.8806, 108.8981, 108.9155,
      108.9329, 108.9504, 108.9678, 108.9852, 109.0026, 109.02, 109.0374,
      109.0548, 109.0722, 109.0896, 109.107, 109.1244, 109.1417, 109.1591,
      109.1764, 109.1938, 109.2112, 109.2285, 109.2458, 109.2632, 109.2805,
      109.2978, 109.3151, 109.3324, 109.3498, 109.3671, 109.3844, 109.4016,
      109.4189, 109.4362, 109.4535, 109.4708, 109.488, 109.5053, 109.5225,
      109.5398, 109.557, 109.5743, 109.5915, 109.6088, 109.626, 109.6432,
      109.6604, 109.6776, 109.6948, 109.712, 109.7292, 109.7464, 109.7636,
      109.7808, 109.798, 109.8151, 109.8323, 109.8494, 109.8666, 109.8837,
      109.9009, 109.918, 109.9352,
    ],
  },
  SD2: {
    label: '2 SD',
    weight: [
      52.873, 53.048, 53.223, 53.398, 53.573, 53.748, 53.922, 54.096, 54.271,
      54.445, 54.621, 54.795, 54.969, 55.144, 55.318, 55.463, 55.608, 55.752,
      55.896, 56.038, 56.18, 56.32, 56.46, 56.598, 56.736, 56.871, 57.007,
      57.141, 57.275, 57.407, 57.538, 57.668, 57.799, 57.927, 58.054, 58.18,
      58.307, 58.431, 58.554, 58.677, 58.798, 58.919, 59.039, 59.158, 59.276,
      59.393, 59.51, 59.625, 59.739, 59.853, 59.966, 60.077, 60.189, 60.299,
      60.409, 60.517, 60.625, 60.732, 60.839, 60.944, 61.049, 61.153, 61.256,
      61.359, 61.461, 61.563, 61.663, 61.763, 61.862, 61.961, 62.06, 62.157,
      62.254, 62.351, 62.446, 62.541, 62.636, 62.729, 62.823, 62.915, 63.008,
      63.099, 63.191, 63.281, 63.371, 63.46, 63.549, 63.638, 63.725, 63.813,
      63.899, 63.986, 64.072, 64.156, 64.242, 64.326, 64.411, 64.493, 64.576,
      64.659, 64.741, 64.823, 64.903, 64.984, 65.065, 65.145, 65.224, 65.303,
      65.382, 65.46, 65.537, 65.614, 65.691, 65.767, 65.843, 65.919, 65.994,
      66.069, 66.143, 66.217, 66.29, 66.365, 66.437, 66.509, 66.581, 66.653,
      66.724, 66.794, 66.864, 66.934, 67.005, 67.074, 67.143, 67.211, 67.279,
      67.348, 67.415, 67.482, 67.549, 67.615, 67.682, 67.747, 67.813, 67.879,
      67.943, 68.008, 68.073, 68.137, 68.2, 68.264, 68.327, 68.39, 68.453,
      68.515, 68.577, 68.639, 68.7, 68.762, 68.823, 68.885, 68.945, 69.005,
      69.065, 69.125, 69.185, 69.244, 69.304, 69.363, 69.422, 69.48, 69.54,
      69.599, 69.656, 69.715, 69.772, 69.83, 69.887, 69.944, 70.002, 70.058,
      70.116, 70.173, 70.228, 70.285, 70.341, 70.397, 70.453, 70.51, 70.564,
      70.62, 70.676, 70.73, 70.786, 70.841, 70.895, 70.95, 71.005, 71.059,
      71.114, 71.167, 71.221, 71.276, 71.33, 71.382, 71.436, 71.49, 71.544,
      71.597, 71.651, 71.703, 71.756, 71.809, 71.862, 71.915, 71.967, 72.02,
      72.073, 72.124, 72.176, 72.228, 72.28, 72.333, 72.384, 72.436, 72.488,
      72.54, 72.591, 72.643, 72.694, 72.746, 72.797, 72.848, 72.899, 72.95,
      73.001, 73.052, 73.102, 73.153, 73.203, 73.254, 73.304, 73.354, 73.405,
      73.455, 73.505, 73.555, 73.605, 73.655, 73.704, 73.754, 73.804, 73.854,
      73.904, 73.953, 74.003, 74.052, 74.101, 74.15, 74.199, 74.248, 74.298,
      74.347, 74.396, 74.444, 74.493, 74.541, 74.591, 74.64, 74.688, 74.736,
      74.785, 74.834, 74.882, 74.93, 74.978, 75.026, 75.075, 75.123, 75.17,
      75.218, 75.266, 75.315, 75.362, 75.41, 75.457, 75.506, 75.553, 75.6,
      75.648, 75.695, 75.742, 75.789, 75.838, 75.884, 75.931, 75.979, 76.026,
      76.072, 76.119, 76.167, 76.213, 76.26, 76.307, 76.353, 76.4, 76.447,
      76.493, 76.539, 76.587, 76.633, 76.678, 76.725, 76.771, 76.818, 76.864,
      76.909, 76.956, 77.002, 77.047, 77.094, 77.139, 77.186, 77.231, 77.276,
      77.323, 77.368, 77.414, 77.459, 77.504, 77.55, 77.595, 77.641, 77.686,
      77.732, 77.776, 77.821, 77.867, 77.911, 77.957, 78.001, 78.047, 78.091,
      78.137, 78.181, 78.226, 78.27, 78.314, 78.36, 78.403, 78.449, 78.492,
      78.537, 78.581, 78.626, 78.67, 78.715, 78.758, 78.803, 78.846, 78.891,
      78.934, 78.979, 79.022, 79.067, 79.11, 79.154, 79.197, 79.242, 79.284,
      79.329, 79.373, 79.416, 79.46, 79.502, 79.547, 79.589, 79.633, 79.675,
      79.719, 79.762, 79.805, 79.849, 79.891, 79.935, 79.977, 80.021, 80.063,
      80.106, 80.148, 80.192, 80.235, 80.277, 80.32, 80.362, 80.405, 80.447,
      80.49, 80.533, 80.574, 80.617, 80.659, 80.702, 80.744, 80.786, 80.828,
      80.87, 80.912, 80.955, 80.996, 81.039, 81.08, 81.122, 81.164, 81.205,
      81.248, 81.288, 81.331, 81.373, 81.414, 81.456, 81.498, 81.538, 81.58,
      81.621, 81.663, 81.704, 81.745, 81.787, 81.828, 81.868, 81.91, 81.952,
      81.992, 82.033, 82.073, 82.115, 82.156, 82.196, 82.238, 82.279, 82.319,
      82.36, 82.401, 82.441, 82.482, 82.523, 82.562, 82.603, 82.644, 82.684,
      82.725, 82.764, 82.805, 82.846, 82.885, 82.926, 82.966, 83.005, 83.046,
      83.087, 83.126, 83.166, 83.207, 83.245, 83.286, 83.326, 83.365, 83.405,
      83.445, 83.486, 83.524, 83.564, 83.605, 83.643, 83.683, 83.723, 83.762,
      83.802, 83.841, 83.88, 83.92, 83.959, 83.998, 84.037, 84.077, 84.115,
      84.155, 84.194, 84.234, 84.272, 84.311, 84.351, 84.389, 84.428, 84.468,
      84.505, 84.545, 84.584, 84.622, 84.661, 84.7, 84.739, 84.777, 84.816,
      84.855, 84.892, 84.931, 84.97, 85.007, 85.046, 85.085, 85.124, 85.161,
      85.2, 85.238, 85.276, 85.314, 85.353, 85.391, 85.428, 85.467, 85.505,
      85.542, 85.581, 85.619, 85.657, 85.694, 85.733, 85.771, 85.807, 85.846,
      85.884, 85.922, 85.958, 85.997, 86.035, 86.071, 86.109, 86.147, 86.185,
      86.221, 86.259, 86.297, 86.333, 86.371, 86.409, 86.446, 86.483, 86.52,
      86.558, 86.595, 86.631, 86.669, 86.706, 86.742, 86.78, 86.817, 86.855,
      86.89, 86.928, 86.965, 87.002, 87.038, 87.075, 87.112, 87.15, 87.185,
      87.222, 87.259, 87.295, 87.332, 87.369, 87.406, 87.441, 87.478, 87.515,
      87.552, 87.587, 87.624, 87.66, 87.697, 87.732, 87.769, 87.806, 87.841,
      87.877, 87.914, 87.95, 87.985, 88.022, 88.058, 88.095, 88.129, 88.166,
      88.202, 88.239, 88.273, 88.31, 88.346, 88.382, 88.417, 88.453, 88.489,
      88.525, 88.559, 88.596, 88.632, 88.668, 88.702, 88.738, 88.774, 88.81,
      88.844, 88.88, 88.916, 88.952, 88.986, 89.022, 89.057, 89.091, 89.127,
      89.163, 89.198, 89.232, 89.268, 89.303, 89.339, 89.373, 89.408, 89.444,
      89.479, 89.513, 89.548, 89.583, 89.619, 89.652, 89.688, 89.723, 89.758,
      89.792, 89.827, 89.862, 89.897, 89.931, 89.966, 90.001, 90.036, 90.069,
      90.104, 90.139, 90.174, 90.207, 90.242, 90.277, 90.312, 90.345, 90.379,
      90.414, 90.449, 90.482, 90.516, 90.551, 90.586, 90.619, 90.653, 90.688,
      90.722, 90.755, 90.79, 90.824, 90.858, 90.891, 90.925, 90.96, 90.994,
      91.027, 91.061, 91.095, 91.129, 91.162, 91.196, 91.23, 91.264, 91.297,
      91.331, 91.365, 91.399, 91.431, 91.465, 91.499, 91.533, 91.565, 91.599,
      91.633, 91.665, 91.699, 91.732, 91.766, 91.798, 91.832, 91.866, 91.899,
      91.931, 91.965, 91.998, 92.032, 92.064, 92.097, 92.131, 92.164, 92.184,
      92.196, 92.217, 92.229, 92.25, 92.263, 92.282, 92.296, 92.314, 92.328,
      92.346, 92.361, 92.379, 92.394, 92.412, 92.428, 92.443, 92.459, 92.476,
      92.492, 92.509, 92.526, 92.54, 92.559, 92.572, 92.59, 92.605, 92.624,
      92.638, 92.657, 92.669, 92.688, 92.701, 92.721, 92.734, 92.754, 92.767,
      92.787, 92.797, 92.818, 92.83, 92.851, 92.862, 92.893, 92.926, 92.958,
      92.991, 93.021, 93.054, 93.086, 93.118, 93.149, 93.181, 93.214, 93.244,
      93.276, 93.309, 93.341, 93.371, 93.403, 93.435, 93.468, 93.498, 93.53,
      93.562, 93.592, 93.624, 93.656, 93.688, 93.719, 93.75, 93.782, 93.812,
      93.844, 93.876, 93.908, 93.938, 93.97, 94.002, 94.031, 94.063, 94.095,
      94.127, 94.156, 94.188, 94.22, 94.251, 94.281, 94.313, 94.344, 94.374,
      94.405, 94.437, 94.468, 94.498, 94.529, 94.561, 94.59, 94.622, 94.653,
      94.682, 94.714, 94.745, 94.776, 94.806, 94.837, 94.868, 94.897, 94.928,
      94.96, 94.991, 95.02, 95.051, 95.082, 95.111, 95.142, 95.173, 95.204,
      95.233, 95.264, 95.295, 95.324, 95.355, 95.385, 95.415, 95.445, 95.476,
      95.507, 95.536, 95.566, 95.597, 95.626, 95.656, 95.687, 95.716, 95.746,
      95.777, 95.807, 95.836, 95.866, 95.897, 95.925, 95.956, 95.986, 96.015,
      96.045, 96.075, 96.106, 96.134, 96.164, 96.195, 96.223, 96.253, 96.283,
      96.312, 96.342, 96.372, 96.4, 96.43, 96.46, 96.49, 96.518, 96.548, 96.578,
      96.607, 96.636, 96.666, 96.694, 96.724, 96.754, 96.782, 96.812, 96.842,
      96.87, 96.899, 96.929, 96.957, 96.987, 97.016, 97.046, 97.074, 97.103,
      97.133, 97.161, 97.19, 97.22, 97.247, 97.277, 97.306, 97.334, 97.363,
      97.393, 97.42, 97.45, 97.479, 97.506, 97.536, 97.565, 97.592, 97.622,
      97.651, 97.678, 97.707, 97.737, 97.764, 97.793, 97.822, 97.849, 97.878,
      97.907, 97.935, 97.964, 97.993, 98.02, 98.049, 98.078, 98.105, 98.134,
      98.163, 98.19, 98.218, 98.247, 98.274, 98.303, 98.332, 98.359, 98.387,
      98.416, 98.443, 98.472, 98.5, 98.527, 98.556, 98.584, 98.611, 98.64,
      98.668, 98.695, 98.723, 98.752, 98.779, 98.807, 98.834, 98.862, 98.89,
      98.917, 98.945, 98.974, 99, 99.029, 99.057, 99.083, 99.112, 99.14, 99.166,
      99.195, 99.223, 99.249, 99.277, 99.304, 99.332, 99.36, 99.386, 99.414,
      99.442, 99.469, 99.497, 99.525, 99.551, 99.579, 99.605, 99.633, 99.661,
      99.687, 99.715, 99.743, 99.769, 99.797, 99.825, 99.851, 99.878, 99.904,
      99.932, 99.96, 99.986, 100.014, 100.041, 100.067, 100.095, 100.121,
      100.149, 100.176, 100.202, 100.23, 100.257, 100.283, 100.311, 100.336,
      100.364, 100.392, 100.417, 100.445, 100.472, 100.498, 100.525, 100.551,
      100.579, 100.606, 100.632, 100.659, 100.685, 100.712, 100.739, 100.765,
      100.792, 100.818, 100.845, 100.872, 100.898, 100.925, 100.952, 100.978,
      101.005, 101.03, 101.058, 101.085, 101.11, 101.137, 101.163, 101.19,
      101.217, 101.242, 101.269, 101.295, 101.322, 101.349, 101.374, 101.401,
      101.426, 101.453, 101.48, 101.505, 101.532, 101.557, 101.585, 101.611,
      101.637, 101.664, 101.689, 101.715, 101.742, 101.767, 101.794, 101.819,
      101.846, 101.873, 101.898, 101.925, 101.95, 101.976, 102.001, 102.028,
      102.055, 102.08, 102.107, 102.131, 102.158, 102.185, 102.21, 102.236,
      102.261, 102.288, 102.314, 102.339, 102.366, 102.39, 102.417, 102.442,
      102.468, 102.495, 102.52, 102.546, 102.571, 102.597, 102.622, 102.649,
      102.675, 102.7, 102.726, 102.751, 102.777, 102.802, 102.828, 102.855,
      102.879, 102.905, 102.93, 102.956, 102.981, 103.007, 103.034, 103.058,
      103.084, 103.109, 103.135, 103.16, 103.186, 103.212, 103.236, 103.263,
      103.287, 103.313, 103.338, 103.364, 103.39, 103.414, 103.44, 103.465,
      103.491, 103.515, 103.541, 103.565, 103.592, 103.618, 103.642, 103.668,
      103.692, 103.718, 103.742, 103.768, 103.794, 103.819, 103.845, 103.869,
      103.895, 103.919, 103.945, 103.969, 103.995, 104.019, 104.045, 104.071,
      104.095, 104.121, 104.145, 104.17, 104.194, 104.22, 104.244, 104.27,
      104.296, 104.32, 104.346, 104.37, 104.395, 104.419, 104.445, 104.469,
      104.495, 104.518, 104.544, 104.57, 104.594, 104.619, 104.643, 104.669,
      104.693, 104.718, 104.742, 104.768, 104.791, 104.817, 104.841, 104.866,
      104.892, 104.916, 104.941, 104.965, 104.99, 105.014, 105.039, 105.063,
      105.089, 105.112, 105.138, 105.161, 105.187, 105.212, 105.236, 105.261,
      105.285, 105.31, 105.334, 105.359, 105.383, 105.408, 105.431, 105.457,
      105.48, 105.506, 105.529, 105.554, 105.578, 105.603, 105.628, 105.652,
      105.677, 105.7, 105.726, 105.749, 105.774, 105.798, 105.823, 105.846,
      105.871, 105.894, 105.92, 105.943, 105.968, 105.991, 106.016, 106.04,
      106.065, 106.088, 106.113, 106.136, 106.161, 106.186, 106.21, 106.235,
      106.258, 106.283, 106.306, 106.331, 106.354, 106.379, 106.402, 106.427,
      106.45, 106.475, 106.498, 106.523, 106.546, 106.571, 106.594, 106.619,
      106.642, 106.667, 106.69, 106.715, 106.738, 106.762, 106.785, 106.81,
      106.833, 106.858, 106.881, 106.905, 106.928, 106.953, 106.976, 107.001,
      107.023, 107.048, 107.073, 107.096, 107.12, 107.143, 107.168, 107.191,
      107.215, 107.238, 107.263, 107.285, 107.31, 107.333, 107.357, 107.38,
      107.404, 107.427, 107.452, 107.474, 107.499, 107.522, 107.546, 107.569,
      107.593, 107.616, 107.64, 107.663, 107.687, 107.71, 107.734, 107.757,
      107.781, 107.804, 107.828, 107.851, 107.875, 107.897, 107.922, 107.944,
      107.969, 107.991, 108.015, 108.038, 108.062, 108.084, 108.109, 108.131,
      108.155, 108.178, 108.2, 108.224, 108.247, 108.271, 108.293, 108.317,
      108.34, 108.364, 108.386, 108.41, 108.432, 108.457, 108.479, 108.503,
      108.525, 108.549, 108.571, 108.596, 108.618, 108.642, 108.664, 108.688,
      108.71, 108.734, 108.756, 108.78, 108.802, 108.826, 108.848, 108.873,
      108.895, 108.919, 108.941, 108.965, 108.987, 109.01, 109.032, 109.056,
      109.078, 109.102, 109.124, 109.146, 109.17, 109.192, 109.216, 109.238,
      109.262, 109.283, 109.307, 109.329, 109.353, 109.375, 109.399, 109.42,
      109.444, 109.466, 109.49, 109.512, 109.535, 109.557, 109.581, 109.603,
      109.626, 109.648, 109.672, 109.693, 109.715, 109.739, 109.76, 109.784,
      109.806, 109.829, 109.851, 109.875, 109.896, 109.92, 109.942, 109.965,
      109.987, 110.01, 110.032, 110.055, 110.077, 110.1, 110.122, 110.144,
      110.167, 110.189, 110.212, 110.234, 110.257, 110.279, 110.302, 110.323,
      110.347, 110.368, 110.392, 110.413, 110.437, 110.458, 110.482, 110.503,
      110.524, 110.548, 110.569, 110.592, 110.614, 110.637, 110.658, 110.682,
      110.703, 110.726, 110.748, 110.771, 110.792, 110.814, 110.837, 110.858,
      110.881, 110.903, 110.926, 110.947, 110.97, 110.992, 111.015, 111.036,
      111.059, 111.08, 111.102, 111.125, 111.146, 111.169, 111.19, 111.213,
      111.235, 111.258, 111.279, 111.302, 111.323, 111.346, 111.367, 111.388,
      111.411, 111.433, 111.456, 111.477, 111.5, 111.521, 111.544, 111.565,
      111.588, 111.609, 111.63, 111.653, 111.674, 111.697, 111.718, 111.741,
      111.762, 111.785, 111.806, 111.829, 111.85, 111.87, 111.893, 111.914,
      111.937, 111.958, 111.981, 112.002, 112.025, 112.046, 112.069, 112.089,
      112.11, 112.133, 112.154, 112.177, 112.198, 112.22, 112.241, 112.264,
      112.285, 112.306, 112.328, 112.349, 112.372, 112.393, 112.415, 112.436,
      112.459, 112.48, 112.5, 112.523, 112.544, 112.567, 112.587, 112.61,
      112.631, 112.653, 112.674, 112.695, 112.717, 112.738, 112.761, 112.781,
      112.804, 112.825, 112.847, 112.868, 112.888, 112.911, 112.932, 112.954,
      112.975, 112.997, 113.018, 113.038, 113.061, 113.082, 113.104, 113.125,
      113.147, 113.168, 113.19, 113.211, 113.231, 113.254, 113.274, 113.297,
      113.317, 113.34, 113.36, 113.381, 113.403, 113.424, 113.446, 113.467,
      113.489, 113.509, 113.532, 113.552, 113.573, 113.595, 113.616, 113.638,
      113.658, 113.681, 113.701, 113.721, 113.744, 113.764, 113.787, 113.807,
      113.829, 113.849, 113.87, 113.892, 113.912, 113.935, 113.955, 113.977,
      113.998, 114.018, 114.04, 114.06, 114.083, 114.103, 114.125, 114.145,
      114.166, 114.188, 114.208, 114.23, 114.251, 114.273, 114.293, 114.313,
      114.335, 114.356, 114.378, 114.398, 114.42, 114.44, 114.46, 114.483,
      114.503, 114.525, 114.545, 114.567, 114.587, 114.607, 114.63, 114.65,
      114.672, 114.692, 114.714, 114.734, 114.754, 114.776, 114.796, 114.818,
      114.838, 114.858, 114.88, 114.9, 114.922, 114.942, 114.964, 114.984,
      115.004, 115.026, 115.046, 115.068, 115.088, 115.11, 115.13, 115.15,
      115.172, 115.192, 115.214, 115.234, 115.254, 115.276, 115.296, 115.318,
      115.338, 115.36, 115.379, 115.399, 115.421, 115.441, 115.463, 115.483,
      115.503, 115.524, 115.544, 115.566, 115.586, 115.608, 115.628, 115.647,
      115.669, 115.689, 115.711, 115.731, 115.75, 115.772, 115.792, 115.814,
      115.833, 115.855, 115.875, 115.895, 115.916, 115.936, 115.958, 115.978,
      115.997, 116.019, 116.039, 116.061, 116.08, 116.1, 116.122, 116.141,
      116.163, 116.183, 116.204, 116.224, 116.243, 116.265, 116.285, 116.306,
      116.326, 116.346, 116.367, 116.387, 116.408, 116.428, 116.447, 116.469,
      116.489, 116.51, 116.53, 116.551, 116.571, 116.59, 116.612, 116.631,
      116.653, 116.672, 116.692, 116.714, 116.733, 116.754, 116.774, 116.793,
      116.815, 116.834, 116.856, 116.875, 116.895, 116.916, 116.936, 116.957,
      116.976, 116.998, 117.017, 117.037, 117.058, 117.077, 117.099, 117.118,
      117.138, 117.159, 117.178, 117.2, 117.219, 117.238, 117.26, 117.279,
      117.3, 117.32, 117.339, 117.36, 117.38, 117.401, 117.42, 117.439, 117.461,
      117.48, 117.501, 117.521, 117.54, 117.561, 117.58, 117.602, 117.621,
      117.642, 117.661, 117.68, 117.702, 117.721, 117.742, 117.761, 117.78,
      117.802, 117.821, 117.842, 117.861, 117.88, 117.902, 117.921, 117.942,
      117.961, 117.98, 118.001, 118.02, 118.041, 118.061, 118.08, 118.101,
      118.12, 118.141, 118.16, 118.179, 118.2, 118.219, 118.24, 118.259,
      118.278, 118.299, 118.318, 118.34, 118.358, 118.377, 118.399, 118.417,
      118.439, 118.457, 118.476, 118.497, 118.516, 118.537, 118.556, 118.575,
      118.596, 118.615, 118.636, 118.655, 118.674, 118.695, 118.714, 118.735,
      118.754, 118.773, 118.794, 118.812, 118.833, 118.852, 118.871, 118.892,
      118.911, 118.93, 118.951, 118.969, 118.99, 119.009, 119.028, 119.049,
      119.068, 119.088, 119.107, 119.126, 119.147, 119.166, 119.187, 119.205,
      119.224, 119.245, 119.264, 119.284, 119.303, 119.322, 119.343, 119.361,
      119.382, 119.401, 119.419, 119.44, 119.459, 119.48, 119.498, 119.517,
    ],
  },
  SD3: {
    label: '3 SD',
    weight: [
      54.736, 54.914, 55.091, 55.27, 55.448, 55.625, 55.802, 55.98, 56.157,
      56.334, 56.513, 56.69, 56.867, 57.044, 57.221, 57.369, 57.518, 57.665,
      57.812, 57.957, 58.102, 58.245, 58.388, 58.53, 58.671, 58.809, 58.948,
      59.085, 59.221, 59.357, 59.491, 59.624, 59.758, 59.889, 60.019, 60.148,
      60.277, 60.404, 60.53, 60.656, 60.78, 60.904, 61.026, 61.148, 61.268,
      61.389, 61.508, 61.625, 61.743, 61.86, 61.975, 62.089, 62.203, 62.316,
      62.428, 62.539, 62.65, 62.759, 62.868, 62.976, 63.083, 63.189, 63.295,
      63.4, 63.504, 63.609, 63.712, 63.814, 63.916, 64.016, 64.118, 64.217,
      64.316, 64.416, 64.513, 64.61, 64.708, 64.803, 64.9, 64.994, 65.089,
      65.182, 65.276, 65.368, 65.461, 65.551, 65.643, 65.734, 65.823, 65.913,
      66.001, 66.09, 66.178, 66.264, 66.352, 66.439, 66.525, 66.609, 66.695,
      66.78, 66.864, 66.948, 67.03, 67.113, 67.195, 67.277, 67.359, 67.44,
      67.521, 67.601, 67.679, 67.758, 67.837, 67.915, 67.993, 68.07, 68.147,
      68.224, 68.3, 68.376, 68.451, 68.528, 68.602, 68.676, 68.75, 68.823,
      68.896, 68.968, 69.04, 69.111, 69.184, 69.255, 69.325, 69.395, 69.465,
      69.536, 69.605, 69.673, 69.742, 69.809, 69.879, 69.946, 70.013, 70.081,
      70.147, 70.213, 70.28, 70.345, 70.41, 70.476, 70.54, 70.604, 70.67,
      70.733, 70.796, 70.861, 70.923, 70.988, 71.05, 71.113, 71.175, 71.236,
      71.299, 71.359, 71.422, 71.482, 71.544, 71.604, 71.665, 71.725, 71.786,
      71.847, 71.905, 71.966, 72.024, 72.084, 72.142, 72.202, 72.261, 72.319,
      72.378, 72.437, 72.494, 72.552, 72.609, 72.667, 72.725, 72.783, 72.839,
      72.897, 72.954, 73.01, 73.067, 73.124, 73.179, 73.236, 73.292, 73.349,
      73.405, 73.46, 73.516, 73.572, 73.628, 73.681, 73.737, 73.792, 73.848,
      73.903, 73.958, 74.011, 74.066, 74.121, 74.175, 74.23, 74.284, 74.339,
      74.393, 74.445, 74.499, 74.553, 74.607, 74.661, 74.714, 74.768, 74.821,
      74.875, 74.928, 74.981, 75.034, 75.087, 75.14, 75.192, 75.245, 75.298,
      75.35, 75.403, 75.455, 75.507, 75.559, 75.611, 75.664, 75.715, 75.767,
      75.819, 75.871, 75.922, 75.974, 76.025, 76.076, 76.128, 76.179, 76.232,
      76.283, 76.334, 76.385, 76.436, 76.486, 76.537, 76.588, 76.638, 76.691,
      76.741, 76.791, 76.842, 76.892, 76.942, 76.994, 77.044, 77.094, 77.144,
      77.193, 77.245, 77.295, 77.344, 77.394, 77.443, 77.495, 77.544, 77.593,
      77.642, 77.692, 77.743, 77.792, 77.841, 77.889, 77.94, 77.989, 78.038,
      78.088, 78.137, 78.185, 78.234, 78.284, 78.333, 78.381, 78.431, 78.479,
      78.527, 78.575, 78.626, 78.674, 78.721, 78.771, 78.819, 78.867, 78.916,
      78.964, 79.011, 79.061, 79.108, 79.156, 79.205, 79.252, 79.302, 79.349,
      79.396, 79.445, 79.492, 79.539, 79.587, 79.634, 79.683, 79.73, 79.776,
      79.825, 79.872, 79.92, 79.966, 80.013, 80.061, 80.107, 80.156, 80.202,
      80.25, 80.296, 80.342, 80.39, 80.436, 80.484, 80.529, 80.577, 80.623,
      80.671, 80.716, 80.764, 80.809, 80.855, 80.902, 80.947, 80.995, 81.04,
      81.087, 81.132, 81.179, 81.224, 81.271, 81.316, 81.363, 81.408, 81.455,
      81.5, 81.546, 81.591, 81.638, 81.682, 81.729, 81.773, 81.82, 81.864,
      81.91, 81.957, 82.001, 82.047, 82.091, 82.137, 82.181, 82.227, 82.271,
      82.317, 82.361, 82.407, 82.453, 82.496, 82.542, 82.586, 82.631, 82.675,
      82.72, 82.764, 82.809, 82.855, 82.898, 82.943, 82.986, 83.032, 83.075,
      83.12, 83.165, 83.208, 83.253, 83.296, 83.341, 83.386, 83.428, 83.473,
      83.516, 83.56, 83.605, 83.648, 83.692, 83.735, 83.779, 83.824, 83.866,
      83.91, 83.952, 83.997, 84.041, 84.083, 84.127, 84.171, 84.213, 84.257,
      84.299, 84.343, 84.387, 84.429, 84.473, 84.516, 84.558, 84.602, 84.645,
      84.687, 84.73, 84.772, 84.815, 84.859, 84.9, 84.943, 84.987, 85.028,
      85.071, 85.114, 85.155, 85.199, 85.242, 85.282, 85.326, 85.369, 85.409,
      85.452, 85.493, 85.536, 85.579, 85.619, 85.662, 85.705, 85.745, 85.788,
      85.83, 85.871, 85.913, 85.956, 85.996, 86.038, 86.081, 86.121, 86.163,
      86.206, 86.248, 86.288, 86.33, 86.372, 86.412, 86.454, 86.496, 86.536,
      86.578, 86.62, 86.659, 86.701, 86.743, 86.782, 86.824, 86.866, 86.905,
      86.947, 86.989, 87.03, 87.07, 87.111, 87.153, 87.192, 87.233, 87.275,
      87.314, 87.355, 87.396, 87.435, 87.477, 87.518, 87.559, 87.598, 87.639,
      87.68, 87.719, 87.76, 87.801, 87.839, 87.88, 87.921, 87.962, 88, 88.041,
      88.082, 88.12, 88.161, 88.201, 88.242, 88.28, 88.321, 88.361, 88.399,
      88.44, 88.48, 88.521, 88.559, 88.599, 88.639, 88.677, 88.717, 88.758,
      88.798, 88.835, 88.876, 88.916, 88.953, 88.993, 89.033, 89.073, 89.111,
      89.151, 89.19, 89.228, 89.268, 89.308, 89.347, 89.385, 89.424, 89.464,
      89.503, 89.541, 89.58, 89.62, 89.657, 89.696, 89.736, 89.775, 89.812,
      89.851, 89.891, 89.93, 89.967, 90.006, 90.045, 90.085, 90.121, 90.16,
      90.199, 90.236, 90.275, 90.314, 90.353, 90.39, 90.428, 90.467, 90.506,
      90.543, 90.581, 90.62, 90.659, 90.695, 90.734, 90.773, 90.809, 90.847,
      90.886, 90.925, 90.961, 90.999, 91.038, 91.076, 91.112, 91.15, 91.189,
      91.227, 91.263, 91.301, 91.339, 91.378, 91.413, 91.452, 91.49, 91.528,
      91.564, 91.602, 91.64, 91.678, 91.713, 91.751, 91.789, 91.827, 91.862,
      91.9, 91.938, 91.976, 92.011, 92.049, 92.087, 92.122, 92.16, 92.197,
      92.235, 92.27, 92.308, 92.345, 92.383, 92.418, 92.455, 92.493, 92.53,
      92.565, 92.602, 92.639, 92.677, 92.711, 92.749, 92.786, 92.823, 92.858,
      92.895, 92.932, 92.969, 93.004, 93.041, 93.078, 93.115, 93.149, 93.186,
      93.223, 93.26, 93.294, 93.331, 93.368, 93.405, 93.439, 93.476, 93.512,
      93.549, 93.583, 93.62, 93.657, 93.693, 93.727, 93.764, 93.8, 93.837,
      93.871, 93.907, 93.943, 93.98, 94.014, 94.05, 94.086, 94.122, 94.156,
      94.192, 94.229, 94.265, 94.298, 94.335, 94.371, 94.407, 94.44, 94.476,
      94.512, 94.548, 94.582, 94.618, 94.653, 94.689, 94.723, 94.758, 94.794,
      94.828, 94.863, 94.899, 94.935, 94.968, 95.003, 95.039, 95.075, 95.108,
      95.143, 95.179, 95.214, 95.247, 95.283, 95.318, 95.354, 95.386, 95.411,
      95.422, 95.445, 95.457, 95.48, 95.493, 95.515, 95.525, 95.547, 95.561,
      95.582, 95.596, 95.617, 95.631, 95.652, 95.664, 95.684, 95.699, 95.719,
      95.734, 95.753, 95.769, 95.786, 95.802, 95.82, 95.837, 95.855, 95.872,
      95.889, 95.904, 95.921, 95.94, 95.956, 95.974, 95.991, 96.01, 96.025,
      96.042, 96.057, 96.077, 96.092, 96.126, 96.158, 96.192, 96.227, 96.261,
      96.293, 96.327, 96.361, 96.396, 96.427, 96.462, 96.496, 96.527, 96.562,
      96.596, 96.63, 96.661, 96.696, 96.73, 96.764, 96.795, 96.829, 96.863,
      96.894, 96.928, 96.962, 96.996, 97.027, 97.061, 97.095, 97.126, 97.16,
      97.194, 97.228, 97.259, 97.292, 97.326, 97.357, 97.391, 97.424, 97.458,
      97.489, 97.523, 97.556, 97.59, 97.62, 97.654, 97.687, 97.718, 97.752,
      97.785, 97.818, 97.849, 97.882, 97.916, 97.946, 97.98, 98.013, 98.043,
      98.077, 98.11, 98.143, 98.173, 98.207, 98.24, 98.27, 98.303, 98.336,
      98.369, 98.399, 98.432, 98.465, 98.495, 98.528, 98.561, 98.594, 98.624,
      98.657, 98.69, 98.72, 98.753, 98.785, 98.815, 98.848, 98.881, 98.913,
      98.943, 98.976, 99.008, 99.038, 99.071, 99.103, 99.133, 99.166, 99.198,
      99.23, 99.26, 99.292, 99.325, 99.354, 99.387, 99.419, 99.449, 99.481,
      99.513, 99.545, 99.575, 99.607, 99.639, 99.668, 99.701, 99.733, 99.762,
      99.794, 99.826, 99.855, 99.887, 99.919, 99.951, 99.98, 100.012, 100.044,
      100.073, 100.105, 100.137, 100.166, 100.198, 100.229, 100.258, 100.29,
      100.322, 100.351, 100.382, 100.414, 100.443, 100.475, 100.506, 100.538,
      100.566, 100.598, 100.629, 100.658, 100.69, 100.721, 100.75, 100.781,
      100.812, 100.841, 100.872, 100.904, 100.932, 100.963, 100.995, 101.023,
      101.054, 101.085, 101.114, 101.145, 101.176, 101.205, 101.236, 101.267,
      101.295, 101.326, 101.357, 101.385, 101.416, 101.447, 101.475, 101.506,
      101.537, 101.565, 101.596, 101.627, 101.655, 101.686, 101.716, 101.744,
      101.775, 101.806, 101.834, 101.864, 101.895, 101.923, 101.954, 101.984,
      102.012, 102.043, 102.073, 102.101, 102.132, 102.162, 102.19, 102.22,
      102.251, 102.278, 102.309, 102.339, 102.367, 102.397, 102.425, 102.455,
      102.485, 102.513, 102.543, 102.573, 102.601, 102.631, 102.661, 102.688,
      102.719, 102.749, 102.776, 102.806, 102.836, 102.864, 102.894, 102.921,
      102.951, 102.981, 103.008, 103.038, 103.068, 103.095, 103.125, 103.155,
      103.182, 103.212, 103.239, 103.269, 103.299, 103.326, 103.356, 103.386,
      103.413, 103.442, 103.472, 103.499, 103.529, 103.556, 103.585, 103.615,
      103.642, 103.672, 103.701, 103.728, 103.758, 103.784, 103.814, 103.844,
      103.87, 103.9, 103.929, 103.956, 103.985, 104.012, 104.042, 104.071,
      104.098, 104.127, 104.156, 104.183, 104.212, 104.239, 104.268, 104.298,
      104.324, 104.353, 104.38, 104.409, 104.438, 104.465, 104.494, 104.521,
      104.55, 104.579, 104.605, 104.634, 104.664, 104.69, 104.719, 104.745,
      104.775, 104.804, 104.83, 104.859, 104.885, 104.914, 104.943, 104.969,
      104.998, 105.024, 105.053, 105.082, 105.109, 105.137, 105.164, 105.192,
      105.221, 105.247, 105.276, 105.302, 105.331, 105.36, 105.386, 105.415,
      105.441, 105.47, 105.498, 105.524, 105.553, 105.579, 105.608, 105.636,
      105.662, 105.691, 105.717, 105.746, 105.771, 105.8, 105.829, 105.854,
      105.883, 105.909, 105.937, 105.966, 105.992, 106.02, 106.046, 106.074,
      106.103, 106.129, 106.157, 106.183, 106.211, 106.237, 106.265, 106.294,
      106.319, 106.348, 106.373, 106.402, 106.427, 106.456, 106.484, 106.509,
      106.538, 106.563, 106.592, 106.617, 106.645, 106.674, 106.699, 106.727,
      106.753, 106.781, 106.806, 106.835, 106.863, 106.888, 106.916, 106.942,
      106.97, 106.995, 107.023, 107.052, 107.077, 107.105, 107.13, 107.158,
      107.183, 107.212, 107.24, 107.265, 107.293, 107.318, 107.346, 107.371,
      107.399, 107.424, 107.452, 107.48, 107.505, 107.533, 107.558, 107.586,
      107.611, 107.639, 107.667, 107.692, 107.72, 107.745, 107.773, 107.798,
      107.826, 107.851, 107.879, 107.903, 107.931, 107.959, 107.984, 108.012,
      108.037, 108.064, 108.089, 108.117, 108.142, 108.169, 108.197, 108.222,
      108.25, 108.274, 108.302, 108.327, 108.354, 108.379, 108.407, 108.431,
      108.459, 108.487, 108.511, 108.539, 108.564, 108.591, 108.616, 108.643,
      108.668, 108.695, 108.72, 108.748, 108.772, 108.8, 108.827, 108.852,
      108.879, 108.904, 108.931, 108.956, 108.983, 109.007, 109.035, 109.059,
      109.087, 109.111, 109.138, 109.166, 109.19, 109.217, 109.242, 109.269,
      109.294, 109.321, 109.345, 109.372, 109.397, 109.424, 109.448, 109.476,
      109.5, 109.527, 109.551, 109.578, 109.606, 109.63, 109.657, 109.681,
      109.708, 109.733, 109.76, 109.784, 109.811, 109.835, 109.862, 109.886,
      109.913, 109.937, 109.965, 109.989, 110.016, 110.04, 110.067, 110.091,
      110.118, 110.142, 110.169, 110.196, 110.22, 110.247, 110.271, 110.298,
      110.321, 110.348, 110.372, 110.399, 110.423, 110.45, 110.474, 110.501,
      110.525, 110.551, 110.575, 110.602, 110.626, 110.653, 110.676, 110.703,
      110.727, 110.754, 110.778, 110.804, 110.828, 110.855, 110.878, 110.905,
      110.929, 110.956, 110.979, 111.006, 111.03, 111.056, 111.08, 111.106,
      111.133, 111.157, 111.183, 111.207, 111.233, 111.257, 111.283, 111.307,
      111.333, 111.357, 111.384, 111.407, 111.434, 111.457, 111.483, 111.507,
      111.533, 111.557, 111.583, 111.607, 111.633, 111.657, 111.683, 111.706,
      111.733, 111.756, 111.782, 111.806, 111.832, 111.856, 111.882, 111.905,
      111.931, 111.955, 111.981, 112.004, 112.031, 112.054, 112.08, 112.103,
      112.13, 112.153, 112.179, 112.202, 112.228, 112.251, 112.278, 112.301,
      112.324, 112.35, 112.373, 112.399, 112.422, 112.449, 112.472, 112.498,
      112.521, 112.547, 112.57, 112.596, 112.619, 112.645, 112.668, 112.694,
      112.717, 112.743, 112.766, 112.792, 112.815, 112.841, 112.864, 112.89,
      112.913, 112.939, 112.961, 112.987, 113.01, 113.036, 113.059, 113.085,
      113.108, 113.133, 113.156, 113.182, 113.205, 113.231, 113.254, 113.279,
      113.302, 113.325, 113.351, 113.373, 113.399, 113.422, 113.447, 113.47,
      113.496, 113.518, 113.544, 113.567, 113.593, 113.615, 113.641, 113.663,
      113.689, 113.712, 113.737, 113.76, 113.785, 113.808, 113.834, 113.856,
      113.882, 113.904, 113.927, 113.952, 113.975, 114, 114.023, 114.048,
      114.071, 114.096, 114.119, 114.144, 114.167, 114.192, 114.214, 114.24,
      114.262, 114.288, 114.31, 114.335, 114.358, 114.38, 114.406, 114.428,
      114.453, 114.476, 114.501, 114.523, 114.548, 114.571, 114.596, 114.618,
      114.644, 114.666, 114.691, 114.713, 114.739, 114.761, 114.783, 114.808,
      114.83, 114.856, 114.878, 114.903, 114.925, 114.95, 114.973, 114.998,
      115.02, 115.045, 115.067, 115.089, 115.114, 115.136, 115.161, 115.184,
      115.209, 115.231, 115.256, 115.278, 115.303, 115.325, 115.35, 115.372,
      115.394, 115.419, 115.441, 115.466, 115.488, 115.513, 115.535, 115.56,
      115.582, 115.607, 115.629, 115.654, 115.676, 115.697, 115.722, 115.744,
      115.769, 115.791, 115.816, 115.838, 115.863, 115.885, 115.909, 115.931,
      115.953, 115.978, 116, 116.025, 116.046, 116.071, 116.093, 116.118,
      116.139, 116.164, 116.186, 116.208, 116.233, 116.254, 116.279, 116.301,
      116.325, 116.347, 116.372, 116.394, 116.418, 116.44, 116.462, 116.486,
      116.508, 116.533, 116.554, 116.579, 116.6, 116.625, 116.647, 116.668,
      116.693, 116.715, 116.739, 116.761, 116.785, 116.807, 116.832, 116.853,
      116.875, 116.899, 116.921, 116.945, 116.967, 116.991, 117.013, 117.037,
      117.059, 117.08, 117.105, 117.126, 117.151, 117.172, 117.197, 117.218,
      117.243, 117.264, 117.285, 117.31, 117.331, 117.356, 117.377, 117.402,
      117.423, 117.444, 117.469, 117.49, 117.514, 117.536, 117.56, 117.581,
      117.606, 117.627, 117.648, 117.673, 117.694, 117.718, 117.74, 117.764,
      117.785, 117.806, 117.831, 117.852, 117.876, 117.898, 117.922, 117.943,
      117.967, 117.989, 118.01, 118.034, 118.055, 118.079, 118.101, 118.125,
      118.146, 118.167, 118.191, 118.213, 118.237, 118.258, 118.282, 118.303,
      118.324, 118.349, 118.37, 118.394, 118.415, 118.439, 118.46, 118.481,
      118.505, 118.526, 118.55, 118.572, 118.596, 118.617, 118.638, 118.662,
      118.683, 118.707, 118.728, 118.752, 118.773, 118.794, 118.818, 118.839,
      118.863, 118.884, 118.908, 118.929, 118.95, 118.974, 118.995, 119.019,
      119.04, 119.064, 119.085, 119.105, 119.129, 119.15, 119.174, 119.195,
      119.219, 119.24, 119.261, 119.285, 119.305, 119.329, 119.35, 119.371,
      119.395, 119.416, 119.44, 119.46, 119.484, 119.505, 119.526, 119.55,
      119.57, 119.594, 119.615, 119.639, 119.66, 119.68, 119.704, 119.725,
      119.749, 119.769, 119.79, 119.814, 119.834, 119.858, 119.879, 119.903,
      119.923, 119.944, 119.968, 119.988, 120.012, 120.033, 120.053, 120.077,
      120.098, 120.121, 120.142, 120.166, 120.186, 120.207, 120.23, 120.251,
      120.275, 120.295, 120.316, 120.339, 120.36, 120.384, 120.404, 120.428,
      120.448, 120.469, 120.492, 120.513, 120.537, 120.557, 120.577, 120.601,
      120.621, 120.645, 120.665, 120.686, 120.709, 120.73, 120.753, 120.774,
      120.797, 120.818, 120.838, 120.862, 120.882, 120.906, 120.926, 120.946,
      120.97, 120.99, 121.014, 121.034, 121.054, 121.078, 121.098, 121.121,
      121.142, 121.165, 121.185, 121.206, 121.229, 121.249, 121.273, 121.293,
      121.313, 121.337, 121.357, 121.38, 121.401, 121.421, 121.444, 121.464,
      121.488, 121.508, 121.528, 121.551, 121.572, 121.595, 121.615, 121.638,
      121.659, 121.679, 121.702, 121.722, 121.745, 121.765, 121.786, 121.809,
      121.829, 121.852, 121.872, 121.892, 121.916, 121.936, 121.959, 121.979,
      121.999, 122.022, 122.042, 122.065, 122.085, 122.105, 122.129, 122.149,
      122.172, 122.192, 122.212, 122.235, 122.255, 122.278, 122.298, 122.321,
      122.341, 122.361, 122.384, 122.404, 122.427, 122.447, 122.467, 122.49,
      122.51, 122.533, 122.553, 122.573, 122.596, 122.616, 122.639, 122.659,
      122.678, 122.702, 122.721, 122.744, 122.764, 122.784, 122.807, 122.827,
      122.85, 122.87, 122.889, 122.912, 122.932, 122.955, 122.975, 122.995,
      123.018, 123.037, 123.06, 123.08, 123.1, 123.123, 123.142, 123.165,
      123.185, 123.205, 123.228, 123.247, 123.27, 123.29, 123.309, 123.332,
      123.352, 123.375, 123.394, 123.414, 123.437, 123.457, 123.479, 123.499,
      123.519, 123.541, 123.561, 123.584, 123.604, 123.623, 123.646, 123.665,
      123.685, 123.708, 123.727, 123.75, 123.77, 123.789, 123.812, 123.831,
      123.854, 123.874, 123.893, 123.916, 123.935, 123.958, 123.978, 123.997,
      124.02, 124.039, 124.062, 124.081, 124.101, 124.124, 124.143, 124.166,
      124.185, 124.205, 124.227, 124.247, 124.269, 124.289, 124.308,
    ],
  },
  SD3neg: {
    label: '-3 SD',
    weight: [
      43.56, 43.72, 43.88, 44.038, 44.199, 44.359, 44.519, 44.68, 44.84, 45.001,
      45.16, 45.321, 45.482, 45.642, 45.803, 45.933, 46.061, 46.19, 46.316,
      46.444, 46.568, 46.694, 46.817, 46.94, 47.061, 47.184, 47.303, 47.422,
      47.54, 47.658, 47.774, 47.89, 48.003, 48.117, 48.231, 48.343, 48.453,
      48.564, 48.674, 48.781, 48.89, 48.996, 49.102, 49.207, 49.312, 49.415,
      49.518, 49.621, 49.721, 49.822, 49.921, 50.021, 50.119, 50.216, 50.313,
      50.409, 50.504, 50.598, 50.692, 50.785, 50.878, 50.97, 51.061, 51.152,
      51.242, 51.33, 51.42, 51.508, 51.596, 51.684, 51.769, 51.856, 51.942,
      52.025, 52.11, 52.195, 52.277, 52.36, 52.441, 52.524, 52.604, 52.686,
      52.765, 52.845, 52.924, 53.003, 53.081, 53.158, 53.236, 53.312, 53.39,
      53.465, 53.54, 53.616, 53.69, 53.763, 53.837, 53.911, 53.984, 54.056,
      54.127, 54.199, 54.271, 54.342, 54.412, 54.482, 54.551, 54.62, 54.689,
      54.757, 54.827, 54.894, 54.961, 55.028, 55.095, 55.161, 55.227, 55.292,
      55.357, 55.422, 55.486, 55.548, 55.612, 55.675, 55.738, 55.801, 55.863,
      55.925, 55.987, 56.049, 56.108, 56.168, 56.229, 56.289, 56.349, 56.407,
      56.466, 56.525, 56.584, 56.642, 56.698, 56.756, 56.814, 56.869, 56.926,
      56.983, 57.038, 57.094, 57.15, 57.204, 57.26, 57.315, 57.368, 57.423,
      57.478, 57.53, 57.584, 57.636, 57.69, 57.742, 57.795, 57.849, 57.9,
      57.952, 58.003, 58.056, 58.106, 58.158, 58.208, 58.26, 58.309, 58.359,
      58.41, 58.459, 58.51, 58.559, 58.609, 58.658, 58.706, 58.756, 58.804,
      58.852, 58.902, 58.95, 58.999, 59.047, 59.094, 59.141, 59.19, 59.237,
      59.284, 59.332, 59.379, 59.425, 59.473, 59.519, 59.566, 59.611, 59.657,
      59.705, 59.751, 59.796, 59.841, 59.889, 59.934, 59.979, 60.024, 60.069,
      60.114, 60.161, 60.205, 60.25, 60.294, 60.338, 60.383, 60.427, 60.471,
      60.517, 60.561, 60.605, 60.649, 60.692, 60.736, 60.779, 60.823, 60.866,
      60.909, 60.953, 60.996, 61.039, 61.082, 61.125, 61.167, 61.21, 61.253,
      61.295, 61.338, 61.38, 61.423, 61.465, 61.507, 61.55, 61.592, 61.634,
      61.676, 61.718, 61.76, 61.801, 61.843, 61.885, 61.926, 61.966, 62.007,
      62.049, 62.09, 62.131, 62.173, 62.214, 62.255, 62.296, 62.335, 62.376,
      62.417, 62.458, 62.498, 62.539, 62.578, 62.618, 62.659, 62.699, 62.74,
      62.778, 62.818, 62.859, 62.899, 62.939, 62.977, 63.017, 63.057, 63.097,
      63.137, 63.175, 63.214, 63.254, 63.294, 63.331, 63.371, 63.411, 63.448,
      63.487, 63.527, 63.566, 63.603, 63.643, 63.682, 63.719, 63.758, 63.797,
      63.836, 63.873, 63.912, 63.95, 63.987, 64.026, 64.065, 64.101, 64.14,
      64.178, 64.215, 64.253, 64.292, 64.328, 64.366, 64.402, 64.441, 64.479,
      64.515, 64.553, 64.591, 64.627, 64.665, 64.7, 64.738, 64.776, 64.812,
      64.849, 64.885, 64.923, 64.96, 64.996, 65.033, 65.068, 65.106, 65.141,
      65.178, 65.216, 65.251, 65.288, 65.323, 65.36, 65.395, 65.432, 65.467,
      65.504, 65.538, 65.575, 65.612, 65.646, 65.683, 65.718, 65.754, 65.789,
      65.825, 65.86, 65.896, 65.93, 65.966, 66.001, 66.037, 66.071, 66.107,
      66.141, 66.177, 66.211, 66.247, 66.281, 66.317, 66.351, 66.387, 66.42,
      66.454, 66.49, 66.523, 66.559, 66.592, 66.628, 66.661, 66.697, 66.73,
      66.765, 66.799, 66.832, 66.867, 66.9, 66.935, 66.968, 67.003, 67.036,
      67.071, 67.104, 67.137, 67.172, 67.205, 67.24, 67.272, 67.307, 67.34,
      67.372, 67.407, 67.439, 67.474, 67.506, 67.539, 67.573, 67.605, 67.64,
      67.672, 67.704, 67.738, 67.77, 67.805, 67.837, 67.869, 67.903, 67.935,
      67.969, 68.001, 68.032, 68.066, 68.098, 68.129, 68.163, 68.195, 68.229,
      68.26, 68.292, 68.325, 68.357, 68.388, 68.422, 68.453, 68.484, 68.518,
      68.549, 68.582, 68.613, 68.644, 68.678, 68.708, 68.739, 68.773, 68.803,
      68.834, 68.867, 68.898, 68.929, 68.962, 68.993, 69.023, 69.056, 69.087,
      69.119, 69.15, 69.18, 69.213, 69.244, 69.274, 69.307, 69.337, 69.367,
      69.4, 69.43, 69.46, 69.492, 69.523, 69.553, 69.585, 69.615, 69.645,
      69.675, 69.707, 69.737, 69.767, 69.799, 69.829, 69.858, 69.89, 69.92,
      69.95, 69.982, 70.011, 70.041, 70.073, 70.102, 70.132, 70.164, 70.193,
      70.222, 70.252, 70.283, 70.312, 70.342, 70.373, 70.403, 70.432, 70.463,
      70.492, 70.521, 70.553, 70.582, 70.611, 70.64, 70.671, 70.7, 70.729,
      70.76, 70.789, 70.817, 70.848, 70.877, 70.906, 70.934, 70.965, 70.994,
      71.023, 71.054, 71.082, 71.11, 71.139, 71.17, 71.198, 71.226, 71.257,
      71.285, 71.314, 71.342, 71.372, 71.401, 71.429, 71.459, 71.487, 71.515,
      71.543, 71.574, 71.602, 71.63, 71.66, 71.688, 71.716, 71.744, 71.774,
      71.802, 71.829, 71.859, 71.887, 71.915, 71.942, 71.972, 72, 72.028,
      72.055, 72.085, 72.113, 72.14, 72.17, 72.197, 72.225, 72.252, 72.282,
      72.309, 72.336, 72.363, 72.393, 72.42, 72.447, 72.474, 72.504, 72.531,
      72.558, 72.588, 72.615, 72.642, 72.669, 72.698, 72.725, 72.752, 72.778,
      72.808, 72.835, 72.861, 72.888, 72.917, 72.944, 72.97, 73, 73.026, 73.053,
      73.079, 73.108, 73.135, 73.161, 73.188, 73.217, 73.243, 73.269, 73.296,
      73.325, 73.351, 73.377, 73.403, 73.432, 73.458, 73.485, 73.511, 73.539,
      73.565, 73.591, 73.618, 73.646, 73.672, 73.698, 73.724, 73.752, 73.778,
      73.804, 73.83, 73.858, 73.884, 73.91, 73.938, 73.964, 73.99, 74.015,
      74.044, 74.069, 74.095, 74.12, 74.149, 74.174, 74.2, 74.225, 74.253,
      74.279, 74.304, 74.329, 74.357, 74.383, 74.408, 74.433, 74.461, 74.486,
      74.512, 74.537, 74.565, 74.59, 74.615, 74.64, 74.668, 74.693, 74.718,
      74.743, 74.77, 74.795, 74.82, 74.845, 74.873, 74.898, 74.922, 74.947,
      74.975, 74.999, 75.024, 75.049, 75.076, 75.101, 75.125, 75.15, 75.177,
      75.202, 75.227, 75.251, 75.278, 75.303, 75.327, 75.352, 75.379, 75.403,
      75.427, 75.452, 75.479, 75.503, 75.527, 75.552, 75.578, 75.603, 75.627,
      75.651, 75.678, 75.702, 75.726, 75.75, 75.777, 75.801, 75.825, 75.851,
      75.875, 75.899, 75.923, 75.95, 75.974, 75.998, 76.021, 76.048, 76.049,
      76.072, 76.072, 76.096, 76.096, 76.119, 76.119, 76.145, 76.146, 76.168,
      76.169, 76.191, 76.193, 76.214, 76.217, 76.239, 76.243, 76.262, 76.267,
      76.285, 76.29, 76.311, 76.314, 76.334, 76.34, 76.357, 76.364, 76.38,
      76.387, 76.405, 76.411, 76.428, 76.437, 76.451, 76.46, 76.474, 76.484,
      76.499, 76.507, 76.522, 76.533, 76.545, 76.556, 76.57, 76.58, 76.593,
      76.606, 76.616, 76.629, 76.639, 76.652, 76.664, 76.676, 76.687, 76.702,
      76.709, 76.725, 76.732, 76.757, 76.78, 76.802, 76.827, 76.85, 76.872,
      76.895, 76.92, 76.942, 76.965, 76.987, 77.012, 77.035, 77.057, 77.082,
      77.104, 77.127, 77.149, 77.174, 77.196, 77.218, 77.243, 77.265, 77.287,
      77.31, 77.334, 77.356, 77.378, 77.403, 77.425, 77.447, 77.469, 77.494,
      77.516, 77.538, 77.56, 77.584, 77.606, 77.628, 77.652, 77.674, 77.696,
      77.718, 77.742, 77.764, 77.786, 77.81, 77.832, 77.853, 77.878, 77.899,
      77.921, 77.942, 77.967, 77.988, 78.01, 78.034, 78.055, 78.077, 78.098,
      78.122, 78.144, 78.165, 78.189, 78.211, 78.232, 78.253, 78.277, 78.298,
      78.32, 78.343, 78.365, 78.386, 78.41, 78.431, 78.452, 78.473, 78.497,
      78.518, 78.539, 78.563, 78.584, 78.605, 78.628, 78.649, 78.67, 78.691,
      78.715, 78.736, 78.756, 78.78, 78.801, 78.822, 78.845, 78.866, 78.887,
      78.907, 78.931, 78.951, 78.972, 78.995, 79.016, 79.037, 79.06, 79.08,
      79.101, 79.124, 79.145, 79.165, 79.186, 79.209, 79.229, 79.25, 79.273,
      79.293, 79.313, 79.337, 79.357, 79.377, 79.4, 79.42, 79.441, 79.464,
      79.484, 79.504, 79.527, 79.547, 79.567, 79.587, 79.61, 79.63, 79.65,
      79.673, 79.693, 79.713, 79.736, 79.756, 79.776, 79.798, 79.818, 79.838,
      79.861, 79.881, 79.901, 79.923, 79.943, 79.963, 79.985, 80.005, 80.025,
      80.047, 80.067, 80.086, 80.109, 80.128, 80.148, 80.17, 80.19, 80.21,
      80.232, 80.251, 80.271, 80.293, 80.313, 80.332, 80.354, 80.374, 80.393,
      80.415, 80.435, 80.454, 80.476, 80.496, 80.515, 80.537, 80.556, 80.576,
      80.597, 80.617, 80.636, 80.658, 80.677, 80.696, 80.718, 80.737, 80.756,
      80.778, 80.797, 80.816, 80.838, 80.857, 80.879, 80.898, 80.917, 80.939,
      80.958, 80.977, 80.998, 81.017, 81.036, 81.058, 81.077, 81.096, 81.117,
      81.136, 81.155, 81.176, 81.195, 81.217, 81.235, 81.254, 81.276, 81.294,
      81.313, 81.334, 81.353, 81.372, 81.393, 81.412, 81.433, 81.452, 81.47,
      81.492, 81.51, 81.529, 81.55, 81.568, 81.587, 81.608, 81.627, 81.648,
      81.666, 81.685, 81.706, 81.724, 81.743, 81.764, 81.782, 81.803, 81.822,
      81.84, 81.861, 81.879, 81.898, 81.919, 81.937, 81.958, 81.976, 81.994,
      82.015, 82.034, 82.052, 82.073, 82.091, 82.112, 82.13, 82.148, 82.169,
      82.187, 82.208, 82.226, 82.244, 82.265, 82.283, 82.303, 82.321, 82.339,
      82.36, 82.378, 82.396, 82.417, 82.435, 82.455, 82.473, 82.491, 82.512,
      82.53, 82.55, 82.568, 82.586, 82.607, 82.624, 82.645, 82.663, 82.68,
      82.701, 82.719, 82.739, 82.757, 82.775, 82.795, 82.813, 82.833, 82.851,
      82.869, 82.889, 82.907, 82.927, 82.945, 82.962, 82.983, 83, 83.021,
      83.038, 83.056, 83.076, 83.093, 83.114, 83.131, 83.152, 83.169, 83.186,
      83.207, 83.224, 83.244, 83.262, 83.279, 83.299, 83.317, 83.337, 83.354,
      83.372, 83.392, 83.409, 83.429, 83.447, 83.467, 83.484, 83.501, 83.521,
      83.539, 83.559, 83.576, 83.596, 83.613, 83.63, 83.65, 83.668, 83.688,
      83.705, 83.725, 83.742, 83.759, 83.779, 83.796, 83.816, 83.833, 83.853,
      83.87, 83.887, 83.907, 83.924, 83.944, 83.961, 83.981, 83.998, 84.015,
      84.034, 84.051, 84.071, 84.088, 84.108, 84.125, 84.142, 84.162, 84.178,
      84.198, 84.215, 84.235, 84.252, 84.271, 84.288, 84.305, 84.325, 84.341,
      84.361, 84.378, 84.397, 84.414, 84.431, 84.451, 84.467, 84.487, 84.504,
      84.523, 84.54, 84.559, 84.576, 84.596, 84.612, 84.629, 84.648, 84.665,
      84.685, 84.701, 84.721, 84.737, 84.757, 84.773, 84.79, 84.809, 84.826,
      84.845, 84.862, 84.881, 84.898, 84.917, 84.933, 84.953, 84.969, 84.986,
      85.005, 85.021, 85.041, 85.057, 85.076, 85.093, 85.112, 85.128, 85.148,
      85.164, 85.183, 85.2, 85.216, 85.235, 85.251, 85.271, 85.287, 85.306,
      85.322, 85.341, 85.358, 85.377, 85.393, 85.412, 85.428, 85.445, 85.464,
      85.48, 85.499, 85.515, 85.534, 85.55, 85.569, 85.586, 85.605, 85.621,
      85.64, 85.656, 85.675, 85.691, 85.71, 85.726, 85.742, 85.761, 85.777,
      85.796, 85.812, 85.831, 85.847, 85.866, 85.882, 85.901, 85.916, 85.935,
      85.951, 85.97, 85.986, 86.005, 86.021, 86.04, 86.055, 86.074, 86.09,
      86.109, 86.125, 86.141, 86.159, 86.175, 86.194, 86.21, 86.228, 86.244,
      86.263, 86.279, 86.297, 86.313, 86.332, 86.347, 86.366, 86.382, 86.4,
      86.416, 86.435, 86.45, 86.469, 86.485, 86.503, 86.519, 86.537, 86.553,
      86.572, 86.587, 86.606, 86.621, 86.64, 86.655, 86.674, 86.689, 86.708,
      86.723, 86.742, 86.757, 86.773, 86.791, 86.807, 86.825, 86.841, 86.859,
      86.874, 86.893, 86.908, 86.927, 86.942, 86.96, 86.976, 86.994, 87.009,
      87.028, 87.043, 87.061, 87.077, 87.095, 87.11, 87.129, 87.144, 87.162,
      87.177, 87.196, 87.211, 87.229, 87.244, 87.262, 87.278, 87.296, 87.311,
      87.329, 87.344, 87.363, 87.378, 87.396, 87.411, 87.429, 87.444, 87.462,
      87.477, 87.496, 87.511, 87.529, 87.544, 87.562, 87.58, 87.595, 87.613,
      87.628, 87.646, 87.661, 87.679, 87.694, 87.712, 87.727, 87.745, 87.76,
      87.778, 87.793, 87.811, 87.826, 87.844, 87.858, 87.876, 87.891, 87.909,
      87.924, 87.942, 87.957, 87.975, 87.989, 88.007, 88.022, 88.04, 88.055,
      88.072, 88.087, 88.105, 88.12, 88.137, 88.152, 88.17, 88.185, 88.202,
      88.217, 88.235, 88.253, 88.267, 88.285, 88.3, 88.317, 88.332, 88.35,
      88.364, 88.382, 88.397, 88.414, 88.429, 88.446, 88.461, 88.479, 88.493,
      88.511, 88.525, 88.543, 88.557, 88.575, 88.589, 88.607, 88.621, 88.639,
      88.657, 88.671, 88.689, 88.703, 88.72, 88.735, 88.752, 88.767, 88.784,
      88.799, 88.816, 88.831, 88.848, 88.862, 88.88, 88.894, 88.911, 88.926,
      88.943, 88.961, 88.975, 88.992, 89.007, 89.024, 89.038, 89.055, 89.07,
      89.087, 89.101, 89.119, 89.133, 89.15, 89.164, 89.182, 89.196, 89.213,
      89.23, 89.245, 89.262, 89.276, 89.293, 89.307, 89.325, 89.339, 89.356,
      89.37, 89.387, 89.401, 89.418, 89.436, 89.45, 89.467, 89.481, 89.498,
      89.512, 89.529, 89.543, 89.56, 89.574, 89.591, 89.606, 89.623, 89.64,
      89.654, 89.671, 89.685, 89.702, 89.716, 89.733, 89.747, 89.764, 89.778,
      89.795, 89.809, 89.826, 89.843, 89.857, 89.873, 89.887, 89.904, 89.918,
      89.935, 89.949, 89.966, 89.98, 89.997, 90.014, 90.027, 90.044, 90.058,
      90.075, 90.089, 90.106, 90.119, 90.136, 90.15, 90.167, 90.184, 90.198,
      90.214, 90.228, 90.245, 90.259, 90.275, 90.289, 90.306, 90.32, 90.336,
      90.353, 90.367, 90.384, 90.397, 90.414, 90.428, 90.445, 90.458, 90.475,
      90.492, 90.505, 90.522, 90.536, 90.552, 90.566, 90.583, 90.596, 90.613,
      90.629, 90.643, 90.66, 90.673, 90.69, 90.703, 90.72, 90.734, 90.75,
      90.767, 90.78, 90.797, 90.81, 90.827, 90.84, 90.857, 90.87, 90.887,
      90.904, 90.917, 90.934, 90.947, 90.964, 90.977, 90.994, 91.01, 91.024,
      91.04, 91.053, 91.07, 91.083, 91.1, 91.113, 91.13, 91.146, 91.159, 91.176,
      91.189, 91.206, 91.219, 91.235, 91.252, 91.265, 91.282, 91.295, 91.311,
      91.325, 91.341, 91.354, 91.371, 91.387, 91.4, 91.417, 91.43, 91.446,
      91.459, 91.476, 91.492, 91.505, 91.522, 91.535, 91.551, 91.564, 91.581,
      91.597, 91.61, 91.627, 91.64, 91.656, 91.669, 91.685, 91.702, 91.715,
      91.731, 91.744, 91.76, 91.774, 91.79, 91.806, 91.819, 91.835, 91.848,
      91.865, 91.878, 91.894, 91.91, 91.923, 91.939, 91.952, 91.969, 91.982,
      91.998, 92.014, 92.027, 92.043, 92.056, 92.072, 92.085, 92.101, 92.117,
      92.13, 92.146, 92.159, 92.176, 92.188, 92.205, 92.221, 92.234, 92.25,
      92.263, 92.279, 92.295, 92.308, 92.324, 92.336, 92.352, 92.365, 92.381,
      92.397, 92.41, 92.426, 92.439, 92.455, 92.468, 92.484, 92.5, 92.513,
      92.529, 92.542, 92.557, 92.573, 92.586, 92.602, 92.615, 92.631, 92.644,
      92.66, 92.675, 92.688, 92.704, 92.717, 92.733, 92.749, 92.761, 92.777,
      92.79, 92.806, 92.819, 92.834, 92.85, 92.863, 92.879, 92.891, 92.907,
      92.923, 92.936, 92.952, 92.964, 92.98, 92.993, 93.008, 93.024, 93.037,
      93.053, 93.065, 93.081, 93.097, 93.109, 93.125, 93.138, 93.153, 93.169,
      93.182, 93.197, 93.21, 93.226, 93.238, 93.254, 93.27, 93.282, 93.298,
      93.31, 93.326, 93.342, 93.354, 93.37, 93.382, 93.398, 93.414, 93.426,
      93.442, 93.454, 93.47, 93.482, 93.498, 93.513, 93.526, 93.541, 93.554,
      93.569, 93.585, 93.597, 93.613, 93.625, 93.641, 93.656, 93.669, 93.684,
      93.697, 93.712, 93.728, 93.74, 93.756, 93.768, 93.783, 93.796, 93.811,
      93.827, 93.839, 93.855, 93.867, 93.882, 93.898, 93.91, 93.925, 93.938,
      93.953, 93.969, 93.981, 93.996, 94.008, 94.024, 94.039, 94.051, 94.067,
      94.079, 94.094, 94.11, 94.122, 94.137, 94.15, 94.165, 94.18, 94.192,
      94.208, 94.22, 94.235, 94.247, 94.263, 94.278, 94.29, 94.305, 94.317,
      94.333, 94.348, 94.36, 94.376, 94.388, 94.403, 94.418, 94.43, 94.445,
      94.457, 94.473, 94.488, 94.5, 94.515, 94.527, 94.542, 94.558, 94.57,
      94.585, 94.597, 94.612, 94.627, 94.639, 94.655, 94.666, 94.682, 94.697,
      94.709, 94.724, 94.736, 94.751, 94.766, 94.778, 94.793, 94.805, 94.82,
      94.835, 94.847, 94.862, 94.874, 94.889, 94.905, 94.916, 94.931, 94.943,
      94.958, 94.973, 94.985, 95, 95.012, 95.027, 95.042, 95.054, 95.069,
      95.081, 95.096, 95.111, 95.123, 95.138, 95.153, 95.165, 95.18, 95.191,
      95.206, 95.221, 95.233, 95.248, 95.26, 95.275, 95.29, 95.302, 95.317,
      95.328, 95.343, 95.358, 95.37, 95.385, 95.396, 95.411, 95.426, 95.438,
      95.453, 95.464, 95.479, 95.494, 95.506, 95.521, 95.532, 95.547, 95.562,
    ],
  },
  SD2neg: {
    label: '-2 SD',
    weight: [
      45.422, 45.585, 45.748, 45.91, 46.074, 46.237, 46.4, 46.563, 46.726,
      46.89, 47.052, 47.216, 47.379, 47.543, 47.706, 47.839, 47.971, 48.102,
      48.232, 48.362, 48.491, 48.619, 48.746, 48.872, 48.996, 49.121, 49.244,
      49.366, 49.487, 49.608, 49.727, 49.846, 49.962, 50.079, 50.195, 50.31,
      50.424, 50.537, 50.65, 50.76, 50.871, 50.98, 51.09, 51.197, 51.305,
      51.411, 51.516, 51.621, 51.725, 51.828, 51.93, 52.032, 52.133, 52.233,
      52.332, 52.43, 52.528, 52.625, 52.721, 52.817, 52.912, 53.006, 53.1,
      53.193, 53.286, 53.377, 53.468, 53.559, 53.65, 53.739, 53.827, 53.916,
      54.004, 54.09, 54.177, 54.264, 54.349, 54.434, 54.518, 54.602, 54.685,
      54.768, 54.85, 54.932, 55.013, 55.095, 55.175, 55.254, 55.334, 55.412,
      55.491, 55.569, 55.646, 55.724, 55.8, 55.876, 55.951, 56.028, 56.102,
      56.176, 56.25, 56.324, 56.398, 56.47, 56.542, 56.614, 56.686, 56.757,
      56.827, 56.898, 56.969, 57.038, 57.107, 57.176, 57.244, 57.312, 57.38,
      57.447, 57.514, 57.581, 57.647, 57.712, 57.777, 57.842, 57.907, 57.971,
      58.035, 58.099, 58.163, 58.226, 58.287, 58.35, 58.412, 58.474, 58.535,
      58.595, 58.656, 58.716, 58.777, 58.837, 58.895, 58.954, 59.013, 59.071,
      59.13, 59.188, 59.245, 59.303, 59.36, 59.416, 59.473, 59.53, 59.585,
      59.641, 59.697, 59.752, 59.808, 59.862, 59.917, 59.97, 60.025, 60.08,
      60.133, 60.187, 60.239, 60.293, 60.345, 60.399, 60.451, 60.504, 60.555,
      60.607, 60.659, 60.71, 60.762, 60.813, 60.865, 60.915, 60.965, 61.017,
      61.067, 61.116, 61.167, 61.217, 61.267, 61.317, 61.366, 61.415, 61.465,
      61.513, 61.562, 61.612, 61.66, 61.708, 61.758, 61.806, 61.853, 61.901,
      61.949, 61.997, 62.045, 62.092, 62.139, 62.188, 62.234, 62.281, 62.328,
      62.375, 62.421, 62.469, 62.515, 62.562, 62.608, 62.654, 62.7, 62.746,
      62.791, 62.838, 62.884, 62.929, 62.975, 63.02, 63.066, 63.111, 63.156,
      63.201, 63.246, 63.291, 63.335, 63.38, 63.425, 63.469, 63.514, 63.558,
      63.602, 63.647, 63.691, 63.735, 63.779, 63.823, 63.867, 63.911, 63.954,
      63.998, 64.042, 64.085, 64.129, 64.172, 64.215, 64.259, 64.302, 64.344,
      64.387, 64.43, 64.473, 64.516, 64.558, 64.601, 64.644, 64.686, 64.728,
      64.77, 64.813, 64.855, 64.897, 64.94, 64.98, 65.023, 65.065, 65.107,
      65.149, 65.189, 65.231, 65.273, 65.315, 65.356, 65.397, 65.438, 65.48,
      65.521, 65.563, 65.603, 65.644, 65.685, 65.727, 65.766, 65.807, 65.848,
      65.888, 65.929, 65.97, 66.011, 66.05, 66.091, 66.132, 66.171, 66.212,
      66.252, 66.293, 66.332, 66.372, 66.412, 66.451, 66.491, 66.532, 66.57,
      66.61, 66.651, 66.689, 66.729, 66.769, 66.807, 66.847, 66.885, 66.925,
      66.965, 67.003, 67.043, 67.082, 67.12, 67.16, 67.198, 67.237, 67.276,
      67.314, 67.353, 67.391, 67.43, 67.469, 67.507, 67.545, 67.583, 67.622,
      67.659, 67.698, 67.737, 67.774, 67.812, 67.85, 67.888, 67.925, 67.964,
      68.001, 68.039, 68.076, 68.114, 68.152, 68.189, 68.227, 68.264, 68.302,
      68.338, 68.376, 68.413, 68.451, 68.487, 68.525, 68.561, 68.599, 68.635,
      68.673, 68.709, 68.746, 68.782, 68.82, 68.856, 68.893, 68.929, 68.966,
      69.002, 69.038, 69.075, 69.11, 69.148, 69.183, 69.22, 69.256, 69.292,
      69.328, 69.365, 69.4, 69.435, 69.472, 69.507, 69.544, 69.579, 69.615,
      69.65, 69.687, 69.722, 69.757, 69.793, 69.828, 69.864, 69.899, 69.935,
      69.97, 70.004, 70.04, 70.075, 70.111, 70.145, 70.18, 70.216, 70.25,
      70.286, 70.32, 70.354, 70.39, 70.424, 70.46, 70.494, 70.528, 70.563,
      70.597, 70.633, 70.667, 70.7, 70.736, 70.769, 70.803, 70.838, 70.872,
      70.907, 70.941, 70.974, 71.009, 71.043, 71.076, 71.111, 71.144, 71.178,
      71.212, 71.246, 71.28, 71.314, 71.347, 71.381, 71.414, 71.447, 71.482,
      71.515, 71.548, 71.582, 71.615, 71.648, 71.682, 71.715, 71.747, 71.782,
      71.814, 71.848, 71.881, 71.914, 71.948, 71.98, 72.012, 72.046, 72.079,
      72.111, 72.145, 72.177, 72.209, 72.243, 72.275, 72.307, 72.341, 72.373,
      72.405, 72.437, 72.471, 72.502, 72.534, 72.568, 72.6, 72.631, 72.665,
      72.696, 72.728, 72.761, 72.793, 72.825, 72.858, 72.889, 72.921, 72.954,
      72.985, 73.017, 73.048, 73.081, 73.112, 73.144, 73.176, 73.208, 73.239,
      73.272, 73.303, 73.334, 73.366, 73.397, 73.429, 73.46, 73.492, 73.523,
      73.554, 73.586, 73.617, 73.648, 73.68, 73.711, 73.742, 73.772, 73.805,
      73.835, 73.866, 73.898, 73.928, 73.959, 73.989, 74.021, 74.052, 74.082,
      74.114, 74.144, 74.175, 74.205, 74.237, 74.267, 74.297, 74.329, 74.359,
      74.389, 74.419, 74.451, 74.481, 74.511, 74.542, 74.572, 74.602, 74.632,
      74.663, 74.693, 74.723, 74.754, 74.784, 74.814, 74.843, 74.874, 74.904,
      74.934, 74.963, 74.994, 75.024, 75.053, 75.084, 75.114, 75.143, 75.172,
      75.203, 75.233, 75.262, 75.291, 75.322, 75.351, 75.38, 75.409, 75.44,
      75.469, 75.498, 75.529, 75.558, 75.587, 75.616, 75.646, 75.675, 75.704,
      75.733, 75.764, 75.792, 75.821, 75.85, 75.88, 75.909, 75.937, 75.968,
      75.996, 76.025, 76.053, 76.084, 76.112, 76.141, 76.169, 76.199, 76.228,
      76.256, 76.284, 76.314, 76.343, 76.371, 76.399, 76.429, 76.457, 76.485,
      76.514, 76.543, 76.571, 76.6, 76.628, 76.657, 76.685, 76.713, 76.741,
      76.771, 76.799, 76.827, 76.854, 76.884, 76.912, 76.94, 76.969, 76.997,
      77.024, 77.052, 77.081, 77.109, 77.137, 77.164, 77.193, 77.221, 77.248,
      77.276, 77.305, 77.332, 77.36, 77.387, 77.416, 77.444, 77.471, 77.498,
      77.527, 77.554, 77.582, 77.609, 77.638, 77.665, 77.692, 77.719, 77.748,
      77.775, 77.802, 77.829, 77.858, 77.885, 77.912, 77.938, 77.967, 77.994,
      78.021, 78.048, 78.076, 78.103, 78.129, 78.156, 78.185, 78.211, 78.238,
      78.265, 78.293, 78.319, 78.346, 78.372, 78.401, 78.427, 78.454, 78.48,
      78.508, 78.535, 78.561, 78.587, 78.615, 78.642, 78.668, 78.694, 78.722,
      78.748, 78.774, 78.801, 78.828, 78.854, 78.881, 78.907, 78.934, 78.96,
      78.986, 79.014, 79.04, 79.066, 79.092, 79.119, 79.145, 79.171, 79.197,
      79.225, 79.25, 79.276, 79.276, 79.301, 79.302, 79.326, 79.329, 79.352,
      79.355, 79.378, 79.381, 79.403, 79.406, 79.428, 79.434, 79.453, 79.459,
      79.48, 79.485, 79.505, 79.51, 79.53, 79.537, 79.557, 79.563, 79.582,
      79.589, 79.607, 79.614, 79.632, 79.641, 79.658, 79.667, 79.683, 79.692,
      79.708, 79.717, 79.732, 79.744, 79.759, 79.77, 79.784, 79.795, 79.809,
      79.822, 79.835, 79.847, 79.86, 79.873, 79.884, 79.898, 79.909, 79.925,
      79.935, 79.95, 79.96, 79.985, 80.009, 80.035, 80.06, 80.084, 80.111,
      80.135, 80.16, 80.184, 80.21, 80.235, 80.259, 80.283, 80.309, 80.334,
      80.358, 80.384, 80.408, 80.433, 80.457, 80.483, 80.507, 80.531, 80.557,
      80.581, 80.605, 80.629, 80.655, 80.679, 80.703, 80.729, 80.753, 80.777,
      80.801, 80.826, 80.85, 80.874, 80.898, 80.924, 80.947, 80.971, 80.997,
      81.02, 81.044, 81.068, 81.093, 81.117, 81.141, 81.166, 81.19, 81.213,
      81.239, 81.262, 81.286, 81.309, 81.334, 81.358, 81.381, 81.407, 81.43,
      81.453, 81.477, 81.502, 81.525, 81.548, 81.574, 81.597, 81.62, 81.643,
      81.668, 81.691, 81.715, 81.74, 81.763, 81.786, 81.811, 81.834, 81.857,
      81.88, 81.905, 81.928, 81.951, 81.975, 81.998, 82.021, 82.046, 82.069,
      82.092, 82.114, 82.139, 82.162, 82.184, 82.209, 82.232, 82.254, 82.279,
      82.302, 82.324, 82.347, 82.371, 82.394, 82.416, 82.441, 82.463, 82.486,
      82.51, 82.533, 82.555, 82.579, 82.602, 82.624, 82.647, 82.671, 82.693,
      82.715, 82.739, 82.762, 82.784, 82.808, 82.83, 82.853, 82.877, 82.899,
      82.921, 82.945, 82.967, 82.989, 83.013, 83.035, 83.057, 83.079, 83.103,
      83.125, 83.147, 83.171, 83.192, 83.214, 83.238, 83.26, 83.282, 83.305,
      83.327, 83.349, 83.373, 83.394, 83.416, 83.44, 83.461, 83.483, 83.507,
      83.528, 83.55, 83.573, 83.595, 83.616, 83.64, 83.661, 83.683, 83.706,
      83.728, 83.749, 83.772, 83.794, 83.815, 83.839, 83.86, 83.881, 83.904,
      83.926, 83.947, 83.97, 83.992, 84.013, 84.036, 84.057, 84.078, 84.101,
      84.122, 84.144, 84.167, 84.188, 84.209, 84.232, 84.253, 84.274, 84.297,
      84.318, 84.339, 84.362, 84.383, 84.404, 84.426, 84.447, 84.47, 84.491,
      84.512, 84.534, 84.555, 84.576, 84.599, 84.62, 84.64, 84.663, 84.684,
      84.704, 84.727, 84.748, 84.768, 84.791, 84.812, 84.834, 84.855, 84.875,
      84.898, 84.918, 84.939, 84.961, 84.982, 85.002, 85.025, 85.045, 85.067,
      85.088, 85.108, 85.131, 85.151, 85.172, 85.194, 85.214, 85.234, 85.257,
      85.277, 85.299, 85.32, 85.34, 85.362, 85.382, 85.403, 85.425, 85.445,
      85.467, 85.487, 85.507, 85.529, 85.549, 85.57, 85.592, 85.612, 85.634,
      85.654, 85.674, 85.696, 85.716, 85.736, 85.758, 85.778, 85.8, 85.82,
      85.84, 85.861, 85.881, 85.903, 85.923, 85.943, 85.965, 85.985, 86.006,
      86.026, 86.046, 86.068, 86.088, 86.107, 86.129, 86.149, 86.17, 86.19,
      86.21, 86.231, 86.251, 86.273, 86.292, 86.312, 86.334, 86.353, 86.375,
      86.395, 86.414, 86.436, 86.455, 86.477, 86.496, 86.516, 86.537, 86.557,
      86.578, 86.598, 86.617, 86.639, 86.658, 86.679, 86.699, 86.718, 86.74,
      86.759, 86.78, 86.8, 86.819, 86.84, 86.86, 86.881, 86.9, 86.922, 86.941,
      86.96, 86.981, 87.001, 87.022, 87.041, 87.06, 87.081, 87.101, 87.122,
      87.141, 87.16, 87.181, 87.2, 87.222, 87.241, 87.262, 87.281, 87.3, 87.321,
      87.34, 87.361, 87.38, 87.401, 87.42, 87.439, 87.46, 87.479, 87.5, 87.519,
      87.54, 87.559, 87.578, 87.599, 87.618, 87.639, 87.658, 87.679, 87.697,
      87.716, 87.737, 87.756, 87.777, 87.796, 87.816, 87.835, 87.854, 87.875,
      87.894, 87.914, 87.933, 87.954, 87.973, 87.991, 88.012, 88.031, 88.051,
      88.07, 88.091, 88.11, 88.13, 88.149, 88.167, 88.188, 88.207, 88.227,
      88.246, 88.266, 88.285, 88.304, 88.324, 88.343, 88.363, 88.382, 88.402,
      88.421, 88.441, 88.46, 88.48, 88.499, 88.517, 88.538, 88.556, 88.577,
      88.595, 88.615, 88.634, 88.654, 88.673, 88.691, 88.711, 88.73, 88.75,
      88.768, 88.789, 88.807, 88.827, 88.846, 88.866, 88.884, 88.903, 88.923,
      88.941, 88.961, 88.979, 89, 89.018, 89.038, 89.056, 89.076, 89.095,
      89.115, 89.133, 89.151, 89.171, 89.189, 89.209, 89.228, 89.248, 89.266,
      89.286, 89.304, 89.324, 89.342, 89.362, 89.38, 89.398, 89.418, 89.436,
      89.456, 89.474, 89.494, 89.512, 89.532, 89.55, 89.57, 89.588, 89.608,
      89.626, 89.646, 89.664, 89.683, 89.701, 89.719, 89.739, 89.757, 89.777,
      89.795, 89.814, 89.832, 89.852, 89.87, 89.89, 89.907, 89.927, 89.945,
      89.965, 89.982, 90.002, 90.02, 90.04, 90.057, 90.077, 90.095, 90.114,
      90.132, 90.15, 90.169, 90.187, 90.207, 90.224, 90.244, 90.262, 90.281,
      90.299, 90.318, 90.336, 90.355, 90.373, 90.392, 90.41, 90.43, 90.447,
      90.467, 90.484, 90.504, 90.521, 90.54, 90.558, 90.577, 90.595, 90.614,
      90.632, 90.651, 90.669, 90.688, 90.705, 90.725, 90.742, 90.761, 90.779,
      90.798, 90.815, 90.833, 90.852, 90.869, 90.889, 90.906, 90.925, 90.943,
      90.962, 90.979, 90.998, 91.016, 91.035, 91.052, 91.071, 91.088, 91.108,
      91.125, 91.144, 91.161, 91.18, 91.197, 91.217, 91.234, 91.253, 91.27,
      91.289, 91.306, 91.325, 91.342, 91.361, 91.378, 91.397, 91.414, 91.433,
      91.45, 91.469, 91.486, 91.505, 91.522, 91.541, 91.558, 91.577, 91.594,
      91.613, 91.63, 91.649, 91.666, 91.685, 91.704, 91.721, 91.74, 91.757,
      91.775, 91.792, 91.811, 91.828, 91.847, 91.864, 91.882, 91.899, 91.918,
      91.935, 91.954, 91.97, 91.989, 92.006, 92.025, 92.041, 92.06, 92.077,
      92.095, 92.112, 92.131, 92.148, 92.166, 92.183, 92.202, 92.218, 92.237,
      92.253, 92.272, 92.289, 92.307, 92.324, 92.343, 92.359, 92.378, 92.394,
      92.413, 92.431, 92.448, 92.466, 92.483, 92.501, 92.518, 92.536, 92.553,
      92.571, 92.588, 92.606, 92.623, 92.641, 92.658, 92.676, 92.692, 92.711,
      92.727, 92.746, 92.762, 92.78, 92.797, 92.815, 92.832, 92.85, 92.868,
      92.885, 92.903, 92.919, 92.937, 92.954, 92.972, 92.988, 93.007, 93.023,
      93.041, 93.057, 93.076, 93.092, 93.11, 93.126, 93.145, 93.161, 93.179,
      93.197, 93.213, 93.232, 93.248, 93.266, 93.282, 93.3, 93.316, 93.334,
      93.35, 93.369, 93.385, 93.403, 93.419, 93.437, 93.453, 93.471, 93.489,
      93.505, 93.523, 93.539, 93.557, 93.573, 93.591, 93.607, 93.625, 93.641,
      93.659, 93.675, 93.693, 93.711, 93.727, 93.745, 93.761, 93.779, 93.795,
      93.813, 93.829, 93.847, 93.863, 93.88, 93.896, 93.914, 93.932, 93.948,
      93.966, 93.982, 93.999, 94.015, 94.033, 94.049, 94.067, 94.083, 94.1,
      94.116, 94.134, 94.152, 94.168, 94.185, 94.201, 94.219, 94.234, 94.252,
      94.268, 94.286, 94.301, 94.319, 94.337, 94.353, 94.37, 94.386, 94.404,
      94.419, 94.437, 94.453, 94.47, 94.486, 94.503, 94.521, 94.537, 94.554,
      94.57, 94.588, 94.603, 94.621, 94.636, 94.654, 94.669, 94.687, 94.705,
      94.72, 94.738, 94.753, 94.771, 94.786, 94.804, 94.819, 94.837, 94.854,
      94.87, 94.887, 94.903, 94.92, 94.936, 94.953, 94.969, 94.986, 95.004,
      95.019, 95.037, 95.052, 95.069, 95.085, 95.102, 95.117, 95.135, 95.152,
      95.168, 95.185, 95.2, 95.218, 95.233, 95.251, 95.266, 95.283, 95.301,
      95.316, 95.333, 95.349, 95.366, 95.381, 95.398, 95.416, 95.431, 95.448,
      95.464, 95.481, 95.496, 95.513, 95.529, 95.546, 95.563, 95.578, 95.596,
      95.611, 95.628, 95.643, 95.66, 95.678, 95.693, 95.71, 95.725, 95.742,
      95.758, 95.775, 95.79, 95.807, 95.824, 95.839, 95.856, 95.872, 95.889,
      95.904, 95.921, 95.938, 95.953, 95.97, 95.985, 96.002, 96.017, 96.035,
      96.052, 96.067, 96.084, 96.099, 96.116, 96.131, 96.148, 96.165, 96.18,
      96.197, 96.212, 96.229, 96.244, 96.261, 96.278, 96.293, 96.31, 96.325,
      96.342, 96.357, 96.374, 96.391, 96.406, 96.423, 96.437, 96.454, 96.469,
      96.486, 96.503, 96.518, 96.535, 96.55, 96.567, 96.582, 96.598, 96.615,
      96.63, 96.647, 96.662, 96.679, 96.694, 96.71, 96.727, 96.742, 96.759,
      96.774, 96.791, 96.807, 96.822, 96.839, 96.854, 96.87, 96.885, 96.902,
      96.919, 96.934, 96.95, 96.965, 96.982, 96.996, 97.013, 97.03, 97.045,
      97.061, 97.076, 97.093, 97.11, 97.124, 97.141, 97.155, 97.172, 97.187,
      97.204, 97.22, 97.235, 97.252, 97.266, 97.283, 97.299, 97.314, 97.331,
      97.345, 97.362, 97.376, 97.393, 97.41, 97.424, 97.441, 97.455, 97.472,
      97.489, 97.503, 97.52, 97.534, 97.551, 97.565, 97.582, 97.598, 97.613,
      97.629, 97.644, 97.66, 97.677, 97.691, 97.708, 97.722, 97.739, 97.755,
      97.77, 97.786, 97.801, 97.817, 97.831, 97.848, 97.864, 97.879, 97.895,
      97.909, 97.926, 97.942, 97.957, 97.973, 97.987, 98.004, 98.02, 98.035,
      98.051, 98.065, 98.082, 98.096, 98.112, 98.129, 98.143, 98.159, 98.174,
      98.19, 98.206, 98.221, 98.237, 98.251, 98.267, 98.284, 98.298, 98.314,
      98.329, 98.345, 98.361, 98.375, 98.392, 98.406, 98.422, 98.436, 98.452,
      98.469, 98.483, 98.499, 98.513, 98.529, 98.546, 98.56, 98.576, 98.59,
      98.606, 98.622, 98.637, 98.653, 98.667, 98.683, 98.699, 98.713, 98.729,
      98.743, 98.76, 98.776, 98.79, 98.806, 98.82, 98.836, 98.852, 98.866,
      98.882, 98.896, 98.912, 98.926, 98.942, 98.959, 98.972, 98.989, 99.002,
      99.019, 99.035, 99.048, 99.065, 99.078, 99.095, 99.111, 99.124, 99.141,
      99.154, 99.17, 99.186, 99.2, 99.216, 99.23, 99.246, 99.262, 99.276,
      99.292, 99.306, 99.322, 99.338, 99.351, 99.367, 99.381, 99.397, 99.413,
      99.427, 99.443, 99.457, 99.473, 99.488, 99.502, 99.518, 99.532, 99.548,
      99.564, 99.577, 99.593, 99.607, 99.623, 99.639, 99.652, 99.668, 99.682,
      99.698, 99.714, 99.727, 99.743, 99.757, 99.773, 99.788, 99.802, 99.818,
      99.831, 99.847, 99.863, 99.877, 99.892, 99.908, 99.922, 99.938, 99.951,
      99.967, 99.983, 99.996, 100.012, 100.026, 100.041, 100.057, 100.071,
      100.086, 100.1, 100.116, 100.131, 100.145, 100.16, 100.174, 100.19,
      100.205, 100.219, 100.235, 100.248, 100.264, 100.279, 100.293, 100.308,
      100.322, 100.338, 100.353,
    ],
  },
};
