export const lengthPerMonth = {
  date: [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
    59, 60,
  ],
  median: {
    label: 'median',
    weight: [
      49.8842, 54.6645, 58.3299, 61.3115, 63.758, 65.769, 67.4836, 69.0152,
      70.4382, 71.7947, 73.096, 74.3433, 75.5431, 76.703, 77.8251, 78.9122,
      79.9688, 80.9978, 82, 82.9759, 83.9267, 84.8549, 85.7613, 86.6477,
      87.3151, 87.7449, 88.4972, 89.3066, 90.0957, 90.8641, 91.6118, 92.34,
      93.0499, 93.7429, 94.4214, 95.0877, 95.7433, 96.3891, 97.0258, 97.6539,
      98.2735, 98.8846, 99.487, 100.0805, 100.6659, 101.2435, 101.814, 102.3785,
      102.938, 103.4936, 104.046, 104.5961, 105.1445, 105.6917, 106.2377,
      106.7825, 107.3263, 107.8692, 108.4112, 108.9522, 109.4923,
    ],
  },
  SD2: {
    label: '2 SD',
    weight: [
      53.67, 58.556, 62.328, 65.396, 67.916, 69.987, 71.759, 73.351, 74.841,
      76.272, 77.654, 78.988, 80.28, 81.537, 82.759, 83.95, 85.112, 86.251,
      87.364, 88.454, 89.521, 90.566, 91.591, 92.597, 93.406, 93.888, 94.805,
      95.731, 96.635, 97.517, 98.375, 99.21, 100.025, 100.82, 101.597, 102.36,
      103.11, 103.846, 104.572, 105.287, 105.992, 106.685, 107.368, 108.041,
      108.703, 109.357, 110.002, 110.642, 111.276, 111.903, 112.53, 113.152,
      113.773, 114.392, 115.011, 115.628, 116.243, 116.859, 117.472, 118.087,
      118.698,
    ],
  },
  SD3: {
    label: '3 SD',
    weight: [
      55.564, 60.501, 64.327, 67.438, 69.995, 72.097, 73.897, 75.519, 77.042,
      78.51, 79.933, 81.311, 82.648, 83.954, 85.226, 86.469, 87.684, 88.878,
      90.047, 91.193, 92.319, 93.421, 94.506, 95.572, 96.447, 96.966, 97.959,
      98.944, 99.904, 100.844, 101.756, 102.645, 103.512, 104.359, 105.185,
      105.996, 106.793, 107.574, 108.346, 109.103, 109.851, 110.585, 111.309,
      112.021, 112.722, 113.414, 114.096, 114.774, 115.445, 116.108, 116.772,
      117.43, 118.087, 118.743, 119.397, 120.051, 120.701, 121.354, 122.003,
      122.654, 123.301,
    ],
  },
  SD3neg: {
    label: '-3 SD',
    weight: [
      44.205, 48.828, 52.333, 55.185, 57.521, 59.441, 61.07, 62.512, 63.835,
      65.079, 66.259, 67.376, 68.438, 69.452, 70.424, 71.356, 72.253, 73.118,
      73.953, 74.759, 75.535, 76.289, 77.016, 77.724, 78.185, 78.517, 79.035,
      79.67, 80.287, 80.884, 81.468, 82.035, 82.587, 83.127, 83.657, 84.179,
      84.694, 85.204, 85.706, 86.205, 86.696, 87.185, 87.665, 88.14, 88.61,
      89.073, 89.532, 89.983, 90.431, 90.879, 91.32, 91.762, 92.202, 92.641,
      93.078, 93.514, 93.951, 94.384, 94.82, 95.25, 95.683,
    ],
  },
  SD2neg: {
    label: '-2 SD',
    weight: [
      46.098, 50.773, 54.332, 57.227, 59.6, 61.551, 63.208, 64.68, 66.036,
      67.318, 68.538, 69.698, 70.807, 71.869, 72.891, 73.874, 74.825, 75.744,
      76.636, 77.498, 78.332, 79.144, 79.931, 80.698, 81.225, 81.595, 82.189,
      82.882, 83.557, 84.211, 84.849, 85.47, 86.075, 86.665, 87.245, 87.815,
      88.377, 88.932, 89.479, 90.021, 90.555, 91.085, 91.606, 92.12, 92.629,
      93.13, 93.626, 94.115, 94.6, 95.084, 95.562, 96.04, 96.516, 96.991,
      97.465, 97.937, 98.41, 98.879, 99.35, 99.818, 100.286,
    ],
  },
};
