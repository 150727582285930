export const lengthHeightForAgeBoys = {
  date: [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
    59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77,
    78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96,
    97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112,
    113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127,
    128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142,
    143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157,
    158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172,
    173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187,
    188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202,
    203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217,
    218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232,
    233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247,
    248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262,
    263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275, 276, 277,
    278, 279, 280, 281, 282, 283, 284, 285, 286, 287, 288, 289, 290, 291, 292,
    293, 294, 295, 296, 297, 298, 299, 300, 301, 302, 303, 304, 305, 306, 307,
    308, 309, 310, 311, 312, 313, 314, 315, 316, 317, 318, 319, 320, 321, 322,
    323, 324, 325, 326, 327, 328, 329, 330, 331, 332, 333, 334, 335, 336, 337,
    338, 339, 340, 341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351, 352,
    353, 354, 355, 356, 357, 358, 359, 360, 361, 362, 363, 364, 365, 366, 367,
    368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378, 379, 380, 381, 382,
    383, 384, 385, 386, 387, 388, 389, 390, 391, 392, 393, 394, 395, 396, 397,
    398, 399, 400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412,
    413, 414, 415, 416, 417, 418, 419, 420, 421, 422, 423, 424, 425, 426, 427,
    428, 429, 430, 431, 432, 433, 434, 435, 436, 437, 438, 439, 440, 441, 442,
    443, 444, 445, 446, 447, 448, 449, 450, 451, 452, 453, 454, 455, 456, 457,
    458, 459, 460, 461, 462, 463, 464, 465, 466, 467, 468, 469, 470, 471, 472,
    473, 474, 475, 476, 477, 478, 479, 480, 481, 482, 483, 484, 485, 486, 487,
    488, 489, 490, 491, 492, 493, 494, 495, 496, 497, 498, 499, 500, 501, 502,
    503, 504, 505, 506, 507, 508, 509, 510, 511, 512, 513, 514, 515, 516, 517,
    518, 519, 520, 521, 522, 523, 524, 525, 526, 527, 528, 529, 530, 531, 532,
    533, 534, 535, 536, 537, 538, 539, 540, 541, 542, 543, 544, 545, 546, 547,
    548, 549, 550, 551, 552, 553, 554, 555, 556, 557, 558, 559, 560, 561, 562,
    563, 564, 565, 566, 567, 568, 569, 570, 571, 572, 573, 574, 575, 576, 577,
    578, 579, 580, 581, 582, 583, 584, 585, 586, 587, 588, 589, 590, 591, 592,
    593, 594, 595, 596, 597, 598, 599, 600, 601, 602, 603, 604, 605, 606, 607,
    608, 609, 610, 611, 612, 613, 614, 615, 616, 617, 618, 619, 620, 621, 622,
    623, 624, 625, 626, 627, 628, 629, 630, 631, 632, 633, 634, 635, 636, 637,
    638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 648, 649, 650, 651, 652,
    653, 654, 655, 656, 657, 658, 659, 660, 661, 662, 663, 664, 665, 666, 667,
    668, 669, 670, 671, 672, 673, 674, 675, 676, 677, 678, 679, 680, 681, 682,
    683, 684, 685, 686, 687, 688, 689, 690, 691, 692, 693, 694, 695, 696, 697,
    698, 699, 700, 701, 702, 703, 704, 705, 706, 707, 708, 709, 710, 711, 712,
    713, 714, 715, 716, 717, 718, 719, 720, 721, 722, 723, 724, 725, 726, 727,
    728, 729, 730, 731, 732, 733, 734, 735, 736, 737, 738, 739, 740, 741, 742,
    743, 744, 745, 746, 747, 748, 749, 750, 751, 752, 753, 754, 755, 756, 757,
    758, 759, 760, 761, 762, 763, 764, 765, 766, 767, 768, 769, 770, 771, 772,
    773, 774, 775, 776, 777, 778, 779, 780, 781, 782, 783, 784, 785, 786, 787,
    788, 789, 790, 791, 792, 793, 794, 795, 796, 797, 798, 799, 800, 801, 802,
    803, 804, 805, 806, 807, 808, 809, 810, 811, 812, 813, 814, 815, 816, 817,
    818, 819, 820, 821, 822, 823, 824, 825, 826, 827, 828, 829, 830, 831, 832,
    833, 834, 835, 836, 837, 838, 839, 840, 841, 842, 843, 844, 845, 846, 847,
    848, 849, 850, 851, 852, 853, 854, 855, 856, 857, 858, 859, 860, 861, 862,
    863, 864, 865, 866, 867, 868, 869, 870, 871, 872, 873, 874, 875, 876, 877,
    878, 879, 880, 881, 882, 883, 884, 885, 886, 887, 888, 889, 890, 891, 892,
    893, 894, 895, 896, 897, 898, 899, 900, 901, 902, 903, 904, 905, 906, 907,
    908, 909, 910, 911, 912, 913, 914, 915, 916, 917, 918, 919, 920, 921, 922,
    923, 924, 925, 926, 927, 928, 929, 930, 931, 932, 933, 934, 935, 936, 937,
    938, 939, 940, 941, 942, 943, 944, 945, 946, 947, 948, 949, 950, 951, 952,
    953, 954, 955, 956, 957, 958, 959, 960, 961, 962, 963, 964, 965, 966, 967,
    968, 969, 970, 971, 972, 973, 974, 975, 976, 977, 978, 979, 980, 981, 982,
    983, 984, 985, 986, 987, 988, 989, 990, 991, 992, 993, 994, 995, 996, 997,
    998, 999, 1000, 1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009, 1010,
    1011, 1012, 1013, 1014, 1015, 1016, 1017, 1018, 1019, 1020, 1021, 1022,
    1023, 1024, 1025, 1026, 1027, 1028, 1029, 1030, 1031, 1032, 1033, 1034,
    1035, 1036, 1037, 1038, 1039, 1040, 1041, 1042, 1043, 1044, 1045, 1046,
    1047, 1048, 1049, 1050, 1051, 1052, 1053, 1054, 1055, 1056, 1057, 1058,
    1059, 1060, 1061, 1062, 1063, 1064, 1065, 1066, 1067, 1068, 1069, 1070,
    1071, 1072, 1073, 1074, 1075, 1076, 1077, 1078, 1079, 1080, 1081, 1082,
    1083, 1084, 1085, 1086, 1087, 1088, 1089, 1090, 1091, 1092, 1093, 1094,
    1095, 1096, 1097, 1098, 1099, 1100, 1101, 1102, 1103, 1104, 1105, 1106,
    1107, 1108, 1109, 1110, 1111, 1112, 1113, 1114, 1115, 1116, 1117, 1118,
    1119, 1120, 1121, 1122, 1123, 1124, 1125, 1126, 1127, 1128, 1129, 1130,
    1131, 1132, 1133, 1134, 1135, 1136, 1137, 1138, 1139, 1140, 1141, 1142,
    1143, 1144, 1145, 1146, 1147, 1148, 1149, 1150, 1151, 1152, 1153, 1154,
    1155, 1156, 1157, 1158, 1159, 1160, 1161, 1162, 1163, 1164, 1165, 1166,
    1167, 1168, 1169, 1170, 1171, 1172, 1173, 1174, 1175, 1176, 1177, 1178,
    1179, 1180, 1181, 1182, 1183, 1184, 1185, 1186, 1187, 1188, 1189, 1190,
    1191, 1192, 1193, 1194, 1195, 1196, 1197, 1198, 1199, 1200, 1201, 1202,
    1203, 1204, 1205, 1206, 1207, 1208, 1209, 1210, 1211, 1212, 1213, 1214,
    1215, 1216, 1217, 1218, 1219, 1220, 1221, 1222, 1223, 1224, 1225, 1226,
    1227, 1228, 1229, 1230, 1231, 1232, 1233, 1234, 1235, 1236, 1237, 1238,
    1239, 1240, 1241, 1242, 1243, 1244, 1245, 1246, 1247, 1248, 1249, 1250,
    1251, 1252, 1253, 1254, 1255, 1256, 1257, 1258, 1259, 1260, 1261, 1262,
    1263, 1264, 1265, 1266, 1267, 1268, 1269, 1270, 1271, 1272, 1273, 1274,
    1275, 1276, 1277, 1278, 1279, 1280, 1281, 1282, 1283, 1284, 1285, 1286,
    1287, 1288, 1289, 1290, 1291, 1292, 1293, 1294, 1295, 1296, 1297, 1298,
    1299, 1300, 1301, 1302, 1303, 1304, 1305, 1306, 1307, 1308, 1309, 1310,
    1311, 1312, 1313, 1314, 1315, 1316, 1317, 1318, 1319, 1320, 1321, 1322,
    1323, 1324, 1325, 1326, 1327, 1328, 1329, 1330, 1331, 1332, 1333, 1334,
    1335, 1336, 1337, 1338, 1339, 1340, 1341, 1342, 1343, 1344, 1345, 1346,
    1347, 1348, 1349, 1350, 1351, 1352, 1353, 1354, 1355, 1356, 1357, 1358,
    1359, 1360, 1361, 1362, 1363, 1364, 1365, 1366, 1367, 1368, 1369, 1370,
    1371, 1372, 1373, 1374, 1375, 1376, 1377, 1378, 1379, 1380, 1381, 1382,
    1383, 1384, 1385, 1386, 1387, 1388, 1389, 1390, 1391, 1392, 1393, 1394,
    1395, 1396, 1397, 1398, 1399, 1400, 1401, 1402, 1403, 1404, 1405, 1406,
    1407, 1408, 1409, 1410, 1411, 1412, 1413, 1414, 1415, 1416, 1417, 1418,
    1419, 1420, 1421, 1422, 1423, 1424, 1425, 1426, 1427, 1428, 1429, 1430,
    1431, 1432, 1433, 1434, 1435, 1436, 1437, 1438, 1439, 1440, 1441, 1442,
    1443, 1444, 1445, 1446, 1447, 1448, 1449, 1450, 1451, 1452, 1453, 1454,
    1455, 1456, 1457, 1458, 1459, 1460, 1461, 1462, 1463, 1464, 1465, 1466,
    1467, 1468, 1469, 1470, 1471, 1472, 1473, 1474, 1475, 1476, 1477, 1478,
    1479, 1480, 1481, 1482, 1483, 1484, 1485, 1486, 1487, 1488, 1489, 1490,
    1491, 1492, 1493, 1494, 1495, 1496, 1497, 1498, 1499, 1500, 1501, 1502,
    1503, 1504, 1505, 1506, 1507, 1508, 1509, 1510, 1511, 1512, 1513, 1514,
    1515, 1516, 1517, 1518, 1519, 1520, 1521, 1522, 1523, 1524, 1525, 1526,
    1527, 1528, 1529, 1530, 1531, 1532, 1533, 1534, 1535, 1536, 1537, 1538,
    1539, 1540, 1541, 1542, 1543, 1544, 1545, 1546, 1547, 1548, 1549, 1550,
    1551, 1552, 1553, 1554, 1555, 1556, 1557, 1558, 1559, 1560, 1561, 1562,
    1563, 1564, 1565, 1566, 1567, 1568, 1569, 1570, 1571, 1572, 1573, 1574,
    1575, 1576, 1577, 1578, 1579, 1580, 1581, 1582, 1583, 1584, 1585, 1586,
    1587, 1588, 1589, 1590, 1591, 1592, 1593, 1594, 1595, 1596, 1597, 1598,
    1599, 1600, 1601, 1602, 1603, 1604, 1605, 1606, 1607, 1608, 1609, 1610,
    1611, 1612, 1613, 1614, 1615, 1616, 1617, 1618, 1619, 1620, 1621, 1622,
    1623, 1624, 1625, 1626, 1627, 1628, 1629, 1630, 1631, 1632, 1633, 1634,
    1635, 1636, 1637, 1638, 1639, 1640, 1641, 1642, 1643, 1644, 1645, 1646,
    1647, 1648, 1649, 1650, 1651, 1652, 1653, 1654, 1655, 1656, 1657, 1658,
    1659, 1660, 1661, 1662, 1663, 1664, 1665, 1666, 1667, 1668, 1669, 1670,
    1671, 1672, 1673, 1674, 1675, 1676, 1677, 1678, 1679, 1680, 1681, 1682,
    1683, 1684, 1685, 1686, 1687, 1688, 1689, 1690, 1691, 1692, 1693, 1694,
    1695, 1696, 1697, 1698, 1699, 1700, 1701, 1702, 1703, 1704, 1705, 1706,
    1707, 1708, 1709, 1710, 1711, 1712, 1713, 1714, 1715, 1716, 1717, 1718,
    1719, 1720, 1721, 1722, 1723, 1724, 1725, 1726, 1727, 1728, 1729, 1730,
    1731, 1732, 1733, 1734, 1735, 1736, 1737, 1738, 1739, 1740, 1741, 1742,
    1743, 1744, 1745, 1746, 1747, 1748, 1749, 1750, 1751, 1752, 1753, 1754,
    1755, 1756, 1757, 1758, 1759, 1760, 1761, 1762, 1763, 1764, 1765, 1766,
    1767, 1768, 1769, 1770, 1771, 1772, 1773, 1774, 1775, 1776, 1777, 1778,
    1779, 1780, 1781, 1782, 1783, 1784, 1785, 1786, 1787, 1788, 1789, 1790,
    1791, 1792, 1793, 1794, 1795, 1796, 1797, 1798, 1799, 1800, 1801, 1802,
    1803, 1804, 1805, 1806, 1807, 1808, 1809, 1810, 1811, 1812, 1813, 1814,
    1815, 1816, 1817, 1818, 1819, 1820, 1821, 1822, 1823, 1824, 1825, 1826,
    1827, 1828, 1829, 1830, 1831, 1832, 1833, 1834, 1835, 1836, 1837, 1838,
    1839, 1840, 1841, 1842, 1843, 1844, 1845, 1846, 1847, 1848, 1849, 1850,
    1851, 1852, 1853, 1854, 1855, 1856,
  ],
  median: {
    label: 'median',
    weight: [
      49.8842, 50.0601, 50.2359, 50.4118, 50.5876, 50.7635, 50.9393, 51.1152,
      51.291, 51.4669, 51.6427, 51.8186, 51.9944, 52.1702, 52.3461, 52.4978,
      52.6488, 52.799, 52.9483, 53.0967, 53.2441, 53.3905, 53.536, 53.6805,
      53.8239, 53.9664, 54.1079, 54.2485, 54.3881, 54.5268, 54.6645, 54.8012,
      54.9368, 55.0714, 55.2049, 55.3374, 55.4688, 55.5992, 55.7285, 55.8568,
      55.9841, 56.1104, 56.2357, 56.3599, 56.4833, 56.6056, 56.7269, 56.8472,
      56.9666, 57.0851, 57.2026, 57.3192, 57.4349, 57.5497, 57.6637, 57.7767,
      57.8889, 58.0003, 58.1109, 58.2207, 58.3299, 58.4384, 58.5463, 58.6536,
      58.7603, 58.8664, 58.9718, 59.0766, 59.1808, 59.2843, 59.3872, 59.4894,
      59.591, 59.692, 59.7923, 59.892, 59.991, 60.0894, 60.1872, 60.2843,
      60.3808, 60.4767, 60.5719, 60.6665, 60.7605, 60.8539, 60.9466, 61.0388,
      61.1303, 61.2212, 61.3115, 61.4013, 61.4904, 61.579, 61.667, 61.7543,
      61.8411, 61.9274, 62.013, 62.0981, 62.1826, 62.2665, 62.3499, 62.4327,
      62.5149, 62.5966, 62.6778, 62.7584, 62.8384, 62.918, 62.9969, 63.0754,
      63.1533, 63.2307, 63.3076, 63.3839, 63.4598, 63.5351, 63.6099, 63.6842,
      63.758, 63.8313, 63.9041, 63.9765, 64.0483, 64.1197, 64.1906, 64.261,
      64.331, 64.4006, 64.4697, 64.5383, 64.6066, 64.6744, 64.7418, 64.8088,
      64.8755, 64.9417, 65.0075, 65.073, 65.138, 65.2027, 65.2671, 65.331,
      65.3946, 65.4579, 65.5208, 65.5834, 65.6456, 65.7075, 65.769, 65.8303,
      65.8912, 65.9518, 66.0121, 66.0721, 66.1317, 66.1911, 66.2502, 66.3089,
      66.3674, 66.4256, 66.4835, 66.5412, 66.5985, 66.6556, 66.7125, 66.7691,
      66.8254, 66.8815, 66.9373, 66.993, 67.0483, 67.1035, 67.1584, 67.2132,
      67.2677, 67.3219, 67.376, 67.4299, 67.4836, 67.5371, 67.5904, 67.6435,
      67.6964, 67.7491, 67.8017, 67.8541, 67.9062, 67.9583, 68.0101, 68.0618,
      68.1133, 68.1647, 68.2158, 68.2669, 68.3177, 68.3685, 68.419, 68.4695,
      68.5198, 68.5699, 68.6199, 68.6698, 68.7195, 68.7691, 68.8186, 68.8679,
      68.9171, 68.9662, 69.0152, 69.0641, 69.1128, 69.1615, 69.21, 69.2584,
      69.3067, 69.3549, 69.4031, 69.4511, 69.499, 69.5468, 69.5945, 69.6421,
      69.6896, 69.737, 69.7844, 69.8316, 69.8787, 69.9258, 69.9728, 70.0197,
      70.0665, 70.1132, 70.1599, 70.2064, 70.2529, 70.2994, 70.3457, 70.392,
      70.4382, 70.4843, 70.5304, 70.5764, 70.6224, 70.6683, 70.7141, 70.7598,
      70.8055, 70.8511, 70.8967, 70.9422, 70.9876, 71.033, 71.0783, 71.1235,
      71.1687, 71.2138, 71.2589, 71.3039, 71.3488, 71.3937, 71.4385, 71.4832,
      71.5279, 71.5725, 71.6171, 71.6616, 71.706, 71.7504, 71.7947, 71.839,
      71.8832, 71.9273, 71.9714, 72.0154, 72.0594, 72.1033, 72.1472, 72.1909,
      72.2347, 72.2783, 72.3219, 72.3655, 72.4089, 72.4523, 72.4957, 72.539,
      72.5822, 72.6253, 72.6684, 72.7115, 72.7544, 72.7974, 72.8402, 72.883,
      72.9257, 72.9684, 73.011, 73.0535, 73.096, 73.1384, 73.1808, 73.2231,
      73.2653, 73.3075, 73.3497, 73.3917, 73.4337, 73.4757, 73.5176, 73.5594,
      73.6012, 73.6429, 73.6845, 73.7261, 73.7677, 73.8091, 73.8506, 73.8919,
      73.9333, 73.9745, 74.0157, 74.0569, 74.0979, 74.139, 74.18, 74.2209,
      74.2618, 74.3026, 74.3433, 74.3841, 74.4247, 74.4653, 74.5059, 74.5464,
      74.5868, 74.6272, 74.6676, 74.7079, 74.7481, 74.7883, 74.8285, 74.8686,
      74.9086, 74.9486, 74.9886, 75.0285, 75.0683, 75.1081, 75.1479, 75.1876,
      75.2273, 75.2669, 75.3065, 75.346, 75.3855, 75.425, 75.4644, 75.5037,
      75.5431, 75.5824, 75.6216, 75.6608, 75.6999, 75.7391, 75.7781, 75.8172,
      75.8562, 75.8951, 75.934, 75.9729, 76.0117, 76.0505, 76.0892, 76.1279,
      76.1665, 76.2051, 76.2437, 76.2822, 76.3207, 76.3591, 76.3975, 76.4358,
      76.4741, 76.5124, 76.5506, 76.5888, 76.6269, 76.665, 76.703, 76.741,
      76.779, 76.8169, 76.8548, 76.8926, 76.9304, 76.9682, 77.0059, 77.0435,
      77.0812, 77.1187, 77.1563, 77.1938, 77.2313, 77.2687, 77.306, 77.3434,
      77.3807, 77.4179, 77.4551, 77.4923, 77.5295, 77.5665, 77.6036, 77.6406,
      77.6776, 77.7145, 77.7514, 77.7883, 77.8251, 77.8618, 77.8986, 77.9353,
      77.9719, 78.0085, 78.0451, 78.0817, 78.1182, 78.1546, 78.1911, 78.2275,
      78.2638, 78.3001, 78.3364, 78.3727, 78.4089, 78.4451, 78.4812, 78.5173,
      78.5534, 78.5894, 78.6254, 78.6614, 78.6973, 78.7332, 78.7691, 78.8049,
      78.8407, 78.8764, 78.9122, 78.9479, 78.9835, 79.0191, 79.0547, 79.0903,
      79.1258, 79.1613, 79.1968, 79.2322, 79.2676, 79.303, 79.3383, 79.3736,
      79.4089, 79.4441, 79.4793, 79.5145, 79.5496, 79.5847, 79.6198, 79.6548,
      79.6898, 79.7248, 79.7598, 79.7947, 79.8296, 79.8644, 79.8993, 79.9341,
      79.9688, 80.0036, 80.0383, 80.0729, 80.1076, 80.1422, 80.1768, 80.2113,
      80.2459, 80.2804, 80.3148, 80.3493, 80.3837, 80.4181, 80.4524, 80.4867,
      80.521, 80.5553, 80.5895, 80.6237, 80.6578, 80.692, 80.7261, 80.7602,
      80.7942, 80.8282, 80.8622, 80.8961, 80.9301, 80.964, 80.9978, 81.0317,
      81.0655, 81.0992, 81.133, 81.1667, 81.2004, 81.234, 81.2677, 81.3013,
      81.3348, 81.3684, 81.4019, 81.4353, 81.4688, 81.5022, 81.5356, 81.569,
      81.6023, 81.6356, 81.6689, 81.7021, 81.7353, 81.7685, 81.8017, 81.8348,
      81.8679, 81.9009, 81.934, 81.967, 82, 82.0329, 82.0659, 82.0987, 82.1316,
      82.1644, 82.1973, 82.23, 82.2628, 82.2955, 82.3282, 82.3609, 82.3935,
      82.4261, 82.4587, 82.4912, 82.5237, 82.5562, 82.5887, 82.6211, 82.6535,
      82.6859, 82.7182, 82.7505, 82.7828, 82.8151, 82.8473, 82.8795, 82.9117,
      82.9438, 82.9759, 83.008, 83.04, 83.0721, 83.1041, 83.136, 83.168,
      83.1999, 83.2318, 83.2637, 83.2955, 83.3273, 83.3591, 83.3908, 83.4226,
      83.4543, 83.4859, 83.5176, 83.5492, 83.5808, 83.6124, 83.6439, 83.6754,
      83.7069, 83.7384, 83.7698, 83.8012, 83.8326, 83.864, 83.8953, 83.9267,
      83.9579, 83.9892, 84.0205, 84.0517, 84.0829, 84.114, 84.1452, 84.1763,
      84.2074, 84.2385, 84.2695, 84.3006, 84.3316, 84.3626, 84.3935, 84.4245,
      84.4554, 84.4862, 84.5171, 84.5479, 84.5787, 84.6095, 84.6403, 84.671,
      84.7017, 84.7324, 84.7631, 84.7937, 84.8243, 84.8549, 84.8855, 84.916,
      84.9465, 84.977, 85.0075, 85.0379, 85.0683, 85.0987, 85.1291, 85.1594,
      85.1897, 85.22, 85.2503, 85.2805, 85.3108, 85.341, 85.3711, 85.4013,
      85.4314, 85.4615, 85.4916, 85.5217, 85.5517, 85.5817, 85.6117, 85.6417,
      85.6716, 85.7015, 85.7314, 85.7613, 85.7912, 85.821, 85.8508, 85.8806,
      85.9104, 85.9401, 85.9698, 85.9995, 86.0292, 86.0589, 86.0885, 86.1181,
      86.1477, 86.1773, 86.2068, 86.2363, 86.2659, 86.2954, 86.3248, 86.3543,
      86.3837, 86.4131, 86.4425, 86.4719, 86.5012, 86.5306, 86.5599, 86.5892,
      86.6184, 86.6477, 86.6769, 86.7061, 86.7353, 86.7645, 86.7937, 86.8228,
      86.8519, 86.881, 86.9101, 86.9392, 86.9682, 86.9972, 87.0262, 87.0552,
      87.0842, 87.1131, 87.1303, 87.142, 87.1587, 87.1709, 87.1871, 87.1998,
      87.2155, 87.2287, 87.2439, 87.2575, 87.2722, 87.2863, 87.3006, 87.3151,
      87.3289, 87.3439, 87.3571, 87.3727, 87.3854, 87.4014, 87.4136, 87.4302,
      87.4419, 87.4589, 87.4701, 87.4876, 87.4982, 87.5162, 87.5264, 87.5449,
      87.5545, 87.5735, 87.5826, 87.6021, 87.6107, 87.6307, 87.6388, 87.6593,
      87.6668, 87.6878, 87.6948, 87.7164, 87.7228, 87.7449, 87.7508, 87.7734,
      87.7788, 87.8018, 87.8067, 87.8346, 87.8625, 87.8903, 87.9181, 87.946,
      87.9737, 88.0015, 88.0292, 88.057, 88.0846, 88.1123, 88.14, 88.1676,
      88.1952, 88.2228, 88.2503, 88.2778, 88.3053, 88.3328, 88.3603, 88.3877,
      88.4151, 88.4425, 88.4699, 88.4972, 88.5245, 88.5518, 88.5791, 88.6063,
      88.6335, 88.6607, 88.6879, 88.715, 88.7422, 88.7693, 88.7964, 88.8234,
      88.8504, 88.8775, 88.9044, 88.9314, 88.9584, 88.9853, 89.0122, 89.0391,
      89.0659, 89.0927, 89.1195, 89.1463, 89.1731, 89.1998, 89.2266, 89.2533,
      89.2799, 89.3066, 89.3332, 89.3598, 89.3864, 89.413, 89.4395, 89.466,
      89.4925, 89.519, 89.5455, 89.5719, 89.5983, 89.6247, 89.651, 89.6774,
      89.7037, 89.73, 89.7563, 89.7825, 89.8087, 89.8349, 89.8611, 89.8873,
      89.9134, 89.9395, 89.9656, 89.9917, 90.0177, 90.0437, 90.0697, 90.0957,
      90.1216, 90.1476, 90.1735, 90.1994, 90.2252, 90.251, 90.2769, 90.3026,
      90.3284, 90.3541, 90.3799, 90.4056, 90.4312, 90.4569, 90.4825, 90.5081,
      90.5337, 90.5592, 90.5848, 90.6103, 90.6358, 90.6612, 90.6867, 90.7121,
      90.7375, 90.7628, 90.7882, 90.8135, 90.8388, 90.8641, 90.8893, 90.9146,
      90.9398, 90.965, 90.9901, 91.0153, 91.0404, 91.0655, 91.0905, 91.1156,
      91.1406, 91.1656, 91.1906, 91.2155, 91.2405, 91.2654, 91.2903, 91.3151,
      91.34, 91.3648, 91.3896, 91.4144, 91.4391, 91.4639, 91.4886, 91.5133,
      91.5379, 91.5626, 91.5872, 91.6118, 91.6364, 91.6609, 91.6855, 91.71,
      91.7345, 91.759, 91.7834, 91.8078, 91.8323, 91.8566, 91.881, 91.9053,
      91.9297, 91.954, 91.9783, 92.0025, 92.0268, 92.051, 92.0752, 92.0993,
      92.1235, 92.1476, 92.1717, 92.1958, 92.2199, 92.244, 92.268, 92.292,
      92.316, 92.34, 92.3639, 92.3879, 92.4118, 92.4357, 92.4595, 92.4834,
      92.5072, 92.531, 92.5548, 92.5786, 92.6023, 92.6261, 92.6498, 92.6735,
      92.6971, 92.7208, 92.7444, 92.768, 92.7916, 92.8152, 92.8388, 92.8623,
      92.8858, 92.9093, 92.9328, 92.9562, 92.9797, 93.0031, 93.0265, 93.0499,
      93.0732, 93.0966, 93.1199, 93.1432, 93.1665, 93.1898, 93.213, 93.2363,
      93.2595, 93.2827, 93.3059, 93.329, 93.3522, 93.3753, 93.3984, 93.4215,
      93.4446, 93.4676, 93.4906, 93.5137, 93.5367, 93.5596, 93.5826, 93.6056,
      93.6285, 93.6514, 93.6743, 93.6972, 93.7201, 93.7429, 93.7658, 93.7886,
      93.8114, 93.8342, 93.8569, 93.8797, 93.9024, 93.9252, 93.9479, 93.9706,
      93.9932, 94.0159, 94.0385, 94.0612, 94.0838, 94.1064, 94.129, 94.1516,
      94.1741, 94.1967, 94.2192, 94.2417, 94.2642, 94.2867, 94.3092, 94.3317,
      94.3541, 94.3765, 94.399, 94.4214, 94.4438, 94.4662, 94.4885, 94.5109,
      94.5332, 94.5556, 94.5779, 94.6002, 94.6225, 94.6447, 94.667, 94.6893,
      94.7115, 94.7337, 94.7559, 94.7782, 94.8003, 94.8225, 94.8447, 94.8668,
      94.889, 94.9111, 94.9332, 94.9553, 94.9774, 94.9995, 95.0216, 95.0436,
      95.0657, 95.0877, 95.1097, 95.1317, 95.1537, 95.1757, 95.1977, 95.2197,
      95.2416, 95.2636, 95.2855, 95.3074, 95.3293, 95.3512, 95.3731, 95.3949,
      95.4168, 95.4386, 95.4605, 95.4823, 95.5041, 95.5259, 95.5477, 95.5695,
      95.5913, 95.613, 95.6348, 95.6565, 95.6782, 95.6999, 95.7216, 95.7433,
      95.765, 95.7867, 95.8083, 95.83, 95.8516, 95.8732, 95.8948, 95.9165,
      95.938, 95.9596, 95.9812, 96.0028, 96.0243, 96.0459, 96.0674, 96.0889,
      96.1104, 96.1319, 96.1534, 96.1749, 96.1964, 96.2178, 96.2393, 96.2607,
      96.2821, 96.3035, 96.325, 96.3464, 96.3677, 96.3891, 96.4105, 96.4318,
      96.4532, 96.4745, 96.4958, 96.5172, 96.5385, 96.5598, 96.581, 96.6023,
      96.6236, 96.6448, 96.6661, 96.6873, 96.7085, 96.7298, 96.751, 96.7722,
      96.7933, 96.8145, 96.8357, 96.8568, 96.878, 96.8991, 96.9203, 96.9414,
      96.9625, 96.9836, 97.0047, 97.0258, 97.0468, 97.0679, 97.0889, 97.11,
      97.131, 97.1521, 97.1731, 97.1941, 97.2151, 97.2361, 97.257, 97.278,
      97.299, 97.3199, 97.3409, 97.3618, 97.3827, 97.4036, 97.4245, 97.4454,
      97.4663, 97.4872, 97.5081, 97.5289, 97.5498, 97.5706, 97.5914, 97.6123,
      97.6331, 97.6539, 97.6747, 97.6954, 97.7162, 97.737, 97.7577, 97.7785,
      97.7992, 97.8199, 97.8406, 97.8614, 97.8821, 97.9027, 97.9234, 97.9441,
      97.9647, 97.9854, 98.006, 98.0267, 98.0473, 98.0679, 98.0885, 98.1091,
      98.1297, 98.1503, 98.1708, 98.1914, 98.2119, 98.2325, 98.253, 98.2735,
      98.294, 98.3145, 98.335, 98.3555, 98.3759, 98.3964, 98.4169, 98.4373,
      98.4577, 98.4782, 98.4986, 98.519, 98.5394, 98.5598, 98.5801, 98.6005,
      98.6209, 98.6412, 98.6615, 98.6819, 98.7022, 98.7225, 98.7428, 98.7631,
      98.7834, 98.8036, 98.8239, 98.8442, 98.8644, 98.8846, 98.9049, 98.9251,
      98.9453, 98.9655, 98.9857, 99.0058, 99.026, 99.0461, 99.0663, 99.0864,
      99.1065, 99.1267, 99.1468, 99.1669, 99.1869, 99.207, 99.2271, 99.2471,
      99.2672, 99.2872, 99.3072, 99.3272, 99.3472, 99.3672, 99.3872, 99.4072,
      99.4272, 99.4471, 99.4671, 99.487, 99.5069, 99.5268, 99.5467, 99.5666,
      99.5865, 99.6064, 99.6262, 99.6461, 99.666, 99.6858, 99.7056, 99.7254,
      99.7452, 99.765, 99.7848, 99.8046, 99.8244, 99.8441, 99.8639, 99.8836,
      99.9034, 99.9231, 99.9428, 99.9625, 99.9822, 100.0019, 100.0216, 100.0412,
      100.0609, 100.0805, 100.1002, 100.1198, 100.1394, 100.1591, 100.1787,
      100.1983, 100.2178, 100.2374, 100.257, 100.2765, 100.2961, 100.3156,
      100.3352, 100.3547, 100.3742, 100.3937, 100.4132, 100.4327, 100.4522,
      100.4717, 100.4911, 100.5106, 100.53, 100.5495, 100.5689, 100.5883,
      100.6077, 100.6271, 100.6465, 100.6659, 100.6853, 100.7046, 100.724,
      100.7434, 100.7627, 100.782, 100.8013, 100.8207, 100.84, 100.8593,
      100.8786, 100.8978, 100.9171, 100.9364, 100.9556, 100.9749, 100.9941,
      101.0134, 101.0326, 101.0518, 101.071, 101.0902, 101.1094, 101.1286,
      101.1477, 101.1669, 101.1861, 101.2052, 101.2244, 101.2435, 101.2626,
      101.2817, 101.3008, 101.32, 101.339, 101.3581, 101.3772, 101.3963,
      101.4153, 101.4344, 101.4535, 101.4725, 101.4915, 101.5106, 101.5296,
      101.5486, 101.5676, 101.5866, 101.6056, 101.6246, 101.6435, 101.6625,
      101.6815, 101.7004, 101.7194, 101.7383, 101.7572, 101.7762, 101.7951,
      101.814, 101.8329, 101.8518, 101.8707, 101.8896, 101.9085, 101.9274,
      101.9462, 101.9651, 101.9839, 102.0028, 102.0216, 102.0405, 102.0593,
      102.0781, 102.097, 102.1158, 102.1346, 102.1534, 102.1722, 102.191,
      102.2097, 102.2285, 102.2473, 102.2661, 102.2848, 102.3036, 102.3223,
      102.3411, 102.3598, 102.3785, 102.3972, 102.416, 102.4347, 102.4534,
      102.4721, 102.4908, 102.5095, 102.5282, 102.5469, 102.5655, 102.5842,
      102.6029, 102.6215, 102.6402, 102.6588, 102.6775, 102.6961, 102.7148,
      102.7334, 102.752, 102.7706, 102.7893, 102.8079, 102.8265, 102.8451,
      102.8637, 102.8823, 102.9009, 102.9195, 102.938, 102.9566, 102.9752,
      102.9938, 103.0123, 103.0309, 103.0494, 103.068, 103.0865, 103.1051,
      103.1236, 103.1421, 103.1607, 103.1792, 103.1977, 103.2162, 103.2348,
      103.2533, 103.2718, 103.2903, 103.3088, 103.3273, 103.3458, 103.3643,
      103.3827, 103.4012, 103.4197, 103.4382, 103.4566, 103.4751, 103.4936,
      103.512, 103.5305, 103.5489, 103.5674, 103.5858, 103.6043, 103.6227,
      103.6412, 103.6596, 103.678, 103.6965, 103.7149, 103.7333, 103.7517,
      103.7701, 103.7885, 103.807, 103.8254, 103.8438, 103.8622, 103.8806,
      103.899, 103.9174, 103.9357, 103.9541, 103.9725, 103.9909, 104.0093,
      104.0277, 104.046, 104.0644, 104.0828, 104.1011, 104.1195, 104.1379,
      104.1562, 104.1746, 104.1929, 104.2113, 104.2296, 104.248, 104.2663,
      104.2847, 104.303, 104.3213, 104.3397, 104.358, 104.3763, 104.3947,
      104.413, 104.4313, 104.4496, 104.4679, 104.4863, 104.5046, 104.5229,
      104.5412, 104.5595, 104.5778, 104.5961, 104.6144, 104.6327, 104.651,
      104.6693, 104.6876, 104.7059, 104.7242, 104.7425, 104.7608, 104.7791,
      104.7974, 104.8157, 104.8339, 104.8522, 104.8705, 104.8888, 104.9071,
      104.9253, 104.9436, 104.9619, 104.9802, 104.9984, 105.0167, 105.035,
      105.0532, 105.0715, 105.0898, 105.108, 105.1263, 105.1445, 105.1628,
      105.1811, 105.1993, 105.2176, 105.2358, 105.2541, 105.2723, 105.2906,
      105.3088, 105.3271, 105.3453, 105.3635, 105.3818, 105.4, 105.4183,
      105.4365, 105.4547, 105.473, 105.4912, 105.5094, 105.5277, 105.5459,
      105.5641, 105.5824, 105.6006, 105.6188, 105.637, 105.6553, 105.6735,
      105.6917, 105.7099, 105.7281, 105.7463, 105.7646, 105.7828, 105.801,
      105.8192, 105.8374, 105.8556, 105.8738, 105.892, 105.9102, 105.9284,
      105.9466, 105.9648, 105.983, 106.0012, 106.0194, 106.0376, 106.0558,
      106.074, 106.0922, 106.1104, 106.1286, 106.1467, 106.1649, 106.1831,
      106.2013, 106.2195, 106.2377, 106.2558, 106.274, 106.2922, 106.3104,
      106.3285, 106.3467, 106.3649, 106.3831, 106.4012, 106.4194, 106.4376,
      106.4557, 106.4739, 106.4921, 106.5102, 106.5284, 106.5465, 106.5647,
      106.5829, 106.601, 106.6192, 106.6373, 106.6555, 106.6736, 106.6918,
      106.7099, 106.7281, 106.7462, 106.7644, 106.7825, 106.8006, 106.8188,
      106.8369, 106.8551, 106.8732, 106.8913, 106.9095, 106.9276, 106.9457,
      106.9639, 106.982, 107.0001, 107.0183, 107.0364, 107.0545, 107.0727,
      107.0908, 107.1089, 107.127, 107.1452, 107.1633, 107.1814, 107.1995,
      107.2176, 107.2358, 107.2539, 107.272, 107.2901, 107.3082, 107.3263,
      107.3444, 107.3625, 107.3806, 107.3988, 107.4169, 107.435, 107.4531,
      107.4712, 107.4893, 107.5074, 107.5255, 107.5436, 107.5617, 107.5798,
      107.5979, 107.616, 107.6341, 107.6522, 107.6702, 107.6883, 107.7064,
      107.7245, 107.7426, 107.7607, 107.7788, 107.7969, 107.8149, 107.833,
      107.8511, 107.8692, 107.8873, 107.9053, 107.9234, 107.9415, 107.9596,
      107.9777, 107.9957, 108.0138, 108.0319, 108.0499, 108.068, 108.0861,
      108.1041, 108.1222, 108.1403, 108.1583, 108.1764, 108.1945, 108.2125,
      108.2306, 108.2487, 108.2667, 108.2848, 108.3028, 108.3209, 108.3389,
      108.357, 108.375, 108.3931, 108.4112, 108.4292, 108.4473, 108.4653,
      108.4833, 108.5014, 108.5194, 108.5375, 108.5555, 108.5736, 108.5916,
      108.6097, 108.6277, 108.6457, 108.6638, 108.6818, 108.6998, 108.7179,
      108.7359, 108.7539, 108.772, 108.79, 108.808, 108.8261, 108.8441,
      108.8621, 108.8801, 108.8982, 108.9162, 108.9342, 108.9522, 108.9702,
      108.9883, 109.0063, 109.0243, 109.0423, 109.0603, 109.0783, 109.0963,
      109.1144, 109.1324, 109.1504, 109.1684, 109.1864, 109.2044, 109.2224,
      109.2404, 109.2584, 109.2764, 109.2944, 109.3124, 109.3304, 109.3484,
      109.3664, 109.3843, 109.4023, 109.4203, 109.4383, 109.4563, 109.4743,
      109.4923, 109.5102, 109.5282, 109.5462, 109.5642, 109.5822, 109.6001,
      109.6181, 109.6361, 109.654, 109.672, 109.69, 109.7079, 109.7259,
      109.7439, 109.7618, 109.7798, 109.7978, 109.8157, 109.8337, 109.8516,
      109.8696, 109.8875, 109.9055, 109.9234, 109.9414, 109.9593, 109.9773,
      109.9952, 110.0131, 110.0311, 110.049, 110.0669, 110.0849, 110.1028,
      110.1207, 110.1387, 110.1566, 110.1745, 110.1924, 110.2104, 110.2283,
      110.2462, 110.2641, 110.282, 110.3, 110.3179, 110.3358, 110.3537,
      110.3716, 110.3895, 110.4074, 110.4253, 110.4432, 110.4611, 110.479,
      110.4969,
    ],
  },
  SD2: {
    label: '2 SD',
    weight: [
      53.67, 53.85, 54.029, 54.207, 54.386, 54.565, 54.743, 54.921, 55.1,
      55.279, 55.457, 55.635, 55.813, 55.991, 56.169, 56.325, 56.481, 56.635,
      56.789, 56.942, 57.094, 57.244, 57.394, 57.542, 57.691, 57.837, 57.983,
      58.127, 58.271, 58.415, 58.556, 58.696, 58.836, 58.975, 59.112, 59.249,
      59.384, 59.518, 59.652, 59.784, 59.914, 60.045, 60.173, 60.301, 60.428,
      60.554, 60.678, 60.803, 60.926, 61.047, 61.168, 61.288, 61.407, 61.525,
      61.642, 61.759, 61.874, 61.988, 62.102, 62.216, 62.328, 62.439, 62.551,
      62.661, 62.77, 62.88, 62.988, 63.096, 63.203, 63.31, 63.416, 63.52,
      63.625, 63.73, 63.833, 63.935, 64.037, 64.138, 64.239, 64.339, 64.438,
      64.537, 64.635, 64.732, 64.829, 64.925, 65.02, 65.116, 65.21, 65.303,
      65.396, 65.489, 65.581, 65.672, 65.763, 65.852, 65.942, 66.031, 66.12,
      66.207, 66.294, 66.38, 66.466, 66.551, 66.636, 66.72, 66.803, 66.887,
      66.969, 67.05, 67.132, 67.213, 67.294, 67.374, 67.452, 67.53, 67.609,
      67.686, 67.764, 67.84, 67.916, 67.992, 68.067, 68.141, 68.215, 68.289,
      68.362, 68.434, 68.506, 68.578, 68.649, 68.719, 68.791, 68.86, 68.929,
      68.998, 69.066, 69.136, 69.203, 69.27, 69.337, 69.404, 69.47, 69.536,
      69.602, 69.667, 69.732, 69.796, 69.86, 69.925, 69.987, 70.051, 70.114,
      70.177, 70.238, 70.301, 70.361, 70.423, 70.485, 70.545, 70.606, 70.665,
      70.725, 70.785, 70.843, 70.903, 70.962, 71.02, 71.078, 71.137, 71.195,
      71.252, 71.309, 71.366, 71.423, 71.48, 71.537, 71.591, 71.648, 71.704,
      71.759, 71.815, 71.87, 71.925, 71.98, 72.035, 72.089, 72.144, 72.198,
      72.252, 72.306, 72.359, 72.413, 72.466, 72.519, 72.572, 72.624, 72.678,
      72.731, 72.783, 72.835, 72.887, 72.939, 72.992, 73.043, 73.095, 73.146,
      73.198, 73.249, 73.3, 73.351, 73.403, 73.453, 73.503, 73.555, 73.605,
      73.655, 73.706, 73.756, 73.807, 73.857, 73.906, 73.957, 74.006, 74.056,
      74.105, 74.156, 74.204, 74.255, 74.303, 74.353, 74.402, 74.451, 74.499,
      74.549, 74.597, 74.647, 74.695, 74.744, 74.793, 74.841, 74.89, 74.939,
      74.986, 75.035, 75.082, 75.131, 75.179, 75.227, 75.275, 75.323, 75.372,
      75.419, 75.467, 75.515, 75.563, 75.61, 75.658, 75.705, 75.753, 75.8,
      75.847, 75.895, 75.942, 75.99, 76.036, 76.083, 76.13, 76.178, 76.225,
      76.272, 76.319, 76.366, 76.411, 76.458, 76.505, 76.552, 76.598, 76.645,
      76.691, 76.738, 76.784, 76.83, 76.877, 76.923, 76.969, 77.015, 77.061,
      77.107, 77.153, 77.199, 77.244, 77.29, 77.336, 77.381, 77.427, 77.472,
      77.517, 77.563, 77.609, 77.654, 77.699, 77.744, 77.789, 77.834, 77.879,
      77.924, 77.97, 78.014, 78.059, 78.104, 78.148, 78.192, 78.238, 78.282,
      78.327, 78.371, 78.415, 78.46, 78.504, 78.548, 78.592, 78.637, 78.681,
      78.725, 78.768, 78.813, 78.857, 78.9, 78.945, 78.988, 79.032, 79.075,
      79.119, 79.163, 79.206, 79.25, 79.293, 79.336, 79.38, 79.423, 79.466,
      79.51, 79.552, 79.595, 79.639, 79.681, 79.725, 79.768, 79.81, 79.854,
      79.896, 79.94, 79.982, 80.024, 80.067, 80.109, 80.153, 80.195, 80.238,
      80.28, 80.323, 80.365, 80.406, 80.449, 80.491, 80.534, 80.575, 80.618,
      80.66, 80.703, 80.744, 80.787, 80.828, 80.871, 80.912, 80.954, 80.995,
      81.038, 81.079, 81.121, 81.164, 81.204, 81.247, 81.287, 81.33, 81.37,
      81.412, 81.453, 81.495, 81.537, 81.577, 81.619, 81.659, 81.701, 81.743,
      81.783, 81.825, 81.865, 81.906, 81.948, 81.988, 82.029, 82.071, 82.111,
      82.152, 82.192, 82.233, 82.274, 82.314, 82.355, 82.396, 82.436, 82.476,
      82.517, 82.557, 82.598, 82.638, 82.678, 82.719, 82.759, 82.798, 82.839,
      82.88, 82.918, 82.959, 82.999, 83.04, 83.079, 83.119, 83.159, 83.198,
      83.238, 83.278, 83.319, 83.357, 83.397, 83.437, 83.477, 83.516, 83.556,
      83.596, 83.635, 83.674, 83.713, 83.753, 83.793, 83.831, 83.871, 83.91,
      83.95, 83.988, 84.027, 84.067, 84.106, 84.146, 84.184, 84.223, 84.262,
      84.301, 84.341, 84.378, 84.418, 84.457, 84.496, 84.535, 84.572, 84.611,
      84.65, 84.689, 84.728, 84.767, 84.804, 84.843, 84.882, 84.921, 84.959,
      84.998, 85.035, 85.074, 85.112, 85.151, 85.19, 85.228, 85.267, 85.303,
      85.342, 85.38, 85.419, 85.457, 85.495, 85.533, 85.57, 85.608, 85.646,
      85.685, 85.723, 85.761, 85.799, 85.837, 85.875, 85.911, 85.949, 85.987,
      86.025, 86.063, 86.1, 86.138, 86.176, 86.214, 86.251, 86.289, 86.327,
      86.363, 86.4, 86.438, 86.475, 86.513, 86.55, 86.588, 86.625, 86.662, 86.7,
      86.737, 86.774, 86.811, 86.848, 86.886, 86.923, 86.96, 86.995, 87.032,
      87.069, 87.106, 87.143, 87.18, 87.217, 87.254, 87.291, 87.328, 87.364,
      87.401, 87.438, 87.475, 87.511, 87.548, 87.585, 87.621, 87.658, 87.694,
      87.731, 87.767, 87.803, 87.84, 87.876, 87.913, 87.949, 87.985, 88.021,
      88.058, 88.094, 88.13, 88.166, 88.202, 88.238, 88.274, 88.31, 88.346,
      88.382, 88.418, 88.454, 88.49, 88.526, 88.562, 88.597, 88.633, 88.67,
      88.706, 88.742, 88.777, 88.813, 88.849, 88.884, 88.92, 88.955, 88.991,
      89.026, 89.061, 89.097, 89.132, 89.168, 89.203, 89.238, 89.273, 89.309,
      89.344, 89.381, 89.416, 89.451, 89.486, 89.521, 89.556, 89.591, 89.626,
      89.661, 89.696, 89.731, 89.766, 89.801, 89.836, 89.872, 89.907, 89.942,
      89.977, 90.012, 90.046, 90.081, 90.116, 90.15, 90.185, 90.219, 90.256,
      90.29, 90.325, 90.359, 90.394, 90.428, 90.463, 90.497, 90.531, 90.566,
      90.602, 90.636, 90.67, 90.704, 90.739, 90.773, 90.807, 90.841, 90.875,
      90.911, 90.945, 90.979, 91.013, 91.047, 91.081, 91.115, 91.149, 91.183,
      91.219, 91.252, 91.286, 91.32, 91.354, 91.388, 91.421, 91.455, 91.49,
      91.524, 91.558, 91.591, 91.625, 91.659, 91.692, 91.727, 91.761, 91.794,
      91.828, 91.861, 91.895, 91.928, 91.961, 91.997, 92.03, 92.063, 92.096,
      92.13, 92.163, 92.196, 92.231, 92.264, 92.298, 92.331, 92.364, 92.397,
      92.43, 92.465, 92.498, 92.531, 92.564, 92.597, 92.63, 92.663, 92.697,
      92.73, 92.763, 92.796, 92.829, 92.862, 92.895, 92.929, 92.962, 92.995,
      93.028, 93.06, 93.093, 93.126, 93.16, 93.193, 93.225, 93.243, 93.258,
      93.275, 93.29, 93.308, 93.323, 93.34, 93.356, 93.374, 93.39, 93.406,
      93.422, 93.438, 93.455, 93.47, 93.487, 93.502, 93.52, 93.534, 93.552,
      93.566, 93.585, 93.6, 93.619, 93.631, 93.651, 93.663, 93.683, 93.695,
      93.716, 93.727, 93.748, 93.759, 93.78, 93.791, 93.813, 93.823, 93.847,
      93.856, 93.879, 93.888, 93.911, 93.92, 93.951, 93.983, 94.015, 94.046,
      94.08, 94.111, 94.143, 94.174, 94.206, 94.237, 94.269, 94.3, 94.333,
      94.365, 94.396, 94.427, 94.459, 94.49, 94.521, 94.554, 94.586, 94.617,
      94.648, 94.679, 94.71, 94.741, 94.772, 94.805, 94.836, 94.867, 94.898,
      94.929, 94.96, 94.991, 95.022, 95.053, 95.085, 95.116, 95.147, 95.178,
      95.209, 95.239, 95.27, 95.301, 95.331, 95.364, 95.394, 95.425, 95.455,
      95.486, 95.516, 95.547, 95.578, 95.608, 95.638, 95.671, 95.701, 95.731,
      95.762, 95.792, 95.822, 95.853, 95.883, 95.913, 95.943, 95.973, 96.005,
      96.035, 96.066, 96.096, 96.126, 96.156, 96.186, 96.216, 96.246, 96.276,
      96.305, 96.335, 96.367, 96.397, 96.427, 96.457, 96.486, 96.516, 96.546,
      96.575, 96.605, 96.635, 96.664, 96.694, 96.724, 96.753, 96.783, 96.814,
      96.844, 96.873, 96.903, 96.932, 96.961, 96.991, 97.02, 97.049, 97.079,
      97.108, 97.137, 97.166, 97.196, 97.225, 97.254, 97.283, 97.312, 97.341,
      97.37, 97.399, 97.428, 97.459, 97.488, 97.517, 97.546, 97.575, 97.604,
      97.633, 97.661, 97.69, 97.719, 97.748, 97.777, 97.805, 97.834, 97.863,
      97.891, 97.92, 97.949, 97.977, 98.006, 98.034, 98.063, 98.091, 98.12,
      98.148, 98.176, 98.205, 98.233, 98.261, 98.29, 98.318, 98.346, 98.375,
      98.403, 98.431, 98.459, 98.487, 98.516, 98.544, 98.572, 98.6, 98.628,
      98.656, 98.684, 98.712, 98.74, 98.768, 98.796, 98.824, 98.852, 98.879,
      98.907, 98.935, 98.961, 98.989, 99.016, 99.044, 99.072, 99.1, 99.127,
      99.155, 99.182, 99.21, 99.238, 99.265, 99.293, 99.32, 99.348, 99.375,
      99.403, 99.43, 99.458, 99.485, 99.51, 99.538, 99.565, 99.593, 99.62,
      99.647, 99.674, 99.701, 99.729, 99.756, 99.783, 99.81, 99.837, 99.864,
      99.89, 99.917, 99.944, 99.971, 99.998, 100.025, 100.052, 100.079, 100.106,
      100.133, 100.16, 100.185, 100.211, 100.238, 100.265, 100.292, 100.319,
      100.345, 100.372, 100.399, 100.426, 100.451, 100.477, 100.504, 100.53,
      100.557, 100.584, 100.61, 100.637, 100.662, 100.688, 100.715, 100.741,
      100.768, 100.794, 100.82, 100.847, 100.872, 100.898, 100.924, 100.951,
      100.977, 101.003, 101.03, 101.054, 101.08, 101.107, 101.133, 101.159,
      101.185, 101.212, 101.236, 101.262, 101.288, 101.314, 101.341, 101.367,
      101.391, 101.417, 101.443, 101.469, 101.495, 101.521, 101.545, 101.571,
      101.597, 101.623, 101.649, 101.673, 101.699, 101.725, 101.751, 101.777,
      101.803, 101.827, 101.853, 101.879, 101.905, 101.93, 101.954, 101.98,
      102.006, 102.032, 102.057, 102.081, 102.107, 102.133, 102.159, 102.184,
      102.208, 102.234, 102.259, 102.285, 102.309, 102.334, 102.36, 102.386,
      102.411, 102.435, 102.46, 102.486, 102.512, 102.535, 102.561, 102.586,
      102.612, 102.637, 102.661, 102.686, 102.712, 102.737, 102.761, 102.786,
      102.812, 102.837, 102.86, 102.886, 102.911, 102.937, 102.96, 102.985,
      103.011, 103.036, 103.059, 103.085, 103.11, 103.135, 103.158, 103.184,
      103.209, 103.232, 103.257, 103.283, 103.308, 103.331, 103.356, 103.381,
      103.407, 103.43, 103.455, 103.48, 103.503, 103.528, 103.553, 103.578,
      103.602, 103.627, 103.652, 103.675, 103.7, 103.725, 103.75, 103.773,
      103.798, 103.823, 103.846, 103.871, 103.896, 103.919, 103.944, 103.968,
      103.993, 104.016, 104.041, 104.066, 104.089, 104.114, 104.139, 104.162,
      104.186, 104.211, 104.234, 104.259, 104.284, 104.306, 104.331, 104.356,
      104.379, 104.403, 104.428, 104.451, 104.476, 104.5, 104.523, 104.548,
      104.572, 104.595, 104.62, 104.644, 104.667, 104.692, 104.716, 104.739,
      104.764, 104.788, 104.811, 104.835, 104.86, 104.882, 104.907, 104.932,
      104.954, 104.979, 105.003, 105.026, 105.05, 105.073, 105.097, 105.122,
      105.144, 105.168, 105.193, 105.215, 105.24, 105.264, 105.287, 105.311,
      105.333, 105.358, 105.382, 105.404, 105.429, 105.453, 105.475, 105.5,
      105.522, 105.546, 105.57, 105.593, 105.617, 105.639, 105.664, 105.688,
      105.71, 105.734, 105.756, 105.781, 105.805, 105.827, 105.851, 105.875,
      105.897, 105.922, 105.944, 105.968, 105.992, 106.014, 106.038, 106.06,
      106.084, 106.106, 106.13, 106.154, 106.176, 106.2, 106.223, 106.246,
      106.27, 106.292, 106.316, 106.338, 106.362, 106.386, 106.408, 106.432,
      106.454, 106.478, 106.5, 106.524, 106.548, 106.57, 106.593, 106.615,
      106.639, 106.661, 106.685, 106.708, 106.73, 106.754, 106.776, 106.8,
      106.821, 106.845, 106.869, 106.891, 106.914, 106.936, 106.96, 106.981,
      107.005, 107.027, 107.05, 107.074, 107.096, 107.119, 107.141, 107.164,
      107.186, 107.21, 107.231, 107.255, 107.276, 107.3, 107.323, 107.345,
      107.368, 107.39, 107.413, 107.435, 107.458, 107.48, 107.503, 107.525,
      107.548, 107.572, 107.593, 107.616, 107.638, 107.661, 107.682, 107.706,
      107.727, 107.75, 107.772, 107.795, 107.816, 107.84, 107.861, 107.884,
      107.906, 107.929, 107.952, 107.973, 107.996, 108.018, 108.041, 108.062,
      108.085, 108.106, 108.13, 108.151, 108.174, 108.195, 108.218, 108.239,
      108.263, 108.284, 108.307, 108.328, 108.351, 108.372, 108.395, 108.416,
      108.439, 108.46, 108.483, 108.504, 108.527, 108.548, 108.571, 108.594,
      108.615, 108.638, 108.659, 108.682, 108.703, 108.726, 108.747, 108.77,
      108.791, 108.814, 108.834, 108.857, 108.878, 108.901, 108.922, 108.945,
      108.966, 108.988, 109.009, 109.032, 109.053, 109.076, 109.096, 109.119,
      109.14, 109.163, 109.183, 109.206, 109.227, 109.248, 109.27, 109.291,
      109.314, 109.334, 109.357, 109.378, 109.4, 109.421, 109.444, 109.464,
      109.487, 109.508, 109.53, 109.551, 109.573, 109.594, 109.617, 109.637,
      109.66, 109.68, 109.703, 109.723, 109.746, 109.767, 109.789, 109.81,
      109.832, 109.853, 109.875, 109.896, 109.918, 109.938, 109.959, 109.981,
      110.002, 110.024, 110.045, 110.067, 110.088, 110.11, 110.131, 110.153,
      110.173, 110.196, 110.216, 110.238, 110.259, 110.281, 110.302, 110.324,
      110.344, 110.367, 110.387, 110.407, 110.43, 110.45, 110.472, 110.493,
      110.515, 110.535, 110.557, 110.578, 110.6, 110.62, 110.642, 110.663,
      110.685, 110.705, 110.725, 110.748, 110.768, 110.79, 110.81, 110.833,
      110.853, 110.875, 110.895, 110.917, 110.938, 110.96, 110.98, 111.002,
      111.022, 111.042, 111.065, 111.085, 111.107, 111.127, 111.149, 111.169,
      111.192, 111.212, 111.234, 111.254, 111.276, 111.296, 111.316, 111.338,
      111.358, 111.381, 111.401, 111.423, 111.443, 111.465, 111.485, 111.507,
      111.527, 111.547, 111.569, 111.589, 111.611, 111.631, 111.653, 111.673,
      111.695, 111.715, 111.737, 111.757, 111.777, 111.799, 111.819, 111.842,
      111.861, 111.883, 111.903, 111.925, 111.945, 111.967, 111.987, 112.007,
      112.029, 112.049, 112.071, 112.091, 112.113, 112.133, 112.155, 112.175,
      112.195, 112.217, 112.237, 112.259, 112.279, 112.301, 112.321, 112.343,
      112.363, 112.383, 112.404, 112.424, 112.446, 112.466, 112.488, 112.508,
      112.53, 112.55, 112.572, 112.592, 112.611, 112.633, 112.653, 112.675,
      112.695, 112.717, 112.737, 112.759, 112.779, 112.799, 112.82, 112.84,
      112.862, 112.882, 112.904, 112.924, 112.946, 112.965, 112.985, 113.007,
      113.027, 113.049, 113.069, 113.091, 113.11, 113.13, 113.152, 113.172,
      113.194, 113.214, 113.235, 113.255, 113.277, 113.297, 113.317, 113.339,
      113.358, 113.38, 113.4, 113.422, 113.442, 113.464, 113.483, 113.503,
      113.525, 113.545, 113.567, 113.586, 113.608, 113.628, 113.648, 113.67,
      113.689, 113.711, 113.731, 113.753, 113.773, 113.795, 113.814, 113.834,
      113.856, 113.876, 113.898, 113.917, 113.939, 113.959, 113.979, 114,
      114.02, 114.042, 114.062, 114.084, 114.103, 114.125, 114.145, 114.165,
      114.186, 114.206, 114.228, 114.248, 114.27, 114.289, 114.309, 114.331,
      114.351, 114.373, 114.392, 114.414, 114.434, 114.453, 114.475, 114.495,
      114.517, 114.537, 114.558, 114.578, 114.598, 114.62, 114.639, 114.661,
      114.681, 114.703, 114.722, 114.742, 114.764, 114.784, 114.805, 114.825,
      114.847, 114.867, 114.888, 114.908, 114.928, 114.95, 114.969, 114.991,
      115.011, 115.033, 115.052, 115.072, 115.094, 115.113, 115.135, 115.155,
      115.175, 115.196, 115.216, 115.238, 115.257, 115.279, 115.299, 115.319,
      115.34, 115.36, 115.382, 115.402, 115.423, 115.443, 115.463, 115.484,
      115.504, 115.526, 115.545, 115.567, 115.587, 115.607, 115.628, 115.648,
      115.67, 115.689, 115.711, 115.731, 115.75, 115.772, 115.792, 115.814,
      115.833, 115.855, 115.875, 115.894, 115.916, 115.936, 115.958, 115.977,
      115.997, 116.019, 116.038, 116.06, 116.08, 116.101, 116.121, 116.141,
      116.162, 116.182, 116.204, 116.223, 116.243, 116.265, 116.284, 116.306,
      116.326, 116.348, 116.367, 116.387, 116.409, 116.428, 116.45, 116.469,
      116.491, 116.511, 116.53, 116.552, 116.572, 116.594, 116.613, 116.633,
      116.654, 116.674, 116.696, 116.715, 116.737, 116.757, 116.776, 116.798,
      116.818, 116.839, 116.859, 116.879, 116.9, 116.92, 116.942, 116.961,
      116.981, 117.003, 117.022, 117.044, 117.063, 117.085, 117.105, 117.124,
      117.146, 117.166, 117.187, 117.207, 117.227, 117.248, 117.268, 117.29,
      117.309, 117.329, 117.35, 117.37, 117.392, 117.411, 117.433, 117.453,
      117.472, 117.494, 117.513, 117.535, 117.555, 117.574, 117.596, 117.616,
      117.637, 117.657, 117.676, 117.698, 117.718, 117.739, 117.759, 117.778,
      117.8, 117.82, 117.841, 117.861, 117.883, 117.902, 117.922, 117.944,
      117.963, 117.985, 118.004, 118.024, 118.046, 118.065, 118.087, 118.106,
      118.126, 118.148, 118.167, 118.189, 118.208, 118.228, 118.249, 118.269,
      118.291, 118.31, 118.33, 118.352, 118.371, 118.393, 118.412, 118.432,
      118.453, 118.473, 118.495, 118.514, 118.534, 118.555, 118.575, 118.596,
      118.616, 118.635, 118.657, 118.677, 118.698, 118.718, 118.737, 118.759,
      118.779, 118.8, 118.82, 118.839, 118.861, 118.88, 118.902, 118.922,
      118.941, 118.963, 118.982, 119.004, 119.023, 119.043, 119.064, 119.084,
      119.105, 119.125, 119.144, 119.166, 119.186, 119.207, 119.227, 119.246,
      119.268, 119.287, 119.309, 119.328, 119.348, 119.369, 119.389, 119.41,
      119.43, 119.449, 119.471, 119.49, 119.512, 119.532, 119.551, 119.573,
      119.592, 119.614, 119.633, 119.653, 119.674, 119.694, 119.713, 119.735,
      119.754, 119.776, 119.795, 119.814, 119.836,
    ],
  },
  SD3: {
    label: '3 SD',
    weight: [
      55.564, 55.744, 55.925, 56.104, 56.285, 56.465, 56.646, 56.824, 57.004,
      57.184, 57.364, 57.542, 57.722, 57.902, 58.081, 58.238, 58.396, 58.554,
      58.71, 58.865, 59.018, 59.171, 59.323, 59.473, 59.624, 59.772, 59.921,
      60.067, 60.213, 60.358, 60.501, 60.644, 60.786, 60.927, 61.066, 61.204,
      61.341, 61.477, 61.613, 61.747, 61.879, 62.012, 62.142, 62.271, 62.4,
      62.529, 62.654, 62.78, 62.905, 63.028, 63.151, 63.272, 63.393, 63.513,
      63.632, 63.75, 63.867, 63.982, 64.097, 64.213, 64.327, 64.439, 64.553,
      64.664, 64.775, 64.887, 64.996, 65.106, 65.214, 65.322, 65.43, 65.536,
      65.642, 65.748, 65.853, 65.956, 66.06, 66.163, 66.265, 66.366, 66.467,
      66.567, 66.667, 66.765, 66.863, 66.961, 67.057, 67.155, 67.25, 67.345,
      67.438, 67.533, 67.626, 67.718, 67.811, 67.901, 67.993, 68.082, 68.173,
      68.261, 68.35, 68.436, 68.524, 68.61, 68.696, 68.782, 68.866, 68.951,
      69.035, 69.117, 69.2, 69.282, 69.364, 69.445, 69.524, 69.604, 69.683,
      69.762, 69.84, 69.918, 69.995, 70.072, 70.148, 70.224, 70.299, 70.373,
      70.447, 70.521, 70.594, 70.666, 70.738, 70.809, 70.882, 70.953, 71.023,
      71.093, 71.162, 71.233, 71.301, 71.369, 71.436, 71.505, 71.572, 71.638,
      71.706, 71.771, 71.838, 71.903, 71.967, 72.033, 72.097, 72.162, 72.225,
      72.289, 72.351, 72.415, 72.476, 72.539, 72.602, 72.663, 72.725, 72.785,
      72.846, 72.907, 72.966, 73.027, 73.087, 73.145, 73.205, 73.264, 73.323,
      73.382, 73.439, 73.497, 73.555, 73.613, 73.671, 73.726, 73.783, 73.84,
      73.897, 73.954, 74.01, 74.066, 74.122, 74.178, 74.233, 74.289, 74.344,
      74.399, 74.453, 74.508, 74.562, 74.616, 74.67, 74.724, 74.778, 74.833,
      74.887, 74.94, 74.993, 75.046, 75.098, 75.153, 75.205, 75.257, 75.31,
      75.364, 75.415, 75.467, 75.519, 75.572, 75.623, 75.674, 75.728, 75.778,
      75.829, 75.882, 75.933, 75.985, 76.035, 76.086, 76.138, 76.188, 76.24,
      76.289, 76.341, 76.391, 76.442, 76.492, 76.543, 76.592, 76.644, 76.693,
      76.744, 76.792, 76.843, 76.892, 76.943, 76.993, 77.042, 77.092, 77.143,
      77.191, 77.241, 77.289, 77.339, 77.389, 77.437, 77.487, 77.537, 77.587,
      77.634, 77.684, 77.733, 77.783, 77.83, 77.879, 77.929, 77.978, 78.025,
      78.074, 78.123, 78.172, 78.221, 78.267, 78.316, 78.365, 78.413, 78.462,
      78.51, 78.559, 78.607, 78.653, 78.701, 78.75, 78.798, 78.846, 78.894,
      78.941, 78.989, 79.037, 79.085, 79.132, 79.18, 79.227, 79.275, 79.322,
      79.369, 79.416, 79.464, 79.511, 79.558, 79.605, 79.651, 79.698, 79.745,
      79.792, 79.838, 79.887, 79.933, 79.98, 80.026, 80.072, 80.119, 80.165,
      80.211, 80.259, 80.305, 80.351, 80.397, 80.442, 80.488, 80.536, 80.581,
      80.627, 80.672, 80.718, 80.765, 80.81, 80.856, 80.901, 80.948, 80.993,
      81.038, 81.083, 81.13, 81.175, 81.219, 81.266, 81.311, 81.355, 81.4,
      81.446, 81.491, 81.535, 81.582, 81.626, 81.67, 81.716, 81.76, 81.804,
      81.85, 81.894, 81.938, 81.984, 82.028, 82.074, 82.117, 82.161, 82.207,
      82.25, 82.296, 82.339, 82.382, 82.428, 82.471, 82.516, 82.56, 82.605,
      82.648, 82.693, 82.736, 82.779, 82.824, 82.867, 82.912, 82.955, 83,
      83.042, 83.087, 83.13, 83.174, 83.217, 83.261, 83.304, 83.348, 83.39,
      83.435, 83.477, 83.522, 83.566, 83.608, 83.652, 83.694, 83.738, 83.78,
      83.824, 83.866, 83.91, 83.954, 83.995, 84.039, 84.081, 84.124, 84.168,
      84.21, 84.253, 84.295, 84.338, 84.382, 84.423, 84.466, 84.509, 84.551,
      84.594, 84.635, 84.678, 84.721, 84.762, 84.805, 84.848, 84.889, 84.931,
      84.974, 85.015, 85.058, 85.1, 85.141, 85.184, 85.226, 85.266, 85.309,
      85.352, 85.392, 85.434, 85.477, 85.519, 85.559, 85.601, 85.643, 85.683,
      85.725, 85.768, 85.81, 85.849, 85.891, 85.933, 85.975, 86.015, 86.057,
      86.099, 86.14, 86.18, 86.222, 86.263, 86.305, 86.344, 86.386, 86.427,
      86.469, 86.508, 86.549, 86.591, 86.632, 86.673, 86.712, 86.754, 86.795,
      86.836, 86.877, 86.916, 86.957, 86.998, 87.039, 87.08, 87.119, 87.16,
      87.201, 87.242, 87.282, 87.323, 87.362, 87.402, 87.443, 87.484, 87.524,
      87.565, 87.603, 87.644, 87.684, 87.725, 87.765, 87.806, 87.846, 87.884,
      87.924, 87.965, 88.005, 88.045, 88.085, 88.126, 88.163, 88.203, 88.243,
      88.283, 88.323, 88.364, 88.403, 88.443, 88.483, 88.521, 88.561, 88.6,
      88.64, 88.68, 88.72, 88.759, 88.799, 88.839, 88.878, 88.918, 88.957,
      88.994, 89.034, 89.073, 89.113, 89.152, 89.191, 89.231, 89.27, 89.309,
      89.348, 89.387, 89.427, 89.466, 89.505, 89.544, 89.583, 89.622, 89.659,
      89.697, 89.736, 89.775, 89.814, 89.853, 89.892, 89.93, 89.969, 90.008,
      90.047, 90.085, 90.124, 90.162, 90.201, 90.24, 90.278, 90.316, 90.355,
      90.393, 90.432, 90.47, 90.508, 90.547, 90.585, 90.623, 90.661, 90.7,
      90.738, 90.776, 90.814, 90.852, 90.89, 90.928, 90.966, 91.004, 91.042,
      91.08, 91.117, 91.155, 91.193, 91.231, 91.268, 91.306, 91.344, 91.381,
      91.422, 91.459, 91.497, 91.534, 91.572, 91.609, 91.647, 91.684, 91.721,
      91.759, 91.796, 91.833, 91.871, 91.908, 91.945, 91.982, 92.02, 92.057,
      92.094, 92.131, 92.17, 92.207, 92.245, 92.281, 92.319, 92.355, 92.392,
      92.429, 92.466, 92.503, 92.54, 92.577, 92.613, 92.65, 92.689, 92.726,
      92.763, 92.799, 92.836, 92.873, 92.909, 92.946, 92.982, 93.019, 93.055,
      93.094, 93.131, 93.167, 93.203, 93.24, 93.276, 93.312, 93.349, 93.385,
      93.421, 93.46, 93.496, 93.532, 93.568, 93.604, 93.64, 93.676, 93.712,
      93.748, 93.787, 93.823, 93.859, 93.895, 93.931, 93.966, 94.002, 94.038,
      94.074, 94.112, 94.148, 94.184, 94.219, 94.255, 94.29, 94.326, 94.362,
      94.4, 94.435, 94.471, 94.506, 94.542, 94.577, 94.613, 94.651, 94.686,
      94.721, 94.757, 94.792, 94.827, 94.863, 94.898, 94.936, 94.971, 95.006,
      95.041, 95.076, 95.112, 95.147, 95.184, 95.219, 95.254, 95.289, 95.324,
      95.359, 95.394, 95.432, 95.467, 95.502, 95.537, 95.572, 95.606, 95.641,
      95.679, 95.713, 95.748, 95.783, 95.818, 95.852, 95.887, 95.924, 95.959,
      95.994, 96.028, 96.063, 96.097, 96.132, 96.169, 96.204, 96.238, 96.273,
      96.3, 96.307, 96.334, 96.341, 96.368, 96.376, 96.402, 96.413, 96.439,
      96.447, 96.472, 96.482, 96.506, 96.516, 96.54, 96.55, 96.574, 96.585,
      96.608, 96.619, 96.642, 96.656, 96.678, 96.69, 96.712, 96.724, 96.746,
      96.758, 96.78, 96.793, 96.813, 96.827, 96.847, 96.861, 96.881, 96.898,
      96.914, 96.932, 96.951, 96.966, 96.984, 97.018, 97.052, 97.085, 97.119,
      97.152, 97.188, 97.222, 97.255, 97.289, 97.322, 97.355, 97.388, 97.422,
      97.458, 97.491, 97.524, 97.557, 97.591, 97.624, 97.657, 97.693, 97.726,
      97.759, 97.792, 97.825, 97.858, 97.891, 97.924, 97.959, 97.992, 98.025,
      98.058, 98.091, 98.123, 98.156, 98.189, 98.222, 98.257, 98.29, 98.322,
      98.355, 98.388, 98.42, 98.453, 98.485, 98.518, 98.553, 98.585, 98.618,
      98.65, 98.683, 98.715, 98.747, 98.78, 98.812, 98.844, 98.879, 98.911,
      98.944, 98.976, 99.008, 99.04, 99.072, 99.104, 99.136, 99.168, 99.2,
      99.235, 99.267, 99.299, 99.331, 99.363, 99.395, 99.427, 99.459, 99.49,
      99.522, 99.554, 99.586, 99.62, 99.652, 99.683, 99.715, 99.747, 99.778,
      99.81, 99.841, 99.873, 99.904, 99.936, 99.967, 99.999, 100.03, 100.062,
      100.096, 100.127, 100.158, 100.19, 100.221, 100.252, 100.283, 100.314,
      100.346, 100.377, 100.408, 100.439, 100.47, 100.501, 100.532, 100.563,
      100.594, 100.625, 100.656, 100.687, 100.718, 100.749, 100.782, 100.813,
      100.844, 100.874, 100.905, 100.936, 100.967, 100.997, 101.028, 101.058,
      101.089, 101.12, 101.15, 101.181, 101.211, 101.242, 101.272, 101.303,
      101.333, 101.363, 101.394, 101.424, 101.454, 101.484, 101.515, 101.545,
      101.575, 101.605, 101.636, 101.666, 101.696, 101.726, 101.756, 101.786,
      101.816, 101.846, 101.876, 101.906, 101.936, 101.966, 101.996, 102.026,
      102.055, 102.085, 102.115, 102.145, 102.175, 102.204, 102.234, 102.264,
      102.294, 102.323, 102.353, 102.38, 102.409, 102.439, 102.468, 102.498,
      102.527, 102.557, 102.586, 102.616, 102.645, 102.674, 102.704, 102.733,
      102.763, 102.792, 102.821, 102.85, 102.88, 102.909, 102.938, 102.964,
      102.994, 103.023, 103.052, 103.081, 103.11, 103.139, 103.168, 103.197,
      103.226, 103.255, 103.284, 103.313, 103.342, 103.368, 103.397, 103.426,
      103.455, 103.484, 103.512, 103.541, 103.57, 103.599, 103.627, 103.656,
      103.682, 103.711, 103.739, 103.768, 103.797, 103.825, 103.854, 103.882,
      103.911, 103.939, 103.965, 103.994, 104.022, 104.05, 104.079, 104.107,
      104.136, 104.164, 104.19, 104.218, 104.246, 104.274, 104.303, 104.331,
      104.359, 104.388, 104.413, 104.441, 104.469, 104.497, 104.526, 104.554,
      104.582, 104.607, 104.635, 104.663, 104.691, 104.719, 104.747, 104.775,
      104.801, 104.829, 104.857, 104.885, 104.913, 104.94, 104.965, 104.993,
      105.021, 105.049, 105.077, 105.105, 105.13, 105.158, 105.185, 105.213,
      105.241, 105.266, 105.294, 105.321, 105.349, 105.377, 105.404, 105.429,
      105.457, 105.485, 105.512, 105.54, 105.565, 105.592, 105.62, 105.647,
      105.675, 105.7, 105.727, 105.755, 105.782, 105.81, 105.834, 105.862,
      105.889, 105.917, 105.941, 105.969, 105.996, 106.024, 106.051, 106.075,
      106.103, 106.13, 106.158, 106.182, 106.209, 106.237, 106.264, 106.291,
      106.316, 106.343, 106.37, 106.397, 106.422, 106.449, 106.476, 106.503,
      106.528, 106.555, 106.582, 106.609, 106.633, 106.661, 106.688, 106.715,
      106.739, 106.766, 106.793, 106.82, 106.844, 106.871, 106.898, 106.922,
      106.949, 106.976, 107.003, 107.027, 107.054, 107.081, 107.108, 107.132,
      107.159, 107.186, 107.21, 107.237, 107.264, 107.291, 107.315, 107.342,
      107.368, 107.392, 107.419, 107.446, 107.473, 107.497, 107.524, 107.55,
      107.574, 107.601, 107.628, 107.651, 107.678, 107.705, 107.732, 107.755,
      107.782, 107.809, 107.832, 107.859, 107.886, 107.909, 107.936, 107.962,
      107.986, 108.013, 108.039, 108.063, 108.09, 108.116, 108.14, 108.166,
      108.193, 108.216, 108.243, 108.269, 108.293, 108.319, 108.346, 108.369,
      108.396, 108.422, 108.446, 108.472, 108.498, 108.522, 108.548, 108.575,
      108.598, 108.624, 108.651, 108.674, 108.7, 108.727, 108.75, 108.776,
      108.803, 108.826, 108.852, 108.876, 108.902, 108.928, 108.951, 108.978,
      109.004, 109.027, 109.053, 109.08, 109.103, 109.129, 109.152, 109.178,
      109.204, 109.228, 109.254, 109.28, 109.303, 109.329, 109.352, 109.378,
      109.404, 109.427, 109.454, 109.477, 109.503, 109.529, 109.552, 109.578,
      109.601, 109.627, 109.653, 109.676, 109.702, 109.727, 109.75, 109.776,
      109.799, 109.825, 109.851, 109.874, 109.9, 109.923, 109.949, 109.971,
      109.997, 110.023, 110.046, 110.072, 110.095, 110.12, 110.146, 110.169,
      110.195, 110.217, 110.243, 110.269, 110.292, 110.317, 110.34, 110.366,
      110.389, 110.414, 110.44, 110.463, 110.488, 110.511, 110.536, 110.559,
      110.585, 110.61, 110.633, 110.658, 110.681, 110.707, 110.729, 110.755,
      110.78, 110.803, 110.828, 110.851, 110.876, 110.899, 110.924, 110.946,
      110.972, 110.997, 111.02, 111.045, 111.068, 111.093, 111.115, 111.141,
      111.163, 111.188, 111.211, 111.236, 111.261, 111.284, 111.309, 111.331,
      111.357, 111.379, 111.404, 111.426, 111.452, 111.474, 111.499, 111.524,
      111.546, 111.572, 111.594, 111.619, 111.641, 111.666, 111.688, 111.713,
      111.736, 111.761, 111.783, 111.808, 111.83, 111.855, 111.877, 111.902,
      111.927, 111.949, 111.974, 111.996, 112.021, 112.043, 112.068, 112.09,
      112.115, 112.137, 112.162, 112.184, 112.209, 112.231, 112.256, 112.277,
      112.302, 112.324, 112.349, 112.371, 112.396, 112.418, 112.442, 112.464,
      112.489, 112.511, 112.536, 112.557, 112.582, 112.607, 112.629, 112.653,
      112.675, 112.7, 112.722, 112.746, 112.768, 112.793, 112.814, 112.839,
      112.861, 112.885, 112.907, 112.932, 112.953, 112.978, 112.999, 113.024,
      113.046, 113.07, 113.092, 113.116, 113.138, 113.163, 113.184, 113.209,
      113.23, 113.255, 113.276, 113.298, 113.322, 113.344, 113.368, 113.39,
      113.414, 113.435, 113.46, 113.481, 113.506, 113.527, 113.551, 113.573,
      113.597, 113.619, 113.643, 113.664, 113.689, 113.71, 113.735, 113.756,
      113.78, 113.801, 113.826, 113.847, 113.871, 113.893, 113.917, 113.938,
      113.962, 113.984, 114.008, 114.029, 114.05, 114.075, 114.096, 114.12,
      114.141, 114.165, 114.187, 114.211, 114.232, 114.256, 114.277, 114.302,
      114.323, 114.347, 114.368, 114.392, 114.413, 114.437, 114.459, 114.483,
      114.504, 114.525, 114.549, 114.57, 114.594, 114.615, 114.639, 114.66,
      114.684, 114.705, 114.729, 114.75, 114.774, 114.795, 114.82, 114.841,
      114.862, 114.886, 114.907, 114.931, 114.952, 114.976, 114.996, 115.02,
      115.041, 115.065, 115.086, 115.11, 115.131, 115.155, 115.176, 115.197,
      115.221, 115.242, 115.266, 115.287, 115.311, 115.332, 115.355, 115.376,
      115.4, 115.421, 115.445, 115.466, 115.487, 115.511, 115.531, 115.555,
      115.576, 115.6, 115.621, 115.645, 115.665, 115.689, 115.71, 115.731,
      115.755, 115.776, 115.8, 115.82, 115.844, 115.865, 115.889, 115.909,
      115.933, 115.954, 115.975, 115.999, 116.019, 116.043, 116.064, 116.088,
      116.108, 116.132, 116.153, 116.177, 116.197, 116.218, 116.242, 116.263,
      116.286, 116.307, 116.331, 116.352, 116.375, 116.396, 116.417, 116.44,
      116.461, 116.485, 116.506, 116.529, 116.55, 116.574, 116.594, 116.615,
      116.639, 116.659, 116.683, 116.704, 116.728, 116.748, 116.772, 116.793,
      116.816, 116.837, 116.857, 116.881, 116.902, 116.926, 116.946, 116.97,
      116.99, 117.014, 117.035, 117.055, 117.079, 117.1, 117.123, 117.144,
      117.168, 117.188, 117.212, 117.232, 117.253, 117.277, 117.297, 117.321,
      117.342, 117.365, 117.386, 117.406, 117.43, 117.451, 117.474, 117.495,
      117.519, 117.539, 117.563, 117.583, 117.604, 117.628, 117.648, 117.672,
      117.692, 117.716, 117.736, 117.76, 117.781, 117.801, 117.825, 117.845,
      117.869, 117.89, 117.913, 117.934, 117.954, 117.978, 117.998, 118.022,
      118.043, 118.066, 118.087, 118.11, 118.131, 118.151, 118.175, 118.196,
      118.219, 118.24, 118.263, 118.284, 118.304, 118.328, 118.348, 118.372,
      118.393, 118.416, 118.437, 118.46, 118.481, 118.501, 118.525, 118.546,
      118.569, 118.59, 118.613, 118.634, 118.654, 118.678, 118.698, 118.722,
      118.743, 118.766, 118.787, 118.807, 118.831, 118.851, 118.875, 118.895,
      118.919, 118.939, 118.96, 118.983, 119.004, 119.028, 119.048, 119.072,
      119.092, 119.112, 119.136, 119.157, 119.18, 119.201, 119.224, 119.245,
      119.268, 119.289, 119.309, 119.333, 119.353, 119.377, 119.397, 119.421,
      119.441, 119.462, 119.485, 119.506, 119.529, 119.55, 119.57, 119.594,
      119.614, 119.638, 119.658, 119.682, 119.702, 119.723, 119.746, 119.767,
      119.79, 119.811, 119.834, 119.855, 119.875, 119.899, 119.919, 119.943,
      119.963, 119.987, 120.007, 120.028, 120.051, 120.072, 120.095, 120.116,
      120.139, 120.16, 120.18, 120.204, 120.224, 120.248, 120.268, 120.292,
      120.312, 120.332, 120.356, 120.376, 120.4, 120.42, 120.441, 120.464,
      120.485, 120.508, 120.529, 120.552, 120.573, 120.593, 120.617, 120.637,
      120.661, 120.681, 120.701, 120.725, 120.745, 120.769, 120.789, 120.813,
      120.833, 120.854, 120.877, 120.898, 120.921, 120.941, 120.965, 120.985,
      121.006, 121.029, 121.05, 121.073, 121.094, 121.114, 121.137, 121.158,
      121.181, 121.202, 121.225, 121.246, 121.266, 121.29, 121.31, 121.334,
      121.354, 121.374, 121.398, 121.418, 121.442, 121.462, 121.482, 121.506,
      121.526, 121.55, 121.57, 121.594, 121.614, 121.634, 121.658, 121.678,
      121.702, 121.722, 121.743, 121.766, 121.786, 121.81, 121.83, 121.851,
      121.874, 121.895, 121.918, 121.938, 121.962, 121.982, 122.003, 122.026,
      122.047, 122.07, 122.09, 122.111, 122.134, 122.155, 122.178, 122.199,
      122.219, 122.242, 122.263, 122.286, 122.307, 122.327, 122.35, 122.371,
      122.394, 122.414, 122.438, 122.458, 122.479, 122.502, 122.523, 122.546,
      122.566, 122.587, 122.61, 122.63, 122.654, 122.674, 122.695, 122.718,
      122.738, 122.762, 122.782, 122.803, 122.826, 122.846, 122.87, 122.89,
      122.911, 122.934, 122.954, 122.978, 122.998, 123.018, 123.042, 123.062,
      123.086, 123.106, 123.126, 123.15, 123.17, 123.194, 123.214, 123.234,
      123.258, 123.278, 123.301, 123.322, 123.342, 123.365, 123.386, 123.409,
      123.429, 123.45, 123.473, 123.493, 123.517, 123.537, 123.557, 123.581,
      123.601, 123.625, 123.645, 123.665, 123.689, 123.709, 123.732, 123.753,
      123.773, 123.796, 123.817, 123.84, 123.86, 123.881, 123.904, 123.924,
      123.948, 123.968, 123.988, 124.012, 124.032, 124.055, 124.076, 124.096,
      124.119, 124.139, 124.163, 124.183, 124.203, 124.227, 124.247, 124.271,
      124.291, 124.311, 124.334, 124.355, 124.375, 124.398, 124.418, 124.442,
      124.462, 124.482, 124.506,
    ],
  },
  SD3neg: {
    label: '-3 SD',
    weight: [
      44.205, 44.376, 44.547, 44.719, 44.89, 45.062, 45.233, 45.406, 45.578,
      45.749, 45.921, 46.095, 46.267, 46.439, 46.611, 46.757, 46.901, 47.044,
      47.187, 47.329, 47.47, 47.61, 47.749, 47.888, 48.024, 48.161, 48.295,
      48.43, 48.563, 48.695, 48.828, 48.958, 49.088, 49.216, 49.344, 49.471,
      49.596, 49.721, 49.844, 49.967, 50.089, 50.209, 50.329, 50.449, 50.566,
      50.682, 50.8, 50.914, 51.028, 51.143, 51.255, 51.366, 51.477, 51.586,
      51.696, 51.804, 51.911, 52.018, 52.124, 52.228, 52.333, 52.437, 52.539,
      52.643, 52.746, 52.846, 52.948, 53.047, 53.148, 53.246, 53.344, 53.443,
      53.54, 53.636, 53.731, 53.828, 53.922, 54.016, 54.109, 54.202, 54.294,
      54.386, 54.477, 54.568, 54.658, 54.747, 54.836, 54.923, 55.011, 55.098,
      55.185, 55.269, 55.355, 55.44, 55.523, 55.607, 55.689, 55.772, 55.853,
      55.935, 56.015, 56.097, 56.175, 56.256, 56.333, 56.411, 56.49, 56.566,
      56.642, 56.719, 56.794, 56.869, 56.943, 57.016, 57.091, 57.164, 57.236,
      57.308, 57.379, 57.45, 57.521, 57.591, 57.66, 57.729, 57.798, 57.866,
      57.934, 58.001, 58.068, 58.135, 58.201, 58.267, 58.331, 58.396, 58.461,
      58.525, 58.589, 58.651, 58.714, 58.777, 58.84, 58.9, 58.962, 59.024,
      59.083, 59.144, 59.203, 59.264, 59.324, 59.382, 59.441, 59.499, 59.558,
      59.614, 59.673, 59.729, 59.787, 59.843, 59.898, 59.955, 60.01, 60.067,
      60.121, 60.175, 60.231, 60.285, 60.338, 60.393, 60.446, 60.499, 60.551,
      60.604, 60.658, 60.71, 60.762, 60.813, 60.864, 60.918, 60.969, 61.019,
      61.07, 61.12, 61.171, 61.221, 61.271, 61.32, 61.37, 61.419, 61.469,
      61.518, 61.567, 61.616, 61.664, 61.713, 61.761, 61.81, 61.858, 61.904,
      61.951, 61.999, 62.047, 62.094, 62.141, 62.187, 62.234, 62.281, 62.328,
      62.372, 62.419, 62.465, 62.512, 62.556, 62.602, 62.649, 62.692, 62.738,
      62.784, 62.828, 62.874, 62.917, 62.963, 63.008, 63.051, 63.096, 63.139,
      63.185, 63.227, 63.272, 63.315, 63.36, 63.402, 63.447, 63.489, 63.534,
      63.576, 63.62, 63.662, 63.707, 63.749, 63.791, 63.835, 63.876, 63.918,
      63.962, 64.004, 64.047, 64.089, 64.13, 64.174, 64.215, 64.257, 64.298,
      64.341, 64.382, 64.423, 64.464, 64.507, 64.548, 64.589, 64.63, 64.673,
      64.713, 64.754, 64.795, 64.835, 64.878, 64.918, 64.958, 64.999, 65.039,
      65.079, 65.119, 65.159, 65.201, 65.241, 65.281, 65.321, 65.361, 65.401,
      65.44, 65.48, 65.52, 65.559, 65.599, 65.638, 65.677, 65.717, 65.756,
      65.795, 65.834, 65.873, 65.912, 65.951, 65.99, 66.029, 66.068, 66.106,
      66.145, 66.184, 66.22, 66.259, 66.297, 66.335, 66.374, 66.412, 66.45,
      66.489, 66.524, 66.563, 66.601, 66.639, 66.676, 66.714, 66.75, 66.788,
      66.825, 66.863, 66.901, 66.936, 66.973, 67.011, 67.048, 67.083, 67.121,
      67.158, 67.195, 67.23, 67.267, 67.304, 67.339, 67.376, 67.413, 67.45,
      67.484, 67.521, 67.558, 67.592, 67.629, 67.665, 67.7, 67.736, 67.772,
      67.807, 67.843, 67.879, 67.913, 67.949, 67.983, 68.019, 68.055, 68.089,
      68.125, 68.159, 68.195, 68.231, 68.264, 68.3, 68.334, 68.369, 68.403,
      68.438, 68.472, 68.507, 68.543, 68.576, 68.611, 68.644, 68.68, 68.713,
      68.748, 68.781, 68.816, 68.849, 68.884, 68.917, 68.952, 68.985, 69.02,
      69.052, 69.087, 69.12, 69.152, 69.187, 69.219, 69.254, 69.287, 69.321,
      69.353, 69.388, 69.42, 69.452, 69.487, 69.519, 69.553, 69.585, 69.617,
      69.651, 69.683, 69.717, 69.749, 69.781, 69.815, 69.847, 69.878, 69.912,
      69.944, 69.977, 70.009, 70.04, 70.074, 70.105, 70.137, 70.17, 70.202,
      70.233, 70.266, 70.297, 70.329, 70.362, 70.393, 70.424, 70.457, 70.488,
      70.519, 70.552, 70.583, 70.614, 70.644, 70.677, 70.708, 70.739, 70.772,
      70.802, 70.833, 70.863, 70.896, 70.926, 70.957, 70.987, 71.02, 71.05,
      71.08, 71.11, 71.143, 71.173, 71.203, 71.233, 71.266, 71.296, 71.326,
      71.356, 71.388, 71.418, 71.447, 71.477, 71.507, 71.539, 71.569, 71.599,
      71.628, 71.658, 71.69, 71.719, 71.749, 71.778, 71.808, 71.84, 71.869,
      71.899, 71.928, 71.957, 71.986, 72.018, 72.047, 72.077, 72.106, 72.135,
      72.164, 72.195, 72.224, 72.253, 72.282, 72.311, 72.34, 72.369, 72.4,
      72.429, 72.458, 72.487, 72.516, 72.544, 72.573, 72.604, 72.633, 72.661,
      72.69, 72.719, 72.747, 72.776, 72.804, 72.832, 72.863, 72.892, 72.92,
      72.948, 72.977, 73.005, 73.033, 73.061, 73.089, 73.118, 73.146, 73.174,
      73.204, 73.232, 73.26, 73.288, 73.316, 73.344, 73.372, 73.4, 73.428,
      73.455, 73.483, 73.511, 73.539, 73.566, 73.594, 73.622, 73.649, 73.679,
      73.707, 73.734, 73.762, 73.789, 73.817, 73.844, 73.871, 73.899, 73.926,
      73.953, 73.981, 74.008, 74.035, 74.062, 74.089, 74.116, 74.144, 74.171,
      74.198, 74.225, 74.252, 74.279, 74.305, 74.332, 74.359, 74.386, 74.413,
      74.44, 74.466, 74.493, 74.52, 74.546, 74.573, 74.6, 74.626, 74.653,
      74.679, 74.706, 74.732, 74.759, 74.785, 74.812, 74.838, 74.864, 74.891,
      74.914, 74.941, 74.967, 74.993, 75.019, 75.045, 75.072, 75.098, 75.124,
      75.15, 75.176, 75.202, 75.228, 75.254, 75.28, 75.305, 75.331, 75.357,
      75.383, 75.409, 75.432, 75.458, 75.483, 75.509, 75.535, 75.56, 75.586,
      75.612, 75.637, 75.663, 75.688, 75.714, 75.739, 75.765, 75.788, 75.813,
      75.839, 75.864, 75.889, 75.914, 75.94, 75.965, 75.99, 76.016, 76.041,
      76.063, 76.088, 76.114, 76.139, 76.164, 76.189, 76.214, 76.239, 76.264,
      76.289, 76.311, 76.336, 76.361, 76.386, 76.411, 76.435, 76.46, 76.485,
      76.51, 76.532, 76.557, 76.581, 76.606, 76.63, 76.655, 76.68, 76.704,
      76.729, 76.751, 76.775, 76.8, 76.824, 76.849, 76.873, 76.897, 76.922,
      76.943, 76.968, 76.992, 77.016, 77.04, 77.065, 77.089, 77.11, 77.135,
      77.159, 77.183, 77.207, 77.231, 77.255, 77.279, 77.3, 77.324, 77.348,
      77.372, 77.396, 77.42, 77.444, 77.465, 77.489, 77.513, 77.537, 77.561,
      77.584, 77.608, 77.629, 77.653, 77.677, 77.7, 77.724, 77.747, 77.771,
      77.792, 77.816, 77.839, 77.863, 77.886, 77.91, 77.933, 77.954, 77.961,
      77.977, 77.984, 78.001, 78.006, 78.024, 78.029, 78.048, 78.049, 78.071,
      78.072, 78.094, 78.095, 78.115, 78.117, 78.138, 78.14, 78.162, 78.163,
      78.185, 78.185, 78.205, 78.208, 78.228, 78.231, 78.251, 78.254, 78.273,
      78.275, 78.296, 78.298, 78.318, 78.321, 78.341, 78.344, 78.363, 78.368,
      78.383, 78.391, 78.405, 78.414, 78.428, 78.434, 78.45, 78.457, 78.472,
      78.48, 78.495, 78.503, 78.517, 78.526, 78.537, 78.549, 78.559, 78.572,
      78.581, 78.592, 78.603, 78.615, 78.626, 78.638, 78.648, 78.67, 78.692,
      78.712, 78.734, 78.756, 78.778, 78.8, 78.822, 78.844, 78.863, 78.885,
      78.907, 78.929, 78.951, 78.972, 78.994, 79.016, 79.035, 79.057, 79.079,
      79.1, 79.122, 79.144, 79.165, 79.187, 79.208, 79.227, 79.249, 79.27,
      79.292, 79.313, 79.335, 79.356, 79.377, 79.399, 79.418, 79.439, 79.46,
      79.482, 79.503, 79.524, 79.545, 79.566, 79.588, 79.609, 79.627, 79.648,
      79.67, 79.691, 79.712, 79.733, 79.754, 79.775, 79.796, 79.817, 79.838,
      79.856, 79.877, 79.897, 79.918, 79.939, 79.96, 79.981, 80.001, 80.022,
      80.043, 80.064, 80.084, 80.102, 80.123, 80.143, 80.164, 80.185, 80.205,
      80.226, 80.246, 80.267, 80.287, 80.307, 80.328, 80.348, 80.369, 80.389,
      80.406, 80.427, 80.447, 80.467, 80.487, 80.508, 80.528, 80.548, 80.568,
      80.588, 80.608, 80.628, 80.648, 80.668, 80.688, 80.708, 80.728, 80.748,
      80.768, 80.788, 80.808, 80.828, 80.845, 80.865, 80.884, 80.904, 80.924,
      80.944, 80.963, 80.983, 81.003, 81.022, 81.042, 81.061, 81.081, 81.101,
      81.12, 81.14, 81.159, 81.178, 81.198, 81.217, 81.237, 81.256, 81.275,
      81.295, 81.314, 81.333, 81.353, 81.372, 81.391, 81.41, 81.429, 81.448,
      81.468, 81.487, 81.506, 81.525, 81.544, 81.563, 81.582, 81.601, 81.62,
      81.639, 81.658, 81.677, 81.696, 81.714, 81.733, 81.752, 81.771, 81.79,
      81.808, 81.827, 81.846, 81.867, 81.886, 81.905, 81.923, 81.942, 81.961,
      81.979, 81.998, 82.016, 82.035, 82.053, 82.072, 82.09, 82.109, 82.127,
      82.146, 82.164, 82.182, 82.201, 82.219, 82.24, 82.258, 82.277, 82.295,
      82.313, 82.331, 82.35, 82.368, 82.386, 82.404, 82.422, 82.44, 82.458,
      82.477, 82.497, 82.515, 82.533, 82.551, 82.569, 82.587, 82.605, 82.623,
      82.641, 82.659, 82.677, 82.698, 82.715, 82.733, 82.751, 82.769, 82.787,
      82.804, 82.822, 82.84, 82.857, 82.878, 82.896, 82.913, 82.931, 82.949,
      82.966, 82.984, 83.001, 83.022, 83.039, 83.057, 83.074, 83.092, 83.109,
      83.127, 83.144, 83.164, 83.182, 83.199, 83.216, 83.234, 83.251, 83.268,
      83.289, 83.306, 83.323, 83.34, 83.358, 83.375, 83.392, 83.412, 83.429,
      83.447, 83.464, 83.481, 83.498, 83.518, 83.535, 83.552, 83.569, 83.586,
      83.603, 83.623, 83.64, 83.657, 83.674, 83.691, 83.711, 83.728, 83.745,
      83.762, 83.779, 83.796, 83.816, 83.832, 83.849, 83.866, 83.883, 83.903,
      83.92, 83.937, 83.953, 83.97, 83.99, 84.006, 84.023, 84.04, 84.057,
      84.076, 84.093, 84.11, 84.126, 84.146, 84.163, 84.179, 84.196, 84.212,
      84.232, 84.249, 84.265, 84.282, 84.301, 84.318, 84.334, 84.351, 84.367,
      84.387, 84.403, 84.42, 84.436, 84.456, 84.472, 84.488, 84.505, 84.524,
      84.541, 84.557, 84.573, 84.593, 84.609, 84.625, 84.642, 84.661, 84.677,
      84.694, 84.71, 84.729, 84.745, 84.762, 84.781, 84.797, 84.813, 84.83,
      84.849, 84.865, 84.881, 84.897, 84.916, 84.932, 84.949, 84.968, 84.984,
      85, 85.016, 85.035, 85.051, 85.067, 85.086, 85.102, 85.118, 85.134,
      85.153, 85.169, 85.185, 85.204, 85.22, 85.236, 85.255, 85.271, 85.287,
      85.303, 85.322, 85.338, 85.353, 85.372, 85.388, 85.404, 85.423, 85.439,
      85.455, 85.473, 85.489, 85.505, 85.524, 85.539, 85.555, 85.574, 85.59,
      85.606, 85.624, 85.64, 85.656, 85.674, 85.69, 85.706, 85.724, 85.74,
      85.756, 85.774, 85.79, 85.806, 85.824, 85.84, 85.856, 85.874, 85.89,
      85.905, 85.924, 85.939, 85.955, 85.973, 85.989, 86.004, 86.023, 86.038,
      86.057, 86.072, 86.088, 86.106, 86.122, 86.137, 86.156, 86.171, 86.187,
      86.205, 86.22, 86.239, 86.254, 86.27, 86.288, 86.303, 86.319, 86.337,
      86.352, 86.371, 86.386, 86.401, 86.419, 86.435, 86.453, 86.468, 86.483,
      86.502, 86.517, 86.535, 86.55, 86.566, 86.584, 86.599, 86.614, 86.632,
      86.647, 86.666, 86.681, 86.696, 86.714, 86.729, 86.747, 86.762, 86.78,
      86.795, 86.811, 86.829, 86.844, 86.862, 86.877, 86.892, 86.91, 86.925,
      86.943, 86.958, 86.973, 86.991, 87.006, 87.024, 87.039, 87.056, 87.071,
      87.086, 87.104, 87.119, 87.137, 87.152, 87.17, 87.185, 87.2, 87.217,
      87.232, 87.25, 87.265, 87.283, 87.297, 87.312, 87.33, 87.345, 87.362,
      87.377, 87.395, 87.41, 87.427, 87.442, 87.457, 87.474, 87.489, 87.507,
      87.521, 87.539, 87.554, 87.571, 87.586, 87.604, 87.618, 87.633, 87.65,
      87.665, 87.682, 87.697, 87.715, 87.729, 87.747, 87.761, 87.779, 87.793,
      87.808, 87.825, 87.84, 87.857, 87.872, 87.889, 87.903, 87.921, 87.935,
      87.953, 87.967, 87.984, 87.999, 88.016, 88.031, 88.048, 88.062, 88.077,
      88.094, 88.108, 88.126, 88.14, 88.157, 88.172, 88.189, 88.203, 88.22,
      88.235, 88.252, 88.266, 88.283, 88.297, 88.315, 88.329, 88.346, 88.36,
      88.377, 88.392, 88.409, 88.423, 88.44, 88.454, 88.471, 88.486, 88.503,
      88.517, 88.531, 88.548, 88.562, 88.579, 88.593, 88.61, 88.624, 88.641,
      88.655, 88.672, 88.686, 88.703, 88.717, 88.734, 88.748, 88.765, 88.779,
      88.796, 88.81, 88.827, 88.841, 88.858, 88.872, 88.889, 88.903, 88.92,
      88.933, 88.95, 88.964, 88.981, 88.998, 89.012, 89.029, 89.042, 89.059,
      89.073, 89.09, 89.104, 89.12, 89.134, 89.151, 89.165, 89.182, 89.195,
      89.212, 89.226, 89.243, 89.256, 89.273, 89.287, 89.303, 89.317, 89.334,
      89.347, 89.364, 89.378, 89.394, 89.408, 89.425, 89.438, 89.455, 89.469,
      89.485, 89.502, 89.516, 89.532, 89.546, 89.562, 89.576, 89.593, 89.606,
      89.623, 89.636, 89.653, 89.666, 89.683, 89.696, 89.713, 89.726, 89.743,
      89.757, 89.773, 89.787, 89.803, 89.82, 89.833, 89.849, 89.863, 89.879,
      89.893, 89.909, 89.923, 89.939, 89.953, 89.969, 89.983, 89.999, 90.012,
      90.029, 90.045, 90.059, 90.075, 90.088, 90.105, 90.118, 90.135, 90.148,
      90.164, 90.178, 90.194, 90.207, 90.224, 90.237, 90.253, 90.27, 90.283,
      90.299, 90.313, 90.329, 90.342, 90.359, 90.372, 90.388, 90.402, 90.418,
      90.431, 90.447, 90.464, 90.477, 90.493, 90.506, 90.523, 90.536, 90.552,
      90.565, 90.582, 90.595, 90.611, 90.627, 90.641, 90.657, 90.67, 90.686,
      90.699, 90.716, 90.729, 90.745, 90.758, 90.775, 90.791, 90.804, 90.82,
      90.833, 90.849, 90.863, 90.879, 90.892, 90.908, 90.921, 90.937, 90.954,
      90.967, 90.983, 90.996, 91.012, 91.025, 91.041, 91.054, 91.071, 91.087,
      91.1, 91.116, 91.129, 91.145, 91.158, 91.174, 91.187, 91.204, 91.22,
      91.233, 91.249, 91.262, 91.278, 91.291, 91.307, 91.32, 91.336, 91.349,
      91.365, 91.382, 91.395, 91.411, 91.424, 91.44, 91.453, 91.469, 91.482,
      91.498, 91.514, 91.527, 91.543, 91.556, 91.572, 91.585, 91.601, 91.614,
      91.63, 91.646, 91.659, 91.675, 91.688, 91.704, 91.717, 91.733, 91.749,
      91.762, 91.778, 91.791, 91.807, 91.82, 91.836, 91.849, 91.865, 91.881,
      91.894, 91.91, 91.923, 91.939, 91.952, 91.968, 91.981, 91.997, 92.013,
      92.026, 92.042, 92.055, 92.071, 92.084, 92.1, 92.116, 92.129, 92.145,
      92.157, 92.173, 92.186, 92.202, 92.215, 92.231, 92.247, 92.26, 92.276,
      92.289, 92.305, 92.318, 92.334, 92.35, 92.363, 92.379, 92.391, 92.407,
      92.42, 92.436, 92.449, 92.465, 92.481, 92.494, 92.51, 92.523, 92.539,
      92.551, 92.567, 92.583, 92.596, 92.612, 92.625, 92.641, 92.654, 92.67,
      92.686, 92.698, 92.714, 92.727, 92.743, 92.756, 92.772, 92.788, 92.801,
      92.817, 92.829, 92.845, 92.858, 92.874, 92.89, 92.903, 92.919, 92.931,
      92.947, 92.96, 92.976, 92.989, 93.005, 93.021, 93.033, 93.049, 93.062,
      93.078, 93.091, 93.107, 93.123, 93.135, 93.151, 93.164, 93.18, 93.196,
      93.209, 93.224, 93.237, 93.253, 93.266, 93.282, 93.298, 93.31, 93.326,
      93.339, 93.355, 93.368, 93.383, 93.399, 93.412, 93.428, 93.441, 93.457,
      93.469, 93.485, 93.501, 93.514, 93.53, 93.542, 93.558, 93.571, 93.587,
      93.603, 93.615, 93.631, 93.644, 93.66, 93.672, 93.688, 93.704, 93.717,
      93.733, 93.745, 93.761, 93.777, 93.79, 93.806, 93.818, 93.834, 93.847,
      93.863, 93.879, 93.891, 93.907, 93.92, 93.935, 93.951, 93.964, 93.98,
      93.992, 94.008, 94.021, 94.037, 94.053, 94.065, 94.081, 94.094, 94.11,
      94.122, 94.138, 94.154, 94.166, 94.182, 94.195, 94.211, 94.226, 94.239,
      94.255, 94.268, 94.283, 94.296, 94.312, 94.328, 94.34, 94.356, 94.369,
      94.384, 94.4, 94.413, 94.429, 94.441, 94.457, 94.473, 94.485, 94.501,
      94.514, 94.53, 94.542, 94.558, 94.574, 94.586, 94.602, 94.615, 94.631,
      94.646, 94.659, 94.675, 94.687, 94.703, 94.719, 94.731, 94.747, 94.76,
      94.776, 94.788, 94.804, 94.82, 94.832, 94.848, 94.86, 94.876, 94.892,
      94.905, 94.92, 94.933, 94.949, 94.964, 94.977, 94.993, 95.005, 95.021,
      95.037, 95.049, 95.065, 95.078, 95.093, 95.106, 95.122, 95.137, 95.15,
      95.166, 95.178, 95.194, 95.21, 95.222, 95.238, 95.25, 95.266, 95.282,
      95.294, 95.31, 95.323, 95.338, 95.354, 95.367, 95.382, 95.395, 95.411,
      95.426, 95.439, 95.454, 95.467, 95.483, 95.498, 95.511, 95.527, 95.539,
      95.555, 95.571, 95.583, 95.599, 95.611, 95.627, 95.643, 95.655, 95.671,
      95.683, 95.699, 95.715, 95.727, 95.743, 95.755, 95.771, 95.786, 95.799,
      95.815, 95.827, 95.843, 95.858, 95.871, 95.887, 95.899, 95.915, 95.93,
      95.943, 95.958, 95.971, 95.986, 96.002, 96.015, 96.03, 96.043, 96.058,
      96.074, 96.086, 96.102, 96.114, 96.13, 96.146, 96.158, 96.174, 96.186,
      96.202, 96.217, 96.23, 96.245, 96.258, 96.273, 96.289, 96.301, 96.317,
      96.329, 96.345, 96.361, 96.373, 96.389, 96.404, 96.417, 96.432, 96.445,
      96.46, 96.476, 96.488,
    ],
  },
  SD2neg: {
    label: '-2 SD',
    weight: [
      46.098, 46.271, 46.443, 46.617, 46.789, 46.962, 47.135, 47.309, 47.482,
      47.655, 47.828, 48.003, 48.176, 48.349, 48.523, 48.671, 48.817, 48.963,
      49.107, 49.251, 49.395, 49.537, 49.678, 49.819, 49.957, 50.096, 50.233,
      50.37, 50.505, 50.639, 50.773, 50.906, 51.037, 51.168, 51.297, 51.426,
      51.554, 51.681, 51.805, 51.93, 52.054, 52.176, 52.298, 52.419, 52.539,
      52.657, 52.775, 52.892, 53.007, 53.123, 53.237, 53.35, 53.463, 53.574,
      53.685, 53.795, 53.904, 54.012, 54.12, 54.226, 54.332, 54.438, 54.542,
      54.646, 54.75, 54.853, 54.956, 55.057, 55.159, 55.259, 55.358, 55.458,
      55.557, 55.654, 55.752, 55.849, 55.945, 56.041, 56.135, 56.23, 56.323,
      56.416, 56.509, 56.601, 56.692, 56.783, 56.873, 56.961, 57.05, 57.139,
      57.227, 57.313, 57.4, 57.486, 57.571, 57.656, 57.74, 57.824, 57.906,
      57.99, 58.071, 58.153, 58.234, 58.315, 58.394, 58.473, 58.552, 58.63,
      58.707, 58.786, 58.862, 58.938, 59.013, 59.088, 59.163, 59.237, 59.311,
      59.384, 59.456, 59.528, 59.6, 59.671, 59.741, 59.812, 59.881, 59.951,
      60.019, 60.088, 60.156, 60.224, 60.291, 60.358, 60.423, 60.489, 60.554,
      60.62, 60.685, 60.748, 60.812, 60.876, 60.939, 61.001, 61.064, 61.126,
      61.187, 61.249, 61.309, 61.37, 61.431, 61.49, 61.551, 61.609, 61.669,
      61.727, 61.786, 61.843, 61.902, 61.959, 62.015, 62.073, 62.129, 62.186,
      62.242, 62.297, 62.354, 62.408, 62.463, 62.519, 62.573, 62.626, 62.68,
      62.734, 62.788, 62.841, 62.894, 62.947, 62.999, 63.052, 63.104, 63.156,
      63.208, 63.259, 63.311, 63.362, 63.413, 63.463, 63.514, 63.564, 63.615,
      63.665, 63.715, 63.764, 63.814, 63.864, 63.913, 63.962, 64.011, 64.059,
      64.107, 64.156, 64.204, 64.253, 64.301, 64.348, 64.396, 64.444, 64.491,
      64.537, 64.585, 64.632, 64.68, 64.725, 64.773, 64.82, 64.865, 64.912,
      64.958, 65.004, 65.05, 65.095, 65.141, 65.188, 65.232, 65.278, 65.323,
      65.369, 65.413, 65.459, 65.503, 65.548, 65.593, 65.638, 65.682, 65.727,
      65.771, 65.816, 65.859, 65.904, 65.948, 65.991, 66.036, 66.079, 66.122,
      66.167, 66.21, 66.254, 66.297, 66.34, 66.384, 66.427, 66.47, 66.513,
      66.557, 66.599, 66.642, 66.684, 66.728, 66.77, 66.812, 66.855, 66.898,
      66.94, 66.982, 67.024, 67.066, 67.109, 67.151, 67.193, 67.234, 67.276,
      67.318, 67.359, 67.401, 67.443, 67.485, 67.526, 67.567, 67.608, 67.65,
      67.691, 67.732, 67.772, 67.813, 67.854, 67.895, 67.936, 67.976, 68.017,
      68.057, 68.098, 68.138, 68.179, 68.219, 68.259, 68.299, 68.339, 68.38,
      68.42, 68.459, 68.498, 68.538, 68.577, 68.617, 68.657, 68.696, 68.736,
      68.776, 68.814, 68.853, 68.892, 68.932, 68.971, 69.01, 69.048, 69.087,
      69.126, 69.165, 69.203, 69.241, 69.28, 69.318, 69.357, 69.394, 69.433,
      69.471, 69.51, 69.547, 69.585, 69.623, 69.66, 69.698, 69.737, 69.775,
      69.811, 69.849, 69.887, 69.924, 69.962, 69.999, 70.036, 70.073, 70.111,
      70.147, 70.185, 70.222, 70.258, 70.296, 70.332, 70.369, 70.406, 70.442,
      70.479, 70.515, 70.552, 70.589, 70.625, 70.662, 70.697, 70.734, 70.77,
      70.807, 70.842, 70.879, 70.915, 70.95, 70.987, 71.022, 71.059, 71.094,
      71.13, 71.165, 71.202, 71.237, 71.273, 71.308, 71.344, 71.379, 71.415,
      71.449, 71.486, 71.52, 71.555, 71.591, 71.625, 71.661, 71.695, 71.731,
      71.765, 71.801, 71.835, 71.869, 71.905, 71.939, 71.974, 72.008, 72.042,
      72.078, 72.112, 72.147, 72.181, 72.214, 72.249, 72.283, 72.317, 72.352,
      72.385, 72.42, 72.454, 72.487, 72.522, 72.555, 72.589, 72.623, 72.657,
      72.69, 72.724, 72.758, 72.791, 72.825, 72.858, 72.891, 72.925, 72.958,
      72.991, 73.025, 73.058, 73.091, 73.124, 73.158, 73.19, 73.223, 73.257,
      73.289, 73.322, 73.354, 73.388, 73.421, 73.453, 73.485, 73.519, 73.551,
      73.583, 73.615, 73.649, 73.681, 73.713, 73.745, 73.779, 73.811, 73.843,
      73.874, 73.908, 73.94, 73.971, 74.003, 74.035, 74.068, 74.1, 74.131,
      74.163, 74.194, 74.228, 74.259, 74.291, 74.322, 74.353, 74.386, 74.418,
      74.449, 74.48, 74.511, 74.543, 74.575, 74.606, 74.638, 74.669, 74.7,
      74.731, 74.763, 74.794, 74.825, 74.856, 74.887, 74.918, 74.949, 74.981,
      75.012, 75.042, 75.073, 75.104, 75.134, 75.165, 75.197, 75.228, 75.258,
      75.289, 75.319, 75.35, 75.38, 75.411, 75.441, 75.473, 75.503, 75.533,
      75.564, 75.594, 75.624, 75.654, 75.684, 75.714, 75.744, 75.774, 75.804,
      75.836, 75.866, 75.896, 75.926, 75.955, 75.985, 76.015, 76.045, 76.075,
      76.104, 76.134, 76.164, 76.193, 76.223, 76.252, 76.282, 76.311, 76.342,
      76.372, 76.401, 76.431, 76.46, 76.489, 76.519, 76.548, 76.577, 76.606,
      76.636, 76.665, 76.694, 76.723, 76.752, 76.781, 76.81, 76.839, 76.868,
      76.897, 76.926, 76.955, 76.984, 77.012, 77.041, 77.07, 77.099, 77.127,
      77.156, 77.185, 77.213, 77.242, 77.27, 77.299, 77.327, 77.356, 77.384,
      77.413, 77.441, 77.47, 77.498, 77.526, 77.554, 77.583, 77.611, 77.639,
      77.666, 77.694, 77.722, 77.75, 77.778, 77.806, 77.834, 77.862, 77.89,
      77.918, 77.946, 77.974, 78.002, 78.029, 78.057, 78.085, 78.113, 78.14,
      78.168, 78.196, 78.222, 78.249, 78.277, 78.305, 78.332, 78.36, 78.387,
      78.415, 78.442, 78.47, 78.497, 78.524, 78.552, 78.579, 78.605, 78.632,
      78.659, 78.686, 78.714, 78.741, 78.768, 78.795, 78.822, 78.849, 78.876,
      78.902, 78.929, 78.956, 78.983, 79.01, 79.037, 79.064, 79.09, 79.117,
      79.144, 79.169, 79.196, 79.223, 79.25, 79.276, 79.303, 79.33, 79.356,
      79.383, 79.408, 79.434, 79.461, 79.487, 79.514, 79.54, 79.567, 79.593,
      79.62, 79.644, 79.671, 79.697, 79.723, 79.75, 79.776, 79.802, 79.828,
      79.853, 79.879, 79.905, 79.931, 79.957, 79.983, 80.01, 80.034, 80.06,
      80.086, 80.112, 80.138, 80.164, 80.19, 80.216, 80.24, 80.266, 80.291,
      80.317, 80.343, 80.369, 80.395, 80.418, 80.444, 80.47, 80.496, 80.521,
      80.547, 80.572, 80.596, 80.622, 80.647, 80.673, 80.698, 80.724, 80.749,
      80.773, 80.799, 80.824, 80.849, 80.875, 80.9, 80.925, 80.949, 80.974, 81,
      81.017, 81.025, 81.042, 81.05, 81.067, 81.075, 81.091, 81.101, 81.114,
      81.124, 81.139, 81.149, 81.163, 81.174, 81.188, 81.199, 81.212, 81.225,
      81.237, 81.25, 81.261, 81.275, 81.284, 81.298, 81.309, 81.323, 81.333,
      81.348, 81.358, 81.373, 81.382, 81.398, 81.406, 81.423, 81.431, 81.448,
      81.455, 81.471, 81.478, 81.496, 81.502, 81.521, 81.526, 81.546, 81.55,
      81.57, 81.575, 81.595, 81.599, 81.62, 81.623, 81.643, 81.645, 81.668,
      81.669, 81.693, 81.693, 81.718, 81.742, 81.766, 81.79, 81.814, 81.836,
      81.86, 81.884, 81.908, 81.932, 81.955, 81.979, 82.001, 82.025, 82.049,
      82.073, 82.096, 82.12, 82.144, 82.167, 82.189, 82.213, 82.236, 82.26,
      82.283, 82.307, 82.33, 82.354, 82.377, 82.399, 82.422, 82.446, 82.469,
      82.492, 82.516, 82.539, 82.562, 82.585, 82.607, 82.63, 82.653, 82.676,
      82.699, 82.723, 82.746, 82.769, 82.792, 82.815, 82.836, 82.859, 82.882,
      82.905, 82.928, 82.951, 82.973, 82.996, 83.019, 83.042, 83.065, 83.086,
      83.108, 83.131, 83.154, 83.176, 83.199, 83.222, 83.244, 83.267, 83.289,
      83.312, 83.334, 83.355, 83.378, 83.4, 83.422, 83.445, 83.467, 83.49,
      83.512, 83.534, 83.557, 83.579, 83.601, 83.623, 83.646, 83.668, 83.688,
      83.71, 83.732, 83.754, 83.776, 83.798, 83.82, 83.842, 83.864, 83.886,
      83.908, 83.93, 83.952, 83.974, 83.996, 84.018, 84.039, 84.061, 84.083,
      84.105, 84.126, 84.148, 84.168, 84.189, 84.211, 84.233, 84.254, 84.276,
      84.297, 84.319, 84.34, 84.362, 84.383, 84.404, 84.426, 84.447, 84.469,
      84.49, 84.511, 84.532, 84.554, 84.575, 84.596, 84.617, 84.639, 84.66,
      84.681, 84.702, 84.723, 84.744, 84.765, 84.786, 84.807, 84.828, 84.849,
      84.87, 84.891, 84.912, 84.933, 84.953, 84.974, 84.995, 85.016, 85.037,
      85.057, 85.078, 85.099, 85.12, 85.14, 85.161, 85.181, 85.202, 85.223,
      85.243, 85.264, 85.286, 85.307, 85.327, 85.347, 85.368, 85.388, 85.409,
      85.429, 85.45, 85.47, 85.49, 85.511, 85.531, 85.551, 85.571, 85.592,
      85.612, 85.632, 85.652, 85.672, 85.694, 85.714, 85.734, 85.754, 85.774,
      85.795, 85.815, 85.835, 85.854, 85.874, 85.894, 85.914, 85.934, 85.954,
      85.976, 85.996, 86.016, 86.035, 86.055, 86.075, 86.095, 86.114, 86.134,
      86.154, 86.173, 86.195, 86.215, 86.234, 86.254, 86.273, 86.293, 86.313,
      86.332, 86.352, 86.371, 86.392, 86.412, 86.431, 86.451, 86.47, 86.49,
      86.509, 86.528, 86.55, 86.569, 86.588, 86.608, 86.627, 86.646, 86.665,
      86.685, 86.706, 86.725, 86.744, 86.763, 86.782, 86.802, 86.821, 86.842,
      86.861, 86.88, 86.899, 86.918, 86.937, 86.956, 86.977, 86.996, 87.015,
      87.034, 87.053, 87.072, 87.093, 87.111, 87.13, 87.149, 87.168, 87.187,
      87.208, 87.227, 87.245, 87.264, 87.283, 87.304, 87.322, 87.341, 87.36,
      87.379, 87.397, 87.418, 87.437, 87.455, 87.474, 87.493, 87.513, 87.532,
      87.55, 87.569, 87.588, 87.608, 87.627, 87.645, 87.664, 87.682, 87.703,
      87.721, 87.74, 87.758, 87.778, 87.797, 87.815, 87.834, 87.852, 87.873,
      87.891, 87.909, 87.928, 87.948, 87.966, 87.985, 88.003, 88.021, 88.042,
      88.06, 88.078, 88.096, 88.117, 88.135, 88.153, 88.171, 88.191, 88.21,
      88.228, 88.246, 88.266, 88.284, 88.302, 88.321, 88.341, 88.359, 88.377,
      88.395, 88.415, 88.433, 88.451, 88.471, 88.489, 88.507, 88.525, 88.545,
      88.563, 88.581, 88.599, 88.619, 88.637, 88.655, 88.675, 88.693, 88.711,
      88.728, 88.748, 88.766, 88.784, 88.804, 88.822, 88.839, 88.857, 88.877,
      88.895, 88.913, 88.932, 88.95, 88.968, 88.988, 89.005, 89.023, 89.041,
      89.061, 89.078, 89.096, 89.116, 89.133, 89.151, 89.171, 89.188, 89.206,
      89.226, 89.243, 89.261, 89.28, 89.298, 89.315, 89.335, 89.353, 89.37,
      89.39, 89.407, 89.425, 89.444, 89.462, 89.479, 89.498, 89.516, 89.533,
      89.553, 89.57, 89.588, 89.607, 89.625, 89.642, 89.661, 89.679, 89.696,
      89.716, 89.733, 89.75, 89.77, 89.787, 89.804, 89.823, 89.841, 89.86,
      89.877, 89.895, 89.914, 89.931, 89.948, 89.968, 89.985, 90.002, 90.021,
      90.038, 90.058, 90.075, 90.092, 90.111, 90.128, 90.145, 90.165, 90.182,
      90.201, 90.218, 90.235, 90.254, 90.271, 90.29, 90.307, 90.324, 90.343,
      90.36, 90.379, 90.396, 90.413, 90.432, 90.449, 90.466, 90.485, 90.502,
      90.521, 90.538, 90.555, 90.574, 90.591, 90.61, 90.627, 90.646, 90.662,
      90.679, 90.698, 90.715, 90.734, 90.751, 90.768, 90.786, 90.803, 90.822,
      90.839, 90.855, 90.874, 90.891, 90.91, 90.926, 90.945, 90.962, 90.979,
      90.997, 91.014, 91.033, 91.049, 91.068, 91.085, 91.101, 91.12, 91.137,
      91.155, 91.172, 91.19, 91.207, 91.223, 91.242, 91.259, 91.277, 91.294,
      91.312, 91.329, 91.347, 91.364, 91.38, 91.399, 91.415, 91.434, 91.45,
      91.468, 91.485, 91.503, 91.52, 91.538, 91.555, 91.571, 91.589, 91.606,
      91.624, 91.64, 91.659, 91.675, 91.693, 91.71, 91.728, 91.744, 91.76,
      91.779, 91.795, 91.813, 91.829, 91.848, 91.864, 91.882, 91.898, 91.916,
      91.933, 91.951, 91.967, 91.985, 92.001, 92.019, 92.036, 92.052, 92.07,
      92.086, 92.104, 92.12, 92.138, 92.154, 92.172, 92.188, 92.206, 92.223,
      92.24, 92.256, 92.275, 92.29, 92.309, 92.324, 92.342, 92.358, 92.376,
      92.392, 92.41, 92.426, 92.444, 92.46, 92.478, 92.494, 92.512, 92.528,
      92.544, 92.561, 92.577, 92.595, 92.611, 92.629, 92.645, 92.662, 92.678,
      92.696, 92.712, 92.73, 92.745, 92.763, 92.779, 92.797, 92.812, 92.83,
      92.846, 92.864, 92.879, 92.897, 92.913, 92.93, 92.946, 92.964, 92.979,
      92.997, 93.013, 93.03, 93.048, 93.063, 93.081, 93.097, 93.114, 93.13,
      93.147, 93.163, 93.181, 93.196, 93.214, 93.229, 93.247, 93.262, 93.28,
      93.295, 93.313, 93.328, 93.346, 93.361, 93.379, 93.394, 93.412, 93.427,
      93.445, 93.46, 93.477, 93.493, 93.51, 93.526, 93.543, 93.559, 93.576,
      93.593, 93.609, 93.626, 93.641, 93.659, 93.674, 93.692, 93.707, 93.724,
      93.74, 93.757, 93.772, 93.79, 93.805, 93.822, 93.837, 93.855, 93.87,
      93.887, 93.903, 93.92, 93.937, 93.952, 93.97, 93.985, 94.002, 94.017,
      94.035, 94.05, 94.067, 94.082, 94.099, 94.115, 94.132, 94.147, 94.164,
      94.181, 94.196, 94.214, 94.229, 94.246, 94.261, 94.278, 94.293, 94.311,
      94.326, 94.343, 94.358, 94.375, 94.39, 94.407, 94.424, 94.439, 94.456,
      94.472, 94.489, 94.504, 94.521, 94.536, 94.553, 94.568, 94.585, 94.6,
      94.617, 94.634, 94.649, 94.666, 94.681, 94.698, 94.713, 94.73, 94.745,
      94.762, 94.777, 94.794, 94.811, 94.826, 94.843, 94.858, 94.875, 94.89,
      94.907, 94.922, 94.939, 94.954, 94.971, 94.988, 95.003, 95.02, 95.035,
      95.052, 95.067, 95.084, 95.099, 95.116, 95.13, 95.147, 95.164, 95.179,
      95.196, 95.211, 95.228, 95.243, 95.26, 95.275, 95.291, 95.308, 95.323,
      95.34, 95.355, 95.372, 95.387, 95.404, 95.418, 95.435, 95.452, 95.467,
      95.484, 95.499, 95.516, 95.53, 95.547, 95.562, 95.579, 95.594, 95.611,
      95.628, 95.642, 95.659, 95.674, 95.691, 95.706, 95.722, 95.737, 95.754,
      95.771, 95.786, 95.802, 95.817, 95.834, 95.849, 95.866, 95.88, 95.897,
      95.914, 95.929, 95.946, 95.96, 95.977, 95.992, 96.009, 96.025, 96.04,
      96.057, 96.072, 96.088, 96.103, 96.12, 96.135, 96.151, 96.168, 96.183,
      96.2, 96.214, 96.231, 96.246, 96.263, 96.277, 96.294, 96.311, 96.326,
      96.342, 96.357, 96.374, 96.389, 96.405, 96.422, 96.437, 96.454, 96.468,
      96.485, 96.5, 96.516, 96.531, 96.548, 96.565, 96.579, 96.596, 96.611,
      96.627, 96.642, 96.659, 96.676, 96.69, 96.707, 96.722, 96.738, 96.753,
      96.77, 96.784, 96.801, 96.818, 96.832, 96.849, 96.864, 96.88, 96.895,
      96.912, 96.928, 96.943, 96.96, 96.974, 96.991, 97.006, 97.022, 97.039,
      97.054, 97.071, 97.085, 97.102, 97.116, 97.133, 97.15, 97.164, 97.181,
      97.196, 97.212, 97.227, 97.244, 97.26, 97.275, 97.292, 97.306, 97.323,
      97.337, 97.354, 97.369, 97.385, 97.402, 97.417, 97.433, 97.448, 97.465,
      97.479, 97.496, 97.512, 97.527, 97.544, 97.558, 97.575, 97.592, 97.606,
      97.623, 97.637, 97.654, 97.669, 97.685, 97.702, 97.716, 97.733, 97.748,
      97.764, 97.779, 97.795, 97.812, 97.827, 97.843, 97.858, 97.874, 97.889,
      97.905, 97.922, 97.937, 97.953, 97.968, 97.984, 97.999, 98.016, 98.032,
      98.047, 98.063, 98.078, 98.094, 98.109, 98.126, 98.142, 98.157, 98.173,
      98.188, 98.204, 98.221, 98.235, 98.252, 98.267, 98.283, 98.298, 98.314,
      98.331, 98.345, 98.362, 98.376, 98.393, 98.41, 98.424, 98.441, 98.455,
      98.472, 98.486, 98.503, 98.519, 98.534, 98.55, 98.565, 98.582, 98.596,
      98.613, 98.629, 98.644, 98.66, 98.675, 98.691, 98.708, 98.722, 98.739,
      98.753, 98.77, 98.784, 98.801, 98.817, 98.832, 98.848, 98.863, 98.879,
      98.896, 98.91, 98.927, 98.941, 98.958, 98.975, 98.989, 99.005, 99.02,
      99.036, 99.051, 99.067, 99.084, 99.098, 99.115, 99.129, 99.146, 99.162,
      99.177, 99.193, 99.208, 99.224, 99.241, 99.255, 99.272, 99.286, 99.303,
      99.317, 99.334, 99.35, 99.365, 99.381, 99.395, 99.412, 99.429, 99.443,
      99.459, 99.474, 99.49, 99.507, 99.521, 99.538, 99.552, 99.569, 99.585,
      99.599, 99.616, 99.63, 99.647, 99.661, 99.678, 99.694, 99.709, 99.725,
      99.739, 99.756, 99.773, 99.787, 99.803, 99.818, 99.834, 99.851, 99.865,
      99.882, 99.896, 99.912, 99.929, 99.943, 99.96, 99.974, 99.99, 100.007,
      100.021, 100.038, 100.052, 100.069, 100.085, 100.099, 100.116, 100.13,
      100.147, 100.163, 100.177, 100.194, 100.208, 100.225, 100.241, 100.255,
      100.272, 100.286, 100.303, 100.319, 100.333, 100.35, 100.364, 100.381,
      100.397, 100.411, 100.428, 100.442, 100.458, 100.475, 100.489, 100.506,
      100.52, 100.536, 100.553, 100.567, 100.584, 100.598, 100.614, 100.631,
      100.645, 100.661, 100.676, 100.692, 100.708, 100.723, 100.739, 100.753,
      100.77, 100.786, 100.8, 100.817, 100.831, 100.847, 100.864, 100.878,
      100.894, 100.909, 100.925, 100.941, 100.956, 100.972, 100.986, 101.003,
      101.019, 101.033, 101.05, 101.066, 101.08, 101.097, 101.111, 101.127,
      101.144, 101.158,
    ],
  },
};
