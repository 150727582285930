import {
  COMMUNITY_WALKTHROUGH_STEPS,
  communityWalkthroughSteps,
} from './steps';
import ReactJoyride, { CallBackProps } from 'react-joyride';
import { useWalkthroughContext } from '@/context/walkthrougContext';
import { Tooltip } from '@/components/walkthrough/tooltip';
import { useTranslation } from 'react-i18next';
import { getJoyrideStyles } from '@/components/walkthrough/styles';
import { useHistory } from 'react-router';
import ROUTES from '@/routes/routes';
import { CommunityRouteState } from '../community.types';

export const CommunityWalkthrough: React.FC = () => {
  const {
    setState,
    state: { run, stepIndex },
  } = useWalkthroughContext();

  const isFinalWalkthroughStep = stepIndex === COMMUNITY_WALKTHROUGH_STEPS.NINE;

  const { t } = useTranslation();

  const history = useHistory();

  const handleCallback = (data: CallBackProps) => {
    const { index, type } = data;
    if (type === 'step:after') {
      if (index === COMMUNITY_WALKTHROUGH_STEPS.TWO) {
        setState({ stepIndex: stepIndex + 1 });
        history.push(ROUTES.COMMUNITY.ROOT, {
          activeTabIndex: 0,
        } as CommunityRouteState);
      }

      if (index === COMMUNITY_WALKTHROUGH_STEPS.FOUR) {
        setState({ stepIndex: stepIndex + 1 });
        history.push(ROUTES.COMMUNITY.ROOT, {
          activeTabIndex: 0,
        } as CommunityRouteState);
      }

      if (index === COMMUNITY_WALKTHROUGH_STEPS.FIVE) {
        setState({ stepIndex: stepIndex + 1 });
      }

      if (index === COMMUNITY_WALKTHROUGH_STEPS.SIX) {
        setState({ stepIndex: stepIndex + 1 });
      }

      if (index === COMMUNITY_WALKTHROUGH_STEPS.SEVEN) {
        setState({ stepIndex: stepIndex + 1 });
        history.push(ROUTES.COMMUNITY.ROOT, {
          activeTabIndex: 2,
        } as CommunityRouteState);
      }

      if (index === COMMUNITY_WALKTHROUGH_STEPS.EIGHT) {
        setState({ stepIndex: stepIndex + 1 });
        history.push(ROUTES.COMMUNITY.ROOT, {
          activeTabIndex: 0,
        } as CommunityRouteState);
      }

      if (index === COMMUNITY_WALKTHROUGH_STEPS.NINE) {
        setState({ stepIndex: 0, run: false });
      }
    }
  };

  return (
    <ReactJoyride
      steps={communityWalkthroughSteps.map((item) => ({
        ...item,
        content: t(item.content as string),
      }))}
      run={run}
      stepIndex={stepIndex}
      callback={handleCallback}
      continuous={true}
      tooltipComponent={({ ...props }) => (
        <Tooltip
          {...props}
          className={
            stepIndex === COMMUNITY_WALKTHROUGH_STEPS.SEVEN ? 'mt-20' : ''
          }
          pollyNeutralSteps={[...Array(8).keys()]}
          pollyImpressedSteps={[COMMUNITY_WALKTHROUGH_STEPS.NINE]}
          displayCloseButton={props.index < props.size - 1}
        />
      )}
      styles={getJoyrideStyles(
        isFinalWalkthroughStep,
        stepIndex === COMMUNITY_WALKTHROUGH_STEPS.SEVEN ||
          isFinalWalkthroughStep
      )}
    />
  );
};
