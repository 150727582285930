import { EventRecordService } from '@/services/EventRecordService';
import { EventRecordModelInput } from '@ecdlink/graphql';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState, ThunkApiType } from '../types';

export const EventRecordActions = {
  ADD_EVENT_RECORD: 'addEventRecord',
  UPDATE_EVENT_RECORD_STATUS: 'updateEventRecordStatusById',
};

export const addEventRecord = createAsyncThunk<
  any,
  {
    input: EventRecordModelInput;
    isCloseFolder?: boolean;
  },
  ThunkApiType<RootState>
>(
  EventRecordActions.ADD_EVENT_RECORD,
  async ({ input, isCloseFolder }, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        await new EventRecordService(userAuth?.auth_token).addEventRecord(
          input
        );

        if (isCloseFolder) {
          return { isCloseFolder, input };
        }

        return;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateEventRecordStatus = createAsyncThunk<
  any,
  { eventId: string; status: boolean },
  ThunkApiType<RootState>
>(
  EventRecordActions.UPDATE_EVENT_RECORD_STATUS,
  async ({ eventId, status }, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();

    try {
      if (userAuth?.auth_token) {
        await new EventRecordService(
          userAuth?.auth_token
        ).updateEventRecordStatus(eventId, status);
        return;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
