export const noneOption = 'None of the above';

export const riskOption1 = 'Not moving or does not wake up';
export const riskOption2 = 'Diarrhoea, sunken eyes and a sunken fontanelle';

export const options = [
  {
    title: riskOption1,
    id: 'dangerSignA',
  },
  {
    title: 'Shaking (convulsions)',
    id: 'dangerSignB',
  },
  {
    title: riskOption2,
    id: 'dangerSignC',
  },
  {
    title: 'Coughing and breathing fast (more than 50 breaths per minute)',
    id: 'dangerSignD',
  },
  {
    title: 'Signs of malnutrition (swollen ankles and feet)',
    id: 'dangerSignE',
  },
  {
    title: 'Vomiting everything',
    id: 'dangerSignF',
  },
  {
    title: 'Fever and not feeding',
    id: 'dangerSignG',
  },
  {
    title: 'Unable to breastfeed',
    id: 'dangerSignH',
  },
  {
    title: noneOption,
  },
];
