export const lengthPerWeek = {
  date: [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
    59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77,
    78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96,
    97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112,
    113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127,
    128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142,
    143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157,
    158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172,
    173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187,
    188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202,
    203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217,
    218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232,
    233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247,
    248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262,
    263, 264,
  ],
  median: {
    label: 'median',
    weight: [
      50.411771428571434, 51.642714285714284, 52.79725714285714,
      53.82195714285715, 54.799099999999996, 55.72645714285714,
      56.60359999999999, 57.433128571428576, 58.219342857142856,
      58.97054285714286, 59.6907, 60.37954285714286, 61.03754285714286,
      61.66578571428571, 62.26538571428572, 62.83735714285714, 63.3829,
      63.90315714285714, 64.39968571428571, 64.87467142857142, 65.3303, 65.7684,
      66.19050000000001, 66.598, 66.99248571428572, 67.37562857142858,
      67.74877142857143, 68.11298571428571, 68.4691857142857, 68.8183142857143,
      69.16124285714285, 69.49878571428572, 69.83141428571427,
      70.15971428571429, 70.48419999999999, 70.80538571428572,
      71.12339999999999, 71.43835714285714, 71.75028571428571,
      72.05927142857142, 72.36532857142858, 72.66831428571429,
      72.96825714285714, 73.26521428571428, 73.5592857142857, 73.85045714285715,
      74.13888571428572, 74.42461428571428, 74.70777142857142,
      74.98847142857143, 75.26681428571429, 75.54299999999999,
      75.81708571428571, 76.08911428571427, 76.35901428571428,
      76.62681428571429, 76.89254285714286, 77.15621428571428,
      77.41784285714286, 77.6775, 77.93518571428572, 78.19100000000002,
      78.44500000000001, 78.69724285714285, 78.94778571428571,
      79.19671428571428, 79.44404285714288, 79.68977142857143,
      79.93401428571428, 80.17672857142858, 80.418, 80.65780000000002,
      80.89608571428572, 81.13292857142856, 81.36831428571429,
      81.60224285714287, 81.83472857142858, 82.0657857142857, 82.29545714285713,
      82.52367142857143, 82.75047142857144, 82.97585714285715,
      83.19985714285716, 83.42251428571429, 83.64385714285713,
      83.86392857142857, 84.08282857142856, 84.30052857142856,
      84.51704285714287, 84.73235714285715, 84.94647142857141,
      85.15935714285715, 85.37108571428571, 85.58167142857143, 85.7911142857143,
      85.99948571428571, 86.20678571428572, 86.41307142857144, 86.6184,
      86.82275714285713, 87.02618571428572, 87.17204285714286,
      87.27204285714286, 87.37185714285714, 87.47187142857145,
      87.57067142857143, 87.67065714285715, 87.76845714285714, 87.9181,
      88.11227142857142, 88.3052857142857, 88.49715714285715, 88.68784285714287,
      88.87741428571428, 89.06585714285714, 89.2532142857143, 89.43945714285714,
      89.62464285714286, 89.80868571428572, 89.99161428571428,
      90.17342857142856, 90.3541, 90.53364285714285, 90.71204285714285,
      90.88930000000002, 91.06542857142858, 91.24042857142857,
      91.41434285714286, 91.58715714285714, 91.75892857142858,
      91.92962857142857, 92.0993, 92.26795714285717, 92.43562857142857, 92.6023,
      92.76798571428571, 92.93274285714287, 93.09654285714285,
      93.25945714285713, 93.42145714285712, 93.58258571428571, 93.7429,
      93.90241428571429, 94.06114285714285, 94.21917142857141,
      94.37652857142858, 94.53321428571428, 94.68922857142857,
      94.84465714285714, 94.99947142857143, 95.1537, 95.30738571428573,
      95.46044285714285, 95.61299999999999, 95.76497142857144,
      95.91641428571428, 96.06737142857142, 96.21780000000001,
      96.36771428571429, 96.51714285714286, 96.66605714285713,
      96.81450000000002, 96.9624857142857, 97.10997142857143, 97.25702857142858,
      97.4036, 97.54975714285715, 97.69542857142856, 97.84062857142858,
      97.98537142857143, 98.12967142857144, 98.27348571428571,
      98.41684285714287, 98.55975714285715, 98.70217142857143,
      98.84414285714286, 98.98564285714285, 99.12664285714285,
      99.26714285714284, 99.40717142857143, 99.54669999999999,
      99.68575714285714, 99.82434285714285, 99.9625, 100.10015714285713,
      100.23740000000001, 100.37418571428569, 100.51057142857142,
      100.64648571428572, 100.78201428571428, 100.9171, 101.05178571428571,
      101.18605714285715, 101.31991428571429, 101.45344285714285,
      101.5865857142857, 101.71935714285713, 101.85180000000001,
      101.98392857142856, 102.11577142857142, 102.2472857142857,
      102.37851428571427, 102.50948571428572, 102.64017142857142,
      102.77064285714286, 102.90087142857142, 103.03087142857143,
      103.16065714285715, 103.29030000000002, 103.41968571428572,
      103.54892857142856, 103.67802857142856, 103.80695714285716,
      103.93574285714286, 104.06439999999999, 104.1929285714286,
      104.32132857142858, 104.44962857142858, 104.57780000000001, 104.7059,
      104.83394285714287, 104.96188571428571, 105.08975714285715,
      105.21757142857143, 105.3453, 105.47297142857144, 105.60058571428571,
      105.72812857142856, 105.8556, 105.98299999999999, 106.11037142857143,
      106.23765714285715, 106.36488571428572, 106.49205714285715,
      106.61917142857142, 106.74621428571429, 106.8732, 107.00012857142856,
      107.12704285714287, 107.25387142857143, 107.38064285714283,
      107.50739999999999, 107.63407142857143, 107.76068571428571,
      107.88725714285715, 108.01379999999999, 108.14027142857147,
      108.26671428571429, 108.3931, 108.51942857142856, 108.64572857142856,
      108.77197142857142, 108.89815714285714, 109.02428571428571,
      109.1503857142857, 109.2764, 109.40232857142857, 109.52822857142857,
      109.65402857142858, 109.7798, 109.90547142857143, 110.03107142857142,
      110.15658571428573, 110.28204285714287, 110.40740000000001,
    ],
  },
  SD2: {
    label: '2 SD',
    weight: [
      54.207142857142856, 55.45657142857142, 56.63357142857143,
      57.68828571428572, 58.69442857142858, 59.64942857142857,
      60.551857142857145, 61.40514285714285, 62.214000000000006,
      62.98685714285714, 63.728, 64.43685714285714, 65.11414285714285,
      65.76142857142857, 66.37914285714285, 66.96771428571428,
      67.52985714285714, 68.0657142857143, 68.577, 69.06599999999999,
      69.53542857142857, 69.98714285714286, 70.42271428571429,
      70.84328571428571, 71.25142857142858, 71.64771428571429,
      72.03442857142858, 72.41242857142858, 72.78242857142857,
      73.14614285714285, 73.50357142857145, 73.85642857142857,
      74.20457142857143, 74.54857142857142, 74.88971428571428,
      75.22699999999999, 75.56242857142857, 75.89471428571427,
      76.22471428571428, 76.55142857142857, 76.87657142857142,
      77.19857142857143, 77.51757142857142, 77.83414285714287,
      78.14814285714284, 78.45957142857142, 78.76871428571428,
      79.07542857142857, 79.37971428571429, 79.68142857142857,
      79.98171428571429, 80.28, 80.57571428571428, 80.87014285714288,
      81.16285714285713, 81.45342857142857, 81.74214285714285,
      82.02928571428572, 82.31428571428572, 82.59757142857143,
      82.87885714285714, 83.15871428571428, 83.437, 83.71357142857143,
      83.98842857142857, 84.26214285714286, 84.53414285714284,
      84.80485714285714, 85.07414285714286, 85.34228571428571,
      85.60857142857142, 85.87414285714284, 86.13814285714285, 86.4007142857143,
      86.66228571428572, 86.92214285714286, 87.17999999999999,
      87.43785714285714, 87.69414285714286, 87.94885714285715, 88.202, 88.454,
      88.70542857142857, 88.95514285714286, 89.20285714285714,
      89.45071428571428, 89.696, 89.9417142857143, 90.1852857142857,
      90.42814285714283, 90.67, 90.91014285714287, 91.14942857142856,
      91.38771428571428, 91.62514285714285, 91.86114285714287,
      92.09642857142856, 92.3307142857143, 92.564, 92.796, 93.02757142857142,
      93.23485714285712, 93.35671428571429, 93.4705714285714, 93.58385714285714,
      93.69757142857144, 93.80985714285714, 93.9352857142857, 94.14242857142857,
      94.36414285714285, 94.58500000000001, 94.80414285714286, 95.0222857142857,
      95.23928571428573, 95.45528571428572, 95.66985714285715,
      95.88271428571429, 96.09571428571428, 96.30600000000001, 96.516,
      96.72385714285713, 96.932, 97.13714285714286, 97.34100000000001, 97.546,
      97.74771428571428, 97.94857142857143, 98.14800000000001,
      98.34628571428571, 98.54371428571429, 98.74000000000001,
      98.93414285714286, 99.12714285714287, 99.3202857142857, 99.5112857142857,
      99.70142857142858, 99.89042857142856, 100.079, 100.265,
      100.45128571428572, 100.63614285714287, 100.82000000000001,
      101.00271428571429, 101.18485714285715, 101.36585714285715,
      101.54585714285714, 101.72528571428572, 101.90400000000001,
      102.08214285714287, 102.25899999999999, 102.43571428571428,
      102.61114285714288, 102.78642857142857, 102.96085714285712,
      103.13428571428572, 103.30685714285713, 103.47942857142857,
      103.65128571428572, 103.82242857142856, 103.99242857142859,
      104.16214285714287, 104.33114285714286, 104.49971428571429,
      104.66757142857145, 104.83528571428573, 105.00242857142857,
      105.16842857142858, 105.33414285714285, 105.49928571428573,
      105.66357142857144, 105.82742857142857, 105.99114285714288,
      106.15328571428572, 106.31571428571428, 106.47771428571427,
      106.63871428571427, 106.79928571428572, 106.95914285714287,
      107.11857142857143, 107.27714285714285, 107.43528571428571,
      107.59328571428571, 107.74971428571429, 107.90642857142858,
      108.06257142857143, 108.21771428571428, 108.37257142857143,
      108.52671428571428, 108.68142857142857, 108.83500000000001,
      108.98785714285714, 109.14042857142856, 109.29157142857142,
      109.44314285714286, 109.59457142857141, 109.74542857142855,
      109.89585714285714, 110.04528571428571, 110.19514285714286,
      110.34457142857146, 110.49314285714286, 110.64185714285715,
      110.78957142857142, 110.93814285714286, 111.08528571428572,
      111.2332857142857, 111.38, 111.52699999999997, 111.67357142857144,
      111.8197142857143, 111.96614285714284, 112.11242857142857,
      112.25842857142858, 112.40414285714287, 112.55014285714284,
      112.69557142857143, 112.84085714285713, 112.98614285714287,
      113.13114285714286, 113.2762857142857, 113.42128571428573,
      113.56600000000002, 113.71071428571429, 113.85571428571428,
      114.00014285714288, 114.14485714285715, 114.28942857142856,
      114.43371428571427, 114.57814285714286, 114.72242857142855,
      114.86685714285714, 115.01114285714286, 115.15485714285714,
      115.29885714285714, 115.443, 115.58685714285716, 115.73071428571428,
      115.87471428571428, 116.01842857142857, 116.16199999999999,
      116.30557142857141, 116.44928571428571, 116.59257142857143,
      116.73614285714284, 116.87957142857142, 117.02271428571429,
      117.16599999999998, 117.30914285714286, 117.45257142857143,
      117.59571428571428, 117.73828571428571, 117.88185714285714,
      118.02485714285714, 118.16742857142856, 118.3102857142857,
      118.45314285714286, 118.59542857142857, 118.73828571428571,
      118.88071428571429, 119.02328571428572, 119.16571428571429,
      119.30785714285715, 119.45014285714286, 119.59257142857143,
      119.73442857142857,
    ],
  },
  SD3: {
    label: '3 SD',
    weight: [
      56.10471428571429, 57.363142857142854, 58.55171428571428,
      59.62157142857143, 60.64214285714286, 61.61042857142858,
      62.52585714285715, 63.39128571428571, 64.21114285714285,
      64.99485714285714, 65.74642857142858, 66.46571428571428, 67.1527142857143,
      67.80914285714286, 68.4357142857143, 69.03328571428571, 69.60314285714286,
      70.147, 70.66528571428572, 71.162, 71.638, 72.09657142857144,
      72.53871428571429, 72.96614285714286, 73.38071428571428,
      73.78342857142857, 74.17742857142856, 74.56171428571429, 74.9392857142857,
      75.31014285714286, 75.6747142857143, 76.03528571428572, 76.39114285714287,
      76.74285714285715, 77.09214285714286, 77.43785714285715,
      77.78171428571429, 78.12285714285713, 78.46171428571428,
      78.79757142857143, 79.13214285714285, 79.46357142857143, 79.792,
      80.11885714285714, 80.44285714285715, 80.76414285714284, 81.0837142857143,
      81.40057142857142, 81.71542857142857, 82.028, 82.33900000000001,
      82.64814285714286, 82.95528571428572, 83.26057142857144,
      83.56485714285714, 83.86671428571428, 84.16714285714285,
      84.46614285714284, 84.76257142857142, 85.05757142857144,
      85.35085714285715, 85.64257142857143, 85.93285714285715,
      86.22185714285716, 86.50885714285712, 86.7947142857143, 87.07914285714286,
      87.36257142857143, 87.64428571428572, 87.925, 88.20371428571427,
      88.48214285714286, 88.75928571428571, 89.03442857142856, 89.309,
      89.58228571428572, 89.8527142857143, 90.12385714285715, 90.39314285714286,
      90.66142857142857, 90.928, 91.19285714285715, 91.45842857142858,
      91.72128571428571, 91.98242857142857, 92.244, 92.50285714285714,
      92.76228571428571, 93.01942857142856, 93.276, 93.53157142857143,
      93.78571428571429, 94.03871428571429, 94.29085714285713,
      94.54214285714285, 94.792, 95.0412857142857, 95.289, 95.53671428571428,
      95.78285714285714, 96.02814285714287, 96.26071428571429,
      96.39800000000001, 96.52, 96.63971428571429, 96.76085714285715, 96.88,
      97.025, 97.25471428571429, 97.49014285714286, 97.72514285714286,
      97.95814285714286, 98.18971428571429, 98.42014285714288,
      98.65014285714285, 98.878, 99.10400000000001, 99.33100000000002,
      99.5547142857143, 99.77814285714287, 99.99914285714286,
      100.22071428571428, 100.43900000000001, 100.65599999999999,
      100.87442857142857, 101.08900000000001, 101.30257142857143,
      101.51457142857143, 101.72600000000001, 101.936, 102.14471428571429,
      102.35171428571428, 102.55671428571428, 102.76242857142859,
      102.96571428571428, 103.16799999999999, 103.36928571428574,
      103.56985714285715, 103.76800000000001, 103.96614285714286,
      104.16285714285712, 104.35842857142858, 104.55285714285715,
      104.74642857142858, 104.93914285714287, 105.13099999999999,
      105.32171428571428, 105.51142857142858, 105.70085714285715,
      105.88885714285713, 106.07671428571427, 106.26314285714285,
      106.44928571428572, 106.63471428571428, 106.81871428571428,
      107.00171428571427, 107.18514285714286, 107.36757142857142,
      107.54957142857144, 107.73028571428571, 107.91, 108.08957142857142,
      108.26842857142856, 108.44642857142858, 108.62428571428572,
      108.80142857142856, 108.97757142857142, 109.15342857142856,
      109.32857142857144, 109.50285714285714, 109.67657142857142,
      109.84971428571428, 110.02185714285716, 110.19414285714285,
      110.36542857142857, 110.53600000000002, 110.70614285714285,
      110.87528571428571, 111.0442857142857, 111.212, 111.37971428571429,
      111.54685714285715, 111.71257142857142, 111.87828571428572,
      112.04385714285715, 112.208, 112.37171428571428, 112.53514285714286,
      112.699, 112.86157142857144, 113.02314285714284, 113.18499999999999,
      113.34457142857141, 113.50471428571429, 113.66528571428572,
      113.82485714285714, 113.984, 114.14214285714286, 114.30071428571429,
      114.45899999999999, 114.61585714285714, 114.77342857142855,
      114.92999999999999, 115.08685714285714, 115.24285714285715,
      115.39928571428571, 115.55442857142857, 115.71014285714286,
      115.86571428571428, 116.0202857142857, 116.1752857142857, 116.33, 116.484,
      116.63828571428571, 116.79299999999999, 116.947, 117.10057142857144,
      117.25428571428573, 117.40771428571429, 117.56157142857144,
      117.71500000000002, 117.86814285714286, 118.02114285714286,
      118.17457142857143, 118.32742857142856, 118.48085714285715,
      118.63371428571428, 118.7867142857143, 118.9392857142857,
      119.09214285714286, 119.24514285714285, 119.39771428571429,
      119.54971428571427, 119.70228571428571, 119.85471428571428,
      120.00728571428571, 120.15971428571429, 120.312, 120.46385714285714,
      120.61628571428572, 120.76785714285714, 120.92014285714286,
      121.07185714285713, 121.22399999999998, 121.37571428571428,
      121.52714285714286, 121.67871428571428, 121.83028571428572,
      121.98228571428571, 122.13385714285714, 122.28485714285715,
      122.43657142857141, 122.588, 122.73885714285714, 122.89014285714286,
      123.04142857142858, 123.19228571428573, 123.3432857142857,
      123.4937142857143, 123.64499999999998, 123.79585714285714,
      123.94642857142857, 124.09714285714287, 124.24757142857143,
      124.39771428571429,
    ],
  },
  SD3neg: {
    label: '-3 SD',
    weight: [
      44.71885714285714, 45.922142857142866, 47.04271428571428,
      48.02242857142858, 48.956, 49.84242857142857, 50.68114285714286,
      51.47528571428571, 52.22714285714286, 52.946285714285715,
      53.63485714285715, 54.293142857142854, 54.92257142857143,
      55.52214285714285, 56.094857142857144, 56.641571428571424,
      57.16242857142857, 57.659285714285716, 58.133857142857146,
      58.58757142857143, 59.02228571428571, 59.440285714285714,
      59.84214285714285, 60.230000000000004, 60.60428571428571,
      60.967571428571425, 61.320142857142855, 61.66414285714286,
      61.999142857142864, 62.32657142857142, 62.647571428571425,
      62.96242857142857, 63.27142857142858, 63.576428571428565,
      63.87642857142857, 64.17285714285714, 64.46485714285714,
      64.75399999999999, 65.03871428571428, 65.32085714285714,
      65.59857142857143, 65.873, 66.14442857142856, 66.41157142857142,
      66.67585714285714, 66.93671428571429, 67.194, 67.44871428571427,
      67.70014285714286, 67.94871428571429, 68.19471428571428, 68.438, 68.679,
      68.91757142857143, 69.153, 69.38685714285715, 69.61785714285715,
      69.84657142857142, 70.07314285714287, 70.29742857142858,
      70.51957142857144, 70.73928571428573, 70.95700000000001,
      71.17257142857143, 71.38685714285714, 71.59857142857143,
      71.80885714285715, 72.01700000000001, 72.22342857142857,
      72.42842857142857, 72.63199999999999, 72.83342857142857, 73.033,
      73.23142857142857, 73.42757142857143, 73.62228571428571,
      73.81657142857141, 74.0077142857143, 74.19785714285715, 74.38585714285713,
      74.573, 74.75871428571428, 74.94128571428571, 75.12385714285713,
      75.30542857142858, 75.48371428571429, 75.66271428571429,
      75.83885714285714, 76.01471428571428, 76.189, 76.36128571428571, 76.533,
      76.70342857142857, 76.87257142857142, 77.03999999999999,
      77.20700000000001, 77.372, 77.5367142857143, 77.70014285714285,
      77.86271428571429, 77.98671428571427, 78.06542857142857,
      78.14571428571428, 78.22314285714286, 78.30314285714284,
      78.38114285714286, 78.45942857142857, 78.53757142857141, 78.6147142857143,
      78.73457142857141, 78.88585714285715, 79.03614285714286, 79.186,
      79.33457142857142, 79.48157142857143, 79.62842857142857, 79.775,
      79.9182857142857, 80.06271428571429, 80.20514285714285, 80.34771428571428,
      80.48742857142858, 80.628, 80.768, 80.90414285714287, 81.04185714285714,
      81.17842857142855, 81.314, 81.44842857142858, 81.582, 81.71442857142857,
      81.847, 81.97914285714286, 82.1087142857143, 82.23885714285713,
      82.36771428571429, 82.49585714285715, 82.623, 82.751, 82.8767142857143,
      83.00257142857141, 83.12742857142857, 83.25185714285715,
      83.37557142857143, 83.49928571428572, 83.62171428571428,
      83.74457142857143, 83.86699999999999, 83.98842857142857,
      84.11014285714285, 84.23071428571428, 84.35157142857143,
      84.47157142857142, 84.59142857142857, 84.71114285714286,
      84.83085714285714, 84.94942857142857, 85.06785714285714,
      85.18585714285715, 85.30414285714285, 85.422, 85.53942857142859,
      85.65657142857144, 85.77342857142857, 85.88971428571428,
      86.00557142857144, 86.1217142857143, 86.23757142857143, 86.35271428571427,
      86.46814285714287, 86.58285714285714, 86.69714285714284,
      86.81185714285714, 86.92542857142857, 87.039, 87.15242857142857,
      87.26514285714286, 87.37800000000001, 87.48985714285713,
      87.60228571428571, 87.71371428571429, 87.82485714285713, 87.936,
      88.04671428571429, 88.15642857142856, 88.26685714285713,
      88.37657142857144, 88.486, 88.59385714285715, 88.70214285714287,
      88.81085714285713, 88.91871428571429, 89.02771428571428,
      89.13514285714287, 89.24171428571428, 89.34814285714286,
      89.45457142857141, 89.56157142857141, 89.66714285714285, 89.7727142857143,
      89.87842857142857, 89.98342857142858, 90.08928571428571,
      90.19314285714286, 90.29842857142856, 90.40242857142857,
      90.50728571428571, 90.61114285714287, 90.7147142857143, 90.81928571428573,
      90.92257142857143, 91.026, 91.12985714285715, 91.23328571428571,
      91.33571428571427, 91.43914285714287, 91.54214285714286,
      91.64471428571429, 91.74771428571428, 91.85028571428572,
      91.95285714285714, 92.05585714285714, 92.15828571428571,
      92.26042857142859, 92.36314285714286, 92.465, 92.56728571428572,
      92.6697142857143, 92.772, 92.874, 92.97557142857143, 93.07757142857143,
      93.17985714285716, 93.28171428571429, 93.38342857142857,
      93.48528571428571, 93.58671428571428, 93.68828571428571,
      93.79014285714285, 93.89171428571429, 93.9932857142857, 94.09471428571429,
      94.19614285714286, 94.29742857142857, 94.39885714285715,
      94.50028571428572, 94.60171428571428, 94.70299999999999,
      94.80399999999999, 94.905, 95.00657142857142, 95.10728571428571,
      95.20828571428571, 95.30957142857143, 95.41057142857142,
      95.51142857142857, 95.61271428571429, 95.71328571428572,
      95.81414285714287, 95.91471428571428, 96.015, 96.11571428571428,
      96.21600000000001, 96.31642857142857, 96.41714285714286,
    ],
  },
  SD2neg: {
    label: '-2 SD',
    weight: [
      46.61642857142857, 47.82885714285714, 48.961, 49.955714285714286,
      50.90357142857143, 51.80371428571429, 52.65528571428571,
      53.461000000000006, 54.22485714285714, 54.95428571428572,
      55.65328571428571, 56.32214285714286, 56.96071428571428, 57.57,
      58.15185714285714, 58.706857142857146, 59.236000000000004,
      59.74057142857144, 60.22271428571429, 60.68342857142857, 61.125,
      61.54957142857142, 61.95814285714285, 62.35271428571429,
      62.73371428571429, 63.103571428571435, 63.46314285714286,
      63.813857142857145, 64.15585714285713, 64.49042857142857,
      64.81899999999999, 65.14114285714287, 65.45828571428572, 65.771, 66.079,
      66.38357142857144, 66.68457142857143, 66.982, 67.276, 67.56714285714285,
      67.854, 68.13814285714287, 68.419, 68.69614285714286, 68.97042857142857,
      69.24142857142856, 69.509, 69.77385714285715, 70.036, 70.29542857142856,
      70.552, 70.80628571428572, 71.05814285714287, 71.30828571428572,
      71.55528571428572, 71.80014285714284, 72.04285714285713, 72.283,
      72.52142857142857, 72.75757142857142, 72.99128571428571,
      73.22328571428571, 73.453, 73.68071428571429, 73.90714285714286,
      74.13128571428571, 74.354, 74.57457142857142, 74.79371428571429,
      75.01128571428572, 75.22714285714285, 75.44157142857144, 75.654,
      75.86528571428572, 76.07457142857143, 76.28214285714284,
      76.48928571428571, 76.69385714285714, 76.897, 77.09857142857143,
      77.29871428571428, 77.49785714285714, 77.69428571428571, 77.89,
      78.08485714285715, 78.27728571428572, 78.46957142857143,
      78.65942857142859, 78.8487142857143, 79.03671428571428, 79.22299999999998,
      79.40842857142856, 79.59271428571428, 79.77557142857142, 79.957, 80.138,
      80.31728571428572, 80.49542857142858, 80.67271428571428, 80.8492857142857,
      81.00814285714286, 81.10157142857143, 81.18714285714286,
      81.27342857142857, 81.35928571428572, 81.44457142857142,
      81.53014285714286, 81.61428571428571, 81.707, 81.86057142857143,
      82.02542857142858, 82.18985714285714, 82.35314285714286,
      82.51557142857143, 82.67628571428573, 82.83685714285714, 82.9962857142857,
      83.15371428571429, 83.31128571428573, 83.46714285714286,
      83.62314285714287, 83.776, 83.92999999999999, 84.08285714285715,
      84.23257142857143, 84.38300000000001, 84.53242857142858,
      84.68085714285714, 84.828, 84.97428571428573, 85.11942857142857,
      85.26457142857144, 85.40871428571428, 85.55114285714285,
      85.69314285714287, 85.83442857142857, 85.975, 86.11428571428573,
      86.25385714285714, 86.39157142857144, 86.52914285714284,
      86.66600000000001, 86.80214285714285, 86.93757142857143,
      87.07257142857144, 87.20685714285715, 87.34085714285716,
      87.47457142857142, 87.60728571428571, 87.73985714285713,
      87.87171428571428, 88.00357142857145, 88.13442857142857,
      88.26528571428571, 88.39585714285715, 88.52585714285715,
      88.65557142857143, 88.78442857142856, 88.91314285714284,
      89.04171428571428, 89.17014285714285, 89.29785714285713,
      89.42528571428572, 89.55214285714285, 89.67885714285714,
      89.80499999999999, 89.93114285714285, 90.05671428571428, 90.182, 90.307,
      90.43142857142857, 90.55585714285714, 90.68014285714287,
      90.80342857142857, 90.92671428571428, 91.04957142857143, 91.172,
      91.2942857142857, 91.4157142857143, 91.53728571428572, 91.65814285714286,
      91.77828571428572, 91.89885714285715, 92.0185714285714, 92.13742857142857,
      92.25699999999998, 92.37542857142857, 92.49428571428571,
      92.61142857142859, 92.72900000000001, 92.84642857142858,
      92.96314285714286, 93.08042857142857, 93.19671428571428,
      93.31214285714285, 93.42771428571429, 93.54285714285713,
      93.65828571428573, 93.77285714285713, 93.88700000000001,
      94.00157142857142, 94.11514285714286, 94.22928571428572,
      94.34228571428572, 94.45585714285714, 94.56857142857142,
      94.68157142857142, 94.794, 94.90642857142856, 95.01942857142856,
      95.13128571428571, 95.24342857142857, 95.35557142857144,
      95.46728571428571, 95.5787142857143, 95.69014285714286, 95.80185714285717,
      95.91314285714286, 96.02457142857143, 96.13542857142856,
      96.24642857142858, 96.3577142857143, 96.46885714285715, 96.57957142857143,
      96.69057142857143, 96.80100000000002, 96.9117142857143, 97.02242857142858,
      97.13300000000001, 97.24371428571429, 97.35371428571429,
      97.46428571428571, 97.57500000000002, 97.68514285714286,
      97.79542857142857, 97.90542857142857, 98.01557142857143,
      98.12557142857143, 98.23571428571428, 98.34557142857143,
      98.45585714285714, 98.56557142857143, 98.67557142857142,
      98.78514285714286, 98.89485714285716, 99.00485714285715,
      99.11442857142858, 99.22428571428573, 99.3337142857143, 99.44314285714286,
      99.55300000000001, 99.66214285714285, 99.77157142857143,
      99.88128571428571, 99.99042857142857, 100.09971428571428,
      100.20899999999999, 100.31814285714287, 100.42742857142858,
      100.53642857142856, 100.6452857142857, 100.75414285714284,
      100.86285714285715, 100.97171428571428, 101.08057142857142,
    ],
  },
};
