import { EnhancedStore } from '@reduxjs/toolkit';
import { Message } from '@models/messages/messages';
import { RootState } from '@store/types';
import {
  NotificationIntervals,
  NotificationValidator,
} from '../../NotificationService.types';
import { addDays } from 'date-fns';
import ROUTES from '@/routes/routes';

export class VisitsNotCompletedNotificationValidator
  implements NotificationValidator
{
  interval: NotificationIntervals;
  lastCheckTimestamp: number;
  store: EnhancedStore<RootState, any>;

  constructor(store: EnhancedStore<RootState, any>) {
    this.store = store;
    this.interval = NotificationIntervals.hour;
    this.lastCheckTimestamp = 0;
  }

  getNotifications = (): Message[] => {
    const { visits: visitData, user: userData } = this.store.getState();

    const userCreated = new Date(userData?.user?.insertedDate || new Date());
    const twoWeeksAgo = addDays(new Date(), -14);
    const lastCompletedVisit =
      new Date(visitData?.visitStatus?.lastCompletedVisit!) || undefined;
    const motherOverDueVisits =
      visitData?.visitStatus?.motherOverDueVisits || 0;

    const notifications: Message[] = [];

    if (userCreated.getTime() < twoWeeksAgo.getTime()) {
      if (
        lastCompletedVisit === undefined ||
        lastCompletedVisit.getTime() < twoWeeksAgo.getTime()
      ) {
        notifications.push({
          reference: `healthcareworker-visits`,
          title: 'Visit your clients every week',
          message:
            'Other CHWs visit clients every week - join them and visit your clients often!',
          dateCreated: new Date().toISOString(),
          priority: 4,
          viewOnDashboard: true,
          area: 'practitioner',
          icon: 'SwitchVerticalIcon',
          color: 'primary',
          actionText: 'Visit clients',
          cta: 'VisitClients',
          viewType: 'Both',
          routeConfig: {
            route: ROUTES.CLIENTS.ROOT,
          },
        });
      }
    }
    if (motherOverDueVisits > 0) {
      notifications.push({
        reference: `motherOverdue-visits`,
        title: 'Visits overdue for pregnant clients',
        message: 'Schedule a visit as soon as possible.',
        dateCreated: new Date().toISOString(),
        priority: 21,
        viewOnDashboard: false,
        area: 'practitioner',
        icon: 'SwitchVerticalIcon',
        color: 'primary',
        actionText: 'Visit clients',
        cta: 'VisitMothers',
        viewType: 'Messages',
        routeConfig: {
          route: ROUTES.CLIENTS.VISIT_TAB.PREGNANCY_VISITS,
        },
      });

      notifications.push({
        reference: `motherOverdue-visits`,
        title: `${motherOverDueVisits} visits overdue`,
        message:
          'Schedule these visits with your pregnant clients as soon as possible.',
        dateCreated: new Date().toISOString(),
        priority: 21,
        viewOnDashboard: true,
        area: 'practitioner',
        icon: 'SwitchVerticalIcon',
        color: 'primary',
        actionText: 'See clients',
        cta: 'VisitMothers',
        viewType: 'Hub',
        routeConfig: {
          route: ROUTES.CLIENTS.VISIT_TAB.PREGNANCY_VISITS,
        },
      });
    }
    return notifications;
  };
}
