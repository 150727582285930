export const weightPerYear = {
  date: [0, 1, 2, 3, 4],
  median: {
    label: 'median',
    weight: [3.2322, 8.9462, 11.4741, 13.8456, 16.0638],
  },
  SD2: {
    label: '2 SD',
    weight: [4.23, 11.506, 14.841, 18.131, 21.499],
  },
  SD3: {
    label: '3 SD',
    weight: [4.793, 13.114, 17.008, 20.942, 25.159],
  },
  SD3neg: {
    label: '-3 SD',
    weight: [2.033, 6.273, 8.064, 9.608, 10.879],
  },
  SD2neg: {
    label: '-2 SD',
    weight: [2.395, 7.041, 9.033, 10.802, 12.319],
  },
};
