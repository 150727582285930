export const lengthPerWeek = {
  date: [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
    59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77,
    78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96,
    97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112,
    113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127,
    128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142,
    143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157,
    158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172,
    173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187,
    188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202,
    203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217,
    218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232,
    233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247,
    248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262,
    263, 264,
  ],
  median: {
    label: 'median',
    weight: [
      49.654314285714285, 50.836457142857135, 51.92561428571429, 52.8641,
      53.75517142857143, 54.59994285714286, 55.40035714285714,
      56.15927142857144, 56.87920000000001, 57.564657142857136,
      58.219371428571435, 58.84544285714286, 59.444942857142856,
      60.01994285714285, 60.572542857142864, 61.10385714285714,
      61.614842857142854, 62.10635714285714, 62.57925714285714,
      63.03478571428572, 63.47435714285714, 63.89951428571429,
      64.31157142857144, 64.71201428571429, 65.10222857142857,
      65.48338571428572, 65.85652857142857, 66.22251428571428,
      66.58204285714285, 66.93565714285714, 67.28398571428572,
      67.62750000000001, 67.96652857142857, 68.30141428571429,
      68.63235714285715, 68.95967142857144, 69.28347142857143,
      69.60398571428571, 69.92135714285715, 70.23571428571428,
      70.54725714285713, 70.85601428571428, 71.16211428571428,
      71.46551428571429, 71.76632857142856, 72.06462857142857,
      72.36037142857143, 72.6537, 72.94460000000001, 73.23314285714287,
      73.51937142857142, 73.80334285714285, 74.08511428571428, 74.3646857142857,
      74.64214285714284, 74.9174857142857, 75.19075714285714, 75.46201428571429,
      75.73124285714286, 75.99849999999999, 76.26379999999999,
      76.52714285714286, 76.78864285714285, 77.04830000000001,
      77.30614285714286, 77.56225714285713, 77.81664285714285,
      78.06934285714286, 78.32047142857142, 78.56994285714286,
      78.81781428571428, 79.06414285714285, 79.30888571428571,
      79.55207142857144, 79.79374285714286, 80.03392857142856,
      80.27262857142857, 80.50985714285714, 80.74565714285714,
      80.98005714285715, 81.21307142857142, 81.44477142857143,
      81.67511428571427, 81.90412857142857, 82.13184285714286,
      82.35828571428571, 82.58345714285714, 82.80737142857141,
      83.03004285714285, 83.2515, 83.47165714285714, 83.69065714285713,
      83.90842857142857, 84.12495714285714, 84.34027142857144,
      84.55441428571429, 84.76734285714285, 84.97908571428572,
      85.18967142857143, 85.39914285714285, 85.60745714285716,
      85.76547142857143, 85.86612857142858, 85.9701, 86.07010000000001,
      86.17332857142857, 86.27332857142858, 86.37551428571429,
      86.53525714285715, 86.73404285714285, 86.93181428571428, 87.1285857142857,
      87.32435714285714, 87.5191142857143, 87.71287142857145, 87.9056,
      88.09731428571429, 88.28802857142857, 88.47767142857144,
      88.66632857142858, 88.85395714285713, 89.04058571428573,
      89.22621428571428, 89.4108857142857, 89.59455714285714, 89.77725714285715,
      89.95899999999999, 90.1398, 90.31964285714285, 90.49854285714287, 90.6765,
      90.85354285714286, 91.0297, 91.20497142857143, 91.37938571428572,
      91.55294285714287, 91.72567142857142, 91.8976, 92.06875714285715,
      92.23915714285714, 92.40878571428571, 92.57768571428572,
      92.74591428571429, 92.91344285714285, 93.08027142857144,
      93.24645714285714, 93.41197142857143, 93.57685714285712,
      93.74111428571427, 93.90474285714285, 94.06781428571428,
      94.23030000000001, 94.3922, 94.55357142857143, 94.71434285714285,
      94.87462857142859, 95.03437142857142, 95.19358571428572, 95.3523142857143,
      95.5105142857143, 95.66818571428571, 95.82537142857143, 95.98207142857142,
      96.13827142857143, 96.29397142857142, 96.44915714285715,
      96.60390000000002, 96.75814285714286, 96.91191428571429, 97.0652142857143,
      97.21804285714288, 97.37041428571429, 97.52232857142857,
      97.67377142857144, 97.82474285714287, 97.97527142857143, 98.1253,
      98.27491428571429, 98.42404285714285, 98.57270000000001, 98.7209142857143,
      98.86864285714285, 99.01589999999999, 99.16272857142857,
      99.30908571428571, 99.455, 99.60048571428571, 99.74548571428572,
      99.89007142857143, 100.03421428571428, 100.17790000000001,
      100.32114285714285, 100.46394285714284, 100.6063, 100.74821428571428,
      100.88968571428572, 101.03077142857141, 101.17141428571428,
      101.31165714285716, 101.45147142857142, 101.59087142857142,
      101.72987142857143, 101.86847142857143, 102.00668571428571,
      102.14454285714284, 102.282, 102.41911428571429, 102.55587142857145,
      102.69224285714286, 102.8282857142857, 102.96395714285714, 103.0993,
      103.23431428571428, 103.36898571428573, 103.50334285714284,
      103.63737142857144, 103.77107142857143, 103.90445714285714,
      104.03755714285715, 104.17031428571428, 104.3028, 104.43495714285714,
      104.56682857142857, 104.69839999999999, 104.8297, 104.96068571428573,
      105.09137142857142, 105.2218, 105.35194285714284, 105.48180000000002,
      105.6113714285714, 105.74062857142859, 105.86959999999999,
      105.99832857142857, 106.12675714285714, 106.25488571428572,
      106.38275714285714, 106.5103, 106.63761428571429, 106.76462857142856,
      106.89135714285715, 107.01778571428572, 107.14394285714286,
      107.26981428571428, 107.39540000000001, 107.5207, 107.64571428571428,
      107.77045714285715, 107.8949142857143, 108.01907142857142,
      108.14297142857143, 108.2666, 108.3899, 108.513, 108.63581428571429,
      108.75834285714288, 108.8806, 109.00260000000002, 109.12434285714286,
      109.24582857142858, 109.36704285714286, 109.48801428571429,
      109.60874285714284, 109.72919999999999, 109.84942857142856,
    ],
  },
  SD2: {
    label: '2 SD',
    weight: [
      53.39785714285714, 54.62014285714286, 55.750714285714295,
      56.733285714285714, 57.66685714285715, 58.55228571428571,
      59.39142857142857, 60.18714285714286, 60.94257142857142,
      61.66171428571429, 62.34928571428572, 63.00657142857143,
      63.63642857142857, 64.24085714285715, 64.82157142857143,
      65.38071428571428, 65.91799999999999, 66.436, 66.934, 67.41414285714286,
      67.87785714285714, 68.32657142857143, 68.76157142857143,
      69.18442857142857, 69.59771428571429, 70.00142857142858,
      70.39685714285713, 70.78542857142857, 71.16742857142857,
      71.54328571428572, 71.91457142857142, 72.28014285714286,
      72.64271428571429, 73.00071428571428, 73.35428571428572,
      73.70442857142858, 74.05171428571428, 74.39528571428572,
      74.73657142857142, 75.07428571428571, 75.40985714285715,
      75.74228571428571, 76.07242857142856, 76.39985714285716,
      76.72514285714286, 77.04757142857143, 77.36785714285713,
      77.68585714285713, 78.00157142857142, 78.31471428571429,
      78.62557142857143, 78.93457142857143, 79.24128571428572,
      79.54599999999998, 79.84828571428571, 80.14885714285715,
      80.44728571428571, 80.74371428571429, 81.03828571428572,
      81.33071428571428, 81.6212857142857, 81.91, 82.19657142857143,
      82.48171428571428, 82.76471428571429, 83.04599999999998,
      83.32557142857142, 83.604, 83.88028571428572, 84.15485714285715,
      84.42814285714284, 84.69985714285714, 84.96942857142858,
      85.23799999999999, 85.50471428571429, 85.77028571428572,
      86.03414285714288, 86.29642857142856, 86.55742857142856,
      86.81685714285716, 87.07528571428573, 87.33200000000001,
      87.58757142857142, 87.84128571428573, 88.09385714285713,
      88.34571428571428, 88.59585714285716, 88.84485714285714, 89.092,
      89.33814285714286, 89.58314285714287, 89.82714285714287,
      90.06985714285713, 90.31085714285715, 90.55085714285714,
      90.78971428571427, 91.02728571428572, 91.26357142857144, 91.4987142857143,
      91.73214285714288, 91.96499999999999, 92.174, 92.297, 92.41085714285714,
      92.52485714285716, 92.63871428571429, 92.75157142857144,
      92.87685714285715, 93.0857142857143, 93.30828571428572, 93.52985714285715,
      93.75014285714285, 93.96971428571429, 94.18828571428571,
      94.40557142857142, 94.62157142857144, 94.83671428571428, 95.051,
      95.26400000000001, 95.47571428571429, 95.68642857142858,
      95.89614285714286, 96.10485714285713, 96.31214285714285,
      96.51871428571428, 96.724, 96.92857142857142, 97.13242857142858,
      97.3342857142857, 97.53571428571429, 97.736, 97.93514285714286,
      98.13385714285714, 98.33114285714285, 98.5275714285714, 98.72342857142858,
      98.91742857142857, 99.11171428571427, 99.30442857142857,
      99.49671428571428, 99.68757142857145, 99.87814285714286,
      100.06757142857143, 100.25642857142859, 100.44471428571428,
      100.63199999999999, 100.81842857142858, 101.00471428571429,
      101.18971428571429, 101.37428571428572, 101.55814285714284,
      101.74142857142856, 101.92414285714285, 102.1062857142857,
      102.28771428571427, 102.46828571428571, 102.64857142857144,
      102.82814285714286, 103.00714285714285, 103.18585714285715,
      103.3637142857143, 103.54100000000001, 103.71771428571428,
      103.89442857142856, 104.07014285714286, 104.24485714285713,
      104.41985714285714, 104.59385714285715, 104.76714285714286,
      104.94057142857143, 105.1127142857143, 105.28528571428573,
      105.45628571428571, 105.62742857142858, 105.79814285714285,
      105.96742857142856, 106.13699999999999, 106.30657142857144,
      106.47442857142859, 106.64257142857142, 106.80957142857143,
      106.97628571428572, 107.1437142857143, 107.30942857142857,
      107.47485714285715, 107.63971428571429, 107.80428571428571, 107.968,
      108.13128571428571, 108.29371428571429, 108.456, 108.6182857142857,
      108.77942857142857, 108.94142857142857, 109.10114285714285,
      109.26099999999998, 109.421, 109.58028571428572, 109.73842857142859,
      109.89685714285714, 110.0547142857143, 110.21171428571428,
      110.36885714285714, 110.52514285714285, 110.68114285714286,
      110.83657142857142, 110.99214285714285, 111.14642857142859,
      111.30142857142857, 111.45514285714286, 111.609, 111.76257142857142,
      111.91471428571427, 112.06771428571429, 112.21985714285714,
      112.37128571428572, 112.52285714285713, 112.67399999999998,
      112.82485714285714, 112.97500000000001, 113.12528571428571,
      113.27485714285716, 113.42428571428572, 113.57357142857143,
      113.72228571428572, 113.87057142857144, 114.01871428571428,
      114.16642857142857, 114.31414285714287, 114.46128571428572,
      114.60828571428571, 114.75485714285715, 114.90057142857144,
      115.04657142857143, 115.19228571428572, 115.338, 115.4827142857143,
      115.62757142857141, 115.77185714285713, 115.91614285714284,
      116.05971428571429, 116.20328571428573, 116.34642857142858,
      116.48914285714285, 116.63157142857143, 116.77385714285717,
      116.91585714285713, 117.05771428571428, 117.19885714285715,
      117.33985714285713, 117.48028571428571, 117.62100000000002,
      117.76128571428572, 117.9012857142857, 118.04057142857143,
      118.17985714285714, 118.31842857142858, 118.4572857142857,
      118.59571428571428, 118.73414285714286, 118.87157142857144,
      119.00914285714285, 119.14657142857143, 119.28357142857142,
      119.42028571428571,
    ],
  },
  SD3: {
    label: '3 SD',
    weight: [
      55.26942857142858, 56.512142857142855, 57.66342857142856, 58.668,
      59.62271428571429, 60.52842857142856, 61.386714285714284,
      62.201428571428565, 62.97428571428572, 63.71014285714286, 64.414,
      65.08742857142857, 65.73228571428571, 66.35099999999998,
      66.94642857142857, 67.5192857142857, 68.06942857142857, 68.60085714285714,
      69.1112857142857, 69.60357142857143, 70.07985714285714, 70.53971428571428,
      70.98657142857142, 71.42085714285714, 71.84542857142857,
      72.26042857142856, 72.667, 73.06671428571428, 73.46028571428573,
      73.84714285714286, 74.2297142857143, 74.60671428571428, 74.98085714285715,
      75.35000000000001, 75.71514285714285, 76.07657142857143,
      76.43557142857142, 76.79099999999998, 77.14414285714285,
      77.49357142857141, 77.84085714285713, 78.18557142857142,
      78.52757142857142, 78.867, 79.2047142857143, 79.53942857142859,
      79.8717142857143, 80.202, 80.53, 80.85542857142858, 81.17842857142857,
      81.50014285714285, 81.81928571428571, 82.13642857142857,
      82.45171428571429, 82.76457142857143, 83.07557142857142,
      83.38471428571428, 83.69185714285713, 83.99657142857143,
      84.29985714285715, 84.60157142857143, 84.90057142857142,
      85.19842857142858, 85.49385714285714, 85.78771428571429,
      86.08014285714286, 86.37142857142855, 86.65985714285715,
      86.94728571428573, 87.23328571428571, 87.51742857142857, 87.8, 88.081,
      88.36042857142856, 88.63857142857144, 88.91485714285716,
      89.18985714285714, 89.46342857142858, 89.73528571428571,
      90.00642857142857, 90.2752857142857, 90.54342857142856, 90.80985714285713,
      91.07499999999997, 91.33899999999998, 91.60214285714285,
      91.86328571428571, 92.123, 92.3817142857143, 92.63900000000001,
      92.8952857142857, 93.1502857142857, 93.40357142857144, 93.65614285714285,
      93.90742857142857, 94.15714285714286, 94.40557142857142, 94.653,
      94.89857142857144, 95.14357142857143, 95.37414285714284,
      95.51114285714287, 95.63228571428571, 95.7517142857143, 95.87114285714287,
      95.99114285714286, 96.13271428571429, 96.36099999999999,
      96.59542857142857, 96.829, 97.061, 97.29242857142856, 97.52285714285713,
      97.75185714285715, 97.97957142857145, 98.20657142857144, 98.432,
      98.65700000000001, 98.88014285714284, 99.10242857142858,
      99.32385714285714, 99.54414285714286, 99.76271428571428,
      99.98085714285715, 100.19757142857142, 100.4132857142857,
      100.62857142857145, 100.84171428571429, 101.05414285714285,
      101.26599999999999, 101.47585714285712, 101.68557142857144,
      101.89428571428572, 102.10185714285714, 102.30871428571429,
      102.51357142857144, 102.71857142857142, 102.92214285714286, 103.125,
      103.32685714285715, 103.52828571428573, 103.72842857142857,
      103.92800000000001, 104.127, 104.32442857142857, 104.5217142857143,
      104.71871428571428, 104.91400000000002, 105.10871428571429,
      105.30328571428572, 105.49714285714285, 105.69014285714285,
      105.88257142857142, 106.07442857142858, 106.2652857142857,
      106.45557142857145, 106.6452857142857, 106.83457142857144,
      107.02342857142857, 107.21157142857143, 107.39857142857143,
      107.58557142857144, 107.77214285714285, 107.95785714285716,
      108.14285714285714, 108.32757142857145, 108.51171428571426,
      108.69457142857144, 108.87842857142859, 109.05999999999999,
      109.2427142857143, 109.42314285714285, 109.60428571428574,
      109.78471428571429, 109.96371428571429, 110.14328571428572,
      110.32228571428571, 110.49985714285715, 110.67728571428572,
      110.85385714285714, 111.03042857142859, 111.20757142857144,
      111.38271428571429, 111.55757142857144, 111.73185714285715,
      111.90600000000002, 112.07928571428572, 112.25185714285715,
      112.42328571428571, 112.59514285714285, 112.76685714285715,
      112.93771428571428, 113.10842857142858, 113.27814285714285,
      113.44642857142857, 113.616, 113.78457142857143, 113.95185714285715,
      114.11957142857145, 114.2867142857143, 114.45242857142857,
      114.61914285714286, 114.78428571428572, 114.94957142857143,
      115.1137142857143, 115.27885714285716, 115.44185714285713,
      115.60614285714287, 115.76814285714285, 115.93128571428569,
      116.0937142857143, 116.25514285714289, 116.417, 116.578,
      116.73828571428571, 116.89885714285715, 117.05871428571429,
      117.21857142857142, 117.37757142857143, 117.53657142857142,
      117.69485714285715, 117.85285714285715, 118.011, 118.16857142857143,
      118.32571428571428, 118.4822857142857, 118.63928571428572,
      118.79528571428571, 118.95128571428572, 119.10671428571428,
      119.26199999999997, 119.41657142857143, 119.57128571428572,
      119.72528571428572, 119.87928571428571, 120.03271428571429,
      120.18614285714285, 120.33899999999998, 120.492, 120.64342857142856,
      120.79599999999998, 120.94771428571428, 121.09885714285714,
      121.24971428571429, 121.40057142857144, 121.551, 121.70142857142856,
      121.85085714285712, 122.00028571428574, 122.14914285714285,
      122.29842857142857, 122.447, 122.59557142857143, 122.74285714285715,
      122.89071428571431, 123.03785714285716, 123.18499999999999,
      123.33171428571428, 123.478, 123.624, 123.76957142857144,
      123.91542857142856, 124.06057142857142, 124.206,
    ],
  },
  SD3neg: {
    label: '-3 SD',
    weight: [
      44.03928571428571, 45.16085714285715, 46.18785714285714,
      47.060142857142864, 47.887571428571434, 48.67157142857143,
      49.41371428571428, 50.11728571428572, 50.784, 51.41885714285714,
      52.02485714285714, 52.60357142857141, 53.157714285714285,
      53.68885714285714, 54.19871428571428, 54.68857142857143,
      55.16014285714285, 55.611714285714285, 56.047000000000004, 56.466,
      56.869142857142855, 57.25914285714286, 57.636428571428574,
      58.00342857142857, 58.35928571428571, 58.706285714285706,
      59.04614285714286, 59.37842857142857, 59.70385714285714,
      60.02428571428572, 60.33828571428571, 60.64842857142857,
      60.952571428571424, 61.252571428571436, 61.549571428571426,
      61.84271428571429, 62.13128571428572, 62.416999999999994,
      62.69857142857143, 62.97785714285715, 63.25371428571429,
      63.52642857142856, 63.79671428571429, 64.06385714285715,
      64.32814285714286, 64.59, 64.849, 65.10542857142858, 65.35942857142857,
      65.61085714285716, 65.86, 66.10642857142858, 66.35100000000001,
      66.59285714285714, 66.83257142857143, 67.07014285714286,
      67.30614285714286, 67.53942857142857, 67.77071428571428,
      68.00057142857142, 68.22757142857144, 68.45299999999999, 68.6767142857143,
      68.898, 69.1182857142857, 69.337, 69.5532857142857, 69.76742857142857,
      69.98099999999998, 70.19257142857143, 70.40242857142859,
      70.61114285714285, 70.81800000000001, 71.02314285714286,
      71.22699999999999, 71.42928571428571, 71.63042857142857, 71.83,
      72.02785714285714, 72.225, 72.41957142857143, 72.61442857142856,
      72.80671428571428, 72.99842857142858, 73.18871428571427,
      73.37742857142858, 73.56499999999998, 73.75114285714287,
      73.93700000000001, 74.12157142857143, 74.30428571428573, 74.486,
      74.66671428571428, 74.84614285714285, 75.02442857142857,
      75.20142857142856, 75.37757142857143, 75.55271428571429,
      75.72642857142857, 75.89957142857143, 76.05085714285714,
      76.13742857142857, 76.22271428571428, 76.30585714285714,
      76.39028571428571, 76.47314285714285, 76.557, 76.64028571428571,
      76.72742857142858, 76.87257142857142, 77.03457142857144,
      77.19600000000001, 77.35614285714287, 77.51542857142856,
      77.67371428571428, 77.83171428571428, 77.98814285714285, 78.1437142857143,
      78.29828571428571, 78.45242857142857, 78.60542857142856, 78.7572857142857,
      78.90842857142856, 79.059, 79.20814285714286, 79.35671428571428,
      79.50485714285715, 79.65085714285713, 79.79757142857143, 79.943,
      80.08714285714287, 80.231, 80.37371428571429, 80.51571428571427, 80.657,
      80.797, 80.938, 81.07671428571429, 81.21542857142857, 81.353,
      81.49085714285714, 81.627, 81.76342857142858, 81.89885714285715,
      82.03357142857143, 82.16857142857143, 82.30214285714285,
      82.43499999999999, 82.56814285714286, 82.70057142857142,
      82.83214285714287, 82.96357142857143, 83.0942857142857, 83.22442857142856,
      83.35428571428572, 83.48400000000001, 83.61314285714286, 83.742, 83.87,
      83.9977142857143, 84.12485714285715, 84.25200000000002, 84.37814285714285,
      84.50442857142858, 84.63014285714287, 84.75542857142857,
      84.88028571428572, 85.00457142857144, 85.12914285714285,
      85.25228571428572, 85.37585714285716, 85.49814285714285,
      85.62171428571426, 85.74328571428573, 85.86500000000001,
      85.98685714285713, 86.10757142857143, 86.22757142857144,
      86.34814285714286, 86.46814285714287, 86.588, 86.70685714285716,
      86.82428571428571, 86.94285714285715, 87.06042857142859,
      87.17828571428574, 87.29485714285713, 87.41185714285713,
      87.52842857142858, 87.64514285714286, 87.76085714285716,
      87.87542857142857, 87.99028571428572, 88.10400000000001,
      88.21842857142857, 88.33285714285714, 88.44557142857145,
      88.55814285714284, 88.67142857142856, 88.78342857142857,
      88.89485714285715, 89.00742857142856, 89.11771428571429,
      89.22914285714285, 89.33957142857142, 89.45014285714286, 89.5592857142857,
      89.67014285714286, 89.77885714285716, 89.88814285714284,
      89.99671428571428, 90.10471428571428, 90.21357142857143,
      90.32085714285714, 90.42871428571428, 90.53657142857142,
      90.64342857142856, 90.75014285714285, 90.85642857142855,
      90.96328571428572, 91.06899999999999, 91.17499999999998, 91.2807142857143,
      91.38571428571429, 91.49071428571428, 91.59571428571428,
      91.70028571428573, 91.80457142857142, 91.90871428571428, 92.0127142857143,
      92.11571428571428, 92.21957142857143, 92.32271428571428,
      92.42514285714286, 92.52828571428572, 92.63042857142855, 92.7327142857143,
      92.83442857142857, 92.93614285714287, 93.03728571428573,
      93.13900000000001, 93.23957142857144, 93.34028571428573,
      93.44085714285714, 93.54085714285715, 93.64071428571428,
      93.74057142857144, 93.83957142857142, 93.93914285714287,
      94.03771428571427, 94.13685714285715, 94.23471428571429,
      94.33271428571427, 94.43057142857141, 94.52857142857144,
      94.62585714285716, 94.72357142857142, 94.82000000000001,
      94.91657142857142, 95.01328571428571, 95.11014285714285,
      95.20628571428574, 95.30214285714285, 95.39771428571429,
      95.49271428571429,
    ],
  },
  SD2neg: {
    label: '-2 SD',
    weight: [
      45.91085714285714, 47.05271428571429, 48.100428571428566,
      48.99485714285715, 49.84342857142857, 50.64742857142858,
      51.409285714285716, 52.131142857142855, 52.81557142857144,
      53.46742857142857, 54.08957142857143, 54.68414285714287,
      55.253428571428564, 55.799142857142854, 56.32314285714286, 56.827,
      57.31142857142857, 57.77671428571428, 58.22457142857142,
      58.65571428571428, 59.07085714285714, 59.47257142857143,
      59.86157142857142, 60.23942857142856, 60.606857142857145,
      60.96542857142857, 61.31628571428572, 61.65985714285713,
      61.99657142857143, 62.327999999999996, 62.65371428571428,
      62.974714285714285, 63.290571428571425, 63.60228571428571,
      63.91057142857143, 64.21514285714287, 64.51557142857143,
      64.81271428571428, 65.1062857142857, 65.39714285714287, 65.685,
      65.96957142857143, 66.252, 66.53099999999999, 66.80728571428571,
      67.08157142857144, 67.35285714285713, 67.62157142857143, 67.8877142857143,
      68.15157142857143, 68.41314285714284, 68.67214285714286,
      68.92914285714286, 69.18342857142856, 69.43585714285715,
      69.68614285714285, 69.93428571428572, 70.18042857142858, 70.4242857142857,
      70.66642857142857, 70.90628571428572, 71.14428571428572,
      71.38071428571428, 71.61500000000001, 71.84771428571428,
      72.07857142857144, 72.30757142857144, 72.53471428571427,
      72.76085714285715, 72.985, 73.20771428571427, 73.42871428571428,
      73.64828571428572, 73.86614285714283, 74.08242857142857,
      74.29757142857143, 74.51114285714286, 74.72328571428571,
      74.93371428571427, 75.14314285714285, 75.35071428571428,
      75.55757142857142, 75.7627142857143, 75.96685714285715, 76.16985714285714,
      76.371, 76.57114285714285, 76.77000000000001, 76.96828571428571,
      77.16471428571428, 77.36, 77.55414285714285, 77.74714285714286,
      77.9392857142857, 78.12971428571429, 78.31914285714286, 78.50742857142858,
      78.6947142857143, 78.88071428571429, 79.066, 79.24228571428571,
      79.34614285714285, 79.43757142857143, 79.52671428571429,
      79.61757142857142, 79.70614285714285, 79.79628571428573,
      79.88514285714284, 79.99057142857143, 80.15985714285713,
      80.33357142857143, 80.50699999999999, 80.67899999999999,
      80.85000000000001, 81.02, 81.18971428571429, 81.35785714285714,
      81.52514285714287, 81.69142857142857, 81.85728571428571, 82.0217142857143,
      82.18485714285714, 82.34757142857143, 82.50957142857143,
      82.67014285714286, 82.83042857142857, 82.98957142857144, 83.1472857142857,
      83.30485714285714, 83.46128571428572, 83.61685714285714,
      83.77185714285714, 83.92571428571428, 84.07871428571427,
      84.23142857142857, 84.38271428571429, 84.53385714285716,
      84.68371428571429, 84.83328571428571, 84.98171428571428,
      85.13014285714284, 85.2772857142857, 85.42442857142858, 85.57042857142858,
      85.71600000000001, 85.86114285714287, 86.00557142857143,
      86.14900000000002, 86.29228571428571, 86.43542857142857,
      86.57742857142857, 86.71900000000001, 86.86028571428572,
      87.00085714285716, 87.14085714285716, 87.28100000000002, 87.42, 87.559,
      87.69742857142856, 87.83528571428572, 87.97257142857143,
      88.10971428571429, 88.24614285714286, 88.38228571428571,
      88.51814285714285, 88.65328571428572, 88.78814285714286,
      88.92242857142858, 89.05685714285714, 89.18985714285715,
      89.32342857142858, 89.45542857142858, 89.58857142857143, 89.72,
      89.85142857142857, 89.98299999999999, 90.11342857142859,
      90.24342857142857, 90.37342857142858, 90.503, 90.63228571428571,
      90.76071428571429, 90.88814285714285, 91.01614285714287,
      91.14328571428572, 91.27057142857143, 91.39642857142859,
      91.52257142857142, 91.64871428571428, 91.77485714285716,
      91.89985714285714, 92.024, 92.14814285714286, 92.27142857142856,
      92.39514285714286, 92.51828571428572, 92.64057142857143,
      92.76257142857142, 92.88485714285714, 93.00599999999999, 93.127,
      93.2482857142857, 93.36800000000001, 93.48814285714286, 93.60757142857142,
      93.72728571428571, 93.84614285714285, 93.96514285714285,
      94.08314285714286, 94.20157142857143, 94.31914285714286,
      94.43642857142858, 94.55371428571428, 94.67028571428571,
      94.78685714285713, 94.90328571428572, 95.01942857142856,
      95.13485714285714, 95.25028571428572, 95.36557142857143,
      95.48028571428571, 95.59500000000001, 95.70942857142857,
      95.82328571428572, 95.93714285714286, 96.05085714285715,
      96.16414285714286, 96.27714285714286, 96.39, 96.50214285714284,
      96.61442857142858, 96.72642857142856, 96.8382857142857, 96.94971428571428,
      97.06114285714285, 97.17185714285714, 97.28285714285714, 97.393,
      97.50342857142857, 97.61300000000001, 97.72314285714286,
      97.83228571428572, 97.94128571428571, 98.05042857142857,
      98.15899999999999, 98.26742857142857, 98.37571428571428,
      98.48299999999999, 98.591, 98.69814285714286, 98.80571428571429,
      98.91185714285714, 99.01857142857145, 99.12471428571428,
      99.23085714285715, 99.33671428571428, 99.44257142857143,
      99.54771428571428, 99.65271428571428, 99.75771428571429, 99.8622857142857,
      99.967, 100.07100000000001, 100.17485714285714, 100.27842857142858,
    ],
  },
};
