import { createContext, useContext, useMemo } from 'react';
import { Step } from 'react-joyride';
import { useSetState } from 'react-use';

export interface WalkthroughState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  tourActive: boolean;
  attendanceStatus: boolean;
  enableButton: boolean;
}

const walkthroughState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
  attendanceStatus: true,
  enableButton: true,
};

export const WalkthroughProviderContext = createContext({
  state: walkthroughState,
  setState: () => undefined,
});
WalkthroughProviderContext.displayName = 'WalkthroughProviderContext';

export function WalkthroughProvider(props: any) {
  const [state, setState] = useSetState(walkthroughState);

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [setState, state]
  );

  return <WalkthroughProviderContext.Provider value={value} {...props} />;
}

export function useWalkthroughContext(): {
  setState: (
    patch:
      | Partial<WalkthroughState>
      | ((previousState: WalkthroughState) => Partial<WalkthroughState>)
  ) => void;
  state: WalkthroughState;
} {
  const context = useContext(WalkthroughProviderContext);

  if (!context) {
    throw new Error(
      'useWalkthroughContext must be used within a WalkthroughProvider'
    );
  }

  return context;
}
