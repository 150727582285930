import { Alert, Divider } from '@ecdlink/ui';
import { ReactComponent as PollyNeutral } from '@/assets/pollyNeutral.svg';
import { ReactComponent as Polly } from '@/assets/momImageSvg.svg';
import { Header, Label } from '@/pages/infant/infant-profile/components';
import Infant from '@/assets/infant.svg';
import { DynamicFormProps } from '../../dynamic-form';
import { useEffect, useMemo } from 'react';
import { Video } from '../../components/video';
import { useSelector } from 'react-redux';
import { getPreviousVisitInformationForInfantSelector } from '@/store/visit/visit.selectors';

export const MotherCareStep = ({
  infant,
  setEnableButton,
}: DynamicFormProps) => {
  const name = useMemo(() => infant?.user?.firstName || '', [infant]);
  const videoSection = 'Kangaroo Mother Care';

  const previousVisit = useSelector(
    getPreviousVisitInformationForInfantSelector
  );

  const isFirstVisit = !previousVisit?.visitDataStatus?.length;

  const weightAtBirth = useMemo(
    () => infant?.weightAtBirth,
    [infant?.weightAtBirth]
  );
  const weightAtVisit = useMemo(
    () => previousVisit?.weight,
    [previousVisit?.weight]
  );

  const orangeAlert = {
    message: `${name} had a low birth weight.`,
    list: [
      `Kangaroo Mother Care is especially important for babies like ${name} - it can help them thrive.`,
    ],
  };
  useEffect(() => {
    setEnableButton && setEnableButton(true);
  }, [setEnableButton]);

  return (
    <>
      <Header
        backgroundColor="tertiary"
        customIcon={Infant}
        title="Kangaroo Mother Care"
      />
      <div className="flex flex-col gap-4 p-4">
        <Alert
          type="warning"
          title={
            isFirstVisit
              ? `Watch the video on Kangaroo Mother Care with ${infant?.caregiver?.firstName} and answer any questions.`
              : `Observe how ${infant?.caregiver?.firstName} uses the Kangaroo Mother Care technique with ${name}.`
          }
          titleColor="textDark"
          customIcon={
            <div className="rounded-full">
              {isFirstVisit ? (
                <PollyNeutral className="h-14 w-14" />
              ) : (
                <Polly className="h-14 w-14" />
              )}
            </div>
          }
        />
        {isFirstVisit && <Video section={videoSection} />}
        {((!!weightAtBirth && Number(weightAtBirth) < 2.5) ||
          (!!weightAtVisit && Number(weightAtBirth) < 2.5)) && (
          <Alert
            type="warning"
            title={orangeAlert.message}
            list={orangeAlert.list}
          />
        )}
        {!isFirstVisit && (
          <>
            <Divider dividerType="dashed" />
            <Label text={`Do you remember how to keep ${name} warm?`} />
            <Divider dividerType="dashed" />
          </>
        )}
      </div>
    </>
  );
};
