import React from 'react';
import { ActionModal } from '@ecdlink/ui';

export type OfflineSyncSuccessProps = {
  onSubmit: () => void;
};

const OfflineSyncSuccess: React.FC<OfflineSyncSuccessProps> = ({
  onSubmit,
}) => {
  return (
    <ActionModal
      icon={'CheckCircleIcon'}
      iconColor="successMain"
      iconBorderColor="errorBg"
      title="Success!"
      detailText={`We have succesfully synced your data.\nYou can now continue to use the CHW Connect offline.`}
      actionButtons={[
        {
          text: 'Okay',
          textColour: 'white',
          colour: 'primary',
          type: 'filled',
          onClick: () => onSubmit && onSubmit(),
          leadingIcon: 'CheckCircleIcon',
        },
      ]}
    />
  );
};

export default OfflineSyncSuccess;
