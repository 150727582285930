export const weightPerMonth = {
  date: [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
    59, 60,
  ],
  median: {
    label: 'median',
    weight: [
      3.1957, 4.1716, 5.1049, 5.8181, 6.3935, 6.8673, 7.265, 7.6094, 7.9151,
      8.191, 8.4445, 8.6822, 8.9092, 9.1289, 9.3436, 9.5549, 9.7638, 9.9713,
      10.1776, 10.3827, 10.5869, 10.7909, 10.9951, 11.2, 11.4055, 11.6113,
      11.817, 12.0215, 12.224, 12.4237, 12.6204, 12.814, 13.0048, 13.1934,
      13.3806, 13.5669, 13.7528, 13.9384, 14.1238, 14.3089, 14.4936, 14.6776,
      14.8609, 15.0433, 15.2249, 15.4057, 15.5859, 15.7654, 15.9445, 16.1234,
      16.302, 16.4806, 16.659, 16.8372, 17.0152, 17.1927, 17.3697, 17.5459,
      17.7211, 17.8954, 18.0686,
    ],
  },
  SD2: {
    label: '2 SD',
    weight: [
      4.222, 5.456, 6.601, 7.48, 8.196, 8.79, 9.295, 9.738, 10.137, 10.501,
      10.838, 11.154, 11.457, 11.749, 12.032, 12.31, 12.585, 12.856, 13.127,
      13.396, 13.664, 13.933, 14.203, 14.475, 14.749, 15.024, 15.3, 15.576,
      15.852, 16.124, 16.395, 16.664, 16.93, 17.196, 17.462, 17.728, 17.996,
      18.266, 18.538, 18.812, 19.087, 19.364, 19.641, 19.919, 20.197, 20.476,
      20.755, 21.034, 21.313, 21.592, 21.872, 22.154, 22.434, 22.715, 22.997,
      23.278, 23.559, 23.839, 24.119, 24.397, 24.675,
    ],
  },
  SD3: {
    label: '3 SD',
    weight: [
      4.793, 6.212, 7.488, 8.473, 9.28, 9.954, 10.531, 11.042, 11.506, 11.932,
      12.327, 12.7, 13.056, 13.399, 13.731, 14.056, 14.376, 14.693, 15.007,
      15.32, 15.633, 15.946, 16.262, 16.58, 16.901, 17.224, 17.548, 17.873,
      18.199, 18.522, 18.844, 19.165, 19.485, 19.805, 20.126, 20.449, 20.776,
      21.107, 21.441, 21.779, 22.12, 22.464, 22.81, 23.159, 23.509, 23.86,
      24.213, 24.567, 24.921, 25.277, 25.634, 25.993, 26.352, 26.712, 27.072,
      27.433, 27.794, 28.156, 28.516, 28.875, 29.235,
    ],
  },
  SD3neg: {
    label: '-3 SD',
    weight: [
      1.994, 2.72, 3.428, 3.971, 4.407, 4.764, 5.061, 5.315, 5.538, 5.736,
      5.917, 6.085, 6.247, 6.403, 6.557, 6.708, 6.858, 7.007, 7.154, 7.301,
      7.445, 7.589, 7.732, 7.874, 8.016, 8.158, 8.298, 8.437, 8.572, 8.705,
      8.835, 8.961, 9.083, 9.204, 9.322, 9.438, 9.552, 9.665, 9.776, 9.886,
      9.995, 10.101, 10.207, 10.31, 10.413, 10.514, 10.615, 10.714, 10.813,
      10.911, 11.01, 11.107, 11.205, 11.303, 11.4, 11.497, 11.593, 11.689,
      11.784, 11.879, 11.972,
    ],
  },
  SD2neg: {
    label: '-2 SD',
    weight: [
      2.352, 3.148, 3.922, 4.513, 4.988, 5.378, 5.703, 5.982, 6.227, 6.446,
      6.646, 6.833, 7.012, 7.185, 7.355, 7.523, 7.689, 7.854, 8.018, 8.18,
      8.341, 8.501, 8.661, 8.821, 8.98, 9.139, 9.298, 9.454, 9.608, 9.759,
      9.907, 10.051, 10.192, 10.331, 10.468, 10.603, 10.736, 10.868, 10.999,
      11.129, 11.257, 11.384, 11.51, 11.634, 11.757, 11.879, 12, 12.12, 12.24,
      12.359, 12.477, 12.596, 12.714, 12.832, 12.95, 13.067, 13.184, 13.3,
      13.416, 13.53, 13.644,
    ],
  },
};
