// walkthrough data
export const dummy = [
  {
    id: '7a45f211-fbfb-4449-bda5-1605d961d06e',
    meetingDate: '2023-12-04T22:00:00.000Z',
    clientsAttendedConfirmed: true,
    clients: [
      {
        caregiverId: '7d6df56c-80c2-4236-b792-df75aed1e3e6',
        firstName: 'Molly',
        surname: 'Moo',
      },
      {
        caregiverId: '64fd0fea-3cc7-4da3-ba5a-9d81237472bc',
        firstName: 'r_MotherB',
        surname: 'Surname',
      },
      {
        caregiverId: '25afb528-ffd8-4035-b975-e1cf91fb0ab1',
        firstName: 'Father_Road_H01',
        surname: 'Father_Road_H01',
      },
      {
        caregiverId: '5e8aa3ee-882e-45a1-9490-dbbb2c0ea259',
        firstName: 'Jessica',
        surname: 'Jones',
      },
    ],
  },
  {
    id: '7a45f211-fbfb-4449-bda5-1605d961d06e',
    meetingDate: '2023-11-04T22:00:00.000Z',
    clientsAttendedConfirmed: true,
    clients: [
      {
        caregiverId: '7d6df56c-80c2-4236-b792-df75aed1e3e6',
        firstName: 'Molly',
        surname: 'Moo',
      },
      {
        caregiverId: '64fd0fea-3cc7-4da3-ba5a-9d81237472bc',
        firstName: 'r_MotherB',
        surname: 'Surname',
      },
      {
        caregiverId: '25afb528-ffd8-4035-b975-e1cf91fb0ab1',
        firstName: 'Father_Road_H01',
        surname: 'Father_Road_H01',
      },
      {
        caregiverId: '5e8aa3ee-882e-45a1-9490-dbbb2c0ea259',
        firstName: 'Jessica',
        surname: 'Jones',
      },
    ],
  },
  {
    id: '7a45f211-fbfb-4449-bda5-1605d961d06e',
    meetingDate: '2023-11-04T22:00:00.000Z',
    clientsAttendedConfirmed: true,
    clients: [
      {
        caregiverId: '7d6df56c-80c2-4236-b792-df75aed1e3e6',
        firstName: 'Molly',
        surname: 'Moo',
      },
      {
        caregiverId: '64fd0fea-3cc7-4da3-ba5a-9d81237472bc',
        firstName: 'r_MotherB',
        surname: 'Surname',
      },
      {
        caregiverId: '25afb528-ffd8-4035-b975-e1cf91fb0ab1',
        firstName: 'Father_Road_H01',
        surname: 'Father_Road_H01',
      },
      {
        caregiverId: '5e8aa3ee-882e-45a1-9490-dbbb2c0ea259',
        firstName: 'Jessica',
        surname: 'Jones',
      },
    ],
  },
  {
    id: '7a45f211-fbfb-4449-bda5-1605d961d06e',
    meetingDate: '2023-11-04T22:00:00.000Z',
    clientsAttendedConfirmed: true,
    clients: [
      {
        caregiverId: '7d6df56c-80c2-4236-b792-df75aed1e3e6',
        firstName: 'Molly',
        surname: 'Moo',
      },
      {
        caregiverId: '64fd0fea-3cc7-4da3-ba5a-9d81237472bc',
        firstName: 'r_MotherB',
        surname: 'Surname',
      },
      {
        caregiverId: '25afb528-ffd8-4035-b975-e1cf91fb0ab1',
        firstName: 'Father_Road_H01',
        surname: 'Father_Road_H01',
      },
      {
        caregiverId: '5e8aa3ee-882e-45a1-9490-dbbb2c0ea259',
        firstName: 'Jessica',
        surname: 'Jones',
      },
    ],
  },
  {
    id: '7a45f211-fbfb-4449-bda5-1605d961d06e',
    meetingDate: '2023-11-04T22:00:00.000Z',
    clientsAttendedConfirmed: true,
    clients: [
      {
        caregiverId: '7d6df56c-80c2-4236-b792-df75aed1e3e6',
        firstName: 'Molly',
        surname: 'Moo',
      },
      {
        caregiverId: '64fd0fea-3cc7-4da3-ba5a-9d81237472bc',
        firstName: 'r_MotherB',
        surname: 'Surname',
      },
      {
        caregiverId: '25afb528-ffd8-4035-b975-e1cf91fb0ab1',
        firstName: 'Father_Road_H01',
        surname: 'Father_Road_H01',
      },
      {
        caregiverId: '5e8aa3ee-882e-45a1-9490-dbbb2c0ea259',
        firstName: 'Jessica',
        surname: 'Jones',
      },
      {
        caregiverId: '5e8aa3ee-882e-45a1-9490-dbbb2c0ea259',
        firstName: 'Jessica',
        surname: 'Jones',
      },
    ],
  },
  {
    id: '7a45f211-fbfb-4449-bda5-1605d961d06e',
    meetingDate: '2024-02-04T22:00:00.000Z',
    clientsAttendedConfirmed: true,
    clients: [
      {
        caregiverId: '7d6df56c-80c2-4236-b792-df75aed1e3e6',
        firstName: 'Molly',
        surname: 'Moo',
      },
      {
        caregiverId: '64fd0fea-3cc7-4da3-ba5a-9d81237472bc',
        firstName: 'r_MotherB',
        surname: 'Surname',
      },
      {
        caregiverId: '25afb528-ffd8-4035-b975-e1cf91fb0ab1',
        firstName: 'Father_Road_H01',
        surname: 'Father_Road_H01',
      },
      {
        caregiverId: '5e8aa3ee-882e-45a1-9490-dbbb2c0ea259',
        firstName: 'Jessica',
        surname: 'Jones',
      },
    ],
  },
  {
    id: 'a06b715d-fc0f-4472-97b1-fcaa4a676b0d',
    meetingDate: '2023-10-04T22:00:00.000Z',
    clientsAttendedConfirmed: true,
    clients: [
      {
        caregiverId: '25afb528-ffd8-4035-b975-e1cf91fb0ab1',
        firstName: 'Father_Road_H01',
        surname: 'Father_Road_H01',
      },
      {
        caregiverId: '64fd0fea-3cc7-4da3-ba5a-9d81237472bc',
        firstName: 'r_MotherB',
        surname: 'Surname',
      },
      {
        caregiverId: '7d6df56c-80c2-4236-b792-df75aed1e3e6',
        firstName: 'Molly',
        surname: 'Moo',
      },
      {
        caregiverId: '5e8aa3ee-882e-45a1-9490-dbbb2c0ea259',
        firstName: 'Jessica',
        surname: 'Jones',
      },
    ],
  },
  {
    id: '400368f7-ce2e-4118-af86-1c0c539793ba',
    meetingDate: '2024-02-04T22:00:00.000Z',
    clientsAttendedConfirmed: true,
    clients: [
      {
        caregiverId: '7d6df56c-80c2-4236-b792-df75aed1e3e6',
        firstName: 'Molly',
        surname: 'Moo',
      },
      {
        caregiverId: '25afb528-ffd8-4035-b975-e1cf91fb0ab1',
        firstName: 'Father_Road_H01',
        surname: 'Father_Road_H01',
      },
      {
        caregiverId: '5e8aa3ee-882e-45a1-9490-dbbb2c0ea259',
        firstName: 'Jessica',
        surname: 'Jones',
      },
      {
        caregiverId: '64fd0fea-3cc7-4da3-ba5a-9d81237472bc',
        firstName: 'r_MotherB',
        surname: 'Surname',
      },
    ],
  },
  {
    id: 'fa67098c-ab1b-4461-9b40-b43e56189ff6',
    meetingDate: '2024-01-04T22:00:00.000Z',
    clientsAttendedConfirmed: true,
    clients: [
      {
        caregiverId: '5e8aa3ee-882e-45a1-9490-dbbb2c0ea259',
        firstName: 'Jessica',
        surname: 'Jones',
      },
      {
        caregiverId: '25afb528-ffd8-4035-b975-e1cf91fb0ab1',
        firstName: 'Father_Road_H01',
        surname: 'Father_Road_H01',
      },
      {
        caregiverId: '7d6df56c-80c2-4236-b792-df75aed1e3e6',
        firstName: 'Molly',
        surname: 'Moo',
      },
    ],
  },
];
