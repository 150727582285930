import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import {
  APIs,
  ThemeProvider,
  useConfig,
  Config,
  DialogServiceProvider,
} from '@ecdlink/core';
import { useEffect, useState } from 'react';
import App from '@/App';
import { persistor, store } from '@/store';
import Loader from '@/components/loader/loader';
import { WalkthroughHookProvider } from '@/context/walkthroughHookContext';
import { OnlineStatusProvider } from './hooks/useOnlineStatus';
import { WalkthroughProvider } from './context/walkthrougContext';

const ConfigWrapper: React.FC = () => {
  const { loading } = useConfig();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    // minimum loading effect
    if (!loading) {
      setTimeout(() => {
        setLoader(false);
      }, 2500);
    }
  }, [loading]);

  if (loader) {
    return <Loader />;
  } else {
    const pollUrl = new URL(APIs.onlineCheck, Config.authApi).href;
    return (
      <OnlineStatusProvider pollUrl={pollUrl} interval={10000} timeout={5000}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider
              themeEndPoint={Config.themeUrl}
              overRideCache={false}
            >
              <DialogServiceProvider>
                <WalkthroughProvider>
                  <WalkthroughHookProvider>
                    <App />
                  </WalkthroughHookProvider>
                </WalkthroughProvider>
              </DialogServiceProvider>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </OnlineStatusProvider>
    );
  }
};

export default ConfigWrapper;
