export const questionsTimes = {
  fourteenWeeks: {
    hearing: ['Gets a fright when they hear a loud sound'],
    seeing: ['Follows faces or close objects with his/her eyes'],
    brain: ['Smiles at people'],
    moving: ['Holds up their head upright when held against shoulder'],
  },
  sixMonths: {
    hearing: [
      'Moves eyes or head in direction of sounds',
      'Responds by making sounds when talked to',
    ],
    seeing: [
      'Eyes move well together (no squinting). recognises familiar faces and looks at own hands',
    ],
    brain: [
      'Laughs out loud',
      'Uses different cries or sounds to show hunger. tiredness or discomfort?',
    ],
    moving: [
      'Grabs toys in each hand',
      'Lifts their head when lying on their tummy',
    ],
  },
  nineMonths: {
    hearing: ['Babbles (ma-ma. da-daa)', 'Turns when called'],
    seeing: ['Eyes focus on far objects?'],
    brain: [
      'Throws. bangs toys/objects',
      'React when caregiver leaves and calms when they return',
    ],
    moving: ['Moves objects from hand to hand', 'Sits without support'],
  },
  twelveMonths: {
    hearing: [
      'Uses simple gestures (lifts arms to be picked up)',
      'Has one meaningful word (dada. mama) although it may not be clear',
    ],
    seeing: [
      'Looks for toys/objects that disappear',
      'Looks closely at toys/objects and pictures',
    ],
    brain: ['Imitates gestures (clapping hands)'],
    moving: ['Moves objects from hand to hand', 'Sits without support'],
  },
  eighteenMonths: {
    hearing: [
      'Understands names of at least 2 common objects (cup)',
      'Uses at least 3 words',
    ],
    seeing: ['Looks at small shapes clearly at a distance'],
    brain: ['Follows simple commands'],
    moving: ['Walks alone', 'Uses fingers to feed themselves'],
  },
};
