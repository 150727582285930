export const dialogContent = 'h-full w-screen bg-white overflow-y-hidden ';
export const searchTextWrapper =
  'flex flex-row items-center justify-between px-4 h-16 bg-uiBg';
export const iconFill = 'w-6 text-primary cursor-pointer';
export const searchIcon = 'w-6 text-white cursor-pointer';
export const searchInput =
  'outline-none block w-full sm:text-sm text-black py-2 px-4 bg-white ml-4 rounded-xl';
export const dialogContentStackedList = 'flex flex-col gap-1 pb-2 px-4 pt-4';
export const quickSearchWrapper =
  'w-full flex flex-row items-center px-5 py-2 bg-uiBg overflow-x-auto';
export const searchIconWrapper =
  'inline-flex items-center p-2 cursor-pointer rounded-full round bg-secondary border-secondary mr-5';
