export const lengthPerYear = {
  date: [0, 1, 2, 3, 4],
  median: {
    label: 'median',
    weight: [49.1477, 74.0049, 86.0502, 95.0344, 102.7117],
  },
  SD2: { label: '2 SD', weight: [52.873, 79.154, 92.509, 102.649, 111.323] },
  SD3: { label: '3 SD', weight: [54.736, 81.729, 95.734, 106.456, 115.629] },
  SD3neg: { label: '-3 SD', weight: [43.56, 66.281, 76.38, 83.613, 89.795] },
  SD2neg: { label: '-2 SD', weight: [45.422, 68.856, 79.607, 87.42, 94.1] },
};
