export enum Tier {
  Bronze = 'Bronze',
  Silver = 'Silver',
  Gold = 'Gold',
}

export enum LeagueType {
  League = 'League',
  SuperLeague = 'Super League',
}

export enum ActivitySectionName {
  ChildFoldersOpened = 'Community - Team - Child folders opened',
  PregnantMomFoldersOpened = 'Community - Team - Pregnant mom folders opened',
  EarlyIdentificationOfPregnancy = 'Community - Team - Early identification of pregnancy',
  MaternalDistressScreening = 'Community - Team - Maternal distress screening',
  MaternalMalnutritionScreening = 'Community - Team - Maternal malnutrition screening',
  AlcoholAbuseScreening = 'Community - Team - Alcohol abuse screening',
  ChildSupportGrant = 'Community - Team - Child support grant',
  DevelopmentalScreening = 'Community - Team - Developmental screening',
  GrowthMonitoringWeight = 'Community - Team - Growth monitoring - weight',
  GrowthMonitoringLength = 'Community - Team - Growth monitoring - length/height',
  GrowthMonitoringMUAC = 'Community - Team - Growth monitoring - MUAC',
  Immunisations = 'Community - Team - Immunisations',
  VitaminA = 'Community - Team - Vitamin A',
  Deworming = 'Community - Team - Deworming',
  BreastfeedingClubs = 'Community - Team - Breastfeeding clubs',
}

export enum ActivityId {
  ChildFoldersOpened = '8d6655da-3e2d-4c32-af67-5f6777ffdf0f',
  PregnantMomFoldersOpened = '8307b244-2aa7-4dde-9db0-46b0a118fb20',
  EarlyIdentificationOfPregnancy = '518a0610-fae0-48fc-bf97-5ba7b1c8636d',
  MaternalDistressScreening = '187df06f-0218-4f45-bc33-c04a5f844008',
  MaternalMalnutritionScreening = 'd37dd44d-143a-4a48-91d1-4765a2df8d29',
  AlcoholAbuseScreening = '8172390a-83d6-43d5-ac6c-bd350edf71fc',
  ChildSupportGrant = 'e8ad3a50-c9fe-4c02-8fe8-271320886586',
  DevelopmentalScreening = '2f69d79f-948a-4703-bf08-2ea546323eaf',
  GrowthMonitoringWeight = '9f8d3607-dae2-4e6c-ac40-189435963740',
  GrowthMonitoringLength = 'db23970c-ee2b-459e-9d54-d090ec5fced1',
  GrowthMonitoringMUAC = 'fcef8716-4f76-4143-9b2a-f99dbac10e05',
  Immunisations = '26d07e91-4f7b-409e-b302-bce09dd6d67d',
  VitaminA = 'b8c771e3-2283-426f-beb9-c6878b64c33a',
  Deworming = '4cd64b45-6cf0-441e-acfe-e7c1525cd843',
  BreastfeedingClubs = '5f8fd37a-d2f5-44bc-9670-0513a47b88e5',
}
/*
  NOTE that the user can earn more points than this 
  since it depends on the number of clients they have, 
  number of visits they conduct, etc. 
  785 is just the calculation for the max bar. 
*/
export enum MaxIndividualPoints {
  PerMonth = 785,
  PerYear = 9420,
}
