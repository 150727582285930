export const alerts = {
  dietNotEnough: {
    title: 'Not enough dietary diversity.',
    list: [
      'Encourage {client} to continue to breastfeed.',
      'Encourage {client} to add an egg to the baby’s diet.',
      'Encourage a balanced diet that always includes starches, iron- rich, dairy, fruits and vegetables.',
    ],
  },
  dietCanBeImproved: {
    title: 'Dietary diversity can be improved.',
    list: [
      'Encourage {client} to continue to breastfeed.',
      'Help {client} to identify locally available and affordable food options.',
      'Encourage {client} to add an egg to the baby’s diet.',
      'Encourage a balanced diet that always includes starches, iron- rich, dairy, fruits and vegetables.',
      'Encourage {client} to add dairy, flesh foods, vitamin A rich fruit and vegetables and other fruits and vegetables to the diet.',
    ],
  },
  dietIsGood: {
    title: 'Dietary diversity is good, but could be improved.',
    list: [
      'Encourage {client} to continue breastfeeding and giving the child a balanced diet that always includes starches, dairy, meat, eggs, fruits and vegetables.',
    ],
  },
  dietIsGreat: {
    title: 'Dietary diversity is great!',
    list: [
      'Encourage {client} to continue breastfeeding and giving the child a balanced diet that always includes starches, dairy, meat, eggs, fruits and vegetables.',
    ],
  },
};
