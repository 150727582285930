import ROUTES from '@/routes/routes';
import { Message } from '@models/messages/messages';

interface NotificationTagConfigParams {
  [key: string]: Partial<Message>;
  AcceptAgreement: Partial<Message>;
  SeeWalkthrough: Partial<Message>;
  CompleteProfile: Partial<Message>;
  RedAlertReferralMother: Partial<Message>;
  RedAlertReferralInfant: Partial<Message>;
  DangerSignsReferral: Partial<Message>;
  GrowthIssuesReferral: Partial<Message>;
  SevereChildMuacReferral: Partial<Message>;
  ModerateChildMuacReferral: Partial<Message>;
  AddClub: Partial<Message>;
  SeeScoreBoard: Partial<Message>;
  SeePoints: Partial<Message>;
  SeeSummary: Partial<Message>;
  RoadToHealthBook: Partial<Message>;
  SassaReferral: Partial<Message>;
  HomeAffairsReferral: Partial<Message>;
  MaternalDistressReferral: Partial<Message>;
  ChildFiveYears: Partial<Message>;
  LowMuacReferral: Partial<Message>;
  TAceReferral: Partial<Message>;
  SeeMore: Partial<Message>;
  MotherAge: Partial<Message>;
  InfantCaregiver: Partial<Message>;
  LowBirthWeight: Partial<Message>;
  ClinicReferralInfant: Partial<Message>;
  ClinicReferralMother: Partial<Message>;
  LearnMore: Partial<Message>;
}

export const notificationTagConfig: NotificationTagConfigParams = {
  AcceptAgreement: {
    routeConfig: {
      route: ROUTES.DASHBOARD,
    },
    viewType: 'Both',
  },
  SeeWalkthrough: {
    cta: 'SeeWalkthrough',
  },
  CompleteProfile: {
    cta: 'CompleteProfile',
  },
  RedAlertReferralMother: {
    cta: 'SeeReferralsRedAlertMother',
  },
  MaternalDistressReferral: {
    cta: 'SeeReferralsMaternalDistress',
  },
  LowMuacReferral: {
    cta: 'SeeReferralsLowMuac',
  },
  TAceReferral: {
    cta: 'SeeReferralsTACE',
  },
  RedAlertReferralInfant: {
    cta: 'SeeReferralsRedAlertInfant',
  },
  DangerSignsReferral: {
    cta: 'SeeReferralsDangerSigns',
  },
  GrowthIssuesReferral: {
    cta: 'SeeReferralsGrowthIssues',
  },
  SevereChildMuacReferral: {
    cta: 'SeeReferralsChildMuac',
  },
  ModerateChildMuacReferral: {
    cta: 'SeeReferralsChildMuacM',
  },
  SassaReferral: {
    cta: 'SeeReferralsSassa',
  },
  HomeAffairsReferral: {
    cta: 'SeeReferralsHomeAffairs',
  },
  RoadToHealthBook: {
    cta: 'RoadToHealth',
  },
  AddClub: {
    cta: 'AddClub',
  },
  SeeScoreBoard: {
    cta: 'SeeScoreboard',
  },
  SeePoints: {
    cta: 'SeePoints',
  },
  SeeSummary: {
    cta: 'SeeSummary',
  },
  ChildFiveYears: {
    cta: 'ChildFiveYears',
  },
  SeeMore: {
    cta: 'See More',
  },
  LearnMore: {
    cta: 'LearnMore',
  },
  MotherAge: {
    cta: 'MotherAge',
  },
  InfantCaregiver: {
    cta: 'InfantCaregiver',
  },
  LowBirthWeight: {
    cta: 'LowBirthWeight',
  },
  ClinicReferralInfant: {
    cta: 'SeeReferralsClinicsInfant',
  },
  ClinicReferralMother: {
    cta: 'SeeReferralsClinicsMother',
  },
};

export const enum MessageStatusConstants {
  Amber = 'amber',
  Blue = 'blue',
  Red = 'red',
  Green = 'green',
}
