import { EnhancedStore } from '@reduxjs/toolkit';
import { Message } from '@models/messages/messages';
import { RootState } from '@store/types';
import {
  NotificationIntervals,
  NotificationPriority,
  NotificationValidator,
} from '../../NotificationService.types';
import ROUTES from '@/routes/routes';
import { getAgeInYearsMonthsAndDays } from '@ecdlink/core';
import { addYears, addDays } from 'date-fns';

export class InfantNotificationValidator implements NotificationValidator {
  interval: NotificationIntervals;
  lastCheckTimestamp: number;
  store: EnhancedStore<RootState, any>;

  constructor(store: EnhancedStore<RootState, any>) {
    this.store = store;
    this.interval = NotificationIntervals.oneMinute;
    this.lastCheckTimestamp = 0;
  }

  getNotifications = () => {
    const state = this.store.getState();

    const { infants: infantState } = state;

    if (!infantState) return [];

    const notifications: Message[] = [];
    for (const infant of infantState.infants || []) {
      if (infant && infant.user) {
        const dateOfBirth = infant.user?.dateOfBirth as string;
        const { years: ageYears, months: ageMonths } =
          getAgeInYearsMonthsAndDays(dateOfBirth);

        const removalDate = addDays(new Date(dateOfBirth), 7);
        const childRemovalDate = addYears(removalDate, 5);

        if (ageYears >= 5) {
          notifications.push({
            reference: `${infant.user.id}-5years`,
            title: `${infant.user?.firstName} has turned 5 years old!`,
            message: `You can close ${
              infant.user?.firstName
            }'s folder now. If you don't close the folder, ${
              infant.user?.firstName
            } will be removed on ${childRemovalDate.toLocaleDateString(
              'en-ZA',
              {
                day: 'numeric',
                month: 'long',
              }
            )}`,
            dateCreated: new Date().toISOString(),
            priority: 11,
            viewOnDashboard: true,
            area: 'child-registration',
            icon: 'IdentificationIcon',
            color: 'primary',
            actionText: 'Visit client',
            viewType: 'Messages',
            cta: 'ChildFiveYears',
            routeConfig: {
              route: `${ROUTES.CLIENTS.INFANT_PROFILE.ROOT}${infant?.user?.id}`,
            },
          });
        }
        if (
          ageYears === 0 &&
          ageMonths < 6 &&
          infant.firstVisitCompleted === false
        ) {
          if (infant.weightAtBirth && Number(infant.weightAtBirth) < 2.5) {
            notifications.push({
              reference: `${infant.user.id}-weight`,
              title: `Schedule a visit with ${infant.user?.firstName} as soon as possible.`,
              message: `${infant.user?.firstName}'s birth weight was less than 2.5kg. Schedule a visit to support 
              ${infant.user?.firstName}'s caregiver, ${infant.caregiver?.firstName}.`,
              dateCreated: new Date().toISOString(),
              priority: 19,
              viewOnDashboard: true,
              area: 'child-registration',
              icon: 'IdentificationIcon',
              color: 'primary',
              actionText: 'Book a visit',
              viewType: 'Hub',
              cta: 'LowBirthWeight',
              routeConfig: {
                route: `${ROUTES.CLIENTS.INFANT_PROFILE.ROOT}${infant?.user?.id}`,
              },
            });
          }
        }
        if (
          infant.firstVisitCompleted === false &&
          infant.caregiver &&
          Number(infant.caregiver.age!) < 20
        ) {
          notifications.push({
            reference: `${infant.user.id}-caregiverAge`,
            title: `Schedule a visit with ${infant.caregiver.firstName} as soon as possible`,
            message: `${infant.caregiver.firstName} is a teenager. Schedule a visit to make sure
            ${infant.caregiver.firstName} receives the support they need.`,
            dateCreated: new Date().toISOString(),
            priority: 20,
            viewOnDashboard: true,
            area: 'child-registration',
            icon: 'IdentificationIcon',
            color: 'primary',
            actionText: 'Book a visit',
            viewType: 'Hub',
            cta: 'InfantCaregiver',
            routeConfig: {
              route: `${ROUTES.CLIENTS.INFANT_PROFILE.ROOT}${infant?.user?.id}`,
            },
          });
        }
        if (infant?.missedVisits! > 1) {
          notifications.push({
            reference: `${infant.user.id}-missedVisits`,
            title: `Missed 2 visits with ${infant?.user?.firstName} & ${infant?.caregiver?.firstName}`,
            message: `You have missed 2 visits with ${infant?.user?.firstName} & ${infant?.caregiver?.firstName}. Make sure you visit regularly during this time.`,
            dateCreated: new Date().toISOString(),
            priority: 10,
            viewOnDashboard: true,
            area: 'child-registration',
            icon: 'IdentificationIcon',
            color: 'primary',
            actionText: 'Visit client',
            viewType: 'Both',
            cta: 'InfantCaregiver',
            routeConfig: {
              route: `${ROUTES.CLIENTS.INFANT_PROFILE.ROOT}${infant?.user?.id}`,
            },
          });
        }
      }
    }

    return notifications;
  };
}
