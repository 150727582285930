export const lengthPerMonth = {
  date: [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
    59, 60,
  ],
  median: {
    label: 'median',
    weight: [
      49.1477, 53.6326, 56.9805, 59.6952, 61.9686, 63.9, 65.5911, 67.1355,
      68.5855, 69.9666, 71.2926, 72.5702, 73.8034, 74.9959, 76.1504, 77.2695,
      78.3563, 79.4134, 80.4423, 81.4448, 82.4228, 83.3775, 84.3096, 85.2197,
      85.9039, 86.3426, 87.1286, 87.9605, 88.7737, 89.5684, 90.3453, 91.105,
      91.8486, 92.5777, 93.2938, 93.998, 94.6914, 95.375, 96.0491, 96.7141,
      97.3704, 98.0182, 98.6575, 99.2882, 99.9107, 100.525, 101.1313, 101.7299,
      102.3212, 102.9059, 103.4842, 104.0565, 104.6233, 105.1846, 105.7406,
      106.2915, 106.8371, 107.3775, 107.9127, 108.4427, 108.9678,
    ],
  },
  SD2: {
    label: '2 SD',
    weight: [
      52.873, 57.538, 61.049, 63.899, 66.29, 68.327, 70.116, 71.756, 73.304,
      74.785, 76.213, 77.595, 78.934, 80.235, 81.498, 82.725, 83.92, 85.085,
      86.221, 87.332, 88.417, 89.479, 90.516, 91.533, 92.346, 92.83, 93.75,
      94.682, 95.597, 96.49, 97.363, 98.218, 99.057, 99.878, 100.685, 101.48,
      102.261, 103.034, 103.794, 104.544, 105.285, 106.016, 106.738, 107.452,
      108.155, 108.848, 109.535, 110.212, 110.881, 111.544, 112.198, 112.847,
      113.489, 114.125, 114.754, 115.379, 115.997, 116.612, 117.219, 117.821,
      118.417,
    ],
  },
  SD3: {
    label: '3 SD',
    weight: [
      54.736, 59.491, 63.083, 66.001, 68.451, 70.54, 72.378, 74.066, 75.664,
      77.193, 78.674, 80.107, 81.5, 82.855, 84.171, 85.452, 86.701, 87.921,
      89.111, 90.275, 91.413, 92.53, 93.62, 94.689, 95.561, 96.077, 97.061,
      98.043, 99.008, 99.951, 100.872, 101.775, 102.661, 103.529, 104.38,
      105.221, 106.046, 106.863, 107.667, 108.459, 109.242, 110.016, 110.778,
      111.533, 112.278, 113.01, 113.737, 114.453, 115.161, 115.863, 116.554,
      117.243, 117.922, 118.596, 119.261, 119.923, 120.577, 121.229, 121.872,
      122.51, 123.142,
    ],
  },
  SD3neg: {
    label: '-3 SD',
    weight: [
      43.56, 47.774, 50.878, 53.39, 55.486, 57.26, 58.804, 60.205, 61.507,
      62.74, 63.912, 65.033, 66.107, 67.137, 68.129, 69.087, 70.011, 70.906,
      71.774, 72.615, 73.432, 74.225, 74.999, 75.75, 76.262, 76.616, 77.196,
      77.878, 78.539, 79.186, 79.818, 80.435, 81.036, 81.627, 82.208, 82.775,
      83.337, 83.887, 84.431, 84.969, 85.499, 86.021, 86.537, 87.043, 87.544,
      88.04, 88.525, 89.007, 89.481, 89.949, 90.414, 90.87, 91.325, 91.774,
      92.221, 92.66, 93.097, 93.526, 93.953, 94.376, 94.793,
    ],
  },
  SD2neg: {
    label: '-2 SD',
    weight: [
      45.422, 49.727, 52.912, 55.491, 57.647, 59.473, 61.067, 62.515, 63.867,
      65.149, 66.372, 67.545, 68.673, 69.757, 70.803, 71.814, 72.793, 73.742,
      74.663, 75.558, 76.429, 77.276, 78.103, 78.907, 79.48, 79.86, 80.507,
      81.239, 81.951, 82.647, 83.327, 83.992, 84.64, 85.277, 85.903, 86.516,
      87.122, 87.716, 88.304, 88.884, 89.456, 90.02, 90.577, 91.125, 91.666,
      92.202, 92.727, 93.248, 93.761, 94.268, 94.771, 95.266, 95.758, 96.244,
      96.727, 97.204, 97.677, 98.143, 98.606, 99.065, 99.518,
    ],
  },
};
