export const weightPerMonth = {
  date: [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
    59, 60,
  ],
  median: {
    label: 'median',
    weight: [
      3.3174, 4.4525, 5.5407, 6.3457, 6.9699, 7.4772, 7.9002, 8.2627, 8.5804,
      8.8658, 9.1282, 9.3739, 9.6079, 9.833, 10.0514, 10.2647, 10.4743, 10.6811,
      10.8852, 11.0873, 11.2878, 11.4876, 11.6866, 11.885, 12.0826, 12.279,
      12.4735, 12.6654, 12.8542, 13.0396, 13.2215, 13.4001, 13.5757, 13.7486,
      13.9192, 14.088, 14.2554, 14.4218, 14.5875, 14.7527, 14.9177, 15.0827,
      15.2476, 15.4125, 15.5772, 15.7418, 15.9061, 16.0703, 16.2343, 16.3981,
      16.5618, 16.7254, 16.8891, 17.0527, 17.2164, 17.38, 17.5434, 17.7067,
      17.8697, 18.0324, 18.1948,
    ],
  },
  SD2: {
    label: '2 SD',
    weight: [
      4.394, 5.776, 7.06, 7.989, 8.709, 9.303, 9.808, 10.249, 10.641, 10.997,
      11.327, 11.638, 11.935, 12.222, 12.503, 12.777, 13.049, 13.318, 13.586,
      13.852, 14.117, 14.383, 14.65, 14.916, 15.183, 15.45, 15.715, 15.978,
      16.238, 16.493, 16.745, 16.993, 17.238, 17.479, 17.718, 17.955, 18.19,
      18.424, 18.658, 18.891, 19.124, 19.357, 19.592, 19.826, 20.062, 20.299,
      20.536, 20.774, 21.013, 21.253, 21.494, 21.736, 21.979, 22.223, 22.468,
      22.713, 22.959, 23.206, 23.453, 23.701, 23.948,
    ],
  },
  SD3: {
    label: '3 SD',
    weight: [
      5.013, 6.542, 7.935, 8.934, 9.707, 10.351, 10.904, 11.392, 11.829, 12.228,
      12.6, 12.95, 13.287, 13.612, 13.932, 14.245, 14.555, 14.864, 15.171,
      15.478, 15.785, 16.093, 16.402, 16.713, 17.024, 17.337, 17.647, 17.956,
      18.262, 18.564, 18.861, 19.155, 19.445, 19.732, 20.016, 20.298, 20.578,
      20.858, 21.137, 21.416, 21.694, 21.974, 22.256, 22.538, 22.822, 23.108,
      23.394, 23.683, 23.974, 24.267, 24.561, 24.857, 25.155, 25.455, 25.758,
      26.061, 26.365, 26.671, 26.978, 27.285, 27.593,
    ],
  },
  SD3neg: {
    label: '-3 SD',
    weight: [
      2.065, 2.919, 3.764, 4.41, 4.915, 5.318, 5.646, 5.92, 6.156, 6.365, 6.555,
      6.731, 6.899, 7.059, 7.213, 7.362, 7.508, 7.651, 7.79, 7.928, 8.062,
      8.196, 8.328, 8.458, 8.587, 8.714, 8.84, 8.963, 9.083, 9.201, 9.316,
      9.428, 9.538, 9.646, 9.753, 9.858, 9.962, 10.066, 10.169, 10.271, 10.373,
      10.475, 10.577, 10.678, 10.779, 10.878, 10.977, 11.075, 11.172, 11.268,
      11.364, 11.459, 11.554, 11.647, 11.741, 11.834, 11.926, 12.018, 12.11,
      12.201, 12.291,
    ],
  },
  SD2neg: {
    label: '-2 SD',
    weight: [
      2.437, 3.376, 4.296, 4.992, 5.533, 5.968, 6.325, 6.625, 6.885, 7.116,
      7.327, 7.524, 7.71, 7.889, 8.061, 8.229, 8.393, 8.554, 8.712, 8.867,
      9.021, 9.173, 9.323, 9.473, 9.621, 9.767, 9.912, 10.054, 10.193, 10.33,
      10.463, 10.594, 10.722, 10.848, 10.972, 11.094, 11.216, 11.336, 11.456,
      11.576, 11.695, 11.814, 11.932, 12.051, 12.168, 12.285, 12.402, 12.518,
      12.633, 12.747, 12.861, 12.974, 13.086, 13.199, 13.311, 13.422, 13.533,
      13.644, 13.754, 13.864, 13.973,
    ],
  },
};
