export const weightForAgeBoys = {
  date: [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
    59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77,
    78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96,
    97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112,
    113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127,
    128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142,
    143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157,
    158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172,
    173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187,
    188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202,
    203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217,
    218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232,
    233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247,
    248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262,
    263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275, 276, 277,
    278, 279, 280, 281, 282, 283, 284, 285, 286, 287, 288, 289, 290, 291, 292,
    293, 294, 295, 296, 297, 298, 299, 300, 301, 302, 303, 304, 305, 306, 307,
    308, 309, 310, 311, 312, 313, 314, 315, 316, 317, 318, 319, 320, 321, 322,
    323, 324, 325, 326, 327, 328, 329, 330, 331, 332, 333, 334, 335, 336, 337,
    338, 339, 340, 341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351, 352,
    353, 354, 355, 356, 357, 358, 359, 360, 361, 362, 363, 364, 365, 366, 367,
    368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378, 379, 380, 381, 382,
    383, 384, 385, 386, 387, 388, 389, 390, 391, 392, 393, 394, 395, 396, 397,
    398, 399, 400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412,
    413, 414, 415, 416, 417, 418, 419, 420, 421, 422, 423, 424, 425, 426, 427,
    428, 429, 430, 431, 432, 433, 434, 435, 436, 437, 438, 439, 440, 441, 442,
    443, 444, 445, 446, 447, 448, 449, 450, 451, 452, 453, 454, 455, 456, 457,
    458, 459, 460, 461, 462, 463, 464, 465, 466, 467, 468, 469, 470, 471, 472,
    473, 474, 475, 476, 477, 478, 479, 480, 481, 482, 483, 484, 485, 486, 487,
    488, 489, 490, 491, 492, 493, 494, 495, 496, 497, 498, 499, 500, 501, 502,
    503, 504, 505, 506, 507, 508, 509, 510, 511, 512, 513, 514, 515, 516, 517,
    518, 519, 520, 521, 522, 523, 524, 525, 526, 527, 528, 529, 530, 531, 532,
    533, 534, 535, 536, 537, 538, 539, 540, 541, 542, 543, 544, 545, 546, 547,
    548, 549, 550, 551, 552, 553, 554, 555, 556, 557, 558, 559, 560, 561, 562,
    563, 564, 565, 566, 567, 568, 569, 570, 571, 572, 573, 574, 575, 576, 577,
    578, 579, 580, 581, 582, 583, 584, 585, 586, 587, 588, 589, 590, 591, 592,
    593, 594, 595, 596, 597, 598, 599, 600, 601, 602, 603, 604, 605, 606, 607,
    608, 609, 610, 611, 612, 613, 614, 615, 616, 617, 618, 619, 620, 621, 622,
    623, 624, 625, 626, 627, 628, 629, 630, 631, 632, 633, 634, 635, 636, 637,
    638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 648, 649, 650, 651, 652,
    653, 654, 655, 656, 657, 658, 659, 660, 661, 662, 663, 664, 665, 666, 667,
    668, 669, 670, 671, 672, 673, 674, 675, 676, 677, 678, 679, 680, 681, 682,
    683, 684, 685, 686, 687, 688, 689, 690, 691, 692, 693, 694, 695, 696, 697,
    698, 699, 700, 701, 702, 703, 704, 705, 706, 707, 708, 709, 710, 711, 712,
    713, 714, 715, 716, 717, 718, 719, 720, 721, 722, 723, 724, 725, 726, 727,
    728, 729, 730, 731, 732, 733, 734, 735, 736, 737, 738, 739, 740, 741, 742,
    743, 744, 745, 746, 747, 748, 749, 750, 751, 752, 753, 754, 755, 756, 757,
    758, 759, 760, 761, 762, 763, 764, 765, 766, 767, 768, 769, 770, 771, 772,
    773, 774, 775, 776, 777, 778, 779, 780, 781, 782, 783, 784, 785, 786, 787,
    788, 789, 790, 791, 792, 793, 794, 795, 796, 797, 798, 799, 800, 801, 802,
    803, 804, 805, 806, 807, 808, 809, 810, 811, 812, 813, 814, 815, 816, 817,
    818, 819, 820, 821, 822, 823, 824, 825, 826, 827, 828, 829, 830, 831, 832,
    833, 834, 835, 836, 837, 838, 839, 840, 841, 842, 843, 844, 845, 846, 847,
    848, 849, 850, 851, 852, 853, 854, 855, 856, 857, 858, 859, 860, 861, 862,
    863, 864, 865, 866, 867, 868, 869, 870, 871, 872, 873, 874, 875, 876, 877,
    878, 879, 880, 881, 882, 883, 884, 885, 886, 887, 888, 889, 890, 891, 892,
    893, 894, 895, 896, 897, 898, 899, 900, 901, 902, 903, 904, 905, 906, 907,
    908, 909, 910, 911, 912, 913, 914, 915, 916, 917, 918, 919, 920, 921, 922,
    923, 924, 925, 926, 927, 928, 929, 930, 931, 932, 933, 934, 935, 936, 937,
    938, 939, 940, 941, 942, 943, 944, 945, 946, 947, 948, 949, 950, 951, 952,
    953, 954, 955, 956, 957, 958, 959, 960, 961, 962, 963, 964, 965, 966, 967,
    968, 969, 970, 971, 972, 973, 974, 975, 976, 977, 978, 979, 980, 981, 982,
    983, 984, 985, 986, 987, 988, 989, 990, 991, 992, 993, 994, 995, 996, 997,
    998, 999, 1000, 1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009, 1010,
    1011, 1012, 1013, 1014, 1015, 1016, 1017, 1018, 1019, 1020, 1021, 1022,
    1023, 1024, 1025, 1026, 1027, 1028, 1029, 1030, 1031, 1032, 1033, 1034,
    1035, 1036, 1037, 1038, 1039, 1040, 1041, 1042, 1043, 1044, 1045, 1046,
    1047, 1048, 1049, 1050, 1051, 1052, 1053, 1054, 1055, 1056, 1057, 1058,
    1059, 1060, 1061, 1062, 1063, 1064, 1065, 1066, 1067, 1068, 1069, 1070,
    1071, 1072, 1073, 1074, 1075, 1076, 1077, 1078, 1079, 1080, 1081, 1082,
    1083, 1084, 1085, 1086, 1087, 1088, 1089, 1090, 1091, 1092, 1093, 1094,
    1095, 1096, 1097, 1098, 1099, 1100, 1101, 1102, 1103, 1104, 1105, 1106,
    1107, 1108, 1109, 1110, 1111, 1112, 1113, 1114, 1115, 1116, 1117, 1118,
    1119, 1120, 1121, 1122, 1123, 1124, 1125, 1126, 1127, 1128, 1129, 1130,
    1131, 1132, 1133, 1134, 1135, 1136, 1137, 1138, 1139, 1140, 1141, 1142,
    1143, 1144, 1145, 1146, 1147, 1148, 1149, 1150, 1151, 1152, 1153, 1154,
    1155, 1156, 1157, 1158, 1159, 1160, 1161, 1162, 1163, 1164, 1165, 1166,
    1167, 1168, 1169, 1170, 1171, 1172, 1173, 1174, 1175, 1176, 1177, 1178,
    1179, 1180, 1181, 1182, 1183, 1184, 1185, 1186, 1187, 1188, 1189, 1190,
    1191, 1192, 1193, 1194, 1195, 1196, 1197, 1198, 1199, 1200, 1201, 1202,
    1203, 1204, 1205, 1206, 1207, 1208, 1209, 1210, 1211, 1212, 1213, 1214,
    1215, 1216, 1217, 1218, 1219, 1220, 1221, 1222, 1223, 1224, 1225, 1226,
    1227, 1228, 1229, 1230, 1231, 1232, 1233, 1234, 1235, 1236, 1237, 1238,
    1239, 1240, 1241, 1242, 1243, 1244, 1245, 1246, 1247, 1248, 1249, 1250,
    1251, 1252, 1253, 1254, 1255, 1256, 1257, 1258, 1259, 1260, 1261, 1262,
    1263, 1264, 1265, 1266, 1267, 1268, 1269, 1270, 1271, 1272, 1273, 1274,
    1275, 1276, 1277, 1278, 1279, 1280, 1281, 1282, 1283, 1284, 1285, 1286,
    1287, 1288, 1289, 1290, 1291, 1292, 1293, 1294, 1295, 1296, 1297, 1298,
    1299, 1300, 1301, 1302, 1303, 1304, 1305, 1306, 1307, 1308, 1309, 1310,
    1311, 1312, 1313, 1314, 1315, 1316, 1317, 1318, 1319, 1320, 1321, 1322,
    1323, 1324, 1325, 1326, 1327, 1328, 1329, 1330, 1331, 1332, 1333, 1334,
    1335, 1336, 1337, 1338, 1339, 1340, 1341, 1342, 1343, 1344, 1345, 1346,
    1347, 1348, 1349, 1350, 1351, 1352, 1353, 1354, 1355, 1356, 1357, 1358,
    1359, 1360, 1361, 1362, 1363, 1364, 1365, 1366, 1367, 1368, 1369, 1370,
    1371, 1372, 1373, 1374, 1375, 1376, 1377, 1378, 1379, 1380, 1381, 1382,
    1383, 1384, 1385, 1386, 1387, 1388, 1389, 1390, 1391, 1392, 1393, 1394,
    1395, 1396, 1397, 1398, 1399, 1400, 1401, 1402, 1403, 1404, 1405, 1406,
    1407, 1408, 1409, 1410, 1411, 1412, 1413, 1414, 1415, 1416, 1417, 1418,
    1419, 1420, 1421, 1422, 1423, 1424, 1425, 1426, 1427, 1428, 1429, 1430,
    1431, 1432, 1433, 1434, 1435, 1436, 1437, 1438, 1439, 1440, 1441, 1442,
    1443, 1444, 1445, 1446, 1447, 1448, 1449, 1450, 1451, 1452, 1453, 1454,
    1455, 1456, 1457, 1458, 1459, 1460, 1461, 1462, 1463, 1464, 1465, 1466,
    1467, 1468, 1469, 1470, 1471, 1472, 1473, 1474, 1475, 1476, 1477, 1478,
    1479, 1480, 1481, 1482, 1483, 1484, 1485, 1486, 1487, 1488, 1489, 1490,
    1491, 1492, 1493, 1494, 1495, 1496, 1497, 1498, 1499, 1500, 1501, 1502,
    1503, 1504, 1505, 1506, 1507, 1508, 1509, 1510, 1511, 1512, 1513, 1514,
    1515, 1516, 1517, 1518, 1519, 1520, 1521, 1522, 1523, 1524, 1525, 1526,
    1527, 1528, 1529, 1530, 1531, 1532, 1533, 1534, 1535, 1536, 1537, 1538,
    1539, 1540, 1541, 1542, 1543, 1544, 1545, 1546, 1547, 1548, 1549, 1550,
    1551, 1552, 1553, 1554, 1555, 1556, 1557, 1558, 1559, 1560, 1561, 1562,
    1563, 1564, 1565, 1566, 1567, 1568, 1569, 1570, 1571, 1572, 1573, 1574,
    1575, 1576, 1577, 1578, 1579, 1580, 1581, 1582, 1583, 1584, 1585, 1586,
    1587, 1588, 1589, 1590, 1591, 1592, 1593, 1594, 1595, 1596, 1597, 1598,
    1599, 1600, 1601, 1602, 1603, 1604, 1605, 1606, 1607, 1608, 1609, 1610,
    1611, 1612, 1613, 1614, 1615, 1616, 1617, 1618, 1619, 1620, 1621, 1622,
    1623, 1624, 1625, 1626, 1627, 1628, 1629, 1630, 1631, 1632, 1633, 1634,
    1635, 1636, 1637, 1638, 1639, 1640, 1641, 1642, 1643, 1644, 1645, 1646,
    1647, 1648, 1649, 1650, 1651, 1652, 1653, 1654, 1655, 1656, 1657, 1658,
    1659, 1660, 1661, 1662, 1663, 1664, 1665, 1666, 1667, 1668, 1669, 1670,
    1671, 1672, 1673, 1674, 1675, 1676, 1677, 1678, 1679, 1680, 1681, 1682,
    1683, 1684, 1685, 1686, 1687, 1688, 1689, 1690, 1691, 1692, 1693, 1694,
    1695, 1696, 1697, 1698, 1699, 1700, 1701, 1702, 1703, 1704, 1705, 1706,
    1707, 1708, 1709, 1710, 1711, 1712, 1713, 1714, 1715, 1716, 1717, 1718,
    1719, 1720, 1721, 1722, 1723, 1724, 1725, 1726, 1727, 1728, 1729, 1730,
    1731, 1732, 1733, 1734, 1735, 1736, 1737, 1738, 1739, 1740, 1741, 1742,
    1743, 1744, 1745, 1746, 1747, 1748, 1749, 1750, 1751, 1752, 1753, 1754,
    1755, 1756, 1757, 1758, 1759, 1760, 1761, 1762, 1763, 1764, 1765, 1766,
    1767, 1768, 1769, 1770, 1771, 1772, 1773, 1774, 1775, 1776, 1777, 1778,
    1779, 1780, 1781, 1782, 1783, 1784, 1785, 1786, 1787, 1788, 1789, 1790,
    1791, 1792, 1793, 1794, 1795, 1796, 1797, 1798, 1799, 1800, 1801, 1802,
    1803, 1804, 1805, 1806, 1807, 1808, 1809, 1810, 1811, 1812, 1813, 1814,
    1815, 1816, 1817, 1818, 1819, 1820, 1821, 1822, 1823, 1824, 1825, 1826,
    1827, 1828, 1829, 1830, 1831, 1832, 1833, 1834, 1835, 1836, 1837, 1838,
    1839, 1840, 1841, 1842, 1843, 1844, 1845, 1846, 1847, 1848, 1849, 1850,
    1851, 1852, 1853, 1854, 1855, 1856,
  ],
  median: {
    label: 'median',
    weight: [
      3.3174, 3.337, 3.3464, 3.3627, 3.3915, 3.4223, 3.4545, 3.4879, 3.5222,
      3.5576, 3.5941, 3.6319, 3.671, 3.7113, 3.7529, 3.7956, 3.8389, 3.8828,
      3.927, 3.9714, 4.0158, 4.0603, 4.1046, 4.1489, 4.193, 4.2369, 4.2806,
      4.324, 4.3671, 4.41, 4.4525, 4.4946, 4.5363, 4.5776, 4.6185, 4.659, 4.699,
      4.7386, 4.7778, 4.8166, 4.8549, 4.8928, 4.9303, 4.9674, 5.0041, 5.0404,
      5.0763, 5.1118, 5.1469, 5.1817, 5.2161, 5.2501, 5.2837, 5.3171, 5.35,
      5.3826, 5.4149, 5.4468, 5.4784, 5.5097, 5.5407, 5.5714, 5.6018, 5.6319,
      5.6617, 5.6912, 5.7205, 5.7494, 5.7781, 5.8065, 5.8346, 5.8625, 5.8901,
      5.9174, 5.9445, 5.9713, 5.9979, 6.0242, 6.0503, 6.0762, 6.1018, 6.1272,
      6.1523, 6.1772, 6.2019, 6.2264, 6.2507, 6.2748, 6.2986, 6.3223, 6.3457,
      6.369, 6.3921, 6.4149, 6.4376, 6.4601, 6.4824, 6.5046, 6.5265, 6.5483,
      6.5699, 6.5914, 6.6126, 6.6338, 6.6547, 6.6755, 6.6962, 6.7166, 6.737,
      6.7572, 6.7772, 6.7971, 6.8168, 6.8365, 6.8559, 6.8753, 6.8945, 6.9135,
      6.9325, 6.9513, 6.9699, 6.9885, 7.0069, 7.0252, 7.0434, 7.0615, 7.0794,
      7.0972, 7.1149, 7.1325, 7.15, 7.1674, 7.1846, 7.2018, 7.2188, 7.2357,
      7.2525, 7.2692, 7.2858, 7.3023, 7.3187, 7.335, 7.3512, 7.3673, 7.3833,
      7.3992, 7.415, 7.4307, 7.4463, 7.4618, 7.4772, 7.4925, 7.5077, 7.5228,
      7.5379, 7.5528, 7.5677, 7.5824, 7.5971, 7.6117, 7.6262, 7.6406, 7.655,
      7.6692, 7.6834, 7.6975, 7.7115, 7.7255, 7.7394, 7.7532, 7.7669, 7.7805,
      7.7941, 7.8076, 7.821, 7.8344, 7.8477, 7.8609, 7.8741, 7.8871, 7.9002,
      7.9131, 7.926, 7.9389, 7.9516, 7.9643, 7.977, 7.9895, 8.0021, 8.0145,
      8.0269, 8.0392, 8.0515, 8.0637, 8.0759, 8.0879, 8.1, 8.112, 8.1239,
      8.1357, 8.1475, 8.1593, 8.171, 8.1826, 8.1942, 8.2058, 8.2173, 8.2287,
      8.2401, 8.2514, 8.2627, 8.2739, 8.2851, 8.2963, 8.3074, 8.3184, 8.3294,
      8.3404, 8.3513, 8.3621, 8.3729, 8.3837, 8.3944, 8.4051, 8.4157, 8.4263,
      8.4369, 8.4474, 8.4578, 8.4683, 8.4787, 8.489, 8.4993, 8.5096, 8.5198,
      8.53, 8.5401, 8.5502, 8.5603, 8.5704, 8.5804, 8.5903, 8.6003, 8.6102,
      8.62, 8.6299, 8.6397, 8.6494, 8.6592, 8.6689, 8.6785, 8.6882, 8.6978,
      8.7073, 8.7169, 8.7264, 8.7359, 8.7453, 8.7548, 8.7642, 8.7735, 8.7829,
      8.7922, 8.8015, 8.8107, 8.82, 8.8292, 8.8384, 8.8475, 8.8567, 8.8658,
      8.8748, 8.8839, 8.8929, 8.9019, 8.9109, 8.9199, 8.9288, 8.9377, 8.9466,
      8.9555, 8.9643, 8.9731, 8.9819, 8.9907, 8.9995, 9.0082, 9.0169, 9.0256,
      9.0342, 9.0429, 9.0515, 9.0601, 9.0687, 9.0772, 9.0858, 9.0943, 9.1028,
      9.1113, 9.1198, 9.1282, 9.1366, 9.145, 9.1534, 9.1618, 9.1701, 9.1785,
      9.1868, 9.1951, 9.2034, 9.2117, 9.2199, 9.2282, 9.2364, 9.2446, 9.2528,
      9.261, 9.2691, 9.2773, 9.2854, 9.2935, 9.3016, 9.3097, 9.3178, 9.3258,
      9.3339, 9.3419, 9.3499, 9.3579, 9.3659, 9.3739, 9.3819, 9.3898, 9.3978,
      9.4057, 9.4136, 9.4215, 9.4294, 9.4373, 9.4452, 9.453, 9.4609, 9.4687,
      9.4765, 9.4844, 9.4922, 9.4999, 9.5077, 9.5155, 9.5232, 9.531, 9.5387,
      9.5464, 9.5542, 9.5619, 9.5696, 9.5772, 9.5849, 9.5926, 9.6002, 9.6079,
      9.6155, 9.6231, 9.6308, 9.6384, 9.646, 9.6535, 9.6611, 9.6687, 9.6763,
      9.6838, 9.6914, 9.6989, 9.7064, 9.7139, 9.7214, 9.7289, 9.7364, 9.7439,
      9.7514, 9.7588, 9.7663, 9.7738, 9.7812, 9.7886, 9.796, 9.8035, 9.8109,
      9.8183, 9.8257, 9.833, 9.8404, 9.8478, 9.8551, 9.8625, 9.8699, 9.8772,
      9.8845, 9.8918, 9.8992, 9.9065, 9.9138, 9.9211, 9.9284, 9.9357, 9.9429,
      9.9502, 9.9575, 9.9647, 9.972, 9.9792, 9.9865, 9.9937, 10.0009, 10.0082,
      10.0154, 10.0226, 10.0298, 10.037, 10.0442, 10.0514, 10.0586, 10.0657,
      10.0729, 10.0801, 10.0872, 10.0944, 10.1015, 10.1087, 10.1158, 10.123,
      10.1301, 10.1372, 10.1443, 10.1515, 10.1586, 10.1657, 10.1728, 10.1799,
      10.187, 10.1941, 10.2011, 10.2082, 10.2153, 10.2224, 10.2294, 10.2365,
      10.2435, 10.2506, 10.2576, 10.2647, 10.2717, 10.2788, 10.2858, 10.2928,
      10.2998, 10.3069, 10.3139, 10.3209, 10.3279, 10.3349, 10.3419, 10.3489,
      10.3559, 10.3629, 10.3699, 10.3769, 10.3839, 10.3908, 10.3978, 10.4048,
      10.4118, 10.4187, 10.4257, 10.4326, 10.4396, 10.4465, 10.4535, 10.4604,
      10.4674, 10.4743, 10.4813, 10.4882, 10.4951, 10.502, 10.509, 10.5159,
      10.5228, 10.5297, 10.5366, 10.5435, 10.5505, 10.5574, 10.5643, 10.5712,
      10.578, 10.5849, 10.5918, 10.5987, 10.6056, 10.6125, 10.6193, 10.6262,
      10.6331, 10.6399, 10.6468, 10.6537, 10.6605, 10.6674, 10.6742, 10.6811,
      10.6879, 10.6948, 10.7016, 10.7084, 10.7153, 10.7221, 10.7289, 10.7357,
      10.7426, 10.7494, 10.7562, 10.763, 10.7698, 10.7766, 10.7835, 10.7903,
      10.7971, 10.8039, 10.8107, 10.8174, 10.8242, 10.831, 10.8378, 10.8446,
      10.8514, 10.8582, 10.8649, 10.8717, 10.8785, 10.8852, 10.892, 10.8988,
      10.9055, 10.9123, 10.9191, 10.9258, 10.9326, 10.9393, 10.9461, 10.9528,
      10.9596, 10.9663, 10.973, 10.9798, 10.9865, 10.9932, 11, 11.0067, 11.0134,
      11.0202, 11.0269, 11.0336, 11.0403, 11.047, 11.0537, 11.0605, 11.0672,
      11.0739, 11.0806, 11.0873, 11.094, 11.1007, 11.1074, 11.1141, 11.1208,
      11.1275, 11.1342, 11.1409, 11.1476, 11.1543, 11.161, 11.1676, 11.1743,
      11.181, 11.1877, 11.1944, 11.2011, 11.2077, 11.2144, 11.2211, 11.2278,
      11.2345, 11.2411, 11.2478, 11.2545, 11.2612, 11.2678, 11.2745, 11.2812,
      11.2878, 11.2945, 11.3012, 11.3078, 11.3145, 11.3212, 11.3278, 11.3345,
      11.3412, 11.3478, 11.3545, 11.3612, 11.3678, 11.3745, 11.3811, 11.3878,
      11.3945, 11.4011, 11.4078, 11.4144, 11.4211, 11.4277, 11.4344, 11.441,
      11.4477, 11.4543, 11.461, 11.4676, 11.4743, 11.4809, 11.4876, 11.4942,
      11.5009, 11.5075, 11.5142, 11.5208, 11.5274, 11.5341, 11.5407, 11.5474,
      11.554, 11.5606, 11.5673, 11.5739, 11.5806, 11.5872, 11.5938, 11.6005,
      11.6071, 11.6137, 11.6204, 11.627, 11.6336, 11.6403, 11.6469, 11.6535,
      11.6601, 11.6668, 11.6734, 11.68, 11.6866, 11.6933, 11.6999, 11.7065,
      11.7131, 11.7198, 11.7264, 11.733, 11.7396, 11.7462, 11.7528, 11.7595,
      11.7661, 11.7727, 11.7793, 11.7859, 11.7925, 11.7991, 11.8057, 11.8124,
      11.819, 11.8256, 11.8322, 11.8388, 11.8454, 11.852, 11.8586, 11.8652,
      11.8718, 11.8784, 11.885, 11.8916, 11.8982, 11.9048, 11.9114, 11.918,
      11.9246, 11.9312, 11.9378, 11.9444, 11.951, 11.9576, 11.9642, 11.9707,
      11.9773, 11.9839, 11.9905, 11.9971, 12.0037, 12.0103, 12.0168, 12.0234,
      12.03, 12.0366, 12.0431, 12.0497, 12.0563, 12.0629, 12.0694, 12.076,
      12.0826, 12.0891, 12.0957, 12.1023, 12.1088, 12.1154, 12.122, 12.1285,
      12.1351, 12.1416, 12.1482, 12.1548, 12.1613, 12.1679, 12.1744, 12.181,
      12.1875, 12.1941, 12.2006, 12.2072, 12.2137, 12.2202, 12.2268, 12.2333,
      12.2398, 12.2464, 12.2529, 12.2594, 12.266, 12.2725, 12.279, 12.2855,
      12.292, 12.2986, 12.3051, 12.3116, 12.3181, 12.3246, 12.3311, 12.3376,
      12.3441, 12.3506, 12.3571, 12.3636, 12.3701, 12.3766, 12.383, 12.3895,
      12.396, 12.4025, 12.409, 12.4154, 12.4219, 12.4284, 12.4348, 12.4413,
      12.4477, 12.4542, 12.4606, 12.4671, 12.4735, 12.48, 12.4864, 12.4929,
      12.4993, 12.5057, 12.5121, 12.5186, 12.525, 12.5314, 12.5378, 12.5442,
      12.5506, 12.557, 12.5634, 12.5698, 12.5762, 12.5826, 12.589, 12.5954,
      12.6018, 12.6082, 12.6145, 12.6209, 12.6273, 12.6336, 12.64, 12.6464,
      12.6527, 12.6591, 12.6654, 12.6718, 12.6781, 12.6844, 12.6908, 12.6971,
      12.7034, 12.7098, 12.7161, 12.7224, 12.7287, 12.735, 12.7413, 12.7476,
      12.7539, 12.7602, 12.7665, 12.7728, 12.7791, 12.7854, 12.7916, 12.7979,
      12.8042, 12.8104, 12.8167, 12.823, 12.8292, 12.8355, 12.8417, 12.848,
      12.8542, 12.8604, 12.8667, 12.8729, 12.8791, 12.8853, 12.8915, 12.8978,
      12.904, 12.9102, 12.9164, 12.9226, 12.9288, 12.935, 12.9411, 12.9473,
      12.9535, 12.9597, 12.9658, 12.972, 12.9782, 12.9843, 12.9905, 12.9966,
      13.0028, 13.0089, 13.0151, 13.0212, 13.0273, 13.0334, 13.0396, 13.0457,
      13.0518, 13.0579, 13.064, 13.0701, 13.0762, 13.0823, 13.0884, 13.0945,
      13.1006, 13.1067, 13.1127, 13.1188, 13.1249, 13.131, 13.137, 13.1431,
      13.1491, 13.1552, 13.1612, 13.1673, 13.1733, 13.1793, 13.1854, 13.1914,
      13.1974, 13.2034, 13.2095, 13.2155, 13.2215, 13.2275, 13.2335, 13.2395,
      13.2455, 13.2515, 13.2575, 13.2634, 13.2694, 13.2754, 13.2814, 13.2873,
      13.2933, 13.2993, 13.3052, 13.3112, 13.3171, 13.3231, 13.329, 13.335,
      13.3409, 13.3468, 13.3528, 13.3587, 13.3646, 13.3705, 13.3765, 13.3824,
      13.3883, 13.3942, 13.4001, 13.406, 13.4119, 13.4178, 13.4237, 13.4296,
      13.4354, 13.4413, 13.4472, 13.4531, 13.4589, 13.4648, 13.4707, 13.4765,
      13.4824, 13.4883, 13.4941, 13.5, 13.5058, 13.5116, 13.5175, 13.5233,
      13.5291, 13.535, 13.5408, 13.5466, 13.5524, 13.5583, 13.5641, 13.5699,
      13.5757, 13.5815, 13.5873, 13.5931, 13.5989, 13.6047, 13.6105, 13.6163,
      13.622, 13.6278, 13.6336, 13.6394, 13.6452, 13.6509, 13.6567, 13.6625,
      13.6682, 13.674, 13.6797, 13.6855, 13.6912, 13.697, 13.7027, 13.7085,
      13.7142, 13.7199, 13.7257, 13.7314, 13.7371, 13.7429, 13.7486, 13.7543,
      13.76, 13.7657, 13.7715, 13.7772, 13.7829, 13.7886, 13.7943, 13.8,
      13.8057, 13.8114, 13.8171, 13.8228, 13.8285, 13.8341, 13.8398, 13.8455,
      13.8512, 13.8569, 13.8625, 13.8682, 13.8739, 13.8796, 13.8852, 13.8909,
      13.8966, 13.9022, 13.9079, 13.9135, 13.9192, 13.9248, 13.9305, 13.9361,
      13.9418, 13.9474, 13.9531, 13.9587, 13.9644, 13.97, 13.9756, 13.9813,
      13.9869, 13.9925, 13.9982, 14.0038, 14.0094, 14.015, 14.0207, 14.0263,
      14.0319, 14.0375, 14.0431, 14.0488, 14.0544, 14.06, 14.0656, 14.0712,
      14.0768, 14.0824, 14.088, 14.0936, 14.0992, 14.1048, 14.1104, 14.116,
      14.1216, 14.1272, 14.1328, 14.1384, 14.144, 14.1495, 14.1551, 14.1607,
      14.1663, 14.1719, 14.1775, 14.183, 14.1886, 14.1942, 14.1998, 14.2053,
      14.2109, 14.2165, 14.2221, 14.2276, 14.2332, 14.2388, 14.2443, 14.2499,
      14.2554, 14.261, 14.2666, 14.2721, 14.2777, 14.2832, 14.2888, 14.2944,
      14.2999, 14.3055, 14.311, 14.3166, 14.3221, 14.3277, 14.3332, 14.3387,
      14.3443, 14.3498, 14.3554, 14.3609, 14.3665, 14.372, 14.3775, 14.3831,
      14.3886, 14.3942, 14.3997, 14.4052, 14.4108, 14.4163, 14.4218, 14.4274,
      14.4329, 14.4384, 14.4439, 14.4495, 14.455, 14.4605, 14.4661, 14.4716,
      14.4771, 14.4826, 14.4881, 14.4937, 14.4992, 14.5047, 14.5102, 14.5158,
      14.5213, 14.5268, 14.5323, 14.5378, 14.5433, 14.5489, 14.5544, 14.5599,
      14.5654, 14.5709, 14.5764, 14.5819, 14.5875, 14.593, 14.5985, 14.604,
      14.6095, 14.615, 14.6205, 14.626, 14.6315, 14.6371, 14.6426, 14.6481,
      14.6536, 14.6591, 14.6646, 14.6701, 14.6756, 14.6811, 14.6866, 14.6921,
      14.6976, 14.7032, 14.7087, 14.7142, 14.7197, 14.7252, 14.7307, 14.7362,
      14.7417, 14.7472, 14.7527, 14.7582, 14.7637, 14.7692, 14.7747, 14.7802,
      14.7857, 14.7912, 14.7967, 14.8022, 14.8077, 14.8132, 14.8187, 14.8242,
      14.8297, 14.8352, 14.8407, 14.8462, 14.8517, 14.8572, 14.8627, 14.8682,
      14.8737, 14.8792, 14.8847, 14.8902, 14.8957, 14.9012, 14.9067, 14.9122,
      14.9177, 14.9232, 14.9287, 14.9342, 14.9397, 14.9452, 14.9507, 14.9562,
      14.9617, 14.9672, 14.9727, 14.9782, 14.9837, 14.9892, 14.9947, 15.0002,
      15.0057, 15.0112, 15.0167, 15.0222, 15.0277, 15.0332, 15.0387, 15.0442,
      15.0497, 15.0552, 15.0607, 15.0662, 15.0717, 15.0772, 15.0827, 15.0882,
      15.0937, 15.0992, 15.1047, 15.1102, 15.1157, 15.1212, 15.1267, 15.1322,
      15.1377, 15.1432, 15.1487, 15.1542, 15.1596, 15.1651, 15.1706, 15.1761,
      15.1816, 15.1871, 15.1926, 15.1981, 15.2036, 15.2091, 15.2146, 15.2201,
      15.2256, 15.2311, 15.2366, 15.2421, 15.2476, 15.2531, 15.2586, 15.2641,
      15.2696, 15.2751, 15.2806, 15.2861, 15.2916, 15.2971, 15.3026, 15.3081,
      15.3135, 15.319, 15.3245, 15.33, 15.3355, 15.341, 15.3465, 15.352,
      15.3575, 15.363, 15.3685, 15.374, 15.3795, 15.385, 15.3905, 15.396,
      15.4015, 15.407, 15.4125, 15.4179, 15.4234, 15.4289, 15.4344, 15.4399,
      15.4454, 15.4509, 15.4564, 15.4619, 15.4674, 15.4729, 15.4784, 15.4839,
      15.4894, 15.4948, 15.5003, 15.5058, 15.5113, 15.5168, 15.5223, 15.5278,
      15.5333, 15.5388, 15.5443, 15.5498, 15.5552, 15.5607, 15.5662, 15.5717,
      15.5772, 15.5827, 15.5882, 15.5937, 15.5992, 15.6047, 15.6101, 15.6156,
      15.6211, 15.6266, 15.6321, 15.6376, 15.6431, 15.6486, 15.654, 15.6595,
      15.665, 15.6705, 15.676, 15.6815, 15.687, 15.6924, 15.6979, 15.7034,
      15.7089, 15.7144, 15.7199, 15.7253, 15.7308, 15.7363, 15.7418, 15.7473,
      15.7528, 15.7582, 15.7637, 15.7692, 15.7747, 15.7802, 15.7856, 15.7911,
      15.7966, 15.8021, 15.8076, 15.813, 15.8185, 15.824, 15.8295, 15.835,
      15.8404, 15.8459, 15.8514, 15.8569, 15.8623, 15.8678, 15.8733, 15.8788,
      15.8842, 15.8897, 15.8952, 15.9007, 15.9061, 15.9116, 15.9171, 15.9226,
      15.928, 15.9335, 15.939, 15.9445, 15.9499, 15.9554, 15.9609, 15.9664,
      15.9718, 15.9773, 15.9828, 15.9882, 15.9937, 15.9992, 16.0047, 16.0101,
      16.0156, 16.0211, 16.0265, 16.032, 16.0375, 16.043, 16.0484, 16.0539,
      16.0594, 16.0648, 16.0703, 16.0758, 16.0812, 16.0867, 16.0922, 16.0976,
      16.1031, 16.1086, 16.114, 16.1195, 16.125, 16.1304, 16.1359, 16.1414,
      16.1468, 16.1523, 16.1578, 16.1632, 16.1687, 16.1742, 16.1796, 16.1851,
      16.1906, 16.196, 16.2015, 16.2069, 16.2124, 16.2179, 16.2233, 16.2288,
      16.2343, 16.2397, 16.2452, 16.2506, 16.2561, 16.2616, 16.267, 16.2725,
      16.2779, 16.2834, 16.2889, 16.2943, 16.2998, 16.3053, 16.3107, 16.3162,
      16.3216, 16.3271, 16.3325, 16.338, 16.3435, 16.3489, 16.3544, 16.3598,
      16.3653, 16.3708, 16.3762, 16.3817, 16.3871, 16.3926, 16.3981, 16.4035,
      16.409, 16.4144, 16.4199, 16.4253, 16.4308, 16.4363, 16.4417, 16.4472,
      16.4526, 16.4581, 16.4635, 16.469, 16.4745, 16.4799, 16.4854, 16.4908,
      16.4963, 16.5017, 16.5072, 16.5126, 16.5181, 16.5236, 16.529, 16.5345,
      16.5399, 16.5454, 16.5508, 16.5563, 16.5618, 16.5672, 16.5727, 16.5781,
      16.5836, 16.589, 16.5945, 16.5999, 16.6054, 16.6109, 16.6163, 16.6218,
      16.6272, 16.6327, 16.6381, 16.6436, 16.649, 16.6545, 16.6599, 16.6654,
      16.6709, 16.6763, 16.6818, 16.6872, 16.6927, 16.6981, 16.7036, 16.709,
      16.7145, 16.72, 16.7254, 16.7309, 16.7363, 16.7418, 16.7472, 16.7527,
      16.7581, 16.7636, 16.769, 16.7745, 16.78, 16.7854, 16.7909, 16.7963,
      16.8018, 16.8072, 16.8127, 16.8181, 16.8236, 16.829, 16.8345, 16.84,
      16.8454, 16.8509, 16.8563, 16.8618, 16.8672, 16.8727, 16.8781, 16.8836,
      16.8891, 16.8945, 16.9, 16.9054, 16.9109, 16.9163, 16.9218, 16.9272,
      16.9327, 16.9382, 16.9436, 16.9491, 16.9545, 16.96, 16.9654, 16.9709,
      16.9763, 16.9818, 16.9873, 16.9927, 16.9982, 17.0036, 17.0091, 17.0145,
      17.02, 17.0255, 17.0309, 17.0364, 17.0418, 17.0473, 17.0527, 17.0582,
      17.0636, 17.0691, 17.0746, 17.08, 17.0855, 17.0909, 17.0964, 17.1018,
      17.1073, 17.1127, 17.1182, 17.1237, 17.1291, 17.1346, 17.14, 17.1455,
      17.1509, 17.1564, 17.1618, 17.1673, 17.1728, 17.1782, 17.1837, 17.1891,
      17.1946, 17.2, 17.2055, 17.2109, 17.2164, 17.2218, 17.2273, 17.2328,
      17.2382, 17.2437, 17.2491, 17.2546, 17.26, 17.2655, 17.2709, 17.2764,
      17.2818, 17.2873, 17.2927, 17.2982, 17.3037, 17.3091, 17.3146, 17.32,
      17.3255, 17.3309, 17.3364, 17.3418, 17.3473, 17.3527, 17.3582, 17.3636,
      17.3691, 17.3745, 17.38, 17.3854, 17.3909, 17.3963, 17.4018, 17.4072,
      17.4127, 17.4181, 17.4236, 17.429, 17.4345, 17.4399, 17.4454, 17.4508,
      17.4563, 17.4617, 17.4672, 17.4726, 17.4781, 17.4835, 17.489, 17.4944,
      17.4999, 17.5053, 17.5107, 17.5162, 17.5216, 17.5271, 17.5325, 17.538,
      17.5434, 17.5489, 17.5543, 17.5598, 17.5652, 17.5706, 17.5761, 17.5815,
      17.587, 17.5924, 17.5979, 17.6033, 17.6087, 17.6142, 17.6196, 17.6251,
      17.6305, 17.636, 17.6414, 17.6468, 17.6523, 17.6577, 17.6632, 17.6686,
      17.674, 17.6795, 17.6849, 17.6904, 17.6958, 17.7012, 17.7067, 17.7121,
      17.7175, 17.723, 17.7284, 17.7339, 17.7393, 17.7447, 17.7502, 17.7556,
      17.761, 17.7665, 17.7719, 17.7773, 17.7828, 17.7882, 17.7936, 17.7991,
      17.8045, 17.8099, 17.8154, 17.8208, 17.8262, 17.8317, 17.8371, 17.8425,
      17.848, 17.8534, 17.8588, 17.8642, 17.8697, 17.8751, 17.8805, 17.886,
      17.8914, 17.8968, 17.9022, 17.9077, 17.9131, 17.9185, 17.924, 17.9294,
      17.9348, 17.9402, 17.9457, 17.9511, 17.9565, 17.9619, 17.9674, 17.9728,
      17.9782, 17.9836, 17.989, 17.9945, 17.9999, 18.0053, 18.0107, 18.0162,
      18.0216, 18.027, 18.0324, 18.0378, 18.0432, 18.0487, 18.0541, 18.0595,
      18.0649, 18.0703, 18.0758, 18.0812, 18.0866, 18.092, 18.0974, 18.1028,
      18.1082, 18.1137, 18.1191, 18.1245, 18.1299, 18.1353, 18.1407, 18.1461,
      18.1515, 18.157, 18.1624, 18.1678, 18.1732, 18.1786, 18.184, 18.1894,
      18.1948, 18.2002, 18.2056, 18.211, 18.2164, 18.2218, 18.2272, 18.2327,
      18.2381, 18.2435, 18.2489, 18.2543, 18.2597, 18.2651, 18.2705, 18.2759,
      18.2813, 18.2867, 18.2921, 18.2975, 18.3029, 18.3083, 18.3137, 18.319,
      18.3244, 18.3298, 18.3352, 18.3406, 18.346, 18.3514, 18.3568, 18.3622,
      18.3676, 18.373, 18.3784, 18.3838, 18.3891, 18.3945, 18.3999, 18.4053,
      18.4107, 18.4161, 18.4215, 18.4268, 18.4322, 18.4376, 18.443, 18.4484,
      18.4538, 18.4591, 18.4645, 18.4699, 18.4753, 18.4807, 18.486, 18.4914,
      18.4968,
    ],
  },
  SD2: {
    label: '2 SD',
    weight: [
      4.394, 4.419, 4.421, 4.453, 4.49, 4.528, 4.568, 4.609, 4.65, 4.693, 4.738,
      4.784, 4.831, 4.88, 4.931, 4.983, 5.035, 5.089, 5.143, 5.196, 5.25, 5.304,
      5.358, 5.411, 5.464, 5.517, 5.57, 5.622, 5.674, 5.725, 5.776, 5.827,
      5.877, 5.926, 5.975, 6.023, 6.071, 6.118, 6.164, 6.21, 6.256, 6.3, 6.345,
      6.388, 6.432, 6.475, 6.517, 6.558, 6.6, 6.641, 6.681, 6.721, 6.76, 6.799,
      6.837, 6.876, 6.913, 6.95, 6.987, 7.024, 7.06, 7.095, 7.131, 7.166, 7.2,
      7.234, 7.268, 7.302, 7.335, 7.368, 7.4, 7.433, 7.464, 7.496, 7.527, 7.558,
      7.589, 7.619, 7.649, 7.679, 7.709, 7.738, 7.767, 7.795, 7.824, 7.852,
      7.88, 7.907, 7.935, 7.962, 7.989, 8.016, 8.042, 8.069, 8.095, 8.121,
      8.146, 8.172, 8.197, 8.222, 8.247, 8.272, 8.296, 8.321, 8.345, 8.369,
      8.392, 8.416, 8.44, 8.463, 8.486, 8.509, 8.532, 8.555, 8.577, 8.6, 8.622,
      8.644, 8.666, 8.688, 8.709, 8.731, 8.752, 8.773, 8.794, 8.815, 8.836,
      8.857, 8.878, 8.898, 8.919, 8.939, 8.959, 8.979, 8.999, 9.019, 9.038,
      9.058, 9.077, 9.097, 9.116, 9.135, 9.154, 9.173, 9.192, 9.211, 9.229,
      9.248, 9.266, 9.285, 9.303, 9.321, 9.339, 9.357, 9.375, 9.392, 9.41,
      9.427, 9.445, 9.462, 9.48, 9.497, 9.514, 9.531, 9.548, 9.565, 9.581,
      9.598, 9.615, 9.631, 9.648, 9.664, 9.68, 9.696, 9.713, 9.729, 9.745,
      9.761, 9.776, 9.792, 9.808, 9.823, 9.839, 9.855, 9.87, 9.885, 9.901,
      9.916, 9.931, 9.946, 9.961, 9.976, 9.991, 10.006, 10.021, 10.035, 10.05,
      10.065, 10.079, 10.094, 10.108, 10.123, 10.137, 10.151, 10.165, 10.179,
      10.193, 10.207, 10.221, 10.235, 10.249, 10.263, 10.276, 10.29, 10.304,
      10.317, 10.331, 10.345, 10.358, 10.371, 10.384, 10.398, 10.411, 10.424,
      10.437, 10.45, 10.464, 10.476, 10.489, 10.502, 10.515, 10.528, 10.541,
      10.553, 10.566, 10.579, 10.591, 10.604, 10.616, 10.629, 10.641, 10.654,
      10.666, 10.678, 10.69, 10.703, 10.715, 10.727, 10.739, 10.751, 10.763,
      10.775, 10.787, 10.799, 10.811, 10.823, 10.835, 10.847, 10.858, 10.87,
      10.882, 10.893, 10.905, 10.917, 10.928, 10.94, 10.951, 10.963, 10.974,
      10.986, 10.997, 11.008, 11.02, 11.031, 11.042, 11.054, 11.065, 11.076,
      11.087, 11.098, 11.11, 11.121, 11.132, 11.143, 11.154, 11.165, 11.176,
      11.187, 11.198, 11.209, 11.22, 11.23, 11.241, 11.252, 11.263, 11.274,
      11.284, 11.295, 11.306, 11.316, 11.327, 11.338, 11.348, 11.359, 11.369,
      11.38, 11.39, 11.401, 11.411, 11.422, 11.432, 11.443, 11.453, 11.464,
      11.474, 11.484, 11.495, 11.505, 11.515, 11.525, 11.536, 11.546, 11.556,
      11.566, 11.577, 11.587, 11.597, 11.607, 11.617, 11.628, 11.638, 11.648,
      11.658, 11.668, 11.678, 11.688, 11.698, 11.708, 11.718, 11.728, 11.738,
      11.748, 11.758, 11.768, 11.778, 11.788, 11.798, 11.807, 11.817, 11.827,
      11.837, 11.847, 11.857, 11.867, 11.876, 11.886, 11.896, 11.906, 11.915,
      11.925, 11.935, 11.944, 11.954, 11.964, 11.974, 11.983, 11.993, 12.003,
      12.012, 12.022, 12.032, 12.041, 12.051, 12.06, 12.07, 12.08, 12.089,
      12.099, 12.108, 12.118, 12.127, 12.137, 12.147, 12.156, 12.165, 12.175,
      12.184, 12.194, 12.204, 12.213, 12.222, 12.232, 12.241, 12.251, 12.26,
      12.269, 12.279, 12.288, 12.298, 12.307, 12.316, 12.326, 12.335, 12.344,
      12.354, 12.363, 12.372, 12.382, 12.391, 12.401, 12.41, 12.419, 12.428,
      12.438, 12.447, 12.456, 12.465, 12.475, 12.484, 12.493, 12.503, 12.512,
      12.521, 12.53, 12.539, 12.548, 12.558, 12.567, 12.576, 12.585, 12.595,
      12.604, 12.613, 12.622, 12.631, 12.641, 12.65, 12.659, 12.668, 12.677,
      12.686, 12.695, 12.704, 12.714, 12.723, 12.732, 12.741, 12.75, 12.759,
      12.768, 12.777, 12.786, 12.796, 12.805, 12.814, 12.823, 12.832, 12.841,
      12.85, 12.859, 12.868, 12.877, 12.886, 12.895, 12.905, 12.914, 12.923,
      12.932, 12.941, 12.95, 12.959, 12.968, 12.977, 12.986, 12.995, 13.004,
      13.013, 13.022, 13.031, 13.04, 13.049, 13.058, 13.067, 13.076, 13.085,
      13.094, 13.103, 13.112, 13.121, 13.13, 13.139, 13.148, 13.157, 13.166,
      13.175, 13.184, 13.193, 13.202, 13.211, 13.22, 13.229, 13.238, 13.247,
      13.256, 13.265, 13.274, 13.283, 13.291, 13.301, 13.309, 13.318, 13.327,
      13.336, 13.345, 13.354, 13.363, 13.372, 13.381, 13.39, 13.399, 13.408,
      13.417, 13.425, 13.434, 13.443, 13.452, 13.461, 13.47, 13.479, 13.488,
      13.497, 13.506, 13.515, 13.523, 13.532, 13.541, 13.55, 13.559, 13.568,
      13.577, 13.586, 13.595, 13.603, 13.612, 13.621, 13.63, 13.639, 13.648,
      13.657, 13.666, 13.674, 13.684, 13.692, 13.701, 13.71, 13.719, 13.728,
      13.737, 13.746, 13.754, 13.763, 13.772, 13.781, 13.79, 13.799, 13.807,
      13.816, 13.825, 13.834, 13.843, 13.852, 13.861, 13.869, 13.878, 13.887,
      13.896, 13.905, 13.914, 13.923, 13.932, 13.94, 13.949, 13.958, 13.967,
      13.976, 13.985, 13.993, 14.002, 14.011, 14.02, 14.029, 14.038, 14.047,
      14.055, 14.064, 14.073, 14.082, 14.091, 14.1, 14.109, 14.117, 14.126,
      14.135, 14.144, 14.153, 14.162, 14.171, 14.179, 14.188, 14.197, 14.206,
      14.215, 14.224, 14.233, 14.242, 14.25, 14.259, 14.268, 14.277, 14.286,
      14.295, 14.303, 14.312, 14.321, 14.33, 14.339, 14.348, 14.357, 14.366,
      14.374, 14.383, 14.392, 14.401, 14.41, 14.419, 14.428, 14.436, 14.446,
      14.454, 14.463, 14.472, 14.481, 14.49, 14.499, 14.508, 14.516, 14.525,
      14.534, 14.543, 14.552, 14.561, 14.57, 14.578, 14.588, 14.596, 14.605,
      14.614, 14.623, 14.632, 14.641, 14.65, 14.659, 14.668, 14.676, 14.685,
      14.694, 14.703, 14.712, 14.721, 14.73, 14.739, 14.748, 14.756, 14.765,
      14.774, 14.783, 14.792, 14.801, 14.81, 14.819, 14.828, 14.837, 14.845,
      14.854, 14.863, 14.872, 14.881, 14.89, 14.899, 14.907, 14.916, 14.925,
      14.934, 14.943, 14.952, 14.961, 14.97, 14.979, 14.987, 14.996, 15.005,
      15.014, 15.023, 15.032, 15.041, 15.05, 15.059, 15.068, 15.077, 15.086,
      15.094, 15.103, 15.112, 15.121, 15.13, 15.139, 15.148, 15.157, 15.166,
      15.174, 15.183, 15.192, 15.201, 15.21, 15.219, 15.228, 15.237, 15.246,
      15.254, 15.263, 15.272, 15.281, 15.29, 15.299, 15.308, 15.317, 15.326,
      15.335, 15.343, 15.352, 15.361, 15.37, 15.379, 15.388, 15.397, 15.406,
      15.415, 15.423, 15.432, 15.441, 15.45, 15.459, 15.468, 15.477, 15.485,
      15.494, 15.503, 15.512, 15.521, 15.53, 15.539, 15.548, 15.556, 15.565,
      15.574, 15.583, 15.592, 15.601, 15.609, 15.618, 15.627, 15.636, 15.645,
      15.654, 15.662, 15.671, 15.68, 15.689, 15.698, 15.707, 15.715, 15.724,
      15.733, 15.742, 15.751, 15.759, 15.768, 15.777, 15.786, 15.795, 15.803,
      15.812, 15.821, 15.829, 15.838, 15.847, 15.856, 15.864, 15.873, 15.882,
      15.891, 15.9, 15.908, 15.917, 15.926, 15.934, 15.943, 15.952, 15.961,
      15.969, 15.978, 15.987, 15.996, 16.004, 16.013, 16.022, 16.03, 16.039,
      16.048, 16.057, 16.065, 16.074, 16.082, 16.091, 16.1, 16.108, 16.117,
      16.126, 16.134, 16.143, 16.151, 16.16, 16.169, 16.177, 16.186, 16.195,
      16.203, 16.212, 16.22, 16.229, 16.238, 16.246, 16.255, 16.264, 16.272,
      16.28, 16.289, 16.298, 16.306, 16.315, 16.323, 16.332, 16.34, 16.349,
      16.357, 16.366, 16.375, 16.383, 16.392, 16.4, 16.409, 16.417, 16.426,
      16.434, 16.442, 16.451, 16.459, 16.468, 16.476, 16.485, 16.493, 16.502,
      16.51, 16.519, 16.527, 16.536, 16.544, 16.553, 16.561, 16.569, 16.578,
      16.586, 16.594, 16.603, 16.611, 16.62, 16.628, 16.637, 16.645, 16.653,
      16.662, 16.67, 16.678, 16.687, 16.695, 16.703, 16.712, 16.72, 16.728,
      16.737, 16.745, 16.754, 16.762, 16.77, 16.778, 16.787, 16.795, 16.803,
      16.811, 16.82, 16.828, 16.836, 16.845, 16.853, 16.861, 16.87, 16.878,
      16.886, 16.894, 16.903, 16.911, 16.919, 16.927, 16.936, 16.944, 16.952,
      16.96, 16.968, 16.977, 16.985, 16.993, 17.001, 17.01, 17.018, 17.026,
      17.034, 17.042, 17.05, 17.059, 17.067, 17.075, 17.083, 17.091, 17.099,
      17.108, 17.116, 17.124, 17.132, 17.14, 17.148, 17.157, 17.165, 17.173,
      17.181, 17.189, 17.197, 17.205, 17.213, 17.221, 17.23, 17.238, 17.246,
      17.254, 17.262, 17.27, 17.278, 17.286, 17.294, 17.302, 17.311, 17.319,
      17.327, 17.335, 17.343, 17.351, 17.359, 17.367, 17.375, 17.383, 17.391,
      17.399, 17.407, 17.415, 17.423, 17.431, 17.439, 17.447, 17.455, 17.463,
      17.471, 17.479, 17.487, 17.495, 17.503, 17.511, 17.519, 17.527, 17.535,
      17.543, 17.551, 17.559, 17.567, 17.575, 17.583, 17.591, 17.599, 17.607,
      17.615, 17.623, 17.631, 17.639, 17.647, 17.655, 17.663, 17.67, 17.678,
      17.687, 17.694, 17.702, 17.71, 17.718, 17.726, 17.734, 17.742, 17.75,
      17.758, 17.766, 17.774, 17.782, 17.79, 17.797, 17.805, 17.813, 17.821,
      17.829, 17.837, 17.845, 17.852, 17.861, 17.868, 17.876, 17.884, 17.892,
      17.9, 17.908, 17.915, 17.923, 17.931, 17.939, 17.947, 17.955, 17.963,
      17.971, 17.979, 17.986, 17.994, 18.002, 18.01, 18.018, 18.026, 18.034,
      18.041, 18.049, 18.057, 18.065, 18.073, 18.081, 18.088, 18.096, 18.104,
      18.112, 18.12, 18.127, 18.135, 18.143, 18.151, 18.159, 18.167, 18.175,
      18.183, 18.19, 18.198, 18.206, 18.214, 18.221, 18.229, 18.237, 18.245,
      18.253, 18.26, 18.268, 18.276, 18.284, 18.292, 18.3, 18.307, 18.315,
      18.323, 18.331, 18.338, 18.346, 18.354, 18.362, 18.37, 18.377, 18.385,
      18.393, 18.401, 18.409, 18.417, 18.424, 18.432, 18.44, 18.448, 18.455,
      18.463, 18.471, 18.479, 18.486, 18.494, 18.502, 18.51, 18.517, 18.525,
      18.533, 18.541, 18.549, 18.557, 18.564, 18.572, 18.58, 18.588, 18.595,
      18.603, 18.611, 18.619, 18.626, 18.634, 18.642, 18.65, 18.658, 18.665,
      18.673, 18.681, 18.689, 18.696, 18.704, 18.712, 18.72, 18.727, 18.735,
      18.743, 18.751, 18.759, 18.766, 18.774, 18.782, 18.79, 18.797, 18.805,
      18.813, 18.821, 18.828, 18.836, 18.844, 18.852, 18.86, 18.867, 18.875,
      18.883, 18.891, 18.898, 18.906, 18.914, 18.922, 18.929, 18.937, 18.945,
      18.953, 18.961, 18.968, 18.976, 18.984, 18.992, 18.999, 19.007, 19.015,
      19.023, 19.031, 19.038, 19.046, 19.054, 19.062, 19.069, 19.077, 19.085,
      19.093, 19.1, 19.108, 19.116, 19.124, 19.132, 19.139, 19.147, 19.155,
      19.163, 19.17, 19.178, 19.186, 19.194, 19.202, 19.209, 19.217, 19.225,
      19.233, 19.241, 19.248, 19.256, 19.264, 19.272, 19.279, 19.287, 19.295,
      19.303, 19.311, 19.318, 19.326, 19.334, 19.342, 19.35, 19.357, 19.365,
      19.373, 19.381, 19.389, 19.397, 19.404, 19.412, 19.42, 19.427, 19.435,
      19.443, 19.451, 19.459, 19.467, 19.474, 19.482, 19.49, 19.498, 19.506,
      19.513, 19.521, 19.529, 19.537, 19.545, 19.552, 19.56, 19.568, 19.576,
      19.584, 19.592, 19.599, 19.607, 19.615, 19.623, 19.631, 19.638, 19.646,
      19.654, 19.662, 19.67, 19.677, 19.685, 19.693, 19.701, 19.709, 19.717,
      19.725, 19.732, 19.74, 19.748, 19.756, 19.764, 19.772, 19.78, 19.787,
      19.795, 19.803, 19.811, 19.819, 19.826, 19.834, 19.842, 19.85, 19.858,
      19.866, 19.873, 19.881, 19.889, 19.897, 19.905, 19.913, 19.921, 19.928,
      19.936, 19.944, 19.952, 19.96, 19.968, 19.976, 19.984, 19.991, 19.999,
      20.007, 20.015, 20.023, 20.03, 20.039, 20.046, 20.054, 20.062, 20.07,
      20.078, 20.086, 20.094, 20.102, 20.109, 20.117, 20.125, 20.133, 20.141,
      20.149, 20.156, 20.164, 20.172, 20.18, 20.188, 20.196, 20.204, 20.212,
      20.22, 20.227, 20.235, 20.243, 20.251, 20.259, 20.267, 20.275, 20.283,
      20.291, 20.299, 20.306, 20.314, 20.322, 20.33, 20.338, 20.346, 20.354,
      20.362, 20.37, 20.378, 20.385, 20.393, 20.401, 20.409, 20.417, 20.425,
      20.433, 20.441, 20.449, 20.457, 20.465, 20.473, 20.48, 20.488, 20.496,
      20.504, 20.512, 20.52, 20.528, 20.536, 20.544, 20.552, 20.56, 20.568,
      20.576, 20.583, 20.591, 20.599, 20.607, 20.615, 20.623, 20.631, 20.639,
      20.647, 20.655, 20.663, 20.671, 20.679, 20.687, 20.694, 20.702, 20.71,
      20.718, 20.726, 20.734, 20.742, 20.75, 20.758, 20.766, 20.774, 20.782,
      20.79, 20.798, 20.806, 20.814, 20.822, 20.83, 20.837, 20.846, 20.854,
      20.862, 20.87, 20.878, 20.886, 20.894, 20.902, 20.909, 20.917, 20.925,
      20.933, 20.941, 20.949, 20.957, 20.965, 20.973, 20.981, 20.989, 20.997,
      21.005, 21.013, 21.021, 21.029, 21.037, 21.045, 21.053, 21.061, 21.069,
      21.077, 21.085, 21.093, 21.101, 21.109, 21.117, 21.125, 21.133, 21.141,
      21.149, 21.157, 21.165, 21.173, 21.181, 21.189, 21.197, 21.205, 21.213,
      21.221, 21.229, 21.237, 21.245, 21.253, 21.261, 21.269, 21.277, 21.285,
      21.293, 21.301, 21.309, 21.317, 21.325, 21.333, 21.341, 21.349, 21.357,
      21.365, 21.373, 21.381, 21.389, 21.397, 21.405, 21.413, 21.421, 21.429,
      21.438, 21.445, 21.454, 21.462, 21.47, 21.478, 21.486, 21.494, 21.502,
      21.51, 21.518, 21.526, 21.534, 21.542, 21.55, 21.558, 21.566, 21.574,
      21.582, 21.59, 21.599, 21.607, 21.615, 21.623, 21.631, 21.639, 21.647,
      21.655, 21.663, 21.671, 21.679, 21.688, 21.696, 21.704, 21.712, 21.72,
      21.728, 21.736, 21.744, 21.752, 21.76, 21.768, 21.776, 21.784, 21.792,
      21.8, 21.809, 21.817, 21.825, 21.833, 21.841, 21.849, 21.857, 21.866,
      21.874, 21.882, 21.89, 21.898, 21.906, 21.914, 21.922, 21.93, 21.938,
      21.946, 21.954, 21.962, 21.971, 21.979, 21.987, 21.995, 22.003, 22.012,
      22.02, 22.028, 22.036, 22.044, 22.052, 22.06, 22.068, 22.076, 22.084,
      22.092, 22.101, 22.109, 22.117, 22.125, 22.133, 22.142, 22.15, 22.158,
      22.166, 22.174, 22.182, 22.19, 22.198, 22.206, 22.215, 22.223, 22.231,
      22.239, 22.247, 22.256, 22.264, 22.272, 22.28, 22.288, 22.296, 22.304,
      22.312, 22.32, 22.329, 22.337, 22.345, 22.353, 22.362, 22.37, 22.378,
      22.386, 22.394, 22.402, 22.41, 22.419, 22.427, 22.435, 22.443, 22.451,
      22.46, 22.468, 22.476, 22.484, 22.492, 22.5, 22.508, 22.517, 22.525,
      22.533, 22.541, 22.549, 22.558, 22.566, 22.574, 22.582, 22.59, 22.599,
      22.607, 22.615, 22.623, 22.631, 22.64, 22.648, 22.656, 22.664, 22.672,
      22.68, 22.689, 22.697, 22.705, 22.713, 22.721, 22.73, 22.738, 22.746,
      22.754, 22.763, 22.771, 22.779, 22.787, 22.795, 22.803, 22.812, 22.82,
      22.828, 22.836, 22.845, 22.853, 22.861, 22.869, 22.877, 22.886, 22.894,
      22.902, 22.91, 22.919, 22.927, 22.935, 22.943, 22.951, 22.959, 22.968,
      22.976, 22.984, 22.993, 23.001, 23.009, 23.017, 23.025, 23.033, 23.042,
      23.05, 23.058, 23.067, 23.075, 23.083, 23.091, 23.099, 23.107, 23.116,
      23.124, 23.132, 23.141, 23.149, 23.157, 23.165, 23.173, 23.182, 23.19,
      23.198, 23.206, 23.215, 23.223, 23.231, 23.239, 23.247, 23.256, 23.264,
      23.272, 23.28, 23.289, 23.297, 23.305, 23.313, 23.321, 23.33, 23.338,
      23.346, 23.354, 23.363, 23.371, 23.379, 23.387, 23.396, 23.404, 23.412,
      23.42, 23.428, 23.437, 23.445, 23.453, 23.462, 23.47, 23.478, 23.486,
      23.494, 23.502, 23.511, 23.519, 23.528, 23.536, 23.544, 23.552, 23.56,
      23.569, 23.577, 23.585, 23.594, 23.602, 23.61, 23.618, 23.626, 23.635,
      23.643, 23.651, 23.66, 23.668, 23.676, 23.684, 23.692, 23.701, 23.709,
      23.717, 23.725, 23.734, 23.742, 23.75, 23.759, 23.767, 23.775, 23.783,
      23.791, 23.799, 23.808, 23.816, 23.825, 23.833, 23.841, 23.849, 23.857,
      23.866, 23.874, 23.882, 23.891, 23.899, 23.907, 23.915, 23.924, 23.932,
      23.94, 23.948, 23.956, 23.965, 23.973, 23.981, 23.99, 23.998, 24.006,
      24.014, 24.023, 24.031, 24.039, 24.047, 24.056, 24.064, 24.072, 24.08,
      24.089, 24.097, 24.105, 24.113, 24.121, 24.13, 24.138, 24.147, 24.155,
      24.163, 24.171, 24.179, 24.188, 24.196, 24.204, 24.212, 24.22, 24.229,
      24.237, 24.245, 24.254, 24.262, 24.27, 24.278, 24.287, 24.295, 24.303,
      24.311, 24.32, 24.328, 24.336, 24.345, 24.353, 24.361, 24.369, 24.377,
      24.386, 24.394, 24.402, 24.411,
    ],
  },
  SD3: {
    label: '3 SD',
    weight: [
      5.013, 5.031, 5.045, 5.083, 5.124, 5.167, 5.211, 5.257, 5.303, 5.351, 5.4,
      5.451, 5.503, 5.558, 5.613, 5.671, 5.729, 5.787, 5.847, 5.906, 5.965,
      6.024, 6.083, 6.142, 6.2, 6.259, 6.316, 6.373, 6.43, 6.487, 6.542, 6.597,
      6.652, 6.706, 6.759, 6.812, 6.864, 6.915, 6.965, 7.016, 7.065, 7.114,
      7.162, 7.209, 7.256, 7.303, 7.349, 7.394, 7.438, 7.483, 7.526, 7.569,
      7.612, 7.654, 7.696, 7.737, 7.777, 7.817, 7.857, 7.896, 7.935, 7.974,
      8.012, 8.049, 8.087, 8.123, 8.16, 8.196, 8.232, 8.267, 8.302, 8.337,
      8.371, 8.405, 8.438, 8.471, 8.504, 8.537, 8.569, 8.601, 8.633, 8.664,
      8.695, 8.726, 8.756, 8.787, 8.817, 8.846, 8.876, 8.905, 8.934, 8.962,
      8.991, 9.019, 9.047, 9.075, 9.102, 9.13, 9.157, 9.184, 9.211, 9.237,
      9.263, 9.29, 9.315, 9.341, 9.367, 9.392, 9.418, 9.443, 9.467, 9.492,
      9.516, 9.541, 9.565, 9.59, 9.613, 9.637, 9.661, 9.684, 9.707, 9.731,
      9.754, 9.777, 9.8, 9.822, 9.845, 9.867, 9.889, 9.912, 9.934, 9.956, 9.978,
      9.999, 10.021, 10.042, 10.064, 10.085, 10.106, 10.127, 10.148, 10.168,
      10.189, 10.21, 10.23, 10.251, 10.271, 10.291, 10.311, 10.331, 10.351,
      10.371, 10.39, 10.41, 10.429, 10.449, 10.468, 10.487, 10.506, 10.525,
      10.544, 10.563, 10.582, 10.6, 10.619, 10.637, 10.655, 10.674, 10.692,
      10.71, 10.728, 10.746, 10.764, 10.782, 10.799, 10.817, 10.835, 10.852,
      10.87, 10.887, 10.904, 10.921, 10.939, 10.956, 10.973, 10.99, 11.007,
      11.023, 11.04, 11.057, 11.074, 11.09, 11.106, 11.123, 11.139, 11.155,
      11.172, 11.188, 11.204, 11.22, 11.236, 11.252, 11.268, 11.283, 11.299,
      11.315, 11.331, 11.346, 11.361, 11.377, 11.392, 11.408, 11.423, 11.438,
      11.453, 11.468, 11.483, 11.499, 11.513, 11.528, 11.543, 11.558, 11.573,
      11.587, 11.602, 11.616, 11.631, 11.646, 11.66, 11.674, 11.689, 11.703,
      11.717, 11.731, 11.746, 11.759, 11.774, 11.787, 11.802, 11.816, 11.829,
      11.843, 11.857, 11.871, 11.884, 11.898, 11.912, 11.925, 11.939, 11.953,
      11.966, 11.979, 11.993, 12.006, 12.02, 12.033, 12.046, 12.059, 12.073,
      12.086, 12.099, 12.112, 12.125, 12.138, 12.151, 12.164, 12.177, 12.19,
      12.203, 12.215, 12.228, 12.241, 12.254, 12.267, 12.279, 12.292, 12.304,
      12.317, 12.33, 12.342, 12.355, 12.367, 12.38, 12.392, 12.404, 12.417,
      12.429, 12.442, 12.454, 12.466, 12.478, 12.491, 12.503, 12.515, 12.527,
      12.539, 12.551, 12.564, 12.576, 12.587, 12.6, 12.611, 12.624, 12.635,
      12.647, 12.659, 12.671, 12.683, 12.695, 12.707, 12.718, 12.73, 12.742,
      12.753, 12.765, 12.777, 12.789, 12.8, 12.812, 12.823, 12.835, 12.847,
      12.858, 12.87, 12.881, 12.893, 12.904, 12.916, 12.927, 12.939, 12.95,
      12.962, 12.973, 12.984, 12.996, 13.007, 13.019, 13.03, 13.041, 13.052,
      13.064, 13.075, 13.086, 13.097, 13.109, 13.12, 13.131, 13.142, 13.154,
      13.165, 13.176, 13.187, 13.198, 13.209, 13.22, 13.231, 13.242, 13.253,
      13.264, 13.275, 13.287, 13.297, 13.308, 13.32, 13.331, 13.341, 13.352,
      13.363, 13.374, 13.385, 13.396, 13.407, 13.418, 13.429, 13.44, 13.451,
      13.461, 13.472, 13.483, 13.494, 13.505, 13.516, 13.527, 13.537, 13.548,
      13.559, 13.57, 13.581, 13.591, 13.602, 13.612, 13.623, 13.634, 13.645,
      13.656, 13.666, 13.677, 13.687, 13.698, 13.709, 13.72, 13.73, 13.741,
      13.751, 13.762, 13.773, 13.783, 13.794, 13.805, 13.815, 13.826, 13.836,
      13.847, 13.857, 13.868, 13.879, 13.889, 13.9, 13.91, 13.921, 13.932,
      13.942, 13.952, 13.963, 13.973, 13.984, 13.994, 14.005, 14.015, 14.026,
      14.036, 14.047, 14.057, 14.068, 14.078, 14.089, 14.099, 14.11, 14.12,
      14.13, 14.141, 14.151, 14.162, 14.172, 14.183, 14.193, 14.203, 14.214,
      14.224, 14.234, 14.245, 14.255, 14.266, 14.276, 14.287, 14.297, 14.308,
      14.318, 14.328, 14.339, 14.349, 14.359, 14.369, 14.38, 14.39, 14.4,
      14.411, 14.421, 14.432, 14.442, 14.452, 14.463, 14.473, 14.483, 14.493,
      14.504, 14.514, 14.525, 14.535, 14.545, 14.555, 14.566, 14.576, 14.587,
      14.597, 14.607, 14.617, 14.628, 14.638, 14.648, 14.659, 14.669, 14.679,
      14.689, 14.7, 14.71, 14.72, 14.731, 14.741, 14.751, 14.762, 14.772,
      14.782, 14.792, 14.803, 14.813, 14.823, 14.833, 14.844, 14.854, 14.864,
      14.874, 14.885, 14.895, 14.905, 14.915, 14.926, 14.936, 14.946, 14.957,
      14.967, 14.977, 14.987, 14.997, 15.008, 15.018, 15.028, 15.038, 15.048,
      15.059, 15.069, 15.079, 15.09, 15.1, 15.11, 15.12, 15.13, 15.14, 15.151,
      15.161, 15.171, 15.182, 15.192, 15.202, 15.212, 15.222, 15.233, 15.243,
      15.253, 15.263, 15.273, 15.284, 15.294, 15.304, 15.314, 15.324, 15.335,
      15.345, 15.356, 15.365, 15.376, 15.386, 15.396, 15.406, 15.416, 15.427,
      15.437, 15.447, 15.457, 15.468, 15.478, 15.488, 15.498, 15.509, 15.519,
      15.529, 15.539, 15.55, 15.56, 15.57, 15.58, 15.591, 15.601, 15.611,
      15.621, 15.631, 15.641, 15.652, 15.662, 15.672, 15.683, 15.693, 15.703,
      15.713, 15.724, 15.734, 15.744, 15.754, 15.764, 15.775, 15.785, 15.795,
      15.805, 15.816, 15.826, 15.836, 15.846, 15.856, 15.867, 15.877, 15.887,
      15.898, 15.908, 15.918, 15.929, 15.939, 15.949, 15.959, 15.97, 15.98,
      15.99, 16, 16.011, 16.021, 16.031, 16.042, 16.052, 16.062, 16.073, 16.083,
      16.093, 16.103, 16.113, 16.124, 16.134, 16.144, 16.155, 16.165, 16.175,
      16.186, 16.196, 16.206, 16.216, 16.227, 16.237, 16.247, 16.258, 16.268,
      16.278, 16.289, 16.299, 16.31, 16.319, 16.33, 16.34, 16.351, 16.361,
      16.371, 16.382, 16.392, 16.402, 16.413, 16.423, 16.434, 16.444, 16.454,
      16.464, 16.475, 16.485, 16.495, 16.506, 16.516, 16.526, 16.537, 16.547,
      16.558, 16.568, 16.578, 16.588, 16.599, 16.609, 16.62, 16.63, 16.64,
      16.651, 16.661, 16.672, 16.682, 16.692, 16.702, 16.713, 16.723, 16.733,
      16.744, 16.754, 16.765, 16.775, 16.786, 16.796, 16.806, 16.817, 16.827,
      16.838, 16.848, 16.858, 16.868, 16.879, 16.889, 16.9, 16.91, 16.921,
      16.931, 16.941, 16.952, 16.962, 16.972, 16.983, 16.993, 17.004, 17.014,
      17.024, 17.035, 17.045, 17.056, 17.066, 17.077, 17.087, 17.097, 17.107,
      17.118, 17.128, 17.139, 17.149, 17.16, 17.17, 17.181, 17.191, 17.202,
      17.211, 17.222, 17.232, 17.243, 17.253, 17.264, 17.274, 17.285, 17.295,
      17.305, 17.316, 17.326, 17.337, 17.347, 17.357, 17.367, 17.378, 17.388,
      17.399, 17.409, 17.419, 17.43, 17.44, 17.451, 17.461, 17.472, 17.482,
      17.492, 17.503, 17.513, 17.523, 17.533, 17.544, 17.554, 17.565, 17.575,
      17.585, 17.596, 17.606, 17.616, 17.627, 17.637, 17.647, 17.658, 17.668,
      17.679, 17.689, 17.699, 17.71, 17.72, 17.73, 17.741, 17.751, 17.761,
      17.771, 17.781, 17.792, 17.802, 17.812, 17.823, 17.833, 17.843, 17.854,
      17.864, 17.874, 17.884, 17.895, 17.905, 17.915, 17.926, 17.936, 17.946,
      17.956, 17.967, 17.977, 17.987, 17.997, 18.008, 18.018, 18.028, 18.038,
      18.049, 18.059, 18.069, 18.079, 18.089, 18.099, 18.109, 18.12, 18.13,
      18.14, 18.15, 18.16, 18.171, 18.181, 18.191, 18.201, 18.211, 18.221,
      18.232, 18.242, 18.252, 18.262, 18.272, 18.282, 18.292, 18.302, 18.312,
      18.322, 18.332, 18.343, 18.353, 18.363, 18.373, 18.383, 18.393, 18.403,
      18.413, 18.423, 18.433, 18.443, 18.454, 18.464, 18.474, 18.484, 18.493,
      18.503, 18.513, 18.523, 18.533, 18.543, 18.553, 18.564, 18.574, 18.583,
      18.594, 18.603, 18.614, 18.623, 18.634, 18.643, 18.653, 18.663, 18.673,
      18.683, 18.693, 18.703, 18.713, 18.723, 18.733, 18.743, 18.753, 18.763,
      18.772, 18.782, 18.792, 18.802, 18.812, 18.821, 18.831, 18.841, 18.851,
      18.861, 18.871, 18.881, 18.891, 18.9, 18.91, 18.92, 18.93, 18.94, 18.95,
      18.959, 18.969, 18.979, 18.989, 18.999, 19.009, 19.018, 19.028, 19.037,
      19.047, 19.057, 19.067, 19.077, 19.087, 19.096, 19.106, 19.116, 19.125,
      19.135, 19.145, 19.155, 19.165, 19.174, 19.184, 19.194, 19.204, 19.213,
      19.222, 19.232, 19.242, 19.252, 19.261, 19.271, 19.281, 19.291, 19.301,
      19.31, 19.319, 19.329, 19.339, 19.349, 19.358, 19.368, 19.378, 19.387,
      19.397, 19.406, 19.416, 19.426, 19.435, 19.445, 19.455, 19.464, 19.474,
      19.483, 19.493, 19.503, 19.512, 19.522, 19.531, 19.541, 19.551, 19.56,
      19.569, 19.579, 19.589, 19.598, 19.608, 19.618, 19.627, 19.636, 19.646,
      19.656, 19.665, 19.675, 19.684, 19.694, 19.703, 19.713, 19.722, 19.732,
      19.742, 19.751, 19.761, 19.77, 19.779, 19.789, 19.799, 19.808, 19.818,
      19.827, 19.836, 19.846, 19.855, 19.865, 19.875, 19.884, 19.893, 19.903,
      19.912, 19.922, 19.931, 19.941, 19.95, 19.959, 19.969, 19.979, 19.988,
      19.998, 20.006, 20.016, 20.026, 20.035, 20.045, 20.054, 20.063, 20.073,
      20.082, 20.092, 20.101, 20.11, 20.12, 20.129, 20.139, 20.148, 20.157,
      20.167, 20.176, 20.186, 20.195, 20.204, 20.214, 20.223, 20.233, 20.242,
      20.251, 20.261, 20.27, 20.28, 20.289, 20.298, 20.308, 20.317, 20.327,
      20.335, 20.345, 20.354, 20.364, 20.373, 20.382, 20.392, 20.401, 20.411,
      20.42, 20.429, 20.439, 20.448, 20.457, 20.467, 20.476, 20.485, 20.495,
      20.504, 20.513, 20.523, 20.532, 20.541, 20.551, 20.56, 20.57, 20.578,
      20.588, 20.597, 20.607, 20.616, 20.625, 20.635, 20.644, 20.653, 20.663,
      20.672, 20.682, 20.69, 20.7, 20.709, 20.719, 20.728, 20.737, 20.747,
      20.755, 20.765, 20.774, 20.784, 20.793, 20.802, 20.812, 20.821, 20.83,
      20.839, 20.849, 20.858, 20.867, 20.877, 20.886, 20.895, 20.904, 20.914,
      20.923, 20.932, 20.942, 20.951, 20.96, 20.969, 20.979, 20.988, 20.997,
      21.007, 21.016, 21.025, 21.034, 21.044, 21.053, 21.062, 21.072, 21.081,
      21.09, 21.099, 21.109, 21.118, 21.127, 21.137, 21.146, 21.155, 21.165,
      21.174, 21.183, 21.192, 21.202, 21.211, 21.22, 21.23, 21.239, 21.248,
      21.257, 21.267, 21.276, 21.285, 21.295, 21.304, 21.313, 21.323, 21.332,
      21.341, 21.351, 21.36, 21.369, 21.378, 21.388, 21.397, 21.406, 21.416,
      21.424, 21.434, 21.444, 21.452, 21.462, 21.471, 21.48, 21.49, 21.499,
      21.508, 21.518, 21.527, 21.537, 21.545, 21.555, 21.564, 21.573, 21.583,
      21.592, 21.601, 21.611, 21.62, 21.629, 21.639, 21.648, 21.657, 21.667,
      21.676, 21.685, 21.694, 21.704, 21.713, 21.722, 21.732, 21.741, 21.75,
      21.76, 21.769, 21.778, 21.788, 21.797, 21.806, 21.816, 21.825, 21.834,
      21.844, 21.853, 21.862, 21.872, 21.881, 21.89, 21.9, 21.909, 21.918,
      21.928, 21.937, 21.946, 21.956, 21.965, 21.974, 21.984, 21.993, 22.002,
      22.012, 22.022, 22.03, 22.04, 22.049, 22.058, 22.068, 22.078, 22.086,
      22.096, 22.106, 22.114, 22.124, 22.134, 22.142, 22.152, 22.162, 22.171,
      22.18, 22.19, 22.199, 22.208, 22.218, 22.227, 22.236, 22.246, 22.256,
      22.264, 22.274, 22.284, 22.293, 22.302, 22.312, 22.322, 22.33, 22.34,
      22.35, 22.359, 22.368, 22.378, 22.387, 22.396, 22.406, 22.415, 22.424,
      22.434, 22.444, 22.453, 22.462, 22.472, 22.481, 22.49, 22.5, 22.51,
      22.519, 22.528, 22.538, 22.547, 22.556, 22.566, 22.576, 22.585, 22.594,
      22.604, 22.613, 22.623, 22.632, 22.642, 22.651, 22.66, 22.67, 22.679,
      22.689, 22.698, 22.708, 22.717, 22.727, 22.736, 22.746, 22.755, 22.765,
      22.774, 22.784, 22.793, 22.803, 22.812, 22.822, 22.831, 22.841, 22.85,
      22.86, 22.869, 22.879, 22.888, 22.898, 22.907, 22.917, 22.927, 22.935,
      22.945, 22.955, 22.964, 22.974, 22.983, 22.993, 23.002, 23.012, 23.021,
      23.031, 23.04, 23.05, 23.06, 23.069, 23.078, 23.088, 23.098, 23.108,
      23.117, 23.126, 23.136, 23.146, 23.155, 23.164, 23.174, 23.184, 23.193,
      23.203, 23.212, 23.222, 23.231, 23.241, 23.251, 23.261, 23.27, 23.279,
      23.289, 23.299, 23.308, 23.318, 23.327, 23.337, 23.346, 23.356, 23.366,
      23.376, 23.385, 23.394, 23.404, 23.414, 23.423, 23.433, 23.443, 23.452,
      23.462, 23.471, 23.481, 23.491, 23.501, 23.509, 23.519, 23.529, 23.539,
      23.548, 23.558, 23.568, 23.578, 23.587, 23.596, 23.606, 23.616, 23.626,
      23.635, 23.645, 23.655, 23.664, 23.673, 23.683, 23.693, 23.703, 23.713,
      23.722, 23.732, 23.742, 23.751, 23.76, 23.77, 23.78, 23.79, 23.8, 23.809,
      23.819, 23.829, 23.839, 23.847, 23.857, 23.867, 23.877, 23.887, 23.896,
      23.906, 23.916, 23.926, 23.935, 23.945, 23.955, 23.965, 23.974, 23.984,
      23.993, 24.003, 24.013, 24.023, 24.033, 24.042, 24.052, 24.062, 24.072,
      24.081, 24.091, 24.101, 24.111, 24.12, 24.13, 24.139, 24.149, 24.159,
      24.169, 24.178, 24.188, 24.198, 24.208, 24.218, 24.228, 24.237, 24.247,
      24.257, 24.267, 24.277, 24.286, 24.296, 24.306, 24.316, 24.326, 24.336,
      24.345, 24.355, 24.365, 24.375, 24.384, 24.394, 24.403, 24.413, 24.423,
      24.433, 24.443, 24.453, 24.462, 24.472, 24.482, 24.492, 24.502, 24.512,
      24.521, 24.531, 24.541, 24.551, 24.561, 24.571, 24.581, 24.59, 24.6,
      24.61, 24.62, 24.63, 24.64, 24.65, 24.659, 24.669, 24.679, 24.69, 24.699,
      24.709, 24.719, 24.729, 24.739, 24.749, 24.759, 24.769, 24.779, 24.788,
      24.798, 24.808, 24.818, 24.828, 24.838, 24.848, 24.857, 24.867, 24.877,
      24.887, 24.897, 24.907, 24.917, 24.927, 24.937, 24.947, 24.956, 24.966,
      24.976, 24.986, 24.996, 25.007, 25.017, 25.027, 25.037, 25.046, 25.056,
      25.066, 25.076, 25.086, 25.096, 25.106, 25.116, 25.126, 25.135, 25.145,
      25.155, 25.165, 25.175, 25.186, 25.196, 25.206, 25.216, 25.226, 25.236,
      25.246, 25.256, 25.266, 25.275, 25.285, 25.295, 25.305, 25.315, 25.325,
      25.336, 25.346, 25.356, 25.366, 25.376, 25.386, 25.396, 25.406, 25.416,
      25.426, 25.436, 25.446, 25.455, 25.466, 25.476, 25.486, 25.496, 25.506,
      25.516, 25.526, 25.536, 25.546, 25.556, 25.566, 25.576, 25.586, 25.597,
      25.607, 25.617, 25.627, 25.637, 25.647, 25.657, 25.667, 25.677, 25.687,
      25.697, 25.708, 25.718, 25.728, 25.738, 25.747, 25.758, 25.767, 25.778,
      25.788, 25.798, 25.808, 25.818, 25.828, 25.838, 25.848, 25.858, 25.868,
      25.879, 25.889, 25.899, 25.909, 25.92, 25.93, 25.94, 25.95, 25.96, 25.97,
      25.98, 25.99, 26, 26.01, 26.02, 26.031, 26.041, 26.051, 26.061, 26.071,
      26.081, 26.091, 26.101, 26.111, 26.122, 26.132, 26.142, 26.152, 26.162,
      26.172, 26.182, 26.192, 26.202, 26.212, 26.223, 26.233, 26.244, 26.254,
      26.264, 26.274, 26.284, 26.294, 26.304, 26.315, 26.325, 26.335, 26.345,
      26.355, 26.365, 26.375, 26.385, 26.395, 26.406, 26.416, 26.427, 26.437,
      26.447, 26.457, 26.467, 26.477, 26.487, 26.498, 26.508, 26.518, 26.528,
      26.539, 26.549, 26.559, 26.569, 26.579, 26.59, 26.6, 26.61, 26.62, 26.63,
      26.641, 26.651, 26.661, 26.671, 26.682, 26.692, 26.702, 26.712, 26.722,
      26.732, 26.742, 26.753, 26.763, 26.774, 26.784, 26.794, 26.804, 26.814,
      26.824, 26.834, 26.844, 26.854, 26.865, 26.876, 26.886, 26.896, 26.906,
      26.916, 26.926, 26.936, 26.947, 26.958, 26.968, 26.978, 26.988, 26.998,
      27.008, 27.018, 27.029, 27.039, 27.05, 27.06, 27.07, 27.08, 27.09, 27.101,
      27.111, 27.121, 27.131, 27.142, 27.152, 27.162, 27.173, 27.183, 27.193,
      27.203, 27.213, 27.223, 27.234, 27.245, 27.255, 27.265, 27.275, 27.285,
      27.295, 27.306, 27.316, 27.327, 27.337, 27.347, 27.357, 27.368, 27.378,
      27.388, 27.398, 27.408, 27.418, 27.429, 27.44, 27.45, 27.46, 27.47, 27.48,
      27.491, 27.501, 27.511, 27.522, 27.532, 27.542, 27.553, 27.563, 27.573,
      27.583, 27.593, 27.604, 27.614, 27.625, 27.635, 27.645, 27.656, 27.666,
      27.676, 27.686, 27.696, 27.706, 27.717, 27.728, 27.738, 27.748, 27.758,
      27.769, 27.779, 27.789, 27.799, 27.81, 27.82, 27.831, 27.841, 27.851,
      27.861, 27.872, 27.882, 27.892, 27.902, 27.913, 27.923, 27.933, 27.944,
      27.954, 27.964, 27.975, 27.985, 27.995, 28.005, 28.016, 28.026, 28.036,
      28.046, 28.057, 28.068, 28.078, 28.088, 28.098, 28.109, 28.119, 28.129,
      28.139, 28.15, 28.16, 28.171,
    ],
  },
  SD3neg: {
    label: '-3 SD',
    weight: [
      2.065, 2.08, 2.08, 2.1, 2.122, 2.146, 2.17, 2.195, 2.221, 2.248, 2.276,
      2.304, 2.333, 2.363, 2.395, 2.426, 2.459, 2.491, 2.524, 2.557, 2.59,
      2.624, 2.657, 2.69, 2.723, 2.756, 2.789, 2.822, 2.854, 2.887, 2.919,
      2.951, 2.982, 3.014, 3.045, 3.076, 3.107, 3.137, 3.167, 3.197, 3.227,
      3.256, 3.285, 3.314, 3.342, 3.37, 3.398, 3.426, 3.454, 3.481, 3.508,
      3.534, 3.561, 3.587, 3.613, 3.639, 3.664, 3.689, 3.714, 3.739, 3.764,
      3.788, 3.812, 3.836, 3.86, 3.884, 3.907, 3.93, 3.953, 3.976, 3.998, 4.021,
      4.043, 4.065, 4.087, 4.108, 4.13, 4.151, 4.172, 4.193, 4.213, 4.234,
      4.254, 4.274, 4.294, 4.314, 4.334, 4.353, 4.372, 4.392, 4.41, 4.429,
      4.448, 4.466, 4.485, 4.503, 4.521, 4.539, 4.557, 4.574, 4.592, 4.609,
      4.626, 4.644, 4.66, 4.677, 4.694, 4.711, 4.727, 4.743, 4.759, 4.775,
      4.791, 4.807, 4.823, 4.838, 4.854, 4.869, 4.884, 4.9, 4.915, 4.929, 4.944,
      4.959, 4.974, 4.988, 5.002, 5.017, 5.031, 5.045, 5.059, 5.073, 5.086, 5.1,
      5.113, 5.127, 5.14, 5.154, 5.167, 5.18, 5.193, 5.206, 5.219, 5.231, 5.244,
      5.256, 5.269, 5.281, 5.294, 5.306, 5.318, 5.33, 5.342, 5.354, 5.365,
      5.377, 5.389, 5.4, 5.412, 5.423, 5.434, 5.445, 5.457, 5.468, 5.479, 5.49,
      5.5, 5.511, 5.522, 5.533, 5.543, 5.554, 5.564, 5.575, 5.585, 5.595, 5.605,
      5.616, 5.626, 5.636, 5.646, 5.656, 5.665, 5.675, 5.685, 5.695, 5.704,
      5.714, 5.723, 5.733, 5.742, 5.751, 5.761, 5.77, 5.779, 5.788, 5.797,
      5.807, 5.815, 5.824, 5.833, 5.842, 5.851, 5.86, 5.868, 5.877, 5.886,
      5.894, 5.903, 5.911, 5.92, 5.928, 5.937, 5.945, 5.953, 5.961, 5.97, 5.978,
      5.986, 5.994, 6.002, 6.01, 6.018, 6.026, 6.034, 6.042, 6.049, 6.057,
      6.065, 6.073, 6.08, 6.088, 6.096, 6.103, 6.111, 6.118, 6.126, 6.133,
      6.141, 6.148, 6.156, 6.163, 6.17, 6.177, 6.185, 6.192, 6.199, 6.206,
      6.213, 6.221, 6.228, 6.235, 6.242, 6.249, 6.256, 6.263, 6.27, 6.277,
      6.283, 6.29, 6.297, 6.304, 6.311, 6.318, 6.324, 6.331, 6.338, 6.345,
      6.351, 6.358, 6.365, 6.371, 6.378, 6.384, 6.391, 6.397, 6.404, 6.41,
      6.417, 6.423, 6.43, 6.436, 6.442, 6.449, 6.455, 6.461, 6.468, 6.474,
      6.481, 6.487, 6.493, 6.499, 6.505, 6.512, 6.518, 6.524, 6.53, 6.536,
      6.542, 6.549, 6.555, 6.561, 6.567, 6.573, 6.579, 6.585, 6.591, 6.597,
      6.603, 6.609, 6.615, 6.621, 6.627, 6.633, 6.639, 6.645, 6.65, 6.656,
      6.662, 6.668, 6.674, 6.68, 6.685, 6.691, 6.697, 6.703, 6.708, 6.714, 6.72,
      6.726, 6.731, 6.737, 6.743, 6.749, 6.754, 6.76, 6.765, 6.771, 6.777,
      6.783, 6.788, 6.794, 6.799, 6.805, 6.811, 6.816, 6.822, 6.827, 6.833,
      6.838, 6.844, 6.849, 6.855, 6.86, 6.866, 6.872, 6.877, 6.882, 6.888,
      6.893, 6.899, 6.904, 6.91, 6.915, 6.92, 6.926, 6.931, 6.937, 6.942, 6.948,
      6.953, 6.958, 6.964, 6.969, 6.974, 6.979, 6.985, 6.99, 6.995, 7.001,
      7.006, 7.011, 7.017, 7.022, 7.027, 7.033, 7.038, 7.043, 7.048, 7.054,
      7.059, 7.064, 7.069, 7.074, 7.079, 7.085, 7.09, 7.095, 7.1, 7.105, 7.111,
      7.116, 7.121, 7.126, 7.131, 7.136, 7.141, 7.147, 7.152, 7.157, 7.162,
      7.167, 7.172, 7.177, 7.182, 7.187, 7.192, 7.198, 7.203, 7.208, 7.213,
      7.218, 7.223, 7.228, 7.233, 7.238, 7.243, 7.248, 7.253, 7.258, 7.263,
      7.268, 7.273, 7.278, 7.283, 7.288, 7.293, 7.298, 7.303, 7.308, 7.313,
      7.318, 7.323, 7.328, 7.333, 7.337, 7.342, 7.347, 7.352, 7.357, 7.362,
      7.367, 7.372, 7.377, 7.382, 7.386, 7.391, 7.396, 7.401, 7.406, 7.411,
      7.416, 7.421, 7.426, 7.431, 7.435, 7.44, 7.445, 7.45, 7.455, 7.46, 7.465,
      7.469, 7.474, 7.479, 7.484, 7.488, 7.493, 7.498, 7.503, 7.508, 7.513,
      7.518, 7.522, 7.527, 7.532, 7.537, 7.541, 7.546, 7.551, 7.556, 7.561,
      7.565, 7.57, 7.575, 7.58, 7.584, 7.589, 7.594, 7.599, 7.603, 7.608, 7.613,
      7.618, 7.622, 7.627, 7.632, 7.637, 7.641, 7.646, 7.651, 7.655, 7.66,
      7.665, 7.669, 7.674, 7.679, 7.683, 7.688, 7.693, 7.697, 7.702, 7.707,
      7.711, 7.716, 7.721, 7.725, 7.73, 7.735, 7.739, 7.744, 7.749, 7.753,
      7.758, 7.763, 7.767, 7.772, 7.777, 7.781, 7.786, 7.79, 7.795, 7.8, 7.804,
      7.809, 7.813, 7.818, 7.823, 7.827, 7.832, 7.836, 7.841, 7.846, 7.85,
      7.855, 7.859, 7.864, 7.868, 7.873, 7.878, 7.882, 7.887, 7.891, 7.896, 7.9,
      7.905, 7.909, 7.914, 7.919, 7.923, 7.928, 7.932, 7.937, 7.941, 7.946,
      7.95, 7.955, 7.959, 7.964, 7.968, 7.973, 7.977, 7.982, 7.986, 7.991,
      7.995, 8, 8.004, 8.009, 8.013, 8.018, 8.022, 8.027, 8.031, 8.036, 8.04,
      8.045, 8.049, 8.054, 8.058, 8.062, 8.067, 8.071, 8.076, 8.08, 8.085,
      8.089, 8.094, 8.098, 8.102, 8.107, 8.112, 8.116, 8.12, 8.125, 8.129,
      8.134, 8.138, 8.143, 8.147, 8.152, 8.156, 8.16, 8.165, 8.169, 8.174,
      8.178, 8.183, 8.187, 8.191, 8.196, 8.2, 8.205, 8.209, 8.213, 8.218, 8.222,
      8.227, 8.231, 8.236, 8.24, 8.244, 8.249, 8.253, 8.257, 8.262, 8.266,
      8.271, 8.275, 8.28, 8.284, 8.288, 8.293, 8.297, 8.301, 8.306, 8.31, 8.315,
      8.319, 8.323, 8.328, 8.332, 8.336, 8.341, 8.345, 8.35, 8.354, 8.358,
      8.363, 8.367, 8.371, 8.376, 8.38, 8.384, 8.389, 8.393, 8.397, 8.402,
      8.406, 8.41, 8.415, 8.419, 8.424, 8.428, 8.432, 8.436, 8.441, 8.445, 8.45,
      8.454, 8.458, 8.462, 8.467, 8.471, 8.475, 8.48, 8.484, 8.488, 8.493,
      8.497, 8.501, 8.506, 8.51, 8.514, 8.519, 8.523, 8.527, 8.531, 8.536, 8.54,
      8.544, 8.549, 8.553, 8.557, 8.561, 8.566, 8.57, 8.574, 8.578, 8.583,
      8.587, 8.591, 8.596, 8.6, 8.604, 8.608, 8.613, 8.617, 8.621, 8.626, 8.63,
      8.634, 8.638, 8.643, 8.647, 8.651, 8.655, 8.659, 8.664, 8.668, 8.672,
      8.677, 8.681, 8.685, 8.689, 8.693, 8.698, 8.702, 8.706, 8.71, 8.714,
      8.719, 8.723, 8.727, 8.731, 8.736, 8.74, 8.744, 8.748, 8.752, 8.756,
      8.761, 8.765, 8.769, 8.773, 8.777, 8.781, 8.786, 8.79, 8.794, 8.798,
      8.803, 8.807, 8.811, 8.815, 8.819, 8.823, 8.827, 8.831, 8.836, 8.84,
      8.844, 8.848, 8.852, 8.856, 8.86, 8.864, 8.869, 8.873, 8.877, 8.881,
      8.885, 8.889, 8.893, 8.898, 8.902, 8.906, 8.91, 8.914, 8.918, 8.922,
      8.926, 8.93, 8.934, 8.938, 8.942, 8.946, 8.951, 8.955, 8.959, 8.963,
      8.967, 8.971, 8.975, 8.979, 8.983, 8.987, 8.991, 8.995, 8.999, 9.003,
      9.007, 9.011, 9.015, 9.019, 9.023, 9.027, 9.031, 9.035, 9.039, 9.043,
      9.047, 9.051, 9.055, 9.059, 9.063, 9.067, 9.071, 9.075, 9.079, 9.083,
      9.087, 9.091, 9.095, 9.099, 9.103, 9.107, 9.111, 9.115, 9.119, 9.123,
      9.127, 9.13, 9.134, 9.138, 9.142, 9.146, 9.15, 9.154, 9.158, 9.162, 9.166,
      9.169, 9.174, 9.177, 9.181, 9.185, 9.189, 9.193, 9.197, 9.201, 9.205,
      9.208, 9.212, 9.216, 9.22, 9.224, 9.228, 9.232, 9.236, 9.239, 9.243,
      9.247, 9.251, 9.255, 9.259, 9.262, 9.266, 9.27, 9.274, 9.278, 9.281,
      9.285, 9.289, 9.293, 9.297, 9.301, 9.304, 9.308, 9.312, 9.316, 9.319,
      9.323, 9.327, 9.331, 9.335, 9.338, 9.342, 9.346, 9.35, 9.353, 9.357,
      9.361, 9.365, 9.368, 9.372, 9.376, 9.38, 9.384, 9.387, 9.391, 9.395,
      9.398, 9.402, 9.406, 9.409, 9.413, 9.417, 9.421, 9.425, 9.428, 9.432,
      9.436, 9.439, 9.443, 9.447, 9.45, 9.454, 9.458, 9.461, 9.465, 9.469,
      9.472, 9.476, 9.48, 9.483, 9.487, 9.491, 9.495, 9.498, 9.502, 9.505,
      9.509, 9.513, 9.516, 9.52, 9.524, 9.528, 9.531, 9.535, 9.538, 9.542,
      9.545, 9.549, 9.553, 9.557, 9.56, 9.564, 9.567, 9.571, 9.575, 9.578,
      9.582, 9.585, 9.589, 9.593, 9.596, 9.6, 9.603, 9.607, 9.611, 9.614, 9.618,
      9.621, 9.625, 9.628, 9.632, 9.636, 9.639, 9.643, 9.646, 9.65, 9.653,
      9.657, 9.661, 9.664, 9.668, 9.671, 9.675, 9.678, 9.682, 9.686, 9.689,
      9.693, 9.696, 9.7, 9.703, 9.707, 9.711, 9.714, 9.718, 9.721, 9.724, 9.728,
      9.732, 9.735, 9.739, 9.742, 9.746, 9.749, 9.753, 9.756, 9.76, 9.763,
      9.767, 9.771, 9.774, 9.777, 9.781, 9.784, 9.788, 9.792, 9.795, 9.799,
      9.802, 9.806, 9.809, 9.813, 9.816, 9.82, 9.823, 9.827, 9.83, 9.834, 9.837,
      9.841, 9.844, 9.848, 9.851, 9.855, 9.858, 9.862, 9.865, 9.868, 9.872,
      9.876, 9.879, 9.882, 9.886, 9.89, 9.893, 9.896, 9.9, 9.903, 9.907, 9.91,
      9.914, 9.917, 9.921, 9.924, 9.928, 9.931, 9.935, 9.938, 9.942, 9.945,
      9.949, 9.952, 9.955, 9.959, 9.962, 9.966, 9.969, 9.973, 9.976, 9.98,
      9.983, 9.986, 9.99, 9.993, 9.997, 10, 10.004, 10.007, 10.011, 10.014,
      10.018, 10.021, 10.024, 10.028, 10.031, 10.035, 10.038, 10.042, 10.045,
      10.049, 10.052, 10.056, 10.059, 10.062, 10.066, 10.069, 10.073, 10.076,
      10.08, 10.083, 10.086, 10.09, 10.093, 10.097, 10.1, 10.104, 10.107, 10.11,
      10.114, 10.117, 10.121, 10.124, 10.128, 10.131, 10.134, 10.138, 10.141,
      10.145, 10.148, 10.152, 10.155, 10.158, 10.162, 10.165, 10.169, 10.172,
      10.176, 10.179, 10.182, 10.186, 10.189, 10.193, 10.196, 10.2, 10.203,
      10.206, 10.21, 10.213, 10.216, 10.22, 10.223, 10.227, 10.23, 10.234,
      10.237, 10.24, 10.244, 10.247, 10.251, 10.254, 10.258, 10.261, 10.264,
      10.268, 10.271, 10.275, 10.278, 10.281, 10.285, 10.288, 10.292, 10.295,
      10.299, 10.302, 10.305, 10.309, 10.312, 10.315, 10.319, 10.322, 10.326,
      10.329, 10.333, 10.336, 10.339, 10.343, 10.346, 10.35, 10.353, 10.356,
      10.36, 10.363, 10.367, 10.37, 10.373, 10.377, 10.38, 10.384, 10.387,
      10.391, 10.394, 10.397, 10.401, 10.404, 10.407, 10.411, 10.414, 10.418,
      10.421, 10.425, 10.428, 10.431, 10.435, 10.438, 10.442, 10.445, 10.448,
      10.452, 10.455, 10.458, 10.462, 10.465, 10.469, 10.472, 10.475, 10.479,
      10.482, 10.486, 10.489, 10.492, 10.496, 10.499, 10.502, 10.506, 10.509,
      10.513, 10.516, 10.52, 10.523, 10.526, 10.53, 10.533, 10.537, 10.54,
      10.543, 10.546, 10.55, 10.553, 10.557, 10.56, 10.563, 10.567, 10.57,
      10.574, 10.577, 10.581, 10.584, 10.587, 10.59, 10.594, 10.597, 10.601,
      10.604, 10.607, 10.611, 10.614, 10.618, 10.621, 10.624, 10.628, 10.631,
      10.634, 10.638, 10.641, 10.644, 10.648, 10.651, 10.655, 10.658, 10.661,
      10.665, 10.668, 10.671, 10.675, 10.678, 10.681, 10.685, 10.688, 10.691,
      10.695, 10.698, 10.702, 10.705, 10.708, 10.712, 10.715, 10.718, 10.722,
      10.725, 10.728, 10.732, 10.735, 10.738, 10.742, 10.745, 10.749, 10.752,
      10.755, 10.758, 10.762, 10.765, 10.768, 10.772, 10.775, 10.779, 10.782,
      10.785, 10.789, 10.792, 10.795, 10.798, 10.802, 10.805, 10.808, 10.812,
      10.815, 10.819, 10.822, 10.825, 10.828, 10.832, 10.835, 10.838, 10.842,
      10.845, 10.848, 10.852, 10.855, 10.858, 10.861, 10.865, 10.868, 10.871,
      10.875, 10.878, 10.882, 10.885, 10.888, 10.891, 10.895, 10.898, 10.901,
      10.905, 10.908, 10.911, 10.915, 10.918, 10.921, 10.924, 10.928, 10.931,
      10.934, 10.938, 10.941, 10.944, 10.947, 10.95, 10.954, 10.957, 10.961,
      10.964, 10.967, 10.97, 10.974, 10.977, 10.98, 10.983, 10.987, 10.99,
      10.993, 10.997, 11, 11.003, 11.006, 11.01, 11.013, 11.016, 11.02, 11.023,
      11.026, 11.029, 11.032, 11.036, 11.039, 11.042, 11.046, 11.049, 11.052,
      11.055, 11.059, 11.062, 11.065, 11.069, 11.072, 11.075, 11.078, 11.081,
      11.085, 11.088, 11.091, 11.094, 11.098, 11.101, 11.104, 11.107, 11.111,
      11.114, 11.117, 11.12, 11.123, 11.127, 11.13, 11.133, 11.137, 11.14,
      11.143, 11.146, 11.149, 11.153, 11.156, 11.159, 11.162, 11.165, 11.169,
      11.172, 11.175, 11.179, 11.182, 11.185, 11.188, 11.191, 11.195, 11.198,
      11.201, 11.204, 11.207, 11.211, 11.214, 11.217, 11.221, 11.224, 11.227,
      11.23, 11.233, 11.236, 11.24, 11.243, 11.246, 11.249, 11.252, 11.256,
      11.259, 11.262, 11.265, 11.268, 11.272, 11.275, 11.278, 11.281, 11.284,
      11.288, 11.291, 11.294, 11.297, 11.3, 11.303, 11.307, 11.31, 11.313,
      11.317, 11.32, 11.323, 11.326, 11.329, 11.332, 11.336, 11.339, 11.342,
      11.345, 11.348, 11.351, 11.355, 11.358, 11.361, 11.364, 11.367, 11.371,
      11.374, 11.377, 11.38, 11.383, 11.386, 11.39, 11.393, 11.396, 11.399,
      11.402, 11.405, 11.408, 11.411, 11.415, 11.418, 11.421, 11.424, 11.427,
      11.431, 11.434, 11.437, 11.44, 11.443, 11.446, 11.449, 11.453, 11.456,
      11.459, 11.462, 11.465, 11.469, 11.472, 11.475, 11.478, 11.481, 11.484,
      11.488, 11.491, 11.494, 11.497, 11.5, 11.503, 11.506, 11.509, 11.512,
      11.516, 11.519, 11.522, 11.525, 11.528, 11.531, 11.535, 11.538, 11.541,
      11.544, 11.547, 11.55, 11.554, 11.557, 11.56, 11.563, 11.566, 11.569,
      11.572, 11.575, 11.578, 11.582, 11.585, 11.588, 11.591, 11.594, 11.597,
      11.601, 11.604, 11.607, 11.61, 11.613, 11.616, 11.619, 11.622, 11.625,
      11.629, 11.632, 11.635, 11.638, 11.641, 11.644, 11.647, 11.65, 11.653,
      11.657, 11.66, 11.663, 11.666, 11.669, 11.672, 11.675, 11.679, 11.682,
      11.685, 11.688, 11.691, 11.694, 11.697, 11.7, 11.703, 11.707, 11.71,
      11.713, 11.716, 11.719, 11.722, 11.725, 11.728, 11.731, 11.734, 11.738,
      11.741, 11.744, 11.747, 11.75, 11.753, 11.756, 11.759, 11.762, 11.765,
      11.769, 11.772, 11.775, 11.778, 11.781, 11.784, 11.787, 11.79, 11.793,
      11.797, 11.8, 11.803, 11.806, 11.809, 11.812, 11.815, 11.818, 11.822,
      11.824, 11.827, 11.831, 11.834, 11.837, 11.84, 11.843, 11.846, 11.849,
      11.852, 11.855, 11.858, 11.861, 11.865, 11.868, 11.871, 11.874, 11.877,
      11.88, 11.883, 11.886, 11.889, 11.892, 11.895, 11.898, 11.902, 11.905,
      11.908, 11.911, 11.914, 11.917, 11.92, 11.923, 11.926, 11.929, 11.932,
      11.936, 11.938, 11.941, 11.944, 11.948, 11.951, 11.954, 11.957, 11.96,
      11.963, 11.966, 11.969, 11.972, 11.975, 11.978, 11.981, 11.985, 11.988,
      11.991, 11.994, 11.997, 12, 12.003, 12.006, 12.009, 12.012, 12.015,
      12.018, 12.021, 12.024, 12.027, 12.03, 12.033, 12.037, 12.04, 12.043,
      12.046, 12.049, 12.052, 12.055, 12.058, 12.061, 12.064, 12.067, 12.07,
      12.073, 12.076, 12.079, 12.082, 12.085, 12.088, 12.091, 12.094, 12.098,
      12.101, 12.103, 12.106, 12.11, 12.113, 12.116, 12.119, 12.122, 12.125,
      12.128, 12.131, 12.134, 12.137, 12.14, 12.143, 12.146, 12.149, 12.152,
      12.155, 12.158, 12.161, 12.164, 12.167, 12.17, 12.173, 12.176, 12.18,
      12.183, 12.185, 12.188, 12.192, 12.195, 12.198, 12.201, 12.204, 12.207,
      12.21, 12.213, 12.216, 12.219, 12.222, 12.225, 12.228, 12.231, 12.234,
      12.237, 12.24, 12.243, 12.246, 12.249, 12.252, 12.255, 12.258, 12.261,
      12.264, 12.267, 12.27, 12.273, 12.276, 12.279, 12.282, 12.285, 12.288,
      12.291, 12.294, 12.297, 12.3, 12.303, 12.306, 12.309, 12.312, 12.315,
      12.318, 12.321, 12.324, 12.327, 12.33, 12.333, 12.336, 12.339, 12.342,
      12.345, 12.348, 12.351, 12.354, 12.358, 12.36, 12.363, 12.366, 12.369,
      12.372, 12.375, 12.378, 12.381, 12.384, 12.387, 12.39, 12.393, 12.396,
      12.399, 12.402, 12.405, 12.408, 12.411, 12.414, 12.417, 12.42, 12.423,
      12.426, 12.429, 12.432, 12.435, 12.438, 12.441, 12.444, 12.447, 12.45,
      12.453, 12.456, 12.459,
    ],
  },
  SD2neg: {
    label: '-2 SD',
    weight: [
      2.437, 2.454, 2.459, 2.475, 2.499, 2.525, 2.551, 2.579, 2.607, 2.637,
      2.667, 2.698, 2.73, 2.764, 2.798, 2.833, 2.869, 2.905, 2.941, 2.977,
      3.014, 3.051, 3.087, 3.124, 3.16, 3.197, 3.233, 3.269, 3.305, 3.34, 3.376,
      3.411, 3.445, 3.48, 3.514, 3.548, 3.581, 3.615, 3.648, 3.68, 3.712, 3.744,
      3.776, 3.807, 3.838, 3.869, 3.9, 3.93, 3.96, 3.989, 4.018, 4.047, 4.076,
      4.104, 4.133, 4.16, 4.188, 4.215, 4.242, 4.269, 4.296, 4.322, 4.348,
      4.374, 4.4, 4.425, 4.45, 4.475, 4.5, 4.525, 4.549, 4.573, 4.597, 4.62,
      4.644, 4.667, 4.69, 4.713, 4.736, 4.758, 4.78, 4.802, 4.824, 4.846, 4.867,
      4.888, 4.909, 4.93, 4.951, 4.972, 4.992, 5.012, 5.032, 5.052, 5.072,
      5.091, 5.111, 5.13, 5.149, 5.168, 5.187, 5.205, 5.224, 5.242, 5.26, 5.278,
      5.296, 5.314, 5.332, 5.349, 5.367, 5.384, 5.401, 5.418, 5.435, 5.452,
      5.468, 5.485, 5.501, 5.517, 5.533, 5.549, 5.565, 5.581, 5.597, 5.613,
      5.628, 5.643, 5.658, 5.674, 5.689, 5.704, 5.718, 5.733, 5.748, 5.762,
      5.777, 5.791, 5.805, 5.819, 5.833, 5.847, 5.861, 5.875, 5.888, 5.902,
      5.915, 5.929, 5.942, 5.955, 5.968, 5.981, 5.994, 6.007, 6.02, 6.033,
      6.045, 6.058, 6.07, 6.082, 6.095, 6.107, 6.119, 6.131, 6.143, 6.155,
      6.167, 6.178, 6.19, 6.202, 6.213, 6.225, 6.236, 6.247, 6.259, 6.27, 6.281,
      6.292, 6.303, 6.314, 6.325, 6.336, 6.346, 6.357, 6.368, 6.378, 6.389,
      6.399, 6.41, 6.42, 6.43, 6.441, 6.451, 6.461, 6.471, 6.481, 6.491, 6.501,
      6.511, 6.52, 6.53, 6.54, 6.55, 6.559, 6.569, 6.578, 6.588, 6.597, 6.607,
      6.616, 6.625, 6.634, 6.644, 6.653, 6.662, 6.671, 6.68, 6.689, 6.698,
      6.707, 6.716, 6.724, 6.733, 6.742, 6.751, 6.759, 6.768, 6.776, 6.785,
      6.793, 6.802, 6.81, 6.819, 6.827, 6.836, 6.844, 6.852, 6.86, 6.869, 6.877,
      6.885, 6.893, 6.901, 6.909, 6.917, 6.925, 6.933, 6.941, 6.949, 6.957,
      6.965, 6.973, 6.98, 6.988, 6.996, 7.003, 7.011, 7.019, 7.026, 7.034,
      7.042, 7.049, 7.057, 7.064, 7.072, 7.079, 7.087, 7.094, 7.101, 7.109,
      7.116, 7.123, 7.131, 7.138, 7.145, 7.153, 7.16, 7.167, 7.174, 7.181,
      7.188, 7.196, 7.203, 7.21, 7.217, 7.224, 7.231, 7.238, 7.245, 7.252,
      7.259, 7.266, 7.272, 7.279, 7.286, 7.293, 7.3, 7.307, 7.314, 7.321, 7.327,
      7.334, 7.341, 7.347, 7.354, 7.361, 7.368, 7.374, 7.381, 7.387, 7.394,
      7.401, 7.407, 7.414, 7.42, 7.427, 7.434, 7.44, 7.447, 7.453, 7.46, 7.466,
      7.473, 7.479, 7.485, 7.492, 7.498, 7.505, 7.511, 7.517, 7.524, 7.53,
      7.537, 7.543, 7.549, 7.556, 7.562, 7.568, 7.574, 7.581, 7.587, 7.593, 7.6,
      7.606, 7.612, 7.618, 7.624, 7.631, 7.637, 7.643, 7.649, 7.655, 7.661,
      7.668, 7.674, 7.68, 7.686, 7.692, 7.698, 7.704, 7.71, 7.716, 7.722, 7.729,
      7.735, 7.741, 7.747, 7.753, 7.759, 7.765, 7.771, 7.777, 7.783, 7.789,
      7.795, 7.8, 7.807, 7.812, 7.818, 7.824, 7.83, 7.836, 7.842, 7.848, 7.854,
      7.86, 7.866, 7.871, 7.877, 7.883, 7.889, 7.895, 7.901, 7.906, 7.912,
      7.918, 7.924, 7.93, 7.935, 7.941, 7.947, 7.953, 7.959, 7.964, 7.97, 7.976,
      7.982, 7.987, 7.993, 7.999, 8.005, 8.01, 8.016, 8.022, 8.027, 8.033,
      8.039, 8.044, 8.05, 8.056, 8.061, 8.067, 8.073, 8.078, 8.084, 8.09, 8.095,
      8.101, 8.107, 8.112, 8.118, 8.123, 8.129, 8.134, 8.14, 8.146, 8.151,
      8.157, 8.162, 8.168, 8.174, 8.179, 8.185, 8.19, 8.196, 8.201, 8.207,
      8.212, 8.218, 8.223, 8.229, 8.235, 8.24, 8.246, 8.251, 8.256, 8.262,
      8.267, 8.273, 8.278, 8.284, 8.289, 8.295, 8.3, 8.306, 8.311, 8.317, 8.322,
      8.328, 8.333, 8.339, 8.344, 8.349, 8.355, 8.36, 8.366, 8.371, 8.377,
      8.382, 8.387, 8.393, 8.398, 8.404, 8.409, 8.414, 8.42, 8.425, 8.431,
      8.436, 8.441, 8.447, 8.452, 8.458, 8.463, 8.468, 8.474, 8.479, 8.484,
      8.49, 8.495, 8.5, 8.506, 8.511, 8.517, 8.522, 8.527, 8.533, 8.538, 8.543,
      8.548, 8.554, 8.559, 8.564, 8.57, 8.575, 8.58, 8.586, 8.591, 8.596, 8.601,
      8.607, 8.612, 8.617, 8.623, 8.628, 8.633, 8.638, 8.644, 8.649, 8.654,
      8.659, 8.665, 8.67, 8.675, 8.68, 8.686, 8.691, 8.696, 8.701, 8.707, 8.712,
      8.717, 8.722, 8.727, 8.733, 8.738, 8.743, 8.748, 8.753, 8.759, 8.764,
      8.769, 8.774, 8.779, 8.785, 8.79, 8.795, 8.8, 8.805, 8.811, 8.816, 8.821,
      8.826, 8.831, 8.836, 8.841, 8.847, 8.852, 8.857, 8.862, 8.867, 8.872,
      8.878, 8.883, 8.888, 8.893, 8.898, 8.903, 8.908, 8.913, 8.919, 8.924,
      8.929, 8.934, 8.939, 8.944, 8.949, 8.954, 8.96, 8.965, 8.97, 8.975, 8.98,
      8.985, 8.99, 8.995, 9, 9.005, 9.01, 9.016, 9.021, 9.026, 9.031, 9.036,
      9.041, 9.046, 9.051, 9.056, 9.061, 9.066, 9.072, 9.077, 9.082, 9.087,
      9.092, 9.097, 9.102, 9.107, 9.112, 9.117, 9.122, 9.127, 9.132, 9.137,
      9.142, 9.147, 9.153, 9.158, 9.163, 9.168, 9.173, 9.178, 9.183, 9.188,
      9.193, 9.198, 9.203, 9.208, 9.213, 9.218, 9.223, 9.228, 9.233, 9.238,
      9.243, 9.248, 9.253, 9.258, 9.263, 9.268, 9.273, 9.278, 9.283, 9.288,
      9.293, 9.298, 9.303, 9.308, 9.313, 9.318, 9.323, 9.328, 9.333, 9.338,
      9.343, 9.348, 9.353, 9.358, 9.363, 9.368, 9.373, 9.378, 9.383, 9.388,
      9.393, 9.398, 9.403, 9.408, 9.413, 9.418, 9.423, 9.428, 9.433, 9.438,
      9.443, 9.448, 9.453, 9.458, 9.463, 9.468, 9.473, 9.478, 9.483, 9.488,
      9.493, 9.498, 9.503, 9.507, 9.513, 9.517, 9.522, 9.527, 9.532, 9.537,
      9.542, 9.547, 9.552, 9.557, 9.562, 9.567, 9.572, 9.577, 9.582, 9.587,
      9.591, 9.596, 9.601, 9.606, 9.611, 9.616, 9.621, 9.626, 9.631, 9.636,
      9.641, 9.645, 9.65, 9.655, 9.66, 9.665, 9.67, 9.675, 9.68, 9.685, 9.69,
      9.694, 9.699, 9.704, 9.709, 9.714, 9.719, 9.724, 9.729, 9.734, 9.738,
      9.743, 9.748, 9.753, 9.758, 9.763, 9.767, 9.772, 9.777, 9.782, 9.787,
      9.792, 9.797, 9.801, 9.806, 9.811, 9.816, 9.821, 9.826, 9.83, 9.835, 9.84,
      9.845, 9.85, 9.855, 9.859, 9.864, 9.869, 9.874, 9.879, 9.883, 9.888,
      9.893, 9.898, 9.902, 9.907, 9.912, 9.917, 9.922, 9.926, 9.931, 9.936,
      9.941, 9.945, 9.95, 9.955, 9.96, 9.964, 9.969, 9.974, 9.979, 9.983, 9.988,
      9.993, 9.998, 10.002, 10.007, 10.012, 10.016, 10.021, 10.026, 10.031,
      10.035, 10.04, 10.045, 10.049, 10.054, 10.059, 10.063, 10.068, 10.073,
      10.077, 10.082, 10.087, 10.091, 10.096, 10.101, 10.106, 10.11, 10.115,
      10.119, 10.124, 10.129, 10.133, 10.138, 10.143, 10.147, 10.152, 10.156,
      10.161, 10.166, 10.17, 10.175, 10.18, 10.184, 10.189, 10.193, 10.198,
      10.202, 10.207, 10.212, 10.216, 10.221, 10.226, 10.23, 10.235, 10.239,
      10.244, 10.248, 10.253, 10.257, 10.262, 10.266, 10.271, 10.275, 10.28,
      10.285, 10.289, 10.294, 10.298, 10.303, 10.307, 10.312, 10.316, 10.321,
      10.325, 10.33, 10.334, 10.339, 10.343, 10.348, 10.352, 10.356, 10.361,
      10.366, 10.37, 10.375, 10.379, 10.383, 10.388, 10.392, 10.397, 10.401,
      10.406, 10.41, 10.414, 10.419, 10.423, 10.428, 10.432, 10.437, 10.441,
      10.445, 10.45, 10.454, 10.459, 10.463, 10.467, 10.472, 10.476, 10.481,
      10.485, 10.49, 10.494, 10.498, 10.503, 10.507, 10.511, 10.516, 10.52,
      10.524, 10.529, 10.533, 10.538, 10.542, 10.546, 10.55, 10.555, 10.559,
      10.563, 10.568, 10.572, 10.577, 10.581, 10.585, 10.589, 10.594, 10.598,
      10.602, 10.607, 10.611, 10.615, 10.619, 10.624, 10.628, 10.632, 10.637,
      10.641, 10.645, 10.649, 10.654, 10.658, 10.662, 10.667, 10.671, 10.675,
      10.679, 10.684, 10.688, 10.692, 10.696, 10.701, 10.705, 10.709, 10.713,
      10.718, 10.722, 10.726, 10.73, 10.734, 10.739, 10.743, 10.747, 10.751,
      10.756, 10.76, 10.764, 10.768, 10.773, 10.777, 10.781, 10.785, 10.789,
      10.793, 10.797, 10.802, 10.806, 10.81, 10.814, 10.818, 10.823, 10.827,
      10.831, 10.835, 10.839, 10.844, 10.848, 10.852, 10.856, 10.86, 10.864,
      10.868, 10.873, 10.877, 10.881, 10.885, 10.889, 10.893, 10.897, 10.902,
      10.906, 10.91, 10.914, 10.918, 10.922, 10.926, 10.93, 10.935, 10.939,
      10.943, 10.947, 10.951, 10.955, 10.959, 10.963, 10.968, 10.972, 10.976,
      10.98, 10.984, 10.988, 10.992, 10.996, 11, 11.004, 11.008, 11.013, 11.017,
      11.021, 11.025, 11.029, 11.033, 11.037, 11.041, 11.045, 11.049, 11.053,
      11.058, 11.062, 11.066, 11.07, 11.074, 11.078, 11.082, 11.086, 11.09,
      11.094, 11.098, 11.102, 11.106, 11.11, 11.114, 11.118, 11.123, 11.127,
      11.131, 11.135, 11.139, 11.143, 11.147, 11.151, 11.155, 11.159, 11.163,
      11.167, 11.171, 11.175, 11.179, 11.183, 11.187, 11.191, 11.195, 11.2,
      11.204, 11.207, 11.211, 11.216, 11.22, 11.224, 11.228, 11.232, 11.236,
      11.24, 11.244, 11.248, 11.252, 11.256, 11.26, 11.264, 11.268, 11.272,
      11.276, 11.28, 11.284, 11.288, 11.292, 11.296, 11.3, 11.304, 11.308,
      11.312, 11.316, 11.32, 11.324, 11.328, 11.332, 11.336, 11.34, 11.344,
      11.348, 11.352, 11.356, 11.36, 11.364, 11.368, 11.372, 11.376, 11.38,
      11.384, 11.388, 11.392, 11.396, 11.4, 11.404, 11.408, 11.412, 11.416,
      11.42, 11.424, 11.428, 11.432, 11.436, 11.44, 11.444, 11.448, 11.452,
      11.456, 11.46, 11.464, 11.468, 11.472, 11.476, 11.48, 11.484, 11.488,
      11.492, 11.496, 11.5, 11.504, 11.508, 11.512, 11.516, 11.52, 11.524,
      11.528, 11.532, 11.536, 11.54, 11.544, 11.548, 11.552, 11.556, 11.56,
      11.564, 11.568, 11.572, 11.576, 11.58, 11.584, 11.587, 11.592, 11.595,
      11.599, 11.604, 11.607, 11.611, 11.615, 11.619, 11.623, 11.627, 11.631,
      11.635, 11.639, 11.643, 11.647, 11.651, 11.655, 11.659, 11.663, 11.667,
      11.671, 11.675, 11.679, 11.683, 11.687, 11.691, 11.695, 11.699, 11.703,
      11.707, 11.711, 11.715, 11.719, 11.723, 11.727, 11.731, 11.734, 11.739,
      11.742, 11.746, 11.75, 11.754, 11.758, 11.762, 11.766, 11.77, 11.774,
      11.778, 11.782, 11.786, 11.79, 11.794, 11.798, 11.802, 11.806, 11.81,
      11.814, 11.818, 11.822, 11.826, 11.83, 11.834, 11.838, 11.842, 11.845,
      11.85, 11.853, 11.857, 11.861, 11.865, 11.869, 11.873, 11.877, 11.881,
      11.885, 11.889, 11.893, 11.897, 11.901, 11.905, 11.909, 11.913, 11.917,
      11.921, 11.925, 11.929, 11.932, 11.937, 11.94, 11.944, 11.948, 11.952,
      11.956, 11.96, 11.964, 11.968, 11.972, 11.976, 11.98, 11.984, 11.988,
      11.992, 11.996, 11.999, 12.004, 12.007, 12.011, 12.015, 12.019, 12.023,
      12.027, 12.031, 12.035, 12.039, 12.043, 12.047, 12.051, 12.055, 12.059,
      12.063, 12.066, 12.07, 12.074, 12.078, 12.082, 12.086, 12.09, 12.094,
      12.098, 12.102, 12.106, 12.11, 12.114, 12.118, 12.121, 12.125, 12.129,
      12.133, 12.137, 12.141, 12.145, 12.149, 12.153, 12.157, 12.161, 12.165,
      12.168, 12.172, 12.176, 12.18, 12.184, 12.188, 12.192, 12.196, 12.2,
      12.204, 12.208, 12.211, 12.215, 12.219, 12.223, 12.227, 12.231, 12.235,
      12.239, 12.243, 12.247, 12.251, 12.254, 12.258, 12.262, 12.266, 12.27,
      12.274, 12.278, 12.282, 12.285, 12.29, 12.293, 12.297, 12.301, 12.305,
      12.309, 12.313, 12.317, 12.32, 12.324, 12.328, 12.332, 12.336, 12.34,
      12.344, 12.348, 12.352, 12.355, 12.359, 12.363, 12.367, 12.371, 12.375,
      12.379, 12.383, 12.386, 12.39, 12.394, 12.398, 12.402, 12.406, 12.41,
      12.413, 12.417, 12.421, 12.425, 12.429, 12.433, 12.437, 12.44, 12.444,
      12.448, 12.452, 12.456, 12.46, 12.464, 12.467, 12.471, 12.475, 12.479,
      12.483, 12.487, 12.491, 12.494, 12.498, 12.502, 12.506, 12.51, 12.514,
      12.518, 12.521, 12.525, 12.529, 12.533, 12.537, 12.54, 12.545, 12.548,
      12.552, 12.556, 12.56, 12.564, 12.567, 12.571, 12.575, 12.579, 12.583,
      12.587, 12.591, 12.594, 12.598, 12.602, 12.606, 12.61, 12.613, 12.617,
      12.621, 12.625, 12.629, 12.633, 12.636, 12.64, 12.644, 12.648, 12.652,
      12.655, 12.659, 12.663, 12.667, 12.671, 12.674, 12.678, 12.682, 12.686,
      12.69, 12.694, 12.697, 12.701, 12.705, 12.709, 12.713, 12.716, 12.72,
      12.724, 12.728, 12.732, 12.735, 12.739, 12.743, 12.747, 12.751, 12.754,
      12.758, 12.762, 12.766, 12.77, 12.773, 12.777, 12.781, 12.785, 12.788,
      12.792, 12.796, 12.8, 12.804, 12.808, 12.811, 12.815, 12.819, 12.823,
      12.827, 12.83, 12.834, 12.838, 12.842, 12.845, 12.849, 12.853, 12.857,
      12.861, 12.864, 12.868, 12.872, 12.876, 12.879, 12.883, 12.887, 12.891,
      12.895, 12.898, 12.902, 12.906, 12.91, 12.913, 12.917, 12.921, 12.925,
      12.928, 12.932, 12.936, 12.94, 12.944, 12.947, 12.951, 12.955, 12.959,
      12.962, 12.966, 12.97, 12.974, 12.977, 12.981, 12.985, 12.989, 12.993,
      12.996, 13, 13.004, 13.008, 13.011, 13.015, 13.019, 13.023, 13.026, 13.03,
      13.034, 13.037, 13.041, 13.045, 13.049, 13.053, 13.056, 13.06, 13.064,
      13.068, 13.071, 13.075, 13.079, 13.083, 13.086, 13.09, 13.094, 13.097,
      13.101, 13.105, 13.109, 13.113, 13.116, 13.12, 13.124, 13.128, 13.131,
      13.135, 13.139, 13.143, 13.146, 13.15, 13.154, 13.157, 13.161, 13.165,
      13.169, 13.172, 13.176, 13.18, 13.184, 13.188, 13.191, 13.195, 13.199,
      13.202, 13.206, 13.21, 13.214, 13.217, 13.221, 13.225, 13.229, 13.232,
      13.236, 13.24, 13.244, 13.247, 13.251, 13.255, 13.258, 13.262, 13.266,
      13.27, 13.273, 13.277, 13.281, 13.285, 13.288, 13.292, 13.296, 13.299,
      13.303, 13.307, 13.311, 13.314, 13.318, 13.322, 13.326, 13.329, 13.333,
      13.337, 13.34, 13.344, 13.348, 13.351, 13.355, 13.359, 13.363, 13.366,
      13.37, 13.374, 13.377, 13.381, 13.385, 13.389, 13.392, 13.396, 13.4,
      13.404, 13.407, 13.411, 13.415, 13.418, 13.422, 13.426, 13.43, 13.433,
      13.437, 13.441, 13.444, 13.448, 13.452, 13.455, 13.459, 13.463, 13.467,
      13.47, 13.474, 13.478, 13.481, 13.485, 13.489, 13.492, 13.496, 13.5,
      13.504, 13.507, 13.511, 13.515, 13.518, 13.522, 13.526, 13.529, 13.533,
      13.537, 13.541, 13.544, 13.548, 13.551, 13.555, 13.559, 13.563, 13.566,
      13.57, 13.574, 13.578, 13.581, 13.585, 13.588, 13.592, 13.596, 13.6,
      13.603, 13.607, 13.611, 13.614, 13.618, 13.622, 13.625, 13.629, 13.633,
      13.636, 13.64, 13.644, 13.647, 13.651, 13.655, 13.658, 13.662, 13.666,
      13.67, 13.673, 13.677, 13.68, 13.684, 13.688, 13.691, 13.695, 13.699,
      13.703, 13.706, 13.71, 13.713, 13.717, 13.721, 13.725, 13.728, 13.732,
      13.736, 13.739, 13.743, 13.746, 13.75, 13.754, 13.758, 13.761, 13.765,
      13.769, 13.772, 13.776, 13.779, 13.783, 13.787, 13.791, 13.794, 13.798,
      13.802, 13.805, 13.809, 13.812, 13.816, 13.82, 13.823, 13.827, 13.831,
      13.834, 13.838, 13.842, 13.845, 13.849, 13.853, 13.856, 13.86, 13.864,
      13.867, 13.871, 13.875, 13.878, 13.882, 13.885, 13.889, 13.893, 13.897,
      13.9, 13.904, 13.907, 13.911, 13.915, 13.918, 13.922, 13.926, 13.929,
      13.933, 13.937, 13.94, 13.944, 13.947, 13.951, 13.955, 13.958, 13.962,
      13.966, 13.969, 13.973, 13.977, 13.98, 13.984, 13.987, 13.991, 13.995,
      13.998, 14.002, 14.006, 14.009, 14.013, 14.017, 14.02, 14.024, 14.027,
      14.031, 14.035, 14.038, 14.042, 14.046, 14.049, 14.053, 14.056, 14.06,
      14.063, 14.067, 14.071, 14.074, 14.078, 14.082, 14.085, 14.089, 14.093,
      14.096, 14.1, 14.103, 14.107, 14.111, 14.114, 14.118, 14.121, 14.125,
      14.129, 14.132, 14.136, 14.139, 14.143, 14.147, 14.15, 14.154, 14.158,
      14.161, 14.165, 14.168, 14.172, 14.175,
    ],
  },
};
