export const lengthPerYear = {
  date: [0, 1, 2, 3, 4],
  median: {
    label: 'median',
    weight: [49.8842, 75.7391, 87.4589, 96.0674, 103.3088],
  },
  SD2: { label: '2 SD', weight: [53.67, 80.491, 93.566, 103.48, 111.695] },
  SD3: { label: '3 SD', weight: [55.564, 82.867, 96.619, 107.186, 115.889] },
  SD3neg: { label: '-3 SD', weight: [44.205, 68.611, 78.296, 84.949, 90.729] },
  SD2neg: { label: '-2 SD', weight: [46.098, 70.987, 81.348, 88.655, 94.922] },
};
