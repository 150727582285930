export type CommunityRouteState = {
  activeTabIndex?: number;
};

export const COMMUNITY_SESSION_STORAGE_KEY = 'isFromCommunityWelcome';

export const COMMUNITY_TABS = {
  TEAM: { INDEX: 0, TITLE: 'Team' },
  LEAGUE: { INDEX: 1, TITLE: 'League' },
  BREASTFEEDING_CLUBS: { INDEX: 2, TITLE: 'Breastfeeding clubs' },
  CONNECT: { INDEX: 3, TITLE: 'Connect' },
};
