import { CalendarEventModel } from '@ecdlink/core';
import * as yup from 'yup';
import { EventType, ParticipantType } from '../calendar.types';

export type CalendarAddEventInfo = {
  id?: string;
  eventType?: EventType;
  start?: string;
  end?: string;
  minDate?: string;
  maxDate?: string;
  allDay?: boolean;
  name?: string;
  description?: string;
  participantUserIds?: string[];
  action?: {
    buttonName: string;
    buttonIcon?: string;
    url: string;
    state?: any;
  };
};

export type CalendarEditEventInfo = {
  id: string;
  minDate?: string;
  maxDate?: string;
};

export interface CalendarAddEventProps {
  event?: CalendarAddEventInfo;
  onUpdated: (isNew: boolean, event: CalendarEventModel) => void;
  onCancel: () => void;
}

export interface CalendarAddEventParticipantFormModel {
  userId: string;
  firstName: string;
  surname: string;
  type: ParticipantType;
}

export interface CalendarAddEventFormModel {
  name: string;
  start: Date | undefined;
  end: Date | undefined;
  allDay: boolean;
  description: string;
  eventType?: string;
  participants: CalendarAddEventParticipantFormModel[];
}

export const defaultCalendarAddEventFormSchema: CalendarAddEventFormModel = {
  name: '',
  start: new Date(),
  end: new Date(),
  allDay: false,
  description: '',
  eventType: undefined,
  participants: [],
};

export const CalendarAddEventFormSchema = yup.object().shape({
  name: yup.string().required(),
  //body: yup.string().required(),
  start: yup.date().required(),
  end: yup
    .date()
    .required()
    .test('end', 'Start can not be after end', function (value?: Date) {
      if (!value) return false;
      const { start } = (this as any).parent;
      return start.getTime() <= value.getTime();
    }),
});

export interface CalendarAddEventOptions {
  event: CalendarAddEventInfo;
  onUpdated?: (isNew: boolean, event: CalendarEventModel) => void;
  onCancel?: () => void;
}

export interface CalendarEditEventOptions {
  event: CalendarEditEventInfo;
  onUpdated?: (event: CalendarEventModel) => void;
  onCancel?: () => void;
}
